import { Row, Col, Progress, Slider } from "antd";
import React, { useEffect, useState } from "react";

import "./style.scss";

const iconCollection = [
  "Aggression",
  "Health",
  "Magic",
  "Sturdiness",
  "Storage amount",
];

const renderValue = (value, name) => {
  switch (name) {
    case "Race":
      return value.map((el) => (
        <img
          className="race-image"
          src={`/images/race-icon/${el.toLowerCase()}.png`}
          alt="race"
        />
      ));
    case "Troop rarities":
      return (
        <div className="rarity-container">
          {value.map((el) => (
            <img
              className="race-image"
              src={`/images/rarity-icon/${el.toLowerCase()}.svg`}
              alt="race"
            />
          ))}
        </div>
      );
    default:
      return value.map((el) => (
        <div className="food-container">
          {el} <img className="food-image" src="/images/food.png" alt="food" />
        </div>
      ));
  }
};

const Stats = (props) => {
  const [link, setLink] = useState("/images/");
  const { name, value, max, init, base, isPlot } = props;

  useEffect(() => {
    setLink(`/images/detail-icon/${name.replace(" ", "")}.png`);
  }, [name]);
  return (
    <Row className="stat-item">
      {!isPlot && (
        <Col className="square">
          <img className="square-icon" src={link} alt="square" />
        </Col>
      )}
      <Col md={6} xs={3} className="stat-name">
        {name}
      </Col>
      {!isPlot && (
        <Col span={4} className="stat-value">
          {init}
        </Col>
      )}
      {!isPlot && (
        <Col span={4} className="stat-value">
          {base}
        </Col>
      )}
      <Col
        span={4}
        className={
          isPlot ? "stat-value current plot-stat" : "stat-value current"
        }
      >
        {Array.isArray(value) ? renderValue(value, name) : value}
      </Col>

      {!isPlot && (
        <Col span={4} className="stat-value">
          {max}
        </Col>
      )}
    </Row>
  );
};

export default Stats;
