import { Row, Col,Statistic } from "antd";
import React, { useMemo, useState, useEffect} from "react";
import "./style.scss";
import cliTruncate from "cli-truncate";
import CustomButtonField from "../../elements/button";
import { useHistory} from "react-router-dom";
import ReactGA4 from "react-ga4";


const { Countdown } = Statistic;

function CardGame(props) {
  const {address, def_deck, elo, userInfo, setShowModal, higherList,bonus} = props
  const [disabled, setDisabled] = useState(0)

  useEffect(() => {
   setDisabled(userInfo?.delay*1000 - Date.now())
  }, [userInfo])

  const history = useHistory();

  const handleClickMatch =() => {
    if(parseInt((userInfo?.time_fully_recovery_turn * 1000 - Date.now()) / 7200000) == 4) {
      setShowModal(true)
    } else {
      history.push(`/match-result?address=${address}&status=matching`)
      ReactGA4.event("underhand_match",address);

    }
  }

  const renderList = useMemo(()=>{
    const list = def_deck
    const length = def_deck?.length
    
    for(let i =0; i< 6 - length; i++) {
      list.push({
        image:"/images/empty-item-game.svg",
        score: 0
      })
    }

    return list.map((item)=>(
          <Col >
            <img className="image-item" src={item.image}/>
            <div className="score-item">Score: {item.score || 0}</div>
          </Col>
        ))
  },[def_deck])

  const onFinish = ()=>{
    setDisabled(0)
  }

  const renderListItem = () => {
    return(
      <Row className="card-game__list">
        {renderList}

      </Row>
    )
  }


  return (
    <div className="card-game">
      {userInfo?.win_streak > 4 && higherList &&<div>
        <img className="win-treak-tag" src="/images/expired-icon.svg"/>
        <div className="win-treak-text">Bonus +{userInfo?.win_streak < 9 ? 1 : 2} ELO</div>
      </div>}
      <div className="card-game__header">
        {/* <div>Owner: {address && cliTruncate(address, 12, { position: "middle" })}</div> */}
        <div className="elo-text">Win: +{elo > userInfo?.elo ? 6 + bonus : 2} ELO</div>
      </div>
      {renderListItem()}
      <CustomButtonField
        classNames="btn-card"
        color="green"
        size="medium"
        onClick={handleClickMatch}
        disabled={disabled > 0}
      >
        {"Match"}
        
        {disabled > 0 &&<><Countdown
            className="time-countdown"
            value={disabled + Date.now()}
            format={`ss`}
            onFinish={onFinish}
          />s</>}
      </CustomButtonField>

      
    </div>
  );
}

export default CardGame;
