import React, { useEffect, useState } from "react";
import { Pagination, Col, Tabs } from "antd";
import { withContext } from "../../../../hook/AppContext";
import {
  getAllCollection,
  getListOrder,
  getListBundle,
  getListGame,
  getListItems,
  getListHistoryHomepage
} from "../../../../api";
import { getUser } from "../../../../ultis/common";
import ProductItem from "../product-item";
import { ACTION_SEND_TRANSACTION} from "../../../../constants";
import { useUIContext } from "../../../../hook/AppContext";


const DEFAULT_TAB = {
  bundle: "Bundles",
  items: "Items",
};


function TableItem(props) {
  const {title, isHistory} = props
  const {history} =  useUIContext();
  const { TabPane } = Tabs;
  const [activeTab, setActiveTab] = useState();
  const [listItem, setListItem] = useState({});
  const [dataCol, setDataCol] = useState([]);
  const [dataGame, setDataGame] = useState([]);
  const [listCol, setListCol] = useState([]);
  const [paging, setPaging] = useState({});
  const [totalItem, setTotalItem] = useState();

  useEffect(() => {
    const fn = async () => {
      const resGame = await getListGame();
      setDataGame(resGame);
      const resCol = await getAllCollection({
        game_id: resGame[0]._id,
      });
      let listNFT = {};
      const colection = {};
      let infoNFT = [];
      let filter=[];
      let page = {};
      let idCollection
      idCollection = resCol.find(el => !el.is_erc721)?._id
      resCol.map((el) => {
        if (el.name === "Mongens") {
          localStorage.setItem("MongenContract", el.contract_address);
        }
        if (el.is_erc721 && !el.operator_nft && el.name != "Blueprints" && el.on_home_page) {
          page[el._id] = {
            currentPage: 1
          };
          colection[el._id] = el;
          listNFT[el._id] = <></>;
        } else {
          if (idCollection && !el.is_erc721){
            infoNFT.push(el)
            filter.push({
              type: "Type",
              name: el.name.toUpperCase(),
              contractAddress: el.contract_address
            })
            colection[idCollection] = {
              name: "Items",
              icon_image: "",
              _id: idCollection,
              infoNFT: infoNFT,
              is_erc721: el.is_erc721
            };
            page[idCollection] = {
              currentPage: 1
            };
            listNFT[idCollection] = <></>;
          }
        }
      });
  
      const defaultActiveTab = resCol.find(el => el.on_home_page)
      setActiveTab(defaultActiveTab?._id);
      setListItem(listNFT);
      setPaging(page);
      setDataCol(colection);
    }
    fn();
  }, []);

  useEffect(() => {
    renderListCol();
  }, [dataCol, listItem]);

  useEffect(() => {
    if (Object.keys(dataCol).length) {
      if(isHistory){
        renderHistory()
      } else renderList();
    }
  }, [dataCol, activeTab, paging]);

  const onChangePage = (value) => {
    setPaging((prevState) => {
      let newSta = Object.assign({}, prevState);
      newSta[activeTab].currentPage = value;
      return newSta;
    });
  };

  const renderListCol = async () => {
    let results = [];
    for (const key in dataCol) {
      let el = dataCol[key];
      results.push(
        <TabPane
          className="home-table-item"
          tab={
            <>
              <img className="icon-tab" src={`/images/collection-icon/${el.name?.toLowerCase()}${el.name?.toLowerCase() == "bundles" ? '.png': '.svg'}`} />{el.name}
            </>
          }
          key={el._id}
        >
          <div className="list-item">
            {listItem[el._id]}
          </div>
              
        </TabPane>
      );
    }
    setListCol(results);
  };

  const renderList = async () => {
    const list =  dataCol[activeTab]?.list_contract?.map(el => el.address)
    const params = {
      contract_address: list,
      page: paging[activeTab]?.currentPage,
      limit: 11,
    };
    let listOrder;
    switch (dataCol[activeTab]?.name) {
      case DEFAULT_TAB.bundle:
        params.contract_address = null;
        params.game_id = dataGame[0]._id;
        listOrder = await getListBundle(params);
        break;
      case DEFAULT_TAB.items:
        params.game_id = dataGame[0]._id;
        listOrder = await getListItems(params);
        break;
      default:
        listOrder = await getListOrder(params);
        break;
    }
    const comp = listOrder?.data?.map((el, index) => {
      const isErc721 = !dataCol[activeTab]?.is_erc721;
      let item = el
      if(dataCol[activeTab]?.name == DEFAULT_TAB.bundle){
        const nft_data = {
          image: "/images/collection-icon/bundles.png",
          quantity: el?.nft_data,
          name: "Bundle #" + el.bundle_id
        }
        item = {
          ...item,
          nft_data: nft_data
        }
      }
      if (isErc721 && dataCol[activeTab]?.name != DEFAULT_TAB.bundle){
        const collection = dataCol[activeTab]?.infoNFT?.find(item => item.list_contract.find(item => item.address?.toLowerCase() == el.token_address.toLowerCase()))
        const nft_data = {
          name: collection?.name,
          image: collection?.image
        }
        item = {
          ...item,
          nft_data: el.nft_data ? el.nft_data : nft_data,
        }
      }  

      return (
        <ProductItem 
          {...el}
          {...item}
          type={dataCol[activeTab]?.name}
          token_id={el?.nft_data?.nft_info?.token_id}
          isItems={isErc721}
          renderList={renderList}
          time={el?.last_update_time}
        />
      );
    });
    setListItem((prevState) => {
      let newSta = Object.assign({}, prevState);
      newSta[activeTab] = comp;
      return newSta;
    });
  };

  const renderHistory = async () => {
    const list =  dataCol[activeTab]?.list_contract?.map(el => el.address)
    const params = {
      contract_address: list,
      page: paging[activeTab]?.currentPage,
      limit: 10,
    };
    switch (dataCol[activeTab]?.name) {
      case DEFAULT_TAB.bundle:
        params.contract_address = null;
        params.type = ACTION_SEND_TRANSACTION.buyBundle
        break;
      case DEFAULT_TAB.items:
        params.is_erc721 = 0
        params.type = ACTION_SEND_TRANSACTION.buy
        params.contract_address = null;
        break;
      default:
        params.type = ACTION_SEND_TRANSACTION.buy
        break;
      
    }
    const listOrder  = await getListHistoryHomepage(params);
    setTotalItem(listOrder?.metadata?.total);
    const comp = listOrder?.data?.map((el, index) => {
      const isErc721 = !dataCol[activeTab]?.is_erc721;
      let item = el
      if(dataCol[activeTab]?.name == DEFAULT_TAB.bundle){
        const nft_data =[ {
          image: "/images/collection-icon/bundles.png",
          quantity: el?.data,
        }]
        item = {
          ...item,
          nft_data: nft_data
        }
      }

      if (isErc721 && dataCol[activeTab]?.name != DEFAULT_TAB.bundle){
        const collection = dataCol[activeTab]?.infoNFT?.find(item => item.list_contract.find(item => item.address?.toLowerCase() == el.token_address.toLowerCase()))
        const nft_data = [{
          name: collection?.name,
          image: collection?.image
        }]
        item = {
          ...item,
          nft_data: nft_data
        }   
      }  

      return (
        <ProductItem 
          {...el}
          {...item}
          nft_data= {(isHistory && dataCol[activeTab]?.name != DEFAULT_TAB.bundle && !isErc721)  ? item.data[0] : item.nft_data[0]}
          type={dataCol[activeTab]?.name}
          token_id={el?.nft_data?.nft_info?.token_id}
          isItems={isErc721}
          isHistory={isHistory}
          renderList={renderHistory}
          time={el?.last_update_time}
        />
      );
    });
    setListItem((prevState) => {
      let newSta = Object.assign({}, prevState);
      newSta[activeTab] = comp;
      return newSta;
    });
  };

  const onChangeTab = (value) => {
    setActiveTab(value);
  };

  const goToP2PMarket = () => {
    history.push('/p2p-market')
  }


  return (
    <Col xs={24} md={12} className={isHistory ?  "table-item history" : "table-item"}>
      {title}
      <Tabs
          activeKey={`${activeTab}`}
          onChange={onChangeTab}
          className={"tab-home"}
          destroyInactiveTabPane={true}
        >
          {listCol}
      </Tabs>
      {isHistory ? <Pagination simple defaultCurrent={1} current={paging[activeTab]?.currentPage} total={totalItem > 100 ? 100:  totalItem} onChange={onChangePage}/> :
      <div onClick={goToP2PMarket} className="home-container__view">View more on marketplace <img src="/images/arrow-right-gold.svg"/> </div>}
    </Col>
    
 );
}

export default withContext(TableItem);
