import { useWeb3React } from "@web3-react/core";
import { Tabs, message } from "antd";
import React, { useEffect, useState } from "react";
import Web3 from "web3";
import * as AuthAPI from "../../../api/auth";
import CustomModal from "../../../components/elements/modal";
import {
  DEFAULT_CONNECT_TYPE,
  USER_WALLET_TYPE,
  signMessage,
  walletConnector,
} from "../../../constants";
import { useUIContext } from "../../../hook/AppContext";
import { useWindowResizeMobile } from "../../../hook/ultis.hook";
import { checkTypeWallet } from "../../../ultis/common";
import keplrService from "../../../ultis/keplr";
import "./style.scss";

function ConnectWallet(props) {
  const {
    isShowingLoginPopup,
    setIsShowingLoginPopup,
    setAuthToken,
    setRefreshToken,
    setUser,
    setStatusModal,
    isAuthenticatedUser,
    history,
    chains,
  } = useUIContext();
  const { TabPane } = Tabs;
  const [accounts, setAccounts] = useState([]);
  const [formData, setFormData] = useState({});
  const [activeTab, setActiveTab] = useState();
  const [isMobile] = useWindowResizeMobile(576);
  const { activate, library, account } = useWeb3React();

  useEffect(() => {
    if (chains) setActiveTab(chains[0]?._id);
  }, [chains]);

  const getSignMessage = async (address) => {
    const res = await AuthAPI.getNonce({ address });
    if (res.data > 0) {
      return `${signMessage} Nonce: ${res.data}`;
    }
    return `${signMessage}`;
  };

  const connectMetamask = async (type) => {
    if (!window.ethereum) {
      // message.warning("You have to install Metamask extension!!");
      setStatusModal({
        isChangeNet: {
          type: true,
          title: "Login failed",
          value: `You have to install Metamask extension!`,
        },
      });
      return;
    }

    const accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
    if (accounts[0]) {
      setAccounts(accounts);
    }
    // const chainInfo = chains?.find(el => el?._id == activeTab)
    const web3 = new Web3(Web3.givenProvider);
    // const chainId = await web3.eth.net.getId();
    try {
      // let check = await networkValidation({
      //   chainId,
      //   requiredChainNetwork: chainInfo,
      //   isMetamask: true,
      //   isLogin: true
      // });
      // if (check){
      let mess = await getSignMessage(accounts[0]);
      let sig1 = await web3.eth.personal.sign(mess, accounts[0]);
      if (sig1) {
        const dataLogin = {
          address: accounts[0],
          signature: sig1,
          type: DEFAULT_CONNECT_TYPE.metamask,
        };
        setFormData(dataLogin);
        handleLoginEx(dataLogin);
      }
      // }
    } catch (err) {
      message.error(err.message);
    }
  };

  const connectAura = async (type) => {
    if (!window.keplr) {
      // message.warning("You have to install Metamask extension!!");
      setStatusModal({
        isChangeNet: {
          type: true,
          title: "Login failed",
          value: `You have to install Keplr Wallet extension!`,
        },
      });
      return;
    }

    const account = await keplrService.connect("KEPLR");
    setAccounts([account]);
    // const chainId = await web3.eth.net.getId();
    try {
      let mess = await getSignMessage(account);
      let sig1 = await keplrService.signMessage(account, mess);
      if (sig1) {
        const dataLogin = {
          address: account,
          signature: sig1,
          type: DEFAULT_CONNECT_TYPE.keplrAura,
        };
        setFormData(dataLogin);
        handleLoginEx(dataLogin);
      }
      // }
    } catch (err) {
      message.error(err.message);
    }
  };

  const connectWalletCon = async () => {
    // message.info("Coming soon!!!")
    // return

    try {
      // Create a connector
      let walletConnect = walletConnector;
      // const chainInfo = chains?.find(el => el?._id == activeTab)
      // switch (chainInfo?.sort_name) {
      //   case "BSC":
      //     walletConnect = walletConnector
      //     break;
      //   case "AVAX":
      //     walletConnect = walletConnectorAvax
      //     break;
      //   case "OKEX":
      //     walletConnect = walletConnectorOkex
      //   default:
      // }
      await activate(walletConnect);
    } catch (err) {}
  };

  useEffect(() => {
    if (library) {
      signWalletmess(library);
    }
  }, [library]);

  const signWalletmess = async (connector) => {
    try {
      const chainInfo = chains?.find((el) => el?._id == activeTab);
      let type = USER_WALLET_TYPE.walletConnect;
      // switch (chainInfo?.sort_name) {
      //   case "BSC":
      //     type = USER_WALLET_TYPE.walletConnect
      //     break;
      //   case "AVAX":
      //     type = USER_WALLET_TYPE.walletConnectAvax
      //     break;
      //   case "OKEX":
      //     type = USER_WALLET_TYPE.walletConnectOkex
      //     break;
      //   default:
      // }
      let mess = await getSignMessage(account);
      let sig1 = await connector.eth.personal.sign(mess, account);
      if (sig1) {
        const dataLogin = {
          address: account,
          signature: sig1,
          type: type,
        };
        setFormData(dataLogin);
        handleLoginEx(dataLogin);
      }
    } catch (err) {
      message.error(err.message);
    }
  };

  useEffect(() => {
    let chainInfo;
    if (chains?.length) {
      switch (formData.type) {
        case USER_WALLET_TYPE.metamask:
        case USER_WALLET_TYPE.walletConnect:
          chainInfo = chains?.find((el) => el?.sort_name.includes("BSC"));
          break;
        case USER_WALLET_TYPE.terraStation:
        case USER_WALLET_TYPE.walletConnectTerra:
          chainInfo = chains?.find((el) => el?.sort_name.includes("Terra"));
          break;
        case USER_WALLET_TYPE.metamaskAvax:
        case USER_WALLET_TYPE.walletConnectAvax:
          chainInfo = chains?.find((el) => el?.sort_name.includes("AVAX"));
          break;
        case USER_WALLET_TYPE.metamaskOkex:
        case USER_WALLET_TYPE.walletConnectOkex:
          chainInfo = chains?.find((el) => el?.sort_name.includes("OKEX"));
          break;
        case USER_WALLET_TYPE.keplrAura:
          chainInfo = chains?.find((el) => el?.sort_name.includes("Aura"));
          break;
        default:

        // code block
      }
    }
    if (formData.address && chainInfo) {
      setUser({
        address: formData.address?.toLowerCase(),
        chainInfo,
        walletType: checkTypeWallet(chainInfo?.sort_name),
        walletCon: formData.type,
      });
    }
  }, [formData, chains]);

  const onChangeTab = (value) => {
    setActiveTab(value);
  };

  const handleLoginEx = async (data) => {
    try {
      const res = await AuthAPI.connectWallet(data);
      setAuthToken(res.access_token);
      setRefreshToken(res.refresh_token);
      setIsShowingLoginPopup(false);
      history.push("/home");
    } catch (err) {
      message.error("Login Failed!");
    }
  };

  return (
    <CustomModal
      visible={true}
      footer={null}
      onCancel={() => setIsShowingLoginPopup(false)}
      className={`dark-modal connect-wallet-modal`}
      closeIcon={<></>}
    >
      <div className="popup-content">
        <div className="title-popup">Connect wallet</div>
        <div className="wallet-box-line"></div>
        <div
          className="wallet-connect-btn"
          onClick={() => {
            setIsShowingLoginPopup(false);
            connectMetamask();
          }}
        >
          <img src="/images/metamask-icon.svg"></img>
          <div className="wallet-type">Metamask</div>
          <img className="wallet-arrow" src="/images/arrow-right.svg"></img>
        </div>
        <div
          className="wallet-connect-btn"
          onClick={() => {
            setIsShowingLoginPopup(false);
            connectAura();
          }}
        >
          <img src="/images/keplr.png"></img>
          <div className="wallet-type">Keplr & Leap & C98</div>
          <img className="wallet-arrow" src="/images/arrow-right.svg"></img>
        </div>
        <div
          className="wallet-connect-btn"
          onClick={() => {
            setIsShowingLoginPopup(false);
            connectWalletCon();
          }}
        >
          <img className="wallet-icon" src="/images/wallet-connect.svg"></img>
          <div className="wallet-type">WalletConnect</div>
          <img className="wallet-arrow" src="/images/arrow-right.svg"></img>
        </div>
      </div>
    </CustomModal>
  );
}

export default ConnectWallet;
