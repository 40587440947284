import React, { useEffect, useState } from "react";
import { Row, Col, Button, Form } from "antd";
import CustomInput from "../../../../elements/input";
import "./style.scss";
import useBlockchainFunc from "../../../../../ultis/blockchain";
import { withContext } from "../../../../../hook/AppContext";
import { formatInputNumber } from "../../../../../ultis/common";


function CheckoutBox(props) {
  const { orderInfor, t, type, form, rules } = props;
  const [totalBuy, setTotalBuy] = useState(0);
  const [total, setTotal] = useState(0);
  const { getTotalBuyBox } = useBlockchainFunc();
  const onChangeMaxItem = async () => {
    form.setFieldsValue({ amount: orderInfor.maxBuyPerAddress});
    setTotal(orderInfor.maxBuyPerAddress * orderInfor.price);
  };
  const onChangeQuantity = async (e) => {
    const amount = formatInputNumber(e.target.value?.toString(), 3,10, orderInfor.maxBuyPerAddress)
    form.setFieldsValue({ amount: amount })
    setTotal(amount * orderInfor.price);
  };
  useEffect(() => {}, [total]);


  return (
    <>
      <Row className="amount-row">
        <Col span={18} className="left-col">
          Amount
          <Form.Item className="m-0 mb-0" name="amount" rules={rules.amount}>
            <CustomInput
              classNames={["amount-item"]}
              min={1}
              step={1}
              // max={orderInfor.maxBuyPerAddress - +totalBuy}
              onChange={onChangeQuantity}
              disabled={type === "open-box"}
              type="number"
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Button
            className="max-button"
            onClick={onChangeMaxItem}
            type="button"
          >
            {"Max"}
          </Button>
        </Col>
      </Row>

      <div className="item-box offer-modal-box checkout-box-item">
        <div className="info-item">
          <div className="name">Price</div>
          <div className="value d-block upper-text">
            <img
              className="rune currency-icon"
              src={
                `/images/token/${orderInfor?.currency?.toLowerCase()}.svg`
              }
            ></img>
            {`${orderInfor?.price} ${orderInfor?.currency}`}
          </div>
        </div>
      </div>
      <div className="estimated-amount">
        {" "}
        Estimated amount
        <div className="upper-text">
          {total} {orderInfor?.currency}
        </div>
      </div>
    </>
  );
}

export default withContext(CheckoutBox);
