import React, { useEffect, useState, useMemo, useContext } from "react";
import { Row, Col, Popover } from "antd";
import { withContext } from "../../../../../hook/AppContext";
import { useWindowResizeMobile } from "../../../../../hook/ultis.hook";

function TroopsTab(props) {
  const { troopInfos, renderLinkImage } = props;
  const [isMobile] = useWindowResizeMobile(891);

  const renderContent = (el) => {
    const { additionIcons, description, image, name, stats } = el;
    return (
      <div className="popover-content">
        {name}
        <div className="popover-container">
          <Row>
            <Col span={12} className="popover-container__left">
              <img src={renderLinkImage(image)} className="detail-image" />
              <Row className="detail-attribute">
                {additionIcons &&
                  additionIcons.map((val) => (
                    <img
                      src={renderLinkImage(val)}
                      className="detail-attribute__icon"
                    />
                  ))}
              </Row>
            </Col>
            <Col span={12}>
              {Object.values(stats).map(
                (el, index) =>
                  el != null && (
                    <Row className="popover-container__right">
                      <Col span={12}>
                        <img
                          className="skill-troop"
                          src={`/images/skill-troop/${Object.keys(stats)[
                            index
                          ]?.toLowerCase()}.png`}
                        />
                      </Col>
                      <Col span={12}>{el}</Col>
                    </Row>
                  )
              )}
            </Col>
          </Row>
          <div className="popover-container__description">{description}</div>
        </div>
      </div>
    );
  };

  const renderSkills = (el) => {
    if (!el?.skills) return;
    let listAb = [];
    el?.skills.forEach((val) => {
      let item = (
        <Col className="skill-item">
          <Popover
            placement="topLeft"
            trigger="hover"
            content={renderContent(val)}
          >
            <div>
              <img
                src={renderLinkImage(val?.image)}
                className="troops-skill-image"
              />
            </div>
          </Popover>
        </Col>
      );
      listAb.push(item);
    });
    return listAb;
  };

  const renderAttri = (el) => {
    return (
      <Col xs={24} className="troops-container">
        <Row className="troops-attribute">
          <Row className="troops-icon">
            <img src={`/images/detail-icon/Health.png`} alt="hp" />
            {Math.round(el.Health)}
          </Row>
          <Row className="troops-icon">
            <img src={`/images/detail-icon/Sturdiness.png`} alt="stu" />
            {Math.round(el.Sturdiness)}
          </Row>
          <Row className="troops-icon">
            <img src={`/images/detail-icon/Magic.png`} alt="mag" />
            {Math.round(el.Magic)}
          </Row>
          <Row className="troops-icon">
            <img src={`/images/detail-icon/Aggression.png`} alt="agg" />
            {Math.round(el.Aggression)}
          </Row>
        </Row>
        <Row className="troops-cost">
          Cost
          <div>
            <img src="/images/egg-icon.svg" alt="egg" />
            {el.training_cost}
          </div>
        </Row>
        <Row className="troops-cost">
          Time
          <div>{el.training_time}s</div>
        </Row>
      </Col>
    );
  };

  const renderRarity = (el) => {
    const rarity = el?.attributes[0]?.value;
    return (
      <img
        className="troop-item-rarity"
        src={`/images/rarity-icon/${rarity?.toLowerCase()}.svg`}
      />
    );
  };

  const renderTroopsItem = (el) => {
    return (
      <div className="troop-item">
        {el.name}
        <Row className="troop-item-content">
          {renderRarity(el)}
          <img className="troop-item-image" src={renderLinkImage(el.image)} />
          <Col>{renderSkills(el)}</Col>
          {!isMobile && renderAttri(el)}
        </Row>
        {isMobile && renderAttri(el)}

        <div className="troop-item-description">{el.description}</div>
      </div>
    );
  };

  return (
    <div className="description-tab">
      {troopInfos?.map((el) => renderTroopsItem(el))}
    </div>
  );
}

export default withContext(TroopsTab);
