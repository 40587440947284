import React, { useEffect, useContext } from "react";
import { Select } from "antd";
import "./style.scss";
import ThemeContext from "../../../hook/ThemeContext";

function CustomSelect({ children, ...props }) {
    const { theme, setTheme } = useContext(ThemeContext);

    useEffect(() => {
        const savedThemeLocal = localStorage.getItem("globalTheme");

        if (!!savedThemeLocal) {
            setTheme(savedThemeLocal);
        }
    }, []);

    return (
        <Select
            {...props}
            dropdownClassName={`theme--${theme} ${
                props?.dropdownClassName ?? ""
            }`}
            className={`theme--${theme} ${props?.className ?? ""}`}
        >
            {children}
        </Select>
    );
}

export default CustomSelect
