import React, { useEffect, useState } from "react";
import "./style.scss";
import { useHistory } from "react-router-dom";
import { Row, Col, Tabs } from "antd";
import S3Image from "../../../common/s3-image";
import { withContext } from "../../../../hook/AppContext";
import { getTokens } from "../../../../ultis/common";
import BoxItem from "./box-item";

const { TabPane } = Tabs;

function DetailPrimaryTemplate(props) {
  const { orderInfor, isAuthenticatedUser, statusModal } = props;
  const [nftData, setNftData] = useState(orderInfor[0]?.box_info);
  const history = useHistory();
  const [isShowingPopup, setIsShowingPopup] = useState();
  const [activeBox, setActiveBox] = useState(0);
  const [activeBoxData, setActiveBoxData] = useState(nftData[activeBox]);
  function changeTab(key) {
    setActiveBox(key);
  }

  const [leftBox, setLeftBox] = useState(
    orderInfor?.totalBuyed?.length >= 1
      ? activeBoxData?.maxBuyPerAddress - orderInfor?.totalBuyed[activeBox]
      : activeBoxData?.maxBuyPerAddress
  );

  useEffect(() => {
    setActiveBoxData(nftData[activeBox]);
  }, [activeBox]);

  useEffect(() => {
    let fn = async () => {
      if (statusModal?.isSuccess) {
        setIsShowingPopup(false);
      }
    };
    fn();
  }, [statusModal]);

  // const [remainingTime, setRemainingTime] = useState(
  //   activeBoxData?.end_time * 1000 - Date.now()
  // );
  // const [gainedTime, setGainedTime] = useState(
  //   Date.now() - activeBoxData.start_time * 1000
  // );
  // const [totalTime, setTotalTime] = useState(
  //   (activeBoxData.end_time - activeBoxData.start_time) * 1000
  // );

  useEffect(async () => {
    if (isAuthenticatedUser) {
      // const totalBuyBox = await getTotalBuyBox(activeBoxData.box_id);
      // setTotalBuy(totalBuyBox);
      // setRemainingTime(activeBoxData?.end_time * 1000 - Date.now());
      // setGainedTime(Date.now() - activeBoxData.start_time * 1000);
      // setTotalTime((activeBoxData.end_time - activeBoxData.start_time) * 1000);
    }
  }, [activeBoxData, leftBox]);

  const buyBoxSucessfully = (quantity) => {
    setLeftBox(leftBox - quantity);
    getTab();
  };

  const [changeLayout, setChangeLayout] = useState(window.innerWidth <= 500);

  useEffect(() => {
    if (window.innerWidth <= 500) {
      setChangeLayout(true);
    } else setChangeLayout(false);
  });

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  window.addEventListener("resize", () => {
    setWindowHeight(window.innerHeight);
  });

  const customTabBar = (el, index) => {
    return (
      <div className="about-tab-title">
        <img className={`egg-icon`} src={el?.image}></img>
        {el?.name}
      </div>
    );
  };

  const [tabData, setTabChange] = useState();
  const getTab = () => {
    if (!nftData) return;
    let listAb = [];
    const token = getTokens()?.find(
      (el) =>
        el?.contract_address.toLowerCase() ==
        orderInfor[0]?.payment_token?.toLowerCase()
    );
    nftData?.forEach((el, index) => {
      let item = (
        <TabPane id={"special"} tab={customTabBar(el, index)} key={index}>
          <BoxItem
            boxInfo={{ ...el, payment_token: token?.contract_address }}
            index={index}
            token={token?.sort_name}
            getTab={getTab}
            end_time={orderInfor[0]?.end_time}
            start_time={orderInfor[0]?.start_time}
          />
        </TabPane>
      );

      listAb.push(item);
    });
    setTabChange(listAb);
  };

  useEffect(() => {
    getTab();
  }, [activeBoxData, leftBox]);

  return (
    <div className="detail-box-container">
      {orderInfor[0]?.end_time <= Date.now() && (
        <div className="time-out">Time out! See you in the next pool.</div>
      )}
      <Row
        className="row-infor detail-primary"
        gutter={{ xs: 8, sm: 36, md: 40, lg: 50 }}
        justify={"center"}
      >
        <Col xs={24} sm={24} md={10} className="left-container">
          <div className={`col-fixed left-column left-detail`}>
            <div className="image-block">
              <S3Image src={nftData[activeBox]?.image} className="item-image" />
            </div>
          </div>
        </Col>

        <Col
          xs={24}
          sm={24}
          md={14}
          lg={14}
          xl={14}
          className="mb-100 right-column right-primary-col"
        >
          <div>
            <Tabs
              className="table-section"
              onChange={changeTab}
              type="card"
              tabBarStyle={{ width: "100%" }}
            >
              {tabData}
            </Tabs>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default withContext(DetailPrimaryTemplate);
