import "./style.scss";

import React, { useEffect, useState } from "react";
import { Slider, InputNumber } from "antd";


const SlideRange = (props) => {
  const { callback, name, type, value, setFilterValue, filterValue, _id } = props;
  const [priceMin, setPriceMin] = useState(value.min);
  const [priceMax, setPriceMax] = useState(value.max);
  const [lastChange, setLastChange] = useState(false)

  useEffect(() => {
    if(lastChange){
      let filterData = filterValue?.filterValue;
      let listEl = { [type]: [] };
      listEl[type].push(priceMin);
      listEl[type].push(priceMax)
      filterData = { ...listEl, ...filterData };
      let newStateFilter;
      setFilterValue((prevState) => {
        let newSta = Object.assign({}, prevState);
        newSta.filterValue = { _id, ...newSta.filterValue, ...filterData };
        newStateFilter = newSta;
        return newSta;
      });
      callback({
        ...newStateFilter?.sortValue[0],
        ...newStateFilter?.filterValue,
      });
      setLastChange(false)
    } 
    
  }, [priceMin, priceMax, lastChange])

  const onChangeRange = (value) => {
    setLastChange(false)
    setPriceMin(value[0])
    setPriceMax(value[1])
  }

  const onAfterChange = (value) =>{
    if (value) setLastChange(true)
  }

  const onChangeMin = (e) => {
    setLastChange(true)
    setPriceMin(typeof e === 'number' ? e : 0)
    
  }

  const onChangeMax = (e) => {
    setLastChange(true)
    setPriceMax(typeof e === 'number' ? e : 0)
  }

  return (
    <div className="filter-price">
      {name}
      <Slider 
        range 
        value={[priceMin, priceMax]}
        min={value.min}
        max={value.max} 
        onChange={onChangeRange} 
        className="price-range" 
        onAfterChange={onAfterChange}
      />
      <div className="input-range">
        <InputNumber
          min={value.min}
          max={value.max}
          value={priceMin}
          onChange={onChangeMin}
        />
        <div className="input-range__line"></div>
        <InputNumber
          min={value.min}
          max={value.max}
          value={priceMax}
          onChange={onChangeMax}
        />
      </div>

    </div>
  );
};
export default SlideRange;
