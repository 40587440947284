import { customAxios } from "../../ultis/custom-axios";

const endpoint = process.env.REACT_APP_SERVER_ENDPOINT;

export const connectWallet = (credentials) => {
    return customAxios({
        method: "post",
        url: `${endpoint}/auth/connect-wallet`,
        data: credentials,
    })
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            throw err;
        });
};

export const refreshJWTToken = (credentials) => {
    return customAxios({
        method: "get",
        url: `${endpoint}/auth/refreshtoken`,
        params: credentials,
    })
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            throw err;
        });
};

export const getNonce = async (data) => {
    return customAxios({
        method: "get",
        params: { address: data?.address },
        url: `${endpoint}/auth/get-nonce`,
    })
        .then((res) => res.data)
        .catch((err) => {
            throw err;
        });
};
