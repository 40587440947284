import React, { useEffect, useState } from "react";
import "./style.scss";
import MonItem from "./mon-item";
import { Row, Col } from "antd";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { matchGame, getHistoryGame } from "../../../api";
import { withContext } from "../../../hook/AppContext";
import cliTruncate from "cli-truncate";
import CustomButtonField from "../../elements/button";
import { useWindowResizeMobile } from "../../../hook/ultis.hook";

function MatchResult(props) {
    const {
        gameInfo,
        setGameInfo,
        location,
        user,
        history,
        address,
        onClickHome,
        getQueryUrl,
        getGameUserInfo,
        setStatusModal,
        setStatusTextModal,
    } = props;
    const [deckdata, setDeckdata] = useState({ deckDef: [], deckAtk: [] });
    const [isMatching, setisMatching] = useState(true);
    const [isMobile] = useWindowResizeMobile();

    useEffect(async () => {
        let gAddr = address;
        const query = queryString.parse(location.search, {
            ignoreQueryPrefix: true,
        });
        if (!address) {
            gAddr = query.address;
        }
        if (query?.status === "matching") {
            await getResult(gAddr);
        } else if (query?.status === "history") {
            await getHisory(query?.battle_id);
        } else {
            setStatusTextModal({fail: 'Match failed!'})
            setStatusModal({isFailed: true})
        }
        getGameUserInfo();
        return () => {};
    }, []);

    const splitToChunks = (array, parts) => {
        let result = [];
        for (let i = parts; i > 0; i--) {
            result.push(array.splice(0, Math.ceil(array.length / i)));
        }
        return result;
    };

    const getResult = async (address) => {
        try {
            setisMatching(true);
            const res = await matchGame({ address });
            setDeckdata(res);
            setisMatching(false);
            history.replace(`/match-result?address=${address}`);
        } catch (error) {
            console.log(error);
            setStatusTextModal({fail: 'Match failed!'})
            setStatusModal({isFailed: true})
        }
    };

    const getHisory = async (battle_id) => {
        const res = await getHistoryGame({
            season_id: gameInfo?.seasonId,
            battle_id,
        });
        const nDeckAtk = res[0]?.atk_deck.map((el) =>
            res[0].nft_atk_data.find((ele) => ele?._id === el)
        );
        const deckAtk = splitToChunks(nDeckAtk, +res[0]?.atk_deck.length / 3);
        const nDeckDef = res[0]?.def_deck.map((el) =>
            res[0].nft_def_data.find((ele) => ele?._id === el)
        );
        const deckDef = splitToChunks(nDeckDef, +res[0]?.def_deck.length / 3);
        const roundPoints = res[0]?.round_points;
        const points = {
            atkEloChange: res[0]?.atk_elo_change,
            defEloChange: res[0]?.def_elo_change,
        };
        const newData = { ...res[0], deckAtk, deckDef, roundPoints, points };
        setDeckdata(newData);
        setisMatching(false);
    };

    const checkRs = (rs) => {
        const { atkPoints, defPoints, atkRarityPoints, defRarityPoints } = rs;
        if (atkPoints === defPoints) {
            if (atkRarityPoints > defRarityPoints) {
              return <div className="vic">Victory</div>;
          } else if (atkRarityPoints === defRarityPoints) {
              return <div className="vic">Draw</div>; 
          } else {
              return <div className="fail">failure</div>;
          }
        }
        return atkPoints > defPoints ? <div className="vic">Victory</div> : <div className="fail">failure</div>;
    };

    const onClickhome = () => {
        if (onClickHome) {
            onClickHome();
        } else {
            history.push(
                `/nft-game/home?tab=${
                    getQueryUrl()?.status !== "history" ? "Home" : "History"
                }`
            );
        }
    };

    return (
        <>
            <div className="match-result-page">
                <div className="title-page">Match Result</div>
                <Row justify="center" data-aos="fade-up">
                    <Col sm={16} xs={24}>
                        <Row justify="space-between" className="row-result">
                            <Col span={9} className="">
                                <div className="address-block yl">
                                    {cliTruncate(
                                        deckdata?.atk_address ||
                                            user?.address ||
                                            "",
                                        12,
                                        { position: "middle" }
                                    )}
                                    {(
                                        deckdata?.atk_address ||
                                        user?.address ||
                                        ""
                                    ).toLowerCase() ===
                                        user?.address.toLowerCase() && " (You)"}
                                </div>
                            </Col>
                            <Col span={9} className="">
                                <div className="address-block red">
                                    {cliTruncate(
                                        getQueryUrl()?.address ||
                                            deckdata?.def_address ||
                                            "",
                                        12,
                                        {
                                            position: "middle",
                                        }
                                    )}
                                    {(
                                        getQueryUrl()?.address ||
                                        deckdata?.def_address ||
                                        ""
                                    ).toLowerCase() ===
                                        user?.address.toLowerCase() && " (You)"}
                                </div>
                            </Col>
                        </Row>
                        {deckdata?.deckDef.map((el, index) => (
                            <Row justify="space-between" className="row-result">
                                <Col span={9}>
                                    <div
                                        className="col-mon-items"
                                        data-aos="flip-right"
                                        data-aos-delay={400 * (index + 1)}
                                    >
                                        {deckdata?.deckAtk[index]?.map(
                                            (monitem) => (
                                                <MonItem
                                                    item={monitem}
                                                    key={monitem?._id}
                                                />
                                            )
                                        )}
                                    </div>
                                    {!isMobile &&
                                        deckdata?.roundPoints[index]
                                            ?.totalStrikeBonus.atkPoints +
                                            deckdata?.roundPoints[index]
                                                ?.totalBonus.atkPoints >
                                            0 && (
                                            <div className="txt-bonus yl left">
                                                {"Strike / Combo: Bonus +" +
                                                    (deckdata?.roundPoints[
                                                        index
                                                    ]?.totalStrikeBonus
                                                        .atkPoints +
                                                        deckdata?.roundPoints[
                                                            index
                                                        ]?.totalBonus
                                                            .atkPoints)}
                                            </div>
                                        )}
                                </Col>
                                <Col
                                    span={4}
                                    data-aos-delay={400 * (index + 1)}
                                    data-aos="zoom-in"
                                >
                                    <div className="round-numb-txt yl">
                                        Round {index + 1}
                                    </div>
                                    <div className="result-txt">
                                        {checkRs(
                                            deckdata?.roundPoints[index]
                                        )}
                                    </div>
                                    <div className="round-numb-txt">{`${deckdata?.roundPoints[index].atkPoints} - ${deckdata?.roundPoints[index].defPoints}`}</div>
                                </Col>
                                <Col
                                    span={9}
                                    data-aos="flip-right"
                                    data-aos-delay={400 * (index + 1)}
                                >
                                    <div className="col-mon-items">
                                        {deckdata?.deckDef[index]?.map(
                                            (monitem) => (
                                                <MonItem
                                                    item={monitem}
                                                    key={monitem?._id}
                                                />
                                            )
                                        )}
                                    </div>
                                    {!isMobile &&
                                        deckdata?.roundPoints[index]
                                            ?.totalStrikeBonus.defPoints +
                                            deckdata?.roundPoints[index]
                                                ?.totalBonus.defPoints >
                                            0 && (
                                            <div className="txt-bonus red right">
                                                {"Strike / Combo: Bonus +" +
                                                    (deckdata?.roundPoints[
                                                        index
                                                    ]?.totalStrikeBonus
                                                        .defPoints +
                                                        deckdata?.roundPoints[
                                                            index
                                                        ]?.totalBonus
                                                            .defPoints)}
                                            </div>
                                        )}
                                </Col>
                            </Row>
                        ))}
                    </Col>
                    {/* <Col span={6}>
            <Row justify="space-between">
              <Col>
                {!isMatching && (
                  <div
                    className={`rs-block ${
                      deckdata?.points?.atkEloChange > 0 ? "win" : "lose"
                    }`}
                  >
                    <div className={`point-cont`}>
                      <div className="numb-change">
                        {deckdata?.points?.atkEloChange > 0
                          ? "+" + deckdata?.points?.atkEloChange
                          : deckdata?.points?.atkEloChange}
                      </div>
                      <div className="text-elo">
                        Your ELO:{" "}
                        {+gameInfo?.elo}
                      </div>
                    </div>
                    <img
                      src={`/images/${
                        deckdata?.points?.atkEloChange > 0 ? "win" : "lost"
                      }-match-logo.png`}
                      className="img-result"
                      alt="rs"
                    />
                  </div>
                )}
              </Col>
            </Row>
          </Col> */}
                </Row>
                {!isMatching && (
                    <div
                        className="text-elo"
                        data-aos-delay={400 * 5}
                        data-aos="zoom-in"
                        data-aos-anchor-placement="top-bottom"
                    >
                        You{" "}
                        {((
                            getQueryUrl()?.address ||
                            deckdata?.atk_address ||
                            ""
                        ).toLowerCase() === user?.address.toLowerCase() ||
                            getQueryUrl()?.status !== "history") &&
                            (deckdata?.points?.atkEloChange >= 0
                                ? "Win +" + deckdata?.points?.atkEloChange
                                : "Lost " + deckdata?.points?.atkEloChange)}
                        {(
                            getQueryUrl()?.address ||
                            deckdata?.def_address ||
                            ""
                        ).toLowerCase() === user?.address.toLowerCase() &&
                            (deckdata?.points?.defEloChange >= 0
                                ? "Win +" + deckdata?.points?.defEloChange
                                : "Lost " +
                                  deckdata?.points?.defEloChange)}{" "}
                        ELO
                    </div>
                )}
                <CustomButtonField
                    classNames="btn-card btn-save"
                    color="green"
                    size="medium"
                    onClick={onClickhome}
                >
                    {!(getQueryUrl()?.status === "history")
                        ? "Go Home"
                        : "Go History"}
                </CustomButtonField>
            </div>
        </>
    );
}

export default withContext(MatchResult);
