import React, { useContext, useState } from "react";
import Rating from "react-rating";

import "./style.scss";

const Star = (props) => {
  const { onClick, max, initialRating, stop, readonly, className, fullSymbolStar } = props;
  const [lastStar, setLastStar] = useState(initialRating);
  const [renderKey, setRenderKey] = useState(0);

  const onClickStar = (value) => {
    if (value === lastStar) {
      setRenderKey(renderKey + 1);
      onClick(null);
      setLastStar(0);
    } else {
      onClick(value);
      setLastStar(value);
    }
  };

  return (
    <div key={renderKey} className={`star-container`}>
      <Rating
        emptySymbol={<img src="/images/star-empty.png" />}
        fullSymbol={fullSymbolStar ? <img src={`${fullSymbolStar}`} /> : <img src="/images/star-full.png" />}
        readonly={readonly}
        stop={stop ? stop : initialRating}
        initialRating={readonly ? initialRating : lastStar}
        onClick={onClickStar}
        className={className}

      />
    </div>
  );
};
export default Star;
