import React, { useEffect } from "react";
import "./style.scss";
import ConnectWalletTemplate from "../../components/templates/connect-wallet";
import SiteLayout from "../../layouts/site-layout";

function ConnectWallet(props) {
    return (
        <ConnectWalletTemplate {...props} />
    );
}

export default ConnectWallet;
