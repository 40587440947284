import React, { useMemo } from "react";
import "./style.scss";

function DNAItem(props) {
  const { name, value, rarity } = props;

  const renderColor = useMemo(() => {
    switch (value) {
      case "Common":
        return "silver";
      case "Uncommon":
        return "blue";
      case "Rare":
        return "yellow";
      case "Epic":
        return "red";
      case "Legendary":
        return "pink";
      default:
      // code block
    }
  }, [value]);

  return (
    <div className="dna-item">
      {rarity && (
        <img
          className="rarity-icon"
          alt="rarity"
          src={`/images/rarity-icon/${rarity?.toLowerCase()}.svg`}
        />
      )}
      {`${name || ""} ${value ? `${value}` : ``}`}
    </div>
  );
}

export default DNAItem;
