import "./style.scss";
import React, { useEffect, useState, useMemo } from "react";
import { Row, Pagination, Col, Tabs, Menu } from "antd";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import CustomDropdown from "../../../components/elements/dropdown-custom";
import FilterComponent from "../../common/filter-component";
import CardComponent from "../../common/card-component";
import {
  getInventory,
  getAllCollection,
  getAllNftOwner,
  getListGame,
} from "../../../api";
import CustomButtonField from "../../elements/button";
import { withContext } from "../../../hook/AppContext";
import useBlockchainFunc from "../../../ultis/blockchain";
import { useWindowResizeMobile } from "../../../hook/ultis.hook";
import { DEFAULT_ITEMS_PER_PAGE, WALLET_TYPE } from "../../../constants";
import { getBalanceItem, getUser, getContractFee } from "../../../ultis/common";
import CollectionFilterComponent from "../../common/collection-filter-component";
import { CardModal } from "../../common/card-modal";
import BridgeModal from "./modal-bridge";
import ConfirmModal from "./modal-confirm";

function InventoryTemplate(props) {
  const { setStatusModal, chains } = props;

  const [showModal, setShowModal] = useState(false);
  const [showBridgeModal, setShowBridgeModal] = useState(false);
  const [nftPut, setNftPut] = useState();
  const [showFilter, setShowFilter] = useState(false);
  const { TabPane } = Tabs;
  const history = useHistory();
  const [listCol, setListCol] = useState([]);
  const [dataCol, setDataCol] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [renderKey, setRenderKey] = useState(0);
  const [listItem, setListItem] = useState({});
  const [filterSort, setFilterSort] = useState({});
  const [paging, setPaging] = useState({});
  const [totalItem, setTotalItem] = useState();
  const [dataGame, setDataGame] = useState([]);
  const [isMobile] = useWindowResizeMobile(891);
  const { createOrder, convertNFT } = useBlockchainFunc();
  const [isConverting, setisConverting] = useState(false);
  const [listCheckConvert, setlistCheckConvert] = useState({});
  const [showCardModal, setShowCardModal] = useState(false);
  const [listCard, setlistCard] = useState([]);
  const [listAmount, setListAmount] = useState([]);
  const [isUpdateAmount, setIsUpdateAmount] = useState(false);

  const getAmountItem = async (nftInfo) => {
    let data = listAmount;
    const list = Object.values(dataCol);
    const item = list.find((el) => !el.is_erc721);

    if (item?.infoNFT) {
      const { infoNFT } = item;
      if (nftInfo) {
        let indexItem;
        data.find((ele, index) => {
          if (ele?.contract == nftInfo?.contract) {
            indexItem = index;
            return ele;
          }
        });
        const maxBalance = await getBalanceItem(
          getUser()?.address,
          nftInfo?.contract,
          nftInfo?.chain?.list_rpc ? nftInfo.chain.list_rpc[0] : "",
          nftInfo?.chain?.sort_name
        );
        if (maxBalance > 0) {
          data[indexItem] = {
            ...nftInfo,
            amount: maxBalance,
          };
        } else data.splice(indexItem, 1);
      } else {
        await Promise.all(
          infoNFT.map(async (el) => {
            await Promise.all(
              el.list_contract.map(async (item) => {
                if (item.list_rpc) {
                  const maxBalance = await getBalanceItem(
                    getUser()?.address,
                    item.address,
                    item.list_rpc[0],
                    item.chain_name
                  );
                  if (maxBalance > 0) {
                    data.push({
                      ...el,
                      chain: {
                        rpc: item.list_rpc[0],
                        list_rpc: item.list_rpc,
                        sort_name: item.chain_name,
                        payment_contract: item.payment_contract,
                        name: item.name,
                      },
                      amount: maxBalance,
                      contract: item.address,
                      static_image: true,
                    });
                  }
                }
              })
            );
          })
        );
      }
    }
    setIsUpdateAmount(true);
    setListAmount(data);
  };

  useEffect(() => {
    const fn = async () => {
      const query = queryString.parse(history.location.search, {
        ignoreQueryPrefix: true,
      });
      const resGame = await getListGame();
      setDataGame(resGame);
      const resCol = await getAllCollection({
        game_id: resGame[0]._id,
      });
      let listNFT = {};
      let page = {};
      const colection = {};
      let idCollection;
      let infoNFT = [];
      let activeTabId;
      idCollection = resCol.find((el) => !el.is_erc721)?._id;
      colection[0] = {
        name: "All",
        icon_image: "",
        _id: 0,
        is_erc721: true,
      };
      page[0] = {
        currentPage: 1,
      };
      listNFT[0] = <></>;
      resCol.map((el) => {
        if (el.name !== "Bundles") {
          if (el.is_erc721) {
            page[el._id] = {
              currentPage: 1,
            };
            colection[el._id] = el;
            listNFT[el._id] = <></>;
          } else {
            infoNFT.push(el);
            if (idCollection) {
              colection[idCollection] = {
                name: "Items",
                icon_image: "",
                _id: idCollection,
                infoNFT: infoNFT,
                is_erc721: el.is_erc721,
              };
              page[idCollection] = {
                currentPage: 1,
              };
              listNFT[idCollection] = <></>;
            }
          }
          if (el.name == query?.tab) activeTabId = el._id;
        }
      });
      setActiveTab(activeTabId || 0);
      setPaging(page);
      setListItem(listNFT);
      setDataCol(colection);
    };
    fn();
  }, []);

  useEffect(() => {
    setFilterSort({});
  }, [renderKey]);

  useEffect(() => {
    renderListCol();
  }, [dataCol, listItem, showFilter, listAmount, listCheckConvert]);

  useEffect(async () => await getAmountItem(null, dataCol), [dataCol]);

  useEffect(async () => {
    if (Object.keys(dataCol).length && (isUpdateAmount || activeTab != 0)) {
      renderList();
      setIsUpdateAmount(false);
    }
  }, [
    dataCol,
    activeTab,
    filterSort,
    paging,
    isConverting,
    listCheckConvert,
    listAmount.length,
    isUpdateAmount,
  ]);

  useEffect(() => {
    const query = queryString.parse(history.location.search, {
      ignoreQueryPrefix: true,
    });
    let activeTabId = dataCol[Object.keys(dataCol)[0]]?._id;
    if (query?.tab) {
      for (const key in dataCol) {
        let el = dataCol[key];
        if (el.name == query?.tab) activeTabId = el._id;
      }
    }
    setActiveTab(activeTabId);
  }, [history.location.search]);

  const renderList = async (params) => {
    let listOrder;
    if (activeTab == 0) {
      listOrder = await getAllNftOwner({
        game_id: dataGame[0]._id,
        page: paging[activeTab].currentPage,
        limit: DEFAULT_ITEMS_PER_PAGE,
        chain: chains.map((el) => el._id),
      });
      setTotalItem(listOrder?.metadata?.total);
    } else {
      if (dataCol[activeTab]?.is_erc721) {
        const list = dataCol[activeTab]?.list_contract?.map((el) => el.address);
        listOrder = await getInventory({
          contract_address: dataCol[activeTab]?.contract_address,
          page: paging[activeTab]?.currentPage,
          limit: DEFAULT_ITEMS_PER_PAGE,
          ...filterSort[activeTab],
          contract_address:
            filterSort[activeTab]?.contract_address?.length > 0
              ? filterSort[activeTab]?.contract_address
              : list,
        });
        setTotalItem(listOrder?.metadata?.total);
      } else {
        listOrder = {
          data: listAmount,
        };
      }
    }
    const listNfts =
      activeTab == 0 && paging[activeTab].currentPage == 1
        ? listAmount?.concat(listOrder?.data)
        : listOrder?.data;
    const comp = listNfts.map((el, index) => (
      <Col>
        <CardComponent
          key={index}
          cardFooter={() => renderCardFooter(el)}
          nft_data={el}
          isTabAll={activeTab == 0}
          token_id={el.nft_info ? el.nft_info?.token_id : null}
          quantity={el.amount}
          isItems={el.is_erc721 === false}
          type={dataCol[activeTab]?.name}
          renderList={renderList}
          {...el}
        />
      </Col>
    ));
    setListItem((prevState) => {
      let newSta = Object.assign({}, prevState);
      newSta[activeTab] = comp;
      return newSta;
    });
  };
  const onChangeTab = (value) => {
    setisConverting(false);
    setRenderKey(renderKey + 1);
    setActiveTab(value);
    setIsUpdateAmount(true);
    const name = dataCol[value]?.name;
    history.push(`/inventory?tab=${name}`);
  };

  const onChangePage = (value) => {
    setIsUpdateAmount(true);
    setPaging((prevState) => {
      let newSta = Object.assign({}, prevState);
      newSta[activeTab].currentPage = value;
      return newSta;
    });
  };

  const renderConfirm = useMemo(
    () => (
      <ConfirmModal
        getContractFee={getContractFee}
        type="put"
        nft={nftPut}
        onClick={createOrder}
        showModal={showModal}
        setShowModal={setShowModal}
        setStatusModal={setStatusModal}
        callBack={nftPut?.is_erc721 === false ? getAmountItem : renderList}
        isItems={nftPut?.is_erc721 === false}
      />
    ),
    [nftPut, showModal]
  );

  const renderBridge = useMemo(
    () => (
      <BridgeModal
        getContractFee={getContractFee}
        type="put"
        nft={nftPut}
        onClick={createOrder}
        showModal={showBridgeModal}
        setShowModal={setShowBridgeModal}
        setStatusModal={setStatusModal}
        callBack={nftPut?.is_erc721 === false ? getAmountItem : renderList}
        isItems={nftPut?.is_erc721 === false}
        chains={chains}
      />
    ),
    [nftPut, showBridgeModal]
  );

  const renderListCol = async (dataFilter) => {
    if (dataFilter?._id) {
      setFilterSort((prevState) => {
        let newSta = Object.assign({}, prevState);
        newSta[dataFilter?._id] = dataFilter;
        return newSta;
      });
    }
    let results = [];

    for (const key in dataCol) {
      let el = dataCol[key];
      if (el.name === "Bundles") continue;
      if (isMobile) {
        if (el._id === activeTab) {
          results =
            totalItem > 0 || listAmount.length > 0 ? (
              <>
                <Row className="list-item" xs={24}>
                  {listItem[el._id]}
                </Row>
                {totalItem / DEFAULT_ITEMS_PER_PAGE > 1 &&
                  dataCol[activeTab]?.is_erc721 && (
                    <Pagination
                      defaultCurrent={1}
                      total={totalItem}
                      pageSize={DEFAULT_ITEMS_PER_PAGE}
                      current={paging[activeTab].currentPage}
                      onChange={onChangePage}
                      showSizeChanger={false}
                    />
                  )}
              </>
            ) : null;
        }
      } else {
        results.push(
          <TabPane
            className="market-cont"
            tab={
              <>
                {el._id != 0 && (
                  <img
                    src={`/images/collection-icon/${el.name?.toLowerCase()}.svg`}
                    alt="chain"
                  />
                )}{" "}
                {el.name}
              </>
            }
            key={el._id}
          >
            {/* <div className={!showFilter ? "collection-name ml-91" :"collection-name"}>{el.name} <div>{totalItem} items </div> </div> */}
            <Row
              className={
                !showFilter ? "list-item list-item__no-filter" : "list-item"
              }
              gutter={12}
            >
              {listItem[el._id]}
            </Row>
            {totalItem / DEFAULT_ITEMS_PER_PAGE > 1 &&
              dataCol[activeTab]?.is_erc721 && (
                <Pagination
                  defaultCurrent={1}
                  total={totalItem}
                  pageSize={DEFAULT_ITEMS_PER_PAGE}
                  current={paging[activeTab].currentPage}
                  onChange={onChangePage}
                  showSizeChanger={false}
                />
              )}
          </TabPane>
        );
      }
    }
    setListCol(results);
  };

  const handleShowModal = (nft) => {
    setShowModal(true);
    setNftPut(nft);
  };

  const handleShowBridgeModal = (nft) => {
    setShowBridgeModal(true);
    setNftPut(nft);
  };

  const changeTab = (value) => {
    const name = dataCol[value]?.name;
    history.replace(`/inventory?tab=${name}`);
  };

  const renderCardFooter = (nft_data) => {
    const isBox = nft_data?.token_uri?.includes("get-treasury-box");
    const isBinance =
      !nft_data?.static_image && !nft_data?.is_mongen && !nft_data.is_decor;
    const isAura = nft_data?.chain?.sort_name == "Aura";
    const type = isBox ? "BOX" : "BINANCE";
    if (!isConverting) {
      return (
        <Row className="btn-container">
          <Col xs={!(isBox || isBinance) ? 12 : 12}>
            <CustomButtonField
              classNames="btn-card"
              color="green"
              // size={!(isBox || isBinance) && "medium"}
              onClick={() => handleShowModal(nft_data)}
            >
              {"PUT ON SALE "}
            </CustomButtonField>
          </Col>
          {(!(isBox || isBinance) && !isAura)&& <Col xs={12}>
            <CustomButtonField
              classNames="btn-card"
              color="brown"
              onClick={() => handleShowBridgeModal(nft_data)}
            >
              {"BRIDGE"}
            </CustomButtonField>
          </Col>}

          {isBox && (
            <Col xs={12}>
              <CustomButtonField
                classNames="btn-card "
                color="brown"
                onClick={() => onConfirmConvert(nft_data?.nft_info, type)}
              >
                {"UNBOX"}
              </CustomButtonField>
            </Col>
          )}
          {isBinance && (
            <Col xs={12}>
              <CustomButtonField
                classNames="btn-card"
                color="brown"
                onClick={() => onConfirmConvert(nft_data?.nft_info, type)}
              >
                {"CONVERT"}
              </CustomButtonField>
            </Col>
          )}
        </Row>
      );
    } else {
      return (
        <CustomButtonField
          classNames="btn-card"
          onClick={() => handleCheck(nft_data)}
          color={listCheckConvert[nft_data?._id]?.check ? "red" : "green"}
          size="medium"
        >
          {listCheckConvert[nft_data?._id]?.check ? "Cancel" : "Add"}
        </CustomButtonField>
      );
    }
  };

  const dropdownMenu = (
    <Menu className="dropdown-create-bundle">
      {chains?.map((el) => (
        <>
          <Menu.Item className="chain-item" onClick={() => onClickChain(el)}>
            <img
              className="chain-item__image"
              src={`/images/chain/${el.sort_name.toLowerCase()}.svg`}
              alt="chain"
            />
            {el.sort_name}
          </Menu.Item>
        </>
      ))}
    </Menu>
  );

  const onClickChain = (el) => {
    history.push(`/create-bundle?chain=${el._id}`);
  };

  const handleConvert = () => {
    setlistCheckConvert({});
    setisConverting(true);
  };

  const onConfirmConvert = async (nftData = null, typeNft = null) => {
    const listConvert = nftData?.contract_address ? [nftData] : [];
    if (!nftData?.contract_address) {
      for (let item in listCheckConvert) {
        if (listCheckConvert[item]?.check) {
          listConvert.push(listCheckConvert[item]?.nft);
        }
      }
    }

    if (listConvert.length > 0) {
      const type = typeNft
        ? typeNft
        : dataCol[activeTab]?.name.toLowerCase() ===
          "Treasury Boxes".toLowerCase()
        ? "BOX"
        : "BINANCE";
      await convertNFT(listConvert, setStatusModal, handleConvertDone, type);
      setisConverting(false);
      setlistCheckConvert({});
    }
  };

  const handleConvertDone = (result) => {
    setShowCardModal(true);
    setlistCard(result?.nftdata);
    console.log("convert");
    renderList();
  };

  const handleCheck = (nft) => {
    setlistCheckConvert({
      ...listCheckConvert,
      [nft?._id]: {
        check: !listCheckConvert[nft?._id]?.check,
        nft: nft?.nft_info,
      },
    });
  };

  return (
    <Row className="inventory-page">
      <Row wrap={true} className="inventory-content-tab">
        <div
          className={
            showFilter && activeTab != 0 && dataCol[activeTab]?.is_erc721
              ? "inventory-wall display-filter"
              : "inventory-wall"
          }
        >
          {isMobile ? (
            <CollectionFilterComponent
              listCol={listCol}
              dataCol={dataCol}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              setRenderKey={setRenderKey}
              isInventory={true}
              renderListCol={renderListCol}
              toPage={"/create-bundle"}
              onClick={() => handleShowModal(true)}
              titleBtn="Create Bundle"
              callBack={changeTab}
              handleConvert={handleConvert}
              onConfirmConvert={onConfirmConvert}
              isConverting={isConverting}
              listCheckConvert={listCheckConvert}
              setisConverting={setisConverting}
              isErc721={dataCol[activeTab]?.is_erc721}
              renderKey={renderKey}
            />
          ) : (
            <Tabs activeKey={`${activeTab}`} onChange={onChangeTab}>
              <div className="header-list">
                {activeTab != 0 && dataCol[activeTab]?.is_erc721 && (
                  <CustomButtonField
                    classNames={showFilter ? "btn-filter w-244" : "btn-filter"}
                    color="gardient"
                    size="small"
                    onClick={() => setShowFilter(!showFilter)}
                  >
                    <img src="/images/plus-icon.svg" />
                    {"Filter"}
                  </CustomButtonField>
                )}
                <div>
                  {isConverting ? (
                    <>
                      <div className="select-txt">
                        Selected{" "}
                        {
                          Object.values(listCheckConvert).filter(
                            (el) => el?.check
                          ).length
                        }
                      </div>
                      <CustomButtonField
                        classNames="btn-create"
                        color="green"
                        size="medium"
                        onClick={onConfirmConvert}
                      >
                        {"Confirm"}
                      </CustomButtonField>
                      <CustomButtonField
                        classNames="btn-convert red"
                        size="medium"
                        color="green"
                        onClick={() => setisConverting(false)}
                      >
                        {"Cancel"}
                      </CustomButtonField>
                    </>
                  ) : (
                    <>
                      {/* <Link to="/create-bundle"> */}
                      <CustomDropdown
                        overlay={dropdownMenu}
                        placement="bottomRight"
                        trigger={["click"]}
                      >
                        <CustomButtonField
                          classNames="btn-create brown"
                          color="green"
                          size="medium"
                        >
                          {"Create Bundle"}
                        </CustomButtonField>
                      </CustomDropdown>
                      {/* </Link> */}
                      {dataCol[activeTab]?.name.toLowerCase() ===
                        "Binance NFTs".toLowerCase() && (
                        <CustomButtonField
                          classNames="btn-convert brown"
                          color="green"
                          size="medium"
                          onClick={handleConvert}
                        >
                          {"Multiple Convert"}
                        </CustomButtonField>
                      )}
                      {dataCol[activeTab]?.name.toLowerCase() ===
                        "Treasury Boxes".toLowerCase() && (
                        <CustomButtonField
                          classNames="btn-convert brown"
                          color="green"
                          size="medium"
                          onClick={handleConvert}
                        >
                          {"Multiple Unbox "}
                        </CustomButtonField>
                      )}
                    </>
                  )}
                </div>
              </div>

              {showFilter &&
                activeTab != 0 &&
                dataCol[activeTab]?.is_erc721 && (
                  <FilterComponent
                    {...dataCol[activeTab]}
                    renderListCol={renderListCol}
                    setRenderKey={setRenderKey}
                    renderKey={renderKey}
                    isInventory={true}
                    isErc721={dataCol[activeTab]?.is_erc721}
                  />
                )}

              {listCol}
            </Tabs>
          )}
        </div>
      </Row>
      {renderConfirm}
      {renderBridge}

      <CardModal
        setShowhowModal={setShowCardModal}
        showModal={showCardModal}
        cards={listCard}
        chainInfo={chains?.find((el) => el.sort_name == "BSC")}
      />
    </Row>
  );
}

export default withContext(InventoryTemplate);
