import React, { useEffect } from "react";
import MyListingTemplate from "../../components/templates/my-listing";
import SiteLayout from "../../layouts/site-layout";
import "./style.scss";

function MyListing(props) {

    return (
        <SiteLayout>
            <MyListingTemplate />
        </SiteLayout>
    );
}

export default MyListing;
