import React from "react";
import ConfirmBundleTemplate from "../../components/templates/confirm-bundle";
import SiteLayout from "../../layouts/site-layout";
import "./style.scss";

function ConfirmBundle(props) {

    return (
        <SiteLayout styleMarket="master-layout__market">
            <ConfirmBundleTemplate />
        </SiteLayout>
    );
}

export default ConfirmBundle;
