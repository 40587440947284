import React, { useEffect, useState, useMemo } from "react";
import { getUser, getTokens, getBalanceItem } from "../../../../ultis/common";
import useBlockchainFunc from "../../../../ultis/blockchain";
import CustomButtonField from "../../../elements/button";
import GeneralModal from "../../../elements/modal/general-modal";
import CheckoutBundle from "../../../elements/modal/general-modal/checkout-bundle";
import ConfirmModal from "../../inventory/modal-confirm";

const DEFAULT_TAB = {
  bundle: "Bundles",
};
function CardFooterP2P(props) {
  const { el, nftData, t, setStatusModal, defaultTab, callBack, isItems } = props;
  const {
    buyNFT,
    cancelOrder,
    buyBundle,
    cancelBundle,
    cancelOffer,
    checkLogin,
    updateOrder
  } = useBlockchainFunc();
  const [showPurchase, setShowPurchase] = useState(false);
  const [modalType, setModalType] = useState("");
  const [showBundleModal, setShowBundleModal] = useState(false);
  const [amountMaxItem, setAmountMaxItem] = useState()

  const getAmountItem = async() => {
    if(isItems){
      const balance = await getBalanceItem( getUser()?.address, el?.token_address,el.chain.rpc, el.chain.sort_name);
      setAmountMaxItem(balance + el.quantity)
    }
  }

  useEffect(getAmountItem, [isItems])

  const onBuyNFT = async (values) => {
    const chainInfo =  el.chain
    const token = {
      contract_address: chainInfo.token_contract
    }
    const quantity = values.quantity ? values.quantity : 1;
    await buyNFT(el.order_id, el.price, quantity,token, setStatusModal,chainInfo, callBack);
  };

  const onBuyBundle = async () => {
    const chainInfo =  el.chain
    const token = {
      contract_address: chainInfo.token_contract
    }    
    await buyBundle(el.bundle_id, el.price, token, chainInfo, setStatusModal, callBack);
    setShowBundleModal(false);
  };

  const onCancelNFT = async () => {
    const chainInfo =  el.chain
    if (nftData.bid_id)
      await cancelOffer({ bid_id: el.bid_id }, setStatusModal);
    else
      await cancelOrder(
        { order_id: el.order_id, token_id: nftData.token, chainInfo: chainInfo },
        setStatusModal,
        callBack
      );
  };

  const onCancelBundle = async () => {
    const chainInfo =  el.chain
    await cancelBundle(el.bundle_id, chainInfo, setStatusModal, callBack);
  };

  const onClickConfirm = () => {
    if (!checkLogin()) return;
    //if (defaultTab === DEFAULT_TAB.bundle) onBuyBundle();
    if (defaultTab == DEFAULT_TAB.bundle) setShowBundleModal(true);
    else {
      setModalType(isItems ? "checkout-evol" : "checkout");
      setShowPurchase(true);
    }
  };

   const onClickChange = () => {
    if (!checkLogin()) return;
    //if (defaultTab === DEFAULT_TAB.bundle) onBuyBundle();
    if (defaultTab == DEFAULT_TAB.bundle) setShowBundleModal(true);
    else {
      setModalType("update-on-sale");
      setShowPurchase(true);
    }
  };

  const onClickCancel = () => {
    if (!checkLogin()) return;
    if (defaultTab == DEFAULT_TAB.bundle) onCancelBundle();
    else {
      const type = nftData.bid_id ? "cancel-offer" : "remove-from-sale";
      setModalType(type);
      setShowPurchase(true);
    }
  };

  const renderCardFooter = useMemo(() => {
    const isOwner = getUser()?.address === el.owner_address;
    const token = getTokens()?.find(item => item?.contract_address.toLowerCase() == el?.payment_token?.toLowerCase())?.sort_name
    if (isOwner || el.bid_id)
      return (<div className={isItems && "btn-container"}>
        
        <CustomButtonField
          classNames="btn-card"
          color="red-blur"
          onClick={onClickCancel}
          size={ !isItems && "card"}
        >
          <div className="price">
            Cancel
            <div className="purchase">
              {/* <img className="icon-cancel" src={`/images/token/${token?.toLowerCase()}.svg`} />
              {el.price} {token} */}
            </div>
          </div>
        </CustomButtonField>
        {isItems && <CustomButtonField
          classNames="btn-card"
          color="green-blur"
          onClick={onClickChange}
        >
          <div className="price">
            Change
          </div>
        </CustomButtonField>}
      </div>);
    else
      return (
        <CustomButtonField
          classNames="btn-card"
          color="gray"
          size="card"
          onClick={onClickConfirm}
        >
          Buy now
        </CustomButtonField>
      );
  },[el]);
  return (
    <>
      {renderCardFooter}
      {modalType != "update-on-sale" &&<GeneralModal
        onFinish={
          modalType === "checkout" || modalType === "checkout-evol"
            ? onBuyNFT
            : onCancelNFT
        }
        isShowingPopup={showPurchase}
        setIsShowingPopup={setShowPurchase}
        nftData={nftData}
        orderInfor={el}
        type={modalType}
      />}
      {showBundleModal && (
        <CheckoutBundle
          setVisible={setShowBundleModal}
          onFinish={onBuyBundle}
          bundleData={nftData}
        />
      )}
      {modalType === "update-on-sale" && (
        <ConfirmModal
          nft={{...el, ...el?.nft_data, amount: amountMaxItem, contract: el?.token_address}}
          onClick={updateOrder}
          showModal={showPurchase}
          setShowModal={setShowPurchase}
          setStatusModal={setStatusModal}
          type={"change"}
          isItems={true}
          isUpdateItem={true}
          callBack={callBack}
        />
      )}
    </>
  );
}

export default CardFooterP2P;
