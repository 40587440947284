import React from "react";
import "./style.scss";
import FilterMonComponent from "./filter-mon"

function FilterComponent(props) {
    return (
       <FilterMonComponent key={props.renderKey} {...props}/>
    );
}

export default FilterComponent;
