import React, { useEffect, useMemo, useState } from "react";
import { Row, Col, Button, Form, Select } from "antd";
import CustomInput from "../../../../elements/input";
import "./style.scss";
import { formatInputNumber, getTokens,getUser,getMaxBalance } from "../../../../../ultis/common";
import { withContext } from "../../../../../hook/AppContext";
import CustomSelect from "../../../custom-select";

const { Option } = Select;

function MakeOffer(props) {
  const {
    orderInfor,
    t,
    type,
    form,
    offerPrice,
    rules,
    setRules,
    bidInfor
  } = props;


  const [token, setToken] = useState(getTokens()?.find(el=> el.is_active)|| getTokens()[0]);
  const [price, setPrice] = useState(offerPrice);
  const [balance, setBalance] = useState();

  const offerExpirationOpts = [
    {
      value: "3",
      label: t("offerExpirationOptions.in3days"),
    },
    {
      value: "7",
      label: t("offerExpirationOptions.in7days"),
    },
    {
      value: "10",
      label: t("offerExpirationOptions.in10days"),
    },
    {
      value: "15",
      label: t("offerExpirationOptions.in15days"),
    },
  ];

  useEffect(() => {
    if(bidInfor){
      setPrice(bidInfor?.price)
      const tokenBid = getTokens()?.find(el => el?.contract_address.toLowerCase() == bidInfor?.payment_token.toLowerCase())
      setToken(tokenBid)
    }
  }, [bidInfor])

  useEffect( async() => {

    const balanceMax = await getMaxBalance(
        getUser()?.address,
        token?.contract_address,
        false,
        3
      );
    const checkBalance = token?.is_native_token ? balanceMax?.balanceNative : balanceMax?.token
    const  max = bidInfor?.payment_token.toLowerCase() == token?.contract_address.toLowerCase() ? checkBalance + offerPrice : checkBalance
    setBalance(max)
  }, [token])

  const renderMaxBalance = useMemo(()=>{
    return <div className="max-balance"> Max balance: {balance} {token?.sort_name}</div>
  },[token, balance])

  const onChange = async (e) => {
    const balanceMax = await getMaxBalance(
        getUser()?.address,
        token?.contract_address,
        false,
        3
    );
    const checkBalance = token?.is_native_token ? balanceMax.balanceNative : balanceMax.token
    const maxBalance = bidInfor?.payment_token.toLowerCase() == token?.contract_address.toLowerCase() ? checkBalance + offerPrice : checkBalance
    setPrice(formatInputNumber(e.target.value.toString(), 3,10, Number(Number(maxBalance).toFixed(3))))  
    form.setFieldsValue({ amount: formatInputNumber(e.target.value?.toString(), 3,10, Number(Number(maxBalance).toFixed(3))) })
    
  };

  const onChangeSelect = async (value) => {
    const tokenObj = getTokens()?.find(el=>el.contract_address.toLowerCase() == value?.toLowerCase())
    const balanceMax = await getMaxBalance(
        getUser()?.address,
        tokenObj?.contract_address,
        false,
        3
    );
    const checkBalance = tokenObj?.is_native_token ? balanceMax.balanceNative : balanceMax.token
    const maxBalance = bidInfor?.payment_token.toLowerCase() == value?.toLowerCase() ? checkBalance + offerPrice : checkBalance
    form.setFieldsValue({ amount: formatInputNumber(price?.toString(), 3,10, Number(Number(maxBalance).toFixed(3)))})
    setToken(tokenObj)
  }

  return (
    <>
      <Row className="amount-row">
        <Col span={15} className="left-col">
          {type === "open-box" || type === "checkout-box" ? "Amount" : "Price"}
          <Form.Item className="m-0 mb-0 amount-offer" name="amount" rules={rules.amount}>
            <CustomInput
              classNames={["amount-item"]}
              min={0}
              disabled={type === "open-box"}
              step={0.001}
              onChange={onChange}
              // prefix={
              //   <img
              //     className="prefix-input"
              //     alt="token"
              //     src="/images/rune2.png"
              //   ></img>
              // }
            />
          </Form.Item>
        </Col>
        <Col span={9} className="right-col">
          {/* <Button
            className="max-button"
            onClick={onChangeMaxAmount}
            type="button"
          >
            {"Max"}
          </Button> */}
          <div className="title-select">Payment token</div>
          <Form.Item  name="token" >
            <Select className="select-token" 
                          onChange={onChangeSelect} 
                          defaultValue ={getTokens()?.find(el=>el.is_active)?.contract_address}
                          open={false}
                          showArrow={false}
                          // suffixIcon={
                          // <img
                          //   className="select-icon"
                          //   src="/images/updown-arrow.svg"
                          // />} 
            >
              {getTokens()?.map(el => { if(el.is_active) return <Option value={el.contract_address}><img className="token-icon" src={`/images/token/${el?.sort_name?.toLowerCase()}.svg`}/>{el?.sort_name}</Option>})}

            </Select>
          </Form.Item>
        </Col>
      </Row>
      {renderMaxBalance}
      <Row className="amount-row expire-row mt-12">
        <Col span={24} className="left-col">
          Offer expiration
          <Form.Item
            className="m-0 mb-0"
            name={["offerExpiration"]}
          >
            <CustomSelect
              options={offerExpirationOpts}
              className="expire-select"
              suffixIcon={
                <img
                  className="suffix-select"
                  src="/images/updown-arrow.svg"
                ></img>
              }
            ></CustomSelect>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
}

export default withContext(MakeOffer);
