import React from "react";
import { Progress, Statistic } from "antd";
import "./style.scss";

const { Countdown } = Statistic;
function CountProgress(props) {
  const { totalBuy, maxSupply, title, remaining, percent } = props;
  return (
    <div className="count-progress">
      {!remaining && (
        <div className="remaining">
          {title}: {maxSupply - totalBuy}
        </div>
      )}
      {remaining && (
        <div className="remaining">
          {title}:
          <Countdown
            className="box-countdown"
            value={remaining + Date.now()}
            format={`HH : mm : ss`}
          />
        </div>
      )}

      {remaining ? (
        <Progress
          className="progress-bar"
          strokeColor={{
            "0%": "#FF8266",
            "50%": "#FFD966",
            "100%": "#CEFF66",
          }}
          strokeWidth={14}
          trailColor={"#59544E"}
          strokeLinecap={"square"}
          percent={percent}
          showInfo={false}
        />
      ) : (
        <Progress
          className="progress-bar"
          strokeColor={{
            "0%": "#FF8266",
            "50%": "#FFD966",
            "100%": "#CEFF66",
          }}
          strokeWidth={14}
          trailColor={"#59544E"}
          strokeLinecap={"square"}
          percent={((maxSupply - totalBuy) * 100) / maxSupply}
          showInfo={false}
        />
      )}
    </div>
  );
}

export default CountProgress;
