import React, { useState, useEffect, useContext } from "react";
import { Modal } from "antd";
import "./style.scss";
import ThemeContext from "../../../hook/ThemeContext";
import CloseButton from "../../../components/elements/button/modal-close-button";

function CustomModal({ children, ...props }) {
  const { theme, setTheme } = useContext(ThemeContext);
  useEffect(() => {
    const savedThemeLocal = localStorage.getItem("globalTheme");

    if (!!savedThemeLocal) {
      setTheme(savedThemeLocal);
    }
  }, []);

  return (
    <Modal
      {...props}
      wrapClassName={`theme--${theme} ${props?.wrapClassName ?? ""}`}
      closeIcon={props.closeIcon || <CloseButton />}
    >
      {children}
    </Modal>
  );
}

export default CustomModal;
