import React, { useEffect } from "react";
import NFTGameTemplate from "../../components/templates/register-game";
import SiteLayout from "../../layouts/site-layout";
import "./style.scss";
function iframe() {
  return {
      __html: '<iframe src="/mongenbound/index.html" width="1080" height="900"></iframe>'
  }
}

function NFTGamePage(props) {
  return   <NFTGameTemplate />;
}

export default NFTGamePage;
