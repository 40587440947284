import React, { useEffect, useMemo, useRef } from 'react';
import './style.scss'

function S3Image({ isBinaryImage = false, type, onClick, isOriginal = null, ...props }) {
    const imgElm = useRef(null);

    useEffect(async () => {
        const imgLoader = new Image();
        imgLoader.src = props.src;
        imgLoader.onload = () => {
            imgElm.current?.setAttribute(`src`, `${props.src}`);
            imgElm.current?.setAttribute(
                `class`,
                `${props.className}`
            );
        };
    }, [props.src]);

    return <img
        ref={imgElm}
        alt={props.alt || ''}
        data-size={props['data-size']}
        onClick={onClick}
        className={`img-loading`}
        src="/images/loading-icon-2.png"
    />
}

export default S3Image;
