import React, { useEffect, useState } from "react";
import "./style.scss";
import FilterComponent from "../../common/filter-component";
import CardComponent from "../../common/card-component";
import CardFooterP2P from "./card-footer";
import {
  getAllCollection,
  getListOrder,
  getListBid,
  getListBundle,
  getListGame,
  getListItems,
} from "../../../api";
import { Tabs, Pagination, Row, Col, Select } from "antd";
import { Button, message, Switch } from "antd";
import { withContext } from "../../../hook/AppContext";
import { getUser } from "../../../ultis/common";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import { useWindowResizeMobile } from "../../../hook/ultis.hook";
import CollectionFilterComponent from "../../common/collection-filter-component";
import { DEFAULT_ITEMS_PER_PAGE } from "../../../constants";

const { TabPane } = Tabs;
const DEFAULT_TAB = {
  bundle: "Bundles",
  items: "Items",
};

function P2PMarketTemplate(props) {
  const { setStatusModal, t, chains } = props;
  const [listCol, setListCol] = useState([]);
  const [dataCol, setDataCol] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [listItem, setListItem] = useState({});
  const [filterSort, setFilterSort] = useState({});
  const [paging, setPaging] = useState({});
  const [totalItem, setTotalItem] = useState();
  const [switchValue, setSwitchValue] = useState({
    owned: false,
    offer: false,
  });
  const [renderKey, setRenderKey] = useState(0);
  const [dataGame, setDataGame] = useState([]);
  const [endDelay, setEndDelay] = useState(false);
  const [isMobile] = useWindowResizeMobile(891);
  const history = useHistory();

  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      setEndDelay(true);
    }, 1000);
  }, []);

  useEffect(() => {
    let fn = async () => {
      const query = queryString.parse(history.location.search, {
        ignoreQueryPrefix: true,
      });
      const resGame = await getListGame();
      setDataGame(resGame);
      const resCol = await getAllCollection({
        game_id: resGame[0]._id,
      });

      let listNFT = {};
      let page = {};
      const colection = {};
      let infoNFT = [];
      let filter = [];
      let idCollection;
      let activeTabId;
      idCollection = resCol.find((el) => !el.is_erc721)?._id;
      const listContract = chains?.map((el) => {
        return {
          address: el._id,
          chain: el._id,
          chain_name: el.sort_name,
        };
      });
      resCol.map((el) => {
        if (el.name === "Mongens") {
          localStorage.setItem("MongenContract", el.contract_address);
        }
        if (el.is_erc721) {
          page[el._id] = {
            currentPage: 1,
          };
          if (el.name == DEFAULT_TAB.bundle) {
            colection[el._id] = { ...el, list_contract: listContract };
          } else colection[el._id] = el;
          listNFT[el._id] = <></>;
        } else {
          if (idCollection) {
            infoNFT.push(el);
            filter.push({
              type: "Type",
              name: el.name.toUpperCase(),
              contractAddress: el.contract_address,
              listContract: el.list_contract,
            });
            colection[idCollection] = {
              name: "Items",
              icon_image: "",
              _id: idCollection,
              infoNFT: infoNFT,
              is_erc721: el.is_erc721,
              filter: filter,
              sort: el.sort,
              list_contract: listContract,
            };
            page[idCollection] = {
              currentPage: 1,
            };
            if (query?.tab == "Items") activeTabId = idCollection;
            listNFT[idCollection] = <></>;
          }
        }
        if (el.name == query?.tab) activeTabId = el._id;
      });

      setPaging(page);
      setActiveTab(activeTabId || resCol[0]?._id);
      setListItem(listNFT);
      setDataCol(colection);
      // Object.keys(colection).map((key) => console.log({isErc721: colection[key].is_erc721, name: colection[key].name, image: colection[key].icon_image}))
    };
    fn();
  }, []);

  useEffect(() => {
    const query = queryString.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    let activeTabId = dataCol[Object.keys(dataCol)[0]]?._id;
    if (query?.tab) {
      for (const key in dataCol) {
        let el = dataCol[key];
        if (el.name == query?.tab) activeTabId = el._id;
      }
    }
    setActiveTab(activeTabId);
  }, [location.search]);

  // render tabs by list in api get collection, update item per tab when tab change
  useEffect(() => {
    renderListCol();
  }, [dataCol, listItem]);

  // render list items when tab rendered, paging, switch value change
  useEffect(() => {
    if (Object.keys(dataCol).length) {
      renderList();
    }
  }, [dataCol, filterSort, paging, switchValue]);

  useEffect(() => {
    setPaging({
      ...paging,
      [activeTab]: {
        currentPage: 1,
      },
    });
  }, [filterSort]);

  //reset filter when change tab
  useEffect(() => {
    setFilterSort({});
  }, [renderKey]);

  //render list card component per tab
  const renderList = async (checkItem) => {
    const params = {
      page: paging[activeTab]?.currentPage,
      limit: DEFAULT_ITEMS_PER_PAGE,
      ...filterSort[activeTab],
    };
    if (switchValue.owned) {
      params.address = getUser()?.address;
    }
    let listOrder;
    switch (dataCol[activeTab]?.name) {
      case DEFAULT_TAB.bundle:
        params.contract_address = null;
        params.chain =
          filterSort[activeTab]?.contract_address?.length > 0
            ? filterSort[activeTab]?.contract_address
            : null;
        params.game_id = dataGame[0]._id;
        listOrder = await getListBundle(params);
        break;
      case DEFAULT_TAB.items:
        // const list = dataCol[activeTab].infoNFT
        // params.contract_address = filterSort[activeTab]?.contract_address?.length > 0 ? filterSort[activeTab]?.contract_address : list
        params.game_id = dataGame[0]._id;
        listOrder = await getListItems(params);
        break;
      default:
        const list = dataCol[activeTab]?.list_contract.map((el) => el.address);
        params.contract_address =
          filterSort[activeTab]?.contract_address?.length > 0
            ? filterSort[activeTab]?.contract_address
            : list;
        if (switchValue.offer) {
          params.address = getUser()?.address;
          listOrder = await getListBid(params);
        } else {
          listOrder = await getListOrder(params);
        }
        break;
    }

    setTotalItem(listOrder?.metadata?.total);
    const comp = listOrder?.data?.map((el, index) => {
      const isErc721 = !dataCol[activeTab]?.is_erc721;
      // console.log({isErc721, name: dataCol[activeTab]?.name})
      let item = el;
      if (isErc721 && dataCol[activeTab]?.name != DEFAULT_TAB.bundle) {
        const collection = dataCol[activeTab]?.infoNFT?.find((item) =>
          item.list_contract.find(
            (item) =>
              item.address?.toLowerCase() == el.token_address.toLowerCase()
          )
        );
        const nft_data = {
          name: collection?.name,
          image: collection?.image,
          static_image: true,
        };
        item = {
          ...item,
          nft_data: el.nft_data ? el.nft_data : nft_data,
        };
      }
      return (
        <Col>
          <CardComponent
            key={index}
            cardFooter={() => renderCardFooter(item, dataCol[activeTab]?.name)}
            type={dataCol[activeTab]?.name}
            {...item}
            token_id={el?.nft_data?.nft_info?.token_id}
            isItems={isErc721}
            renderList={renderList}
          />
        </Col>
      );
    });
    setListItem((prevState) => {
      let newSta = Object.assign({}, prevState);
      newSta[activeTab] = comp;
      return newSta;
    });
  };

  const onChangeTab = (value) => {
    setActiveTab(value);
    setRenderKey(renderKey + 1);
    const name = dataCol[value]?.name;
    history.push(`/p2p-market?tab=${name}`);
  };

  const onChangePage = (value) => {
    setPaging((prevState) => {
      let newSta = Object.assign({}, prevState);
      newSta[activeTab].currentPage = value;
      return newSta;
    });
  };

  //render tab
  const renderListCol = async (dataFilter) => {
    if (dataFilter?._id) {
      setFilterSort((prevState) => {
        let newSta = Object.assign({}, prevState);
        newSta[dataFilter?._id] = dataFilter;
        return newSta;
      });
    }
    let results = [];
    for (const key in dataCol) {
      let el = dataCol[key];
      if (isMobile) {
        if (el._id === activeTab) {
          results = (
            <>
              <Row className="list-item" xs={24}>
                {listItem[el._id]}
              </Row>
              {totalItem / DEFAULT_ITEMS_PER_PAGE > 1 && (
                <Pagination
                  defaultCurrent={1}
                  total={totalItem}
                  pageSize={DEFAULT_ITEMS_PER_PAGE}
                  current={paging[activeTab].currentPage}
                  onChange={onChangePage}
                  showSizeChanger={false}
                />
              )}
            </>
          );
        }
      } else {
        results.push(
          <TabPane
            className="market-cont"
            tab={
              <>
                <img
                  src={`/images/collection-icon/${el.name?.toLowerCase()}${
                    el.name?.toLowerCase() == "bundles" ? ".png" : ".svg"
                  }`}
                />
                {el.name}
              </>
            }
            key={el._id}
          >
            {totalItem ? (
              <>
                {totalItem > 0 && (
                  <div className="total-item">
                    {totalItem}{" "}
                    <div className="total-item-name">
                      {dataCol[activeTab]?.name} for sale
                    </div>
                  </div>
                )}
                <Row className="list-item" xs={24}>
                  {listItem[el._id]}
                </Row>
                {totalItem / DEFAULT_ITEMS_PER_PAGE > 1 && (
                  <Pagination
                    defaultCurrent={1}
                    total={totalItem}
                    pageSize={DEFAULT_ITEMS_PER_PAGE}
                    current={paging[activeTab].currentPage}
                    onChange={onChangePage}
                    showSizeChanger={false}
                  />
                )}
              </>
            ) : null}
          </TabPane>
        );
      }
    }
    setListCol(results);
  };

  const renderCardFooter = (el, defaultTab) => {
    const nftData = el?.bundle_id
      ? { ...el }
      : {
          image: el.nft_data?.image,
          name: el.nft_data?.name,
          token: el.nft_data?.nft_info?.token_id,
          bid_id: el.bid_id,
          ...el?.nft_data,
        };
    return (
      <CardFooterP2P
        nftData={nftData}
        t={t}
        el={el}
        setStatusModal={setStatusModal}
        defaultTab={defaultTab}
        callBack={renderList}
        isItems={!dataCol[activeTab]?.is_erc721}
      />
    );
  };

  const onChangeSwitch = (el, value) => {
    setSwitchValue((prevState) => {
      let newSta = Object.assign({}, prevState);
      newSta[el] = value;
      if (newSta.owned && newSta.offer) {
        if (el === "owned") {
          newSta.offer = false;
        } else {
          newSta.owned = false;
        }
      }
      return newSta;
    });
  };

  const changeTab = (value) => {
    const name = dataCol[value]?.name;
    history.push(`/p2p-market?tab=${name}`);
  };

  return (
    <>
      <div className={endDelay ? "market-tab show-filter" : "market-tab"}>
        <div className="tab-block">
          {isMobile ? (
            <CollectionFilterComponent
              listCol={listCol}
              dataCol={dataCol}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              renderKey={renderKey}
              setRenderKey={setRenderKey}
              callBack={changeTab}
              renderListCol={renderListCol}
              isErc721={dataCol[activeTab]?.is_erc721}
              hideSearch={
                dataCol[activeTab]?.name == DEFAULT_TAB.bundle ||
                !dataCol[activeTab]?.is_erc721
              }
            />
          ) : (
            <>
              {getUser()?.address && (
                <div className="switch-filter">
                  <div className="switch-item">
                    Owned
                    <Switch
                      checked={switchValue.owned}
                      onChange={(value) => onChangeSwitch("owned", value)}
                    />
                  </div>
                  {dataCol[activeTab]?.name !== DEFAULT_TAB.bundle &&
                    dataCol[activeTab]?.is_erc721 && (
                      <div className="switch-item">
                        My Offers
                        <Switch
                          checked={switchValue.offer}
                          onChange={(value) => onChangeSwitch("offer", value)}
                        />
                      </div>
                    )}
                </div>
              )}
              <Tabs activeKey={`${activeTab}`} onChange={onChangeTab}>
                {!isMobile && endDelay && (
                  <FilterComponent
                    renderListCol={renderListCol}
                    renderKey={renderKey}
                    setRenderKey={setRenderKey}
                    hideSearch={
                      dataCol[activeTab]?.name == DEFAULT_TAB.bundle ||
                      !dataCol[activeTab]?.is_erc721
                    }
                    isErc721={dataCol[activeTab]?.is_erc721}
                    {...dataCol[activeTab]}
                  />
                )}
                {listCol}
              </Tabs>
            </>
          )}
          {getUser()?.address && (
            <div className="switch-filter">
              <div className="switch-item">
                Owned
                <Switch
                  checked={switchValue.owned}
                  onChange={(value) => onChangeSwitch("owned", value)}
                />
              </div>
              {dataCol[activeTab]?.name !== DEFAULT_TAB.bundle &&
                dataCol[activeTab]?.is_erc721 && (
                  <div className="switch-item">
                    My Offers
                    <Switch
                      checked={switchValue.offer}
                      onChange={(value) => onChangeSwitch("offer", value)}
                    />
                  </div>
                )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default withContext(P2PMarketTemplate);
