import { Checkbox, Input, Select } from "antd";
import React, { useEffect, useState, useMemo } from "react";
import { withContext } from "../../../../hook/AppContext";
import "./style.scss";
import Star from "../../../elements/star";
import SlideRange from "../../../elements/slide-range";
import { CSS_TYPE } from "../../../../constants";

const { Search } = Input;

function FilterMonComponent(props) {
  const {
    filter,
    renderListCol,
    sort,
    _id,
    isInventory,
    setRenderKey,
    renderKey,
    search,
    hideSearch,
    isErc721,
    list_contract,
  } = props;
  const [options, setOptions] = useState([]);
  const [sortSelect, setSortSelect] = useState([]);
  const [filterValue, setFilterValue] = useState({
    sortValue: [],
    filterValue: {},
  });
  const [filterItem, setFilterItem] = useState([]);
  const [searchItem, setSearchItem] = useState();

  useEffect(() => {
    const sortValue = sort?.map((el) => ({
      sort_by: el.type,
      sort_type: null,
      sort_name: el.name,
    }));
    renderFilter();
    renderSort();
    renderSearchItem();
    setFilterValue({
      sortValue: sortValue,
      filterValue: {},
    });
  }, [filter, sort, search]);

  const renderFilter = () => {
    if (!filter) return;
    const optionsN = [];
    let item = {};
    filter.map((el) => {
      if (!item[el.type]) {
        item[el.type] = [];
      }
      if (el.type === "Evolution") {
        item[el.type].push(
          <>
            <div className="filter-evolution">{el.type}</div>
            <Star
              stop={el.name}
              onClick={(value) => onChangeFilter(el, value)}
            />
          </>
        );
      } else {
        item[el.type].push(el.name);
      }
    });
    setFilterItem(item);
    setOptions(optionsN);
  };

  const renderFilterEle = useMemo(() => {
    let renderEle = [];
    Object.entries(filterItem).forEach((val) => {
      if (val[0] !== "Evolution") {
        renderEle.push(
          <div className="filter-ele">
            {val[0]}
            <Checkbox.Group
              options={val[1]}
              onChange={(value) => onChangeFilter(val[0], value)}
            />
          </div>
        );
      } else {
        renderEle.push(val[1]);
      }
    });
    return <>{renderEle.map((el) => el)}</>;
  }, [filterItem]);

  const renderFilterChain = useMemo(() => {
    const listChains = list_contract?.map((el) => {
      return {
        value: el.address,
        label: (
          <>
            <img
              className="chain-image"
              src={`/images/chain/${el.chain_name?.toLowerCase()}.svg`}
              alt="chain"
            />{" "}
            {el.chain_name}{" "}
          </>
        ),
      };
    });
    return (
      <div className="filter-chain">
        <Checkbox.Group
          options={listChains}
          onChange={(value) =>
            onChangeFilter({ type: "contract_address" }, value)
          }
        ></Checkbox.Group>
      </div>
    );
  }, []);

  const onChangeFilter = (item, value) => {
    let filterData = filterValue.filterValue;
    if (item.type !== "Evolution") {
      const title = isErc721
        ? item.type
          ? item.type
          : item
        : item.type
        ? "chain"
        : "contract_address";
      let listEl = { [title]: [] };
      value.map((el) => {
        let item = filter.find((ele) => ele.name === el);
        if (item) {
          if (!listEl[item.type]) {
            listEl[item.type] = [];
          }
          //check filter item
          if (item.listContract) {
            item?.listContract.map((el) =>
              listEl["contract_address"].push(el.address)
            );
          } else listEl[item.type].push(el);
        } else {
          if (!isErc721) listEl["chain"] = value;
          else listEl["contract_address"] = value;
        }
      });
      filterData = { ...listEl, ...filterData };
    } else {
      if (filterData[item.type] === value) {
        filterData[item.type] = undefined;
      } else {
        filterData[item.type] = value;
      }
    }
    let newStateFilter;
    setFilterValue((prevState) => {
      let newSta = Object.assign({}, prevState);
      newSta.filterValue = { _id, ...newSta.filterValue, ...filterData };
      newStateFilter = newSta;
      return newSta;
    });
    const sortBy = newStateFilter?.sortValue.map((el) => el.sort_by);
    const sortType = newStateFilter?.sortValue.map((el) => el.sort_type);
    const sortName = newStateFilter?.sortValue.map((el) => el.sort_name);
    const sort = {
      sort_by: sortBy,
      sort_type: sortType,
      sort_name: sortName,
      _id: filterValue?.sortValue[0]?._id,
    };
    renderListCol({
      ...sort,
      ...newStateFilter?.filterValue,
    });
  };

  const handleChangeSort = (item, value) => {
    const data = {
      sort_by: value,
      sort_type: value ? item.type : null,
      sort_name: item.name,
      _id,
    };

    let newStateFilter;
    let newSta;
    setFilterValue((prevState) => {
      newSta = Object.assign({}, prevState);
      const sortBy = newSta.sortValue.map((el) => el.sort_by);
      const sortType = newSta.sortValue.map((el) => el.sort_type);
      const sortName = newSta.sortValue.map((el) => el.sort_name);
      newStateFilter = {
        sort_by: sortBy,
        sort_type: sortType,
        sort_name: sortName,
        _id,
      };
      let index = newSta.sortValue.findIndex(
        (el) => el.sort_name === data.sort_name //el.sort_type === data.sort_type - neu sort nhieu loai
      );
      if (index !== -1) {
        newSta.sortValue[index] = data;
        newStateFilter.sort_by[index] = data.sort_by;
        newStateFilter.sort_type[index] = data.sort_type;
        newStateFilter.sort_name[index] = data.sort_name;
      } else {
        newSta.sortValue.push(data);
        newStateFilter.sort_by.push(data.sort_by);
        newStateFilter.sort_type.push(data.sort_type);
        newStateFilter.sort_name.push(data.sort_name);
      }
      return newSta;
    });
    renderListCol({
      ...newStateFilter,
      ...newSta.filterValue,
    });
  };

  const handleChangeSearchItem = (item, value) => {
    let newStateFilter;
    setFilterValue((prevState) => {
      let newSta = Object.assign({}, prevState);
      newSta.filterValue = {
        _id,
        search_by: item.type,
        search_value: value,
        ...filterValue.filterValue,
      };
      newStateFilter = newSta;
      return newSta;
    });
    renderListCol({
      ...newStateFilter?.sortValue[0],
      ...newStateFilter.filterValue,
    });
  };

  const onChangeSearchName = (value) => {
    let newStateFilter;
    setFilterValue((prevState) => {
      let newSta = Object.assign({}, prevState);
      newSta.filterValue = { _id, ...filterValue.filterValue, name: value };
      newStateFilter = newSta;
      return newSta;
    });
    renderListCol({
      ...newStateFilter?.sortValue[0],
      ...newStateFilter?.filterValue,
    });
  };

  const renderSort = () => {
    if (!sort) return;
    let sortMap = sort?.map((el) => {
      switch (el.type) {
        case "price":
          return (
            <Select
              defaultValue=""
              style={{ width: 200 }}
              size="large"
              bordered
              onChange={(value) => handleChangeSort(el, value)}
            >
              <Select.Option value="">Recently Added</Select.Option>
              <Select.Option value="desc">Highest Price</Select.Option>
              <Select.Option value="asc">Lowest Price</Select.Option>
            </Select>
          );
        case "level":
          return (
            <Select
              defaultValue=""
              style={{ width: 200 }}
              size="large"
              bordered
              onChange={(value) => handleChangeSort(el, value)}
            >
              <Select.Option value="">Level</Select.Option>
              <Select.Option value="desc">Highest Level</Select.Option>
              <Select.Option value="asc">Lowest Level</Select.Option>
            </Select>
          );
        default:
          return;
      }
    });
    setSortSelect(sortMap);
  };

  const renderSearchItem = () => {
    if (!search) return;
    let sortMap = search?.map((el) => {
      switch (el.css_type) {
        case CSS_TYPE.slideRange:
          return (
            <SlideRange
              _id={_id}
              {...el}
              filterValue={filterValue}
              setFilterValue={setFilterValue}
              callback={renderListCol}
            />
          );
        case CSS_TYPE.searchText:
          return (
            <Search
              className="box-search"
              placeholder="Search by name"
              onSearch={onChangeSearchName}
              style={{ width: 200 }}
            />
          );
        default:
          return (
            <>
              <Select
                defaultValue=""
                style={{ width: 200 }}
                size="large"
                bordered
                onChange={(value) => handleChangeSearchItem(el, value)}
              >
                <Select.Option value="">{el.name}</Select.Option>
                {el?.value?.map((ele, index) => (
                  <Select.Option value={index}>
                    {ele.from} - {ele.to}
                  </Select.Option>
                ))}
              </Select>
            </>
          );
      }
    });
    setSearchItem(sortMap);
  };

  const clearAll = () => {
    setRenderKey(renderKey + 1);
  };

  const onChangeSearch = (value) => {
    let newStateFilter;
    setFilterValue((prevState) => {
      let newSta = Object.assign({}, prevState);
      newSta.filterValue = { _id, ...filterValue.filterValue, token_id: value };
      newStateFilter = newSta;
      return newSta;
    });
    renderListCol({
      ...newStateFilter?.sortValue[0],
      ...newStateFilter.filterValue,
    });
  };

  return (
    <div className="sort-filter">
      <div className="container-sort">
        <div className="filter-title">
          <div>
            <img src="/images/filter-icon.svg" />
            Filter
          </div>

          <div className="clear-filter" onClick={clearAll}>
            Clear Filter
          </div>
        </div>
        <div className="filter-chain-container">{renderFilterChain}</div>
        {!isInventory && sortSelect}
        {!hideSearch && (
          <Search
            className="box-search"
            placeholder="Search by ID"
            onSearch={onChangeSearch}
            style={{ width: 200 }}
            type="number"
          />
        )}
        {searchItem}
        {renderFilterEle}
      </div>
    </div>
  );
}

export default withContext(FilterMonComponent);
