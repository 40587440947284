import React, { useEffect, useState } from "react";
import "./style.scss";
import { Tabs } from "antd";
import { useUIContext, withContext } from "../../../hook/AppContext";
import CustomButtonField from "../../elements/button";
import useBlockchainFunc from "../../../ultis/blockchain";
import RuleRecovery from "../../templates/home-game/rule-recovery";
import { VERSION } from "../../../constants";

function NFTIngameTemplate(props) {
  const {userInfo, setCallBackGameFunc} = props;
  const { buyTurnGame } = useBlockchainFunc();
  const [contentRef, setContentRef] = useState(null);
  const [showModal, setShowModal] = useState(false);

  // const buyMoreTurnGame = (quantity) => {
  //   await buyTurnGame(quantity,renderTotalPrice(),getInfoUser)
  // }
  const updateEnergy = () => {
    contentRef?.contentWindow?.unityGame?.SendMessage('TWeb3', 'CallbackBuyTurn')
  }

  const openModal = () => {
    setShowModal(true)
    if (contentRef?.contentWindow?.unityGame) {
      setCallBackGameFunc(updateEnergy)
    }
  }

  useEffect(() => {
    if (contentRef?.contentWindow && !contentRef?.contentWindow?.buyTurnGame) {
      contentRef.contentWindow.buyTurnGame = openModal;
      contentRef.contentWindow.ingame = true;
      window.ingame = true;
      window.buyTurnGame = openModal
    }
  }, [contentRef]);

  return (
    <div>
      <iframe
        ref={setContentRef}
        src={window.origin+"/angry-mongen/index.html"}
        style={{ border: "none", width: "98vw", height: "91vh" }}
      ></iframe>
      <RuleRecovery userInfo={userInfo} gameIndex={2} isIngame={true} showModal={showModal} setShowModal={setShowModal} />
    </div>
  );
}

export default withContext(NFTIngameTemplate);
