import {
  MaxUint,
  USER_WALLET_TYPE,
  WALLET_TYPE,
  FEE_TYPE
} from "../constants";
import Web3 from "web3";
import tokenABI from "../constants/ABI/token.json";
import paymentABI from "../constants/ABI/payment.json";
import marketABI from "../constants/ABI/market.json";
import { sendTx } from "../api";
import WalletConnect from "@walletconnect/client";
import QRCodeModal from "@walletconnect/qrcode-modal";
import { message } from "antd";
import cliTruncate from "cli-truncate";
import crypto from "crypto";
import keplrService from "./keplr";


let setStatusModalNet = () => { }


export const getUser = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  return user;
};

export const RPC_BSC = getUser()?.chainInfo?.rpc || process.env.REACT_APP_BSC_PROVIDER

export const getChains = () => {
  const chains = JSON.parse(localStorage.getItem("chains"));
  return chains;
};

export const getTokens = () => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  return tokens;
};

export const getWalletType = () => {
  const user = getUser();
  return user?.walletCon
    ? user?.walletCon
    : USER_WALLET_TYPE.metamask;
};

export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const convertRound = (balance, length = 2) => {
  let cvBalance = "" + balance;

  if (Number(balance).toFixed(length) === 0) {
    cvBalance = Number(balance).toFixed(length);
  }

  if (cvBalance.indexOf(".") === -1) return cvBalance;

  // let [wholes, fractions] = cvBalance.split('.')

  const decimal = cvBalance.split(".");

  decimal[1] = decimal[1].substring(0, length);
  return decimal.join(".");
};


export const approveWallet = async (
  contractABI,
  contractAddress,//token
  marketContractAddr,//market
  setStatusModal,
  chainInfo,
  connector,
  price = 0
) => {
  setStatusModalNet = setStatusModal;
  try {
    const web3 = new Web3(chainInfo?.rpc);
    const user = getUser();
    // get is approve
    const tetherContract = new web3.eth.Contract(contractABI, contractAddress);
    console.log({ contractABI, contractAddress, marketContractAddr })

    const approve = await tetherContract.methods
      .allowance(user.address, marketContractAddr)
      .call();

    let remainAllowence = web3.utils.fromWei(approve);

    if (Number(remainAllowence) < price) {
      const approveData = tetherContract.methods
        .approve(marketContractAddr, MaxUint)
        .encodeABI();
      const dataReturn = {
        chainId: 1,
        approveData: {
          data: approveData,
          from: user.address,
          to: contractAddress,
        },
      };
      await handleTransactionExternalWallet(
        -1,
        dataReturn,
        handleResultTransaction,
        null,
        chainInfo,
        connector
      );
    }
    return true
  } catch (err) {
    console.log(err);
    throw err
  }
};

export const approveFTWallet = async (
  contractABI,
  contractAddress,//token
  spenderAddress,//market
  setStatusModal,
  chainInfo,
  connector,
  quantity = 0
) => {
  setStatusModalNet = setStatusModal;
  try {
    const web3 = new Web3(chainInfo?.rpc);
    const user = getUser();
    const executeContract = new web3.eth.Contract(contractABI, contractAddress);

    const approve = await executeContract.methods
      .allowance(user.address, spenderAddress)
      .call();

    let remainAllowance = approve;

    console.log({ remainAllowance })

    if (Number(remainAllowance) < quantity) {
      const approveData = executeContract.methods
        .approve(spenderAddress, MaxUint)
        .encodeABI();
      const dataReturn = {
        chainId: chainInfo?.chain_id,
        approveData: {
          data: approveData,
          from: user.address,
          to: contractAddress,
        },
      };
      await handleTransactionExternalWallet(
        -1,
        dataReturn,
        handleResultTransaction,
        null,
        chainInfo,
        connector
      );
    }
    return true
  } catch (err) {
    console.log(err);
    throw err
  }
};

export const approveAllWallet = async (
  contractABI,//nftABI
  contractAddress,//market
  nftContractAddr,//nft
  setStatusModal,
  chainInfo,
  connector,
) => {
  setStatusModalNet = setStatusModal
  try {
    let web3 = new Web3(chainInfo?.rpc);
    // else web3 =  new Web3(getChains()?.find(el => el.sort_name == "BSC")?.rpc)
    const user = getUser();

    // get is approve
    const sotaCont = await new web3.eth.Contract(contractABI, nftContractAddr);
    const approve = await sotaCont.methods
      .isApprovedForAll(user.address, contractAddress)
      .call();
    if (!approve) {
      const approveData = sotaCont.methods
        .setApprovalForAll(contractAddress, true)
        .encodeABI();
      console.log("nftContractAddr2345", approveData);

      const dataReturn = {
        approveAllData: {
          data: approveData,
          from: user.address,
          to: nftContractAddr
        },
      };
      console.log("nftContractAddr234678", user);

      await handleTransactionExternalWallet(
        1,
        dataReturn,
        handleResultTransaction,
        null,
        connector
      );
    }
  } catch (err) {
    throw err;
  }
};

export const networkValidation = async (args = {}) => {
  const { requiredChainNetwork, isMetamask = false } = args;
  const web3 = new Web3(Web3.givenProvider);
  const chainId = await web3.eth.net.getId();
  let chain = getChains()?.find(el => el.sort_name == requiredChainNetwork.sort_name)
  // }else chain = getChains()?.find(el => el.sort_name == "BSC")

  let rs = +chainId === +chain?.chain_id
  if (!rs) {
    message.warning(`Please change network on Wallet to ${chain?.sort_name} to continue`, 5);
    if (isMetamask) {
      const data = [
        {
          chainId: chain?.hex_chain_id,
          chainName: chain?.sort_name,
          nativeCurrency: {
            name: chain?.sort_name,
            symbol: chain?.native_token,
            decimals: 18,
          },
          rpcUrls: chain?.rpc,
          blockExplorerUrls: [chain?.block_explorer],
        },
      ];
      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: chain?.hex_chain_id?.toString(16) }],
        });
        await sleep(1000);
        const web3 = new Web3(chain?.rpc);
        const nchainId = await web3.eth.net.getId();
        rs = nchainId == chain?.chain_id;
        return rs;
      } catch (err) {
        if (err.code === 4902) {
          console.log("This network is not available in your metamask, please add it")
          try {
            await window.ethereum?.request({
              method: "wallet_addEthereumChain",
              params: data,
            });
          } catch (err) {

          }
        }
        rs = false;
        return rs;
      }
    }
  }

  return rs;
};

export const getContractFee = async (contractAddress, type, paymentContract, chainInfo) => {
  try {
    const user = getUser();
    if (type == FEE_TYPE.default) {
      if (user.walletType === WALLET_TYPE.terra) {
        const rs = await queryContractTerra(chainInfo?.market_contract, { "contract_support_info": { "contract_address": contractAddress } })
        return rs?.fee
      } else if (user.walletType === WALLET_TYPE.aura) {
        let client = await keplrService.getSigner();
        const rs = await client.queryContractSmart(chainInfo?.market_contract, { "contract_support_info": { "contract_address": contractAddress } })
        return rs?.fee
      } {
        const web3 = new Web3(chainInfo?.rpc);
        const feeContract = new web3.eth.Contract(
          paymentABI,
          paymentContract
        );
        const fee = feeContract.methods
          .getContractFee(contractAddress)
          .call();
        return fee;
      }
    }
    if (type == FEE_TYPE.bundle) {
      if (user.walletType === WALLET_TYPE.aura) {
        let client = await keplrService.getSigner();
        const rs = await client.queryContractSmart(chainInfo?.market_contract, { "contract_info": {} })
        return rs?.bundle_fee
      } else {
        const web3 = new Web3(user?.chainInfo?.rpc);
        const feeContract = new web3.eth.Contract(
          marketABI,
          user?.chainInfo?.market_contract
        );
        const fee = feeContract.methods
          .BundleFee()
          .call();
        return fee;
      }
    }
  } catch (err) {
    return 0
  }
};

export const getDiscountPayment = async (contractAddress, paymentContract, chainInfo) => {
  const user = getUser();
  if (user.walletType === WALLET_TYPE.aura) {
    return 0;
  } else {
    const web3 = new Web3(chainInfo?.rpc);
    const feeContract = new web3.eth.Contract(
      paymentABI,
      paymentContract
    );
    const discount = feeContract.methods
      .getDiscountPayment(contractAddress)
      .call();
    return discount;
  }
};

export const checkTypeWallet = (name) => {
  if (name.includes("BSC")) return WALLET_TYPE.bsc
  if (name.includes("Terra")) return WALLET_TYPE.terra
  if (name.includes("AVAX")) return WALLET_TYPE.avax
  if (name.includes("OKEX")) return WALLET_TYPE.okex
  if (name.includes("Aura")) return WALLET_TYPE.aura
}
const getListRpc = () => {
  let list = {}
  getChains()?.forEach(el => {
    const networkAddress = el.rpc;
    const provider = new Web3.providers.HttpProvider(networkAddress);
    const web3 = new Web3(provider);
    list[`${el.sort_name}`] = web3
  })
  return list
}
const listChains = getListRpc()

export const getBalanceItem = async (walletAddr, contractAddress, rpc, chainName = "", length = 2, isToken = false) => {
  try {
    // console.log({chainName, walletAddr})
    switch (chainName.toLowerCase()) {
      case 'aura':
        let tokenBalance = await keplrService.getTokenBalance(walletAddr, contractAddress)
        if (isToken) {
        tokenBalance = Web3.utils.fromWei(`${tokenBalance}`, "ether");
        tokenBalance = convertRound(tokenBalance, length)
        }
        return parseFloat(tokenBalance);
      case 'okex':
      case 'okc':
      case 'bsc':
      case 'avax':
      default:
        let web3;
        if (!web3) {
          const networkAddress = rpc;
          const provider = new Web3.providers.HttpProvider(networkAddress);
          web3 = new Web3(provider);
        } else web3 = listChains[`${chainName}`]
        const tokenCont = await new web3.eth.Contract(tokenABI, contractAddress);
        let balance = await tokenCont.methods.balanceOf(walletAddr).call();
        if (isToken) {
          balance = Web3.utils.fromWei(`${balance}`, "ether");
        }
        balance = convertRound(balance, length);
        return parseFloat(balance);
    }
  } catch (err) {
    console.log({err})
    return 0;
  }
}


export const getBalanceToken = async (walletAddr, chains) => {
  const newAmountBalance = await Promise.all(chains.map(async el => {
    const contractAddress = el.token_contract
    const rpc = el.list_rpc[0]
    const balance = await getBalanceItem(walletAddr, contractAddress, rpc, el.sortName ?? el.sort_name, 2, true)
    return {
      balance: balance,
      sortName: el.sort_name,
    }
  }))
  return newAmountBalance;
}

export const getMaxBalance = async (
  walletAddr,
  contractAddress = process.env.REACT_APP_TOKEN_CONTRACT_ADDRESS,
  isItems = false,
  length = 2,
) => {
  const user = getUser();
  let newAmountBalance = { token: 0 };


  const networkAddress = getUser()?.chainInfo?.rpc;
  const provider = new Web3.providers.HttpProvider(networkAddress);
  const web3 = new Web3(provider);
  const balanceEth = await web3.eth.getBalance(walletAddr);
  const balanceEthConv = Web3.utils.fromWei(`${balanceEth}`, "ether");
  newAmountBalance.balanceNative = parseFloat(convertRound(balanceEthConv, length));
  if (!contractAddress || contractAddress == "0x0000000000000000000000000000000000000000") {
    return newAmountBalance
  }
  const tokenCont = await new web3.eth.Contract(tokenABI, contractAddress);
  let balanceSota = await tokenCont.methods.balanceOf(walletAddr).call();
  let decimals = await tokenCont.methods.decimals().call();
  if (decimals != 0) {
    balanceSota = Web3.utils.fromWei(`${balanceSota}`, "ether");
    balanceSota = convertRound(balanceSota, length);
  }
  newAmountBalance[`token`] = parseFloat(convertRound(balanceSota, length));
  return newAmountBalance;


};

export const queryContractTerra = async (contract, query) => {
  const user = getUser();
  let terra;
  // const terra = new LCDClient({
  //     URL: user.chainInfo.rpc,
  //     chainID: user.chainInfo.chain_id
  // });
  const rs = await terra.wasm.contractQuery(
    contract,
    query // query msg
  );
  return rs
}

export const connectWalletConnect = async (requiredChainNetwork) => {
  // Create a connector
  const connector = new WalletConnect({
    bridge: "https://bridge.walletconnect.org", // Required
    qrcodeModal: QRCodeModal,
  });
  // connector.killSession()
  // await connector.createSession({chainId: 128});
  connector.on("session_update", (error, payload) => {
    if (error) {
      throw error;
    }

    // Get updated accounts and chainId
    const { accounts, chainId } = payload.params[0];
  });

  let check = true;
  if (requiredChainNetwork) {
    check = await networkValidation({
      chainId: connector.chainId,
      requiredChainNetwork,
    });
  }
  if (check) return connector;
  else throw check;
};

export const connectMetamask = async () => {
  const address = getUser()?.address;
  const account = await window.ethereum.request({
    method: "eth_requestAccounts",
  });

  if (account[0].toLowerCase() !== address) {
    message.error(
      `Please connect to address wallet: ${cliTruncate(address, 20, {
        position: "middle",
      })} that you signed up`
    );
    throw false;
  }
  window.ethereum.on("accountsChanged", (account) => {
    if (account.length > 0) {
      if (account[0].toLowerCase() !== address) {
        message.error(
          `Please connect to address wallet: ${cliTruncate(
            address,
            20,
            { position: "middle" }
          )} that you signed up`
        );
        throw false;
      }
    }
  });

  const web3 = new Web3(Web3.givenProvider);
  return web3;
};

export const web3SendTransaction = async (
  web3Connected,
  chainId,
  dataReturn,
  callback,
  action,
  isSignedData,
  chainInfo

) => {
  const { from, to, value, data } = dataReturn;
  const user = getUser()
  try {
    await web3Connected.eth
      .sendTransaction(
        {
          from,
          to,
          value,
          data
        },
        async (err, res) => {

          if (res) {
            callback({ txHash: res, action, chain: chainInfo?._id });
          }
          if (err) {
            throw err;
          }
        }
      )
      .on("receipt", async (rs) => {
        callback({ isRefesh: true });

        return rs;
      });
  } catch (err) {
    throw err;
  }
};

export const walletConnectSendTransaction = async (
  connect,
  chainId,
  dataReturn,
  callback,
  action,
  isSignedData
) => {
  const { from, to, value, data } = dataReturn;
  const user = getUser()
  let connector
  if (connect?._provider?.wc?.peerMeta?.name === "MetaMask") {
    connector = await connectWalletConnect(chainId);
  } else {
    connector = connect?.eth
  }
  try {
    const result = await connector
      .sendTransaction({
        from,
        to,
        data,
        value
      })
    callback({ txHash: result?.transactionHash ? result?.transactionHash : result, action, chain: user.chainInfo._id });
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const handleTransactionExternalWallet = async (
  chainId = 1,
  dataReturn,
  callback,
  action,
  chainInfo,
  connector
) => {
  if (getWalletType() === USER_WALLET_TYPE.walletConnect || getWalletType() === USER_WALLET_TYPE.walletConnectAvax || getWalletType() === USER_WALLET_TYPE.walletConnectOkex) {
    await handleTransactionWalletConnect(
      chainId,
      dataReturn,
      callback,
      action,
      connector
    );
  } else {
    await handleTransactionMetamask(chainId, dataReturn, callback, action, chainInfo);
  }
};

export const handleTransactionMetamask = async (
  chainId = 1,
  dataReturn,
  callback,
  action,
  chainInfo
) => {
  try {
    let web3Connected;
    // if (chainInfo) {
    web3Connected = await connectMetamask();
    // }else web3Connected = new Web3(getChains()?.find(el => el.sort_name == "BSC")?.rpc);
    if (dataReturn?.approveData) {
      let res = await web3SendTransaction(
        web3Connected,
        chainId,
        dataReturn?.approveData,
        callback,
        action,
        false,
        chainInfo
      );
    }
    if (dataReturn?.approveAllData) {
      await web3SendTransaction(
        web3Connected,
        chainId,
        dataReturn?.approveAllData,
        callback,
        action,
        false,
        chainInfo
      );
    }
    if (dataReturn?.signData) {
      let res = await web3SendTransaction(
        web3Connected,
        chainId,
        dataReturn?.signData,
        callback,
        action,
        true,
        chainInfo
      );
      return res;
    }
  } catch (err) {
    throw err;
  }
};

export const handleTransactionWalletConnect = async (
  chainId = 1,
  dataReturn,
  callback,
  action,
  connector
) => {
  try {

    if (dataReturn?.approveData) {
      let res = await walletConnectSendTransaction(
        connector,
        chainId,
        dataReturn?.approveData,
        callback,
        action
      );
    }
    if (dataReturn?.approveAllData) {
      await walletConnectSendTransaction(
        connector,
        chainId,
        dataReturn?.approveAllData,
        callback,
        action
      );
    }
    if (dataReturn?.signData) {
      let res = await walletConnectSendTransaction(
        connector,
        chainId,
        dataReturn?.signData,
        callback,
        action,
        true
      );
    }
  } catch (err) {
    throw err;
  }
};

const IV_SIZE = 16;
const secret = "game_market_secret_key";
let secret_key = crypto.createHash('sha256').update(String(secret)).digest('base64').substr(0, 32);
async function encrypt(data, keyString) {
  const plainText = JSON.stringify(data)
  const iv = crypto.randomBytes(IV_SIZE);
  const cipher = crypto.createCipheriv("aes-256-cbc", keyString, iv);
  let cipherText = cipher.update(Buffer.from(plainText, "utf8"));
  cipherText = Buffer.concat([cipherText, cipher.final()]);
  const combinedData = Buffer.concat([iv, cipherText]);
  const combinedString = combinedData.toString("base64");
  return combinedString;

}

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export const handleResultTransaction = async (rs) => {
  const { txHash, action, chain } = rs
  if (txHash && action != null) {
    console.log("rs", rs);
    const res = { ...rs, src: getCookie("src") }
    const dataEn = await encrypt(res, secret_key)
    await sendTx({ data: dataEn });
    localStorage.setItem("txHash", txHash);
  }
};


export const checkBalanceGas = async (balance) => {
  if (!balance) {
    const res = await getMaxBalance(getUser()?.address, process.env.REACT_APP_TOKEN_CONTRACT_ADDRESS)
    if (res.balanceNative < 0.01) {
      message.error("message.NFTS_INSUFFICIENT_FUNDS")
      throw false
    }
  } else {
    if (balance.balanceNative < 0.01) {
      message.error("message.NFTS_INSUFFICIENT_FUNDS")
      throw false
    }
  }
  return true
}

export const useWeb3 = () => {
  const networkAddress = getUser()?.chainInfo?.rpc;
  const provider = new Web3.providers.HttpProvider(networkAddress);
  const web3 = new Web3(provider);
  return web3;
};

export function formatInputNumber(numberString, decimal = 5, quantityDefault, max) {
  numberString = numberString?.replace(/[\uff01-\uff6e\uff61]/g, function (ch) {
    return String.fromCharCode(ch.charCodeAt(0) - 0xfee0);
  });
  let charCode = numberString.charCodeAt(numberString.length - 1)
  if (charCode === 12290 || charCode === 129) {
    var numberString = numberString.replace(/.$/, ".")
  }
  if (decimal === 0) {
    numberString = numberString.replace(/[^0-9]/g, "");
  } else {
    numberString = numberString.replace(/[^0-9.]/g, "");
  }
  if (numberString.length == 0) return null

  let index = numberString.indexOf(".");
  if (index > 0) {
    let string = numberString.slice(index + 1);
    string = string.replace(/[^0-9]/g, "");
    let value = parseInt(numberString.slice(0, index));
    if (value.length >= 10) value = value.slice(0, quantityDefault)
    if (string.length > decimal) {
      string = string.slice(0, decimal);
    }
    numberString = value + "." + string;
  } else if (index === 0) {
    let string = numberString.slice(index + 1);
    string = string.replace(/[^0-9]/g, "");
    let value = 0;
    if (string.length > decimal) {
      string = string.slice(0, decimal);
    }
    numberString = value + "." + string;
  } else {
    if (numberString.length >= 10) numberString = numberString.slice(0, quantityDefault)
    numberString = parseInt(numberString);
  }
  if (Number(numberString) > max) return max
  return numberString;
}


