import React, { useMemo } from "react";

import "./style.scss";

const Rarity = (props) => {
  const { size, name, value } = props;

  const renderColor = useMemo(() => {
    switch (value) {
      case "Common":
        return "silver";
      case "Uncommon":
        return "blue";
      case "Rare":
        return "yellow";
      case "Epic":
        return "red";
      case "Legendary":
        return "pink";
      default:
      // code block
    }
  }, [value]);

  return (
    <div className="rarity-block" key={size}>
      <div className={`box rarity ${renderColor}`}>
        <div className="box-name">{name}</div>

        <div className="box-value">
          <div className="box-line"></div>
          <div className="content">{value}</div>
        </div>
      </div>
    </div>
  );
};
export default Rarity;
