import Phaser from "phaser";
import "phaser/plugins/spine/dist/SpinePlugin";
// import "./SpinePlugin.min.js";
import { getSkinList, getAnimName } from "../../gamefix/spineutils";
import { useEffect, useMemo, useState } from "react";
import "./style.scss";
const RACES = ["Beast", "Tectos", "Mythic", "Celest", "Chaos"];
const TIERS = ["D", "C", "B", "A", "S"];
const BodyPart = {
  Form: 0,
  Head: 1,
  Eyes: 2,
  Horns: 3,
  Tail: 4,
  Back: 5,
  FrontLeg: 6,
  BackLeg: 7,
  Mouth: 8,
  Aura: 9,
};
const BackMap = { 0: 3, 2: 2, 1: 1, 3: 4, 4: 5 };
function getId() {
  if (!window.animationMonsterraId) {
    window.animationMonsterraId = 1;
  }
  return window.animationMonsterraId++;
}
let globalPlayer = {};
const name = ["beast", "tectos", "mythic", "celest", "chaos"];
const spineName = [
  {
    key: `tail`,
    json: `JSON Tail_Mongens/TAIL.json`,
    atlas: `JSON Tail_Mongens/TAIL.atlas.txt`,
  },
  {
    key: `back`,
    json: `JSON ACC_Mongens/BACK.json`,
    atlas: `JSON ACC_Mongens/BACK.atlas.txt`,
  },
  {
    key: `beast_parts`,
    json: `JSON Beast/Beast.json`,
    atlas: `JSON Beast/Beast.atlas.txt`,
  },
  {
    key: `tectos_parts`,
    json: `JSON Tectos/Tectos.json`,
    atlas: `JSON Tectos/Tectos.atlas.txt`,
  },
  {
    key: `mythic_parts`,
    json: `JSON Mythic/Mythic.json`,
    atlas: `JSON Mythic/Mythic.atlas.txt`,
  },
  {
    key: `celest_parts`,
    json: `JSON Celest/Celest.json`,
    atlas: `JSON Celest/Celest.atlas.txt`,
  },
  {
    key: `chaos_parts`,
    json: `JSON Chaos/Chaos.json`,
    atlas: `JSON Chaos/Chaos.atlas.txt`,
  },

  {
    key: `head`,
    json: `JSON Head_Mongens/HEAD.json`,
    atlas: `JSON Head_Mongens/HEAD.atlas.txt`,
  },

  {
    key: `beast_fleg`,
    json: `JSON Beast_Fleg/Beast_Fleg.json`,
    atlas: `JSON Beast_Fleg/Beast_Fleg.atlas.txt`,
  },
  {
    key: `tectos_fleg`,
    json: `JSON Tectos_Fleg/Tectos_Fleg.json`,
    atlas: `JSON Tectos_Fleg/Tectos_Fleg.atlas.txt`,
  },
  {
    key: `mythic_fleg`,
    json: `JSON Mythic_Fleg/Mythic_Fleg.json`,
    atlas: `JSON Mythic_Fleg/Mythic_Fleg.atlas.txt`,
  },
  {
    key: `celest_fleg`,
    json: `JSON Celest_Fleg/Celest_Fleg.json`,
    atlas: `JSON Celest_Fleg/Celest_Fleg.atlas.txt`,
  },
  {
    key: `chaos_fleg`,
    json: `JSON Chaos_Fleg/Chaos_Fleg.json`,
    atlas: `JSON Chaos_Fleg/Chaos_Fleg.atlas.txt`,
  },
];

function Mongen({ dna, width, height, setAnim, anim, lock, className }) {
  let partObjects = {};
  useEffect(() => {
    startGameWindow();
  }, []);
  function startGameWindow() {
    class Example extends Phaser.Scene {
      animParts = [];
      constructor() {
        super();
      }
      getSpineKey(part, race) {
        switch (part) {
          case 0: //
            return `${name[race]}_parts`;
          case 1: //head
            return "head";
          case 2: //eyes
            return "head";
          case 3:
            return "head";
          case 4: //tail
            return "tail";
          case 5: //back
            return "back";
          case 6:
            return `${name[race]}_fleg`;
          case 7:
            return `${name[race]}_parts`;
          case 8:
            return `head`;
        }
      }

      preload() {
        spineName.forEach((s) => {
          // @ts-ignore
          this.load.spine(s.key, `tech/${s.json}`, `tech/${s.atlas}`, false);
        });
      }

      applyDNA() {
        let skins = getSkinList(dna);
        let orders = [[4], [5], [0, 7, 9], [1, 2, 3, 8], [6]];
        for (var k in partObjects) {
          partObjects[k].alpha = 0;
        }
        orders.forEach((o, index) => {
          let key = this.getSpineKey(o[0], dna[o[0]][0]);
          partObjects[key].alpha = 1;

          let part = partObjects[key];

          // @ts-ignore
          let mainSkin = new window.spine.Skin(skins[o[0]]);
          if (!mainSkin) {
            console.log("skin not found ", { skin: skins[o[0]], key });
          }
          o.forEach((bodypart) => {
            let sk = part.skeleton.data.findSkin(skins[bodypart]);
            if (sk) {
              mainSkin.addSkin(sk);
            } else {
              console.log("skin not found ", skins[bodypart]);
            }
          });
          part.skeleton.setSkin(mainSkin);
          part.skeleton.setSlotsToSetupPose();
          part.skeleton.updateCache();
          part.play(getAnimName("Idle", dna), true);
        });
      }

      create() {
        let sort = [];
        spineName.forEach((k) => {
          partObjects[k.key] = this.make
            // @ts-ignore
            .spine({
              x: 256,
              y: 480,
              key: k.key,
              loop: true,
            })
            .setScale(0.8, 0.8);
          partObjects[k.key].alpha = 0;
        });
        this.applyDNA();
      }
    }

    const config = {
      type: Phaser.AUTO,
      parent: "mongen-pro-player",
      width: 512,
      height: 512,
      scene: Example,
      transparent: true,
      backgroundColor: "rgba(255,110,110,1)",
      plugins: {
        scene: [
          {
            key: "SpinePlugin",
            // @ts-ignore
            key: window.SpinePlugin.name,
            // @ts-ignore
            plugin: window.SpinePlugin,
            mapping: "spine",
            drawDebug: true,
          },
        ],
      },
    };
    const game = new Phaser.Game(config);
  }
  return (
    <div>
      <div
        id={"mongen-pro-player"}
        style={{ width, height }}
        className={className}
      ></div>
    </div>
  );
}
function Soulcore({ dna, width, height, className }) {
  const [opacity, setOpacity] = useState(0);
  const [instance, setInstance] = useState(null);
  const playerId = useMemo(() => {
    return `monsterra-player-${getId()}`;
  });

  function setAnim() {
    if (!instance) {
      return;
    }
    setOpacity(100);
    instance.skeleton.setSkinByName(`Skin${dna[1][0] + 1}`);
    instance.setAnimation("Hold", true);
    instance.skeleton.setToSetupPose();
  }
  useEffect(() => {
    setAnim();
  }, [instance]);
  useEffect(() => {
    let race = dna[0][0];
    let raceNameMap = ["beast", "tectos", "mystic", "celest"];
    let inst = null;
    switch (race) {
      case 0:
      case 1:
      case 2:
        inst = new window.spine.SpinePlayer(playerId, {
          premultipliedAlpha: true,
          animation: "Hold",
          alpha: true,
          alpha: true,
          showControls: false,
          backgroundColor: "#00000000",
          jsonUrl: `/soulcore/${raceNameMap[race]}/Egg_Tectos_1.json`,
          atlasUrl: `/soulcore/${raceNameMap[race]}/Egg_Tectos_1.atlas`,
          success: () => {
            setInstance(inst);
          },
          error: () => {},
        });
        break;
      case 3:
        inst = new window.spine.SpinePlayer(playerId, {
          premultipliedAlpha: true,
          animation: "Hold",
          alpha: true,
          alpha: true,
          showControls: false,
          backgroundColor: "#00000000",
          jsonUrl: `/soulcore/celest/Egg_Celest.json`,
          atlasUrl: `/soulcore/celest/Egg_Celest.atlas.txt`,
          success: () => {
            setInstance(inst);
          },
          error: () => {},
        });
        break;
    }

    return () => {};
  }, []);
  return (
    <div>
      <div
        id={playerId}
        style={{ width, height, opacity }}
        className={className}
      ></div>
    </div>
  );
}
function MongenOpening({ dna, width, height, className, type }) {
  const [opacity, setOpacity] = useState(0);
  const playerId = useMemo(() => {
    return `player-${getId()}`;
  });
  function getSkinList() {
    function gen(bodypart, name) {
      return `${RACES[dna[bodypart][0]]}/${TIERS[dna[bodypart][1]]}/${String(
        dna[bodypart][2]
      ).padStart(2, "0")}/${name}`;
    }
    function genBack(bodypart, name) {
      return `${RACES[dna[bodypart][0]]}/${TIERS[dna[bodypart][1]]}/${String(
        dna[bodypart][2]
      ).padStart(2, "0")}/${name}_${BackMap[dna[BodyPart.Form][0]]}`;
    }

    //form
    return [
      //mouth
      gen(BodyPart.Mouth, "Mouth"),
      gen(BodyPart.FrontLeg, "FrontLegs"),
      gen(BodyPart.BackLeg, "BackLegs"),
      genBack(BodyPart.Back, "Back"),
      genBack(BodyPart.Tail, "Tail"),
      gen(BodyPart.Horns, "Horns"),
      genBack(BodyPart.Eyes, "Eyes"),
      genBack(BodyPart.Head, "Head"),
      gen(BodyPart.Form, "Body"),
    ];
  }
  function getAnimName(anim) {
    let name = RACES[dna[BodyPart.Form][0]];
    return `${name}/${name}_${anim}`;
  }
  useEffect(() => {
    let instance = new window.spine.SpinePlayer(playerId, {
      premultipliedAlpha: true,
      alpha: true,
      showControls: false,
      backgroundColor: "#00000000",
      jsonUrl: "images/unboxing/Pets.20220111.json",
      atlasUrl: "images/unboxing/Pets.20220111.atlas.txt",
      success: () => {
        setOpacity(100);
        let skins = getSkinList();
        let mainSkin = new window.spine.Skin(skins[0]);

        for (var i = 0; i < skins.length; i++) {
          mainSkin.addSkin(instance.skeleton.data.findSkin(skins[i]));
        }
        instance.skeleton.setSkin(mainSkin);
        instance.skeleton.setToSetupPose();
        instance.config.animation = getAnimName(
          type === "BOX" ? "IdleWorking" : "IdleWorking2"
        );
        instance.playTime = 0;
        instance.setAnimation(
          getAnimName(type === "BOX" ? "IdleWorking" : "IdleWorking2"),
          true
        );
      },
      error: () => {},
    });
    return () => {};
  }, []);
  return (
    <div className={className}>
      <div id={playerId} style={{ width, height, opacity }}></div>
    </div>
  );
}
export default function Anim({
  dna,
  width,
  height,
  setAnim,
  anim,
  lock,
  className,
  unboxing,
  type,
}) {
  if (unboxing) {
    return (
      <MongenOpening
        width={250}
        height={250}
        dna={[
          [0, 2, 1],
          [0, 2, 1],
          [0, 2, 1],
          [0, 2, 1],
          [0, 2, 1],
          [0, 2, 1],
          [0, 2, 1],
          [0, 2, 1],
          [0, 2, 1],
          [0, 2, 1],
        ]}
        className={className}
        type={type}
      />
    );
  }
  if (dna?.length === 2) {
    return (
      <Soulcore dna={dna} width={width} height={height} className={className} />
    );
  } else {
    return (
      <Mongen
        lock={lock}
        dna={dna}
        width={width}
        height={height}
        setAnim={setAnim}
        anim={anim}
        className={className}
      />
    );
  }
}
