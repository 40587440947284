import { useWeb3React } from "@web3-react/core";
import { Col, Menu, Row } from "antd";
import cliTruncate from "cli-truncate";
import copy from "copy-to-clipboard";
import React, { useEffect, useMemo, useState } from "react";
import CustomDropdown from "../../../../components/elements/dropdown-custom";
import {
  USER_WALLET_TYPE,
  WALLET_TYPE,
  walletConnector,
  walletConnectorAvax,
  walletConnectorOkex,
} from "../../../../constants";
import { withContext } from "../../../../hook/AppContext";
import { useWindowResizeMobile } from "../../../../hook/ultis.hook";
import { getUser, getWalletType } from "../../../../ultis/common";
import "./style.scss";

function UserLoggedIn(props) {
  const {
    user,
    logout,
    isAuthenticatedUser,
    balanceWallet,
    getBalanceWallet,
    chains,
    setUser,
  } = props;
  const [isMobile] = useWindowResizeMobile(769);

  const signOut = async () => {
    if (getWalletType() === USER_WALLET_TYPE.walletConnectAvax) {
      walletConnectorAvax.close();
    }
    if (getWalletType() === USER_WALLET_TYPE.walletConnect) {
      walletConnector.close();
    }
    if (getWalletType() === USER_WALLET_TYPE.walletConnectOkex) {
      walletConnectorOkex.close();
    }
    logout();
    window.location.reload();
  };

  const copyCodeToClipboard = () => {
    copy(user?.address);
  };

  useEffect(() => {
    if (isAuthenticatedUser) {
      getBalanceWallet();
    }
  }, []);

  useEffect(() => {
    let chainInfo;
    if (chains?.length && user?.address) {
      switch (user.walletType) {
        case WALLET_TYPE.bsc:
          chainInfo = chains?.find((el) => el?.sort_name.includes("BSC"));
          break;
        case WALLET_TYPE.terra:
          chainInfo = chains?.find((el) => el?.sort_name.includes("Terra"));
          break;
        case WALLET_TYPE.avax:
          chainInfo = chains?.find((el) => el?.sort_name.includes("AVAX"));
          break;
        case WALLET_TYPE.aura:
          chainInfo = chains?.find((el) => el?.sort_name.includes("Aura"));
          break;
        default:
        // code block
      }
    }
    if (chainInfo) {
      setUser({
        ...user,
        chainInfo,
      });
    }
  }, [chains]);

  const renderBalance = useMemo(() => {
    return balanceWallet?.map((el) => (
      <>
        <Menu.Item>
          <Row className="balance-item">
            <Col xs={12}>
              <img
                className="balance-item__icon"
                src={`/images/chain/${el.sortName.toLowerCase()}.svg`}
              />
              {el.sortName}
            </Col>
            <Col xs={12}>
              <img className="balance-item__icon" src="/images/rune2.png" />
              {el.balance}
            </Col>
          </Row>
        </Menu.Item>
        <Menu.Divider></Menu.Divider>
      </>
    ));
  }, [balanceWallet]);

  const dropdownMenu = (
    <Menu className="dropdown-menu-login">
      {isMobile && (
        <>
          <Menu.Item>
            <div className="address-block chain-block">
              <img
                className="logo-token"
                src={`/images/chain/${getUser()?.chainInfo?.sort_name?.toLowerCase()}.svg`}
              />
              <span>{getUser()?.chainInfo?.sort_name}</span>
            </div>
          </Menu.Item>
          <Menu.Divider></Menu.Divider>
        </>
      )}
      <Menu.Item
        key="user-address"
        className="copy-address"
        onClick={copyCodeToClipboard}
      >
        {user?.address
          ? cliTruncate(user?.address, 18, { position: "middle" })
          : ""}
        <img src="/images/copy.svg" />
      </Menu.Item>
      <Menu.Divider></Menu.Divider>
      {renderBalance}
      <Menu.Item key="sign-out" className="item-text">
        <a onClick={signOut}>Sign out</a>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="user-logged-block">
      <div className="dropdown-menu">
        <CustomDropdown
          overlay={dropdownMenu}
          placement="bottomRight"
          trigger={["click"]}
        >
          {!isMobile ? (
            <div className="address-block">
              {user?.address
                ? cliTruncate(user?.address, 10, { position: "middle" })
                : ""}
            </div>
          ) : (
            <img src="/images/user-mobile.svg" />
          )}
        </CustomDropdown>
      </div>
    </div>
  );
}

export default withContext(UserLoggedIn);
