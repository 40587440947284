import React, { useEffect, useState } from "react";
import { Row, Col, Button, Form } from "antd";
import "./style.scss";
import { withContext } from "../../../../../hook/AppContext";
import { getContractFee, getTokens, getUser, getDiscountPayment} from "../../../../../ultis/common";
import { FEE_TYPE} from "../../../../../constants";

function AcceptOffer(props) {
  const { orderInfor, nftData } = props;

  const [fee, setFee] = useState(0);
  const [token, setToken] = useState()

  const getFee = async () => {
    const address = nftData?.nft_info?.contract_address
      ? nftData?.nft_info?.contract_address
      : nftData?.contract;
    if (address && token) {
      const fee = await getContractFee(address, FEE_TYPE.default, getUser()?.chainInfo?.payment_contract);
      const discount =  await getDiscountPayment(token.contract_address,getUser()?.chainInfo?.payment_contract)

      setFee((fee- discount) / 100);
    }
  };

  useEffect(() => {
    const tokenObj = getTokens()?.find(el => el.contract_address.toLowerCase() == orderInfor?.payment_token.toLowerCase())
    setToken(tokenObj)
  }, [orderInfor])

  useEffect(() => {
    getFee();

  }, [nftData, token]);

  return (
    <>
      <div className="item-container modal-item-container">
        <div className="item-box offer-modal-box">
          <div className="info-item">
            <div className="name">Price</div>
            <div className="value d-block">
              <img className="rune" src={`/images/token/${token?.sort_name?.toLowerCase()}.svg`}></img>
              {`${orderInfor?.price} ${token?.sort_name}`}
            </div>
          </div>
        </div>
      </div>

      <div className="receive-note">
        You will receive{" "}
        <span className="received-mon">
          {((orderInfor?.price * (100 - fee)) ).toFixed(5)/ 100 || 0}
        </span>{" "}
        {token?.sort_name}
      </div>
      <div>Fee: {fee}%</div>
    </>
  );
}

export default withContext(AcceptOffer);
