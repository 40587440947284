import React, { useEffect, useState, useMemo } from "react";
import { Col } from "antd";
import { withContext } from "../../../../hook/AppContext";
import CustomButtonField from "../../../elements/button";
import CardComponent from "../../../common/card-component";

function CardItem(props) {
  const {
    onClick,
    el,
    index,
    isAdd,
    bundle,
    setStatusModal,
    setStatusTextModal,
    activeTab
  } = props;
  const [checkAdd, setCheckAdd] = useState(isAdd);
  const handleOnClick = () => {
    if (bundle.length === 20 && !checkAdd) {
      setStatusModal({ isNotify: true });
      setStatusTextModal({
        noti: `Bundle has a maximum of 20 items`,
      });
    } else {
      onClick();
      setCheckAdd(!checkAdd);
    }
  };
  useEffect(() => {
    setCheckAdd(isAdd);
  }, [isAdd, activeTab, el]);

  const renderCardFooter = () => (
    <CustomButtonField
      classNames="btn-card"
      onClick={handleOnClick}
      color={checkAdd ? "red" : "green"}
      size="medium"
    >
      {checkAdd ? "Cancel" : "Add"}
    </CustomButtonField>
  );
  const renderCard = useMemo(
    () => (
      <Col>
        <CardComponent
          key={index}
          cardFooter={renderCardFooter}
          nft_data={el}
          isInventory={true}
          token_id={el.nft_info?.token_id}
          callBack={handleOnClick}
          chain={el?.chain}
        />
      </Col>
    ),
    [bundle, checkAdd, isAdd, activeTab, el]
  );
  return renderCard;
}

export default withContext(CardItem);
