import React from "react";
import "./style.scss";
import { Row} from "antd";
import { withContext } from "../../../hook/AppContext";
import TableItem from "./table-item";
import Statistic from "./statistic"

function Home(props) {

  return (
  <div className="home-container">
    <Statistic/>
    <Row className="home-table-container">
      <TableItem title="Recently listed"/>
      <TableItem title="Recently sold" isHistory={true}/>
    </Row>
  </div>
  );
}

export default withContext(Home);
