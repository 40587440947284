import React, { useEffect, useMemo } from "react";
import {
  HashRouter as Router,
  Redirect,
  Route,
  Switch
} from "react-router-dom";
import Header from "../layouts/components/header";
import { I18nProvider, AppI18nProvider } from "../layouts/i18n";
import "../styles/App.scss";
import { AppUIProvider } from "../hook/AppContext";
import ConnectWallet from "./connect-wallet";
import ThemeProvider from "../hook/ThemeProvider";
import P2PMarket from "./p2p-market";
import Inventory from "./inventory";
import MyListing from "./my-listing";
import DetailPage from "./detail";
import CreateBundle from "./create-bundle";
import PrimaryMarket from "./primary-market";
import Boxes from "./boxes";
import ConfirmBundle from "./confirm-bundle";
import NFTGamePage from "./nft-game";
import NFTGameHomePage from './nft-game-home';
import Home from './home';
import SwiperCore, { Mousewheel, Pagination, Navigation } from 'swiper';
import { VERSION } from "../constants";
import { getUser } from "../ultis/common";
import MatchResult from "./match-result";
import AOS from 'aos';
import 'aos/dist/aos.css';
import ReactGA4 from "react-ga4";
import { Web3ReactProvider } from '@web3-react/core'
import Web3 from "web3";
import NFTInGamePage from "./nft-game-game";
import MainTainPage from "./maintain";

SwiperCore.use([Mousewheel, Pagination, Navigation]);

ReactGA4.initialize(process.env.REACT_APP_GA_ID,{debug: true});

const getLibrary = (provider) => {
  const library = new Web3(provider)
  library.pollingInterval = 12000
  return library
}

ReactGA4.initialize(process.env.REACT_APP_GA_ID);

function App() {
  useEffect(() => {
    AOS.init({once: true,})
    const localVer = localStorage.getItem("version");
    if (localVer) {
      if (VERSION != localVer) {
        localStorage.clear();
        localStorage.setItem("version", VERSION);
        window.location.reload(true);
      }
    } else {
      localStorage.clear();
      localStorage.setItem("version", VERSION);
    }
  }, []); 
  

  return <AppI18nProvider>
      <I18nProvider>
        <ThemeProvider>
          <Web3ReactProvider getLibrary={getLibrary}>
          <Router>
        <AppUIProvider>
            <div>
              <Header />
            <Switch >
              <Route exact path="/">
                <Redirect to="/home"/>
              </Route>
              <Route exact path="/p2p-market">
                < P2PMarket/>
              </Route>
              <Route exact path="/primary-market">
                < PrimaryMarket/>
              </Route>
              <Route exact path="/inventory">
                < Inventory />
              </Route>
              <Route exact path="/my-listing">
                < MyListing />
              </Route>
              <Route exact path="/detail">
                < DetailPage />
              </Route>
              <Route exact path="/create-bundle">
                < CreateBundle />
              </Route>
              <Route exact path="/connect-wallet">
                <ConnectWallet />
              </Route>
              <Route exact path="/confirm-bundle">
                < ConfirmBundle />
              </Route>
              <Route exact path="/nft-game">
                < NFTGamePage/>
              </Route>
              <Route exact path="/nft-game/home">
                < NFTGameHomePage/>
              </Route>
              <Route exact path="/nft-game/game">
                < NFTInGamePage/>
              </Route>
              <Route exact path="/match-result">
                <MatchResult />
              </Route>
              <Route exact path="/boxes">
                <Boxes/>
              </Route>
              <Route exact path="/home">
                <Home/>
              </Route>
              <Route exact path="/maintain">
                 <MainTainPage/>
              </Route>
             
            </Switch>
            </div>
        </AppUIProvider>
          </Router>
          </Web3ReactProvider>
        </ThemeProvider>
      </I18nProvider>
    </AppI18nProvider>;
}

export default App;
