import React, { useEffect } from "react";
import InventoryTemplate from "../../components/templates/inventory";
import SiteLayout from "../../layouts/site-layout";
import "./style.scss";

function Inventory(props) {
  return (
    <SiteLayout styleMarket="master-layout__market">
      <InventoryTemplate />
    </SiteLayout>
  );
}

export default Inventory;
