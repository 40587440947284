import React, { useEffect, useState } from "react";
import "./style.scss";
import nftABI from "../../../constants/ABI/nft.json";
import Web3 from "web3";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import {
  getBidInfor,
  getByUrl,
  getOrderInfor,
  getBundleInfo,
  getAllCollection,
  getListGame,
  getListMysteryBox,
  getInfoBundle,
  getNFTInfo,
} from "../../../api";
import DetailMonTemplate from "./detail-mon";
import DetailBundleTemplate from "./detail-bundle";
import DetailPrimaryTemplate from "./detail-primary";
import { getUser, queryContractTerra } from "../../../ultis/common";
import { withContext } from "../../../hook/AppContext";
import { WALLET_TYPE } from "../../../constants";
import keplrService from "../../../ultis/keplr";

function DetailTemplate(props) {
  const {
    statusModal,
    setVisibleModal,
    renderList,
    queryFromModal,
    setStatusModal,
    setStatusTextModal,
    isHome,
    chains,
  } = props;
  const location = useLocation();
  const [nftData, setNftData] = useState({ isPlot: true });
  const [orderInfor, setOrderInfor] = useState({});
  const [bidInfor, setBidInfor] = useState([]);
  const [ownerBid, setOwnerBid] = useState(false);
  const [isOwnerOrder, setIsOwnerOrder] = useState(false);
  const [isOwnerNft, setIsOwnerNft] = useState(true);

  const getOwnerTokenErc721 = async (contract_address, token_id, chain) => {
    if (chain.is_cosmos) {
      const result = await keplrService.queryContractSmart(contract_address, {
        owner_of: { token_id: token_id + "" },
      });
      return result.owner;
    } else {
      const web3 = new Web3(chain?.rpc);
      const contractInstance = new web3.eth.Contract(nftABI, contract_address);
      const ownerAddress = await contractInstance.methods
        .ownerOf(token_id)
        .call();
      return ownerAddress;
    }
  };

  const getUri = async (contract_address, token_id, chain) => {
    if (getUser()?.walletType === WALLET_TYPE.terra) {
      const result = await queryContractTerra(contract_address, {
        nft_info: { token_id: token_id + "" },
      });
      return result.token_uri;
    } else {
      const web3 = new Web3(chain?.rpc);
      const contractInstance = new web3.eth.Contract(nftABI, contract_address);
      const tokenURI = await contractInstance.methods.tokenURI(token_id).call();
      return tokenURI;
    }
  };

  useEffect(() => {
    let fn = async () => {
      setOrderInfor({});
      await getData();
    };
    fn();
  }, [location.search]);

  useEffect(() => {
    const fn = async () => {
      if (orderInfor?.owner_address) return;
      const query = queryString.parse(location.search, {
        ignoreQueryPrefix: true,
      });
      const chain = chains?.find((el) => el._id == query.chain);
      getOwner({ ...query, chain: chain });
    };
    fn();
  }, [orderInfor]);

  const getData = async () => {
    try {
      let query = queryString.parse(location.search, {
        ignoreQueryPrefix: true,
      });
      query = queryFromModal ? queryFromModal : query;
      const chain = chains.find((el) => el._id == query.chain);
      const resGame = await getListGame();
      const collection = await getAllCollection({
        game_id: resGame[0]._id,
      });
      if (query.bundle_id) {
        getBundle(query);
      } else if (query.box_id) {
        getMysteryBox(query);
      } else {
        if (
          collection.find((el) =>
            el?.list_contract?.find(
              (item) =>
                item.address.toLowerCase() == query?.contract?.toLowerCase()
            )
          )
        ) {
          getNFT({ ...query, chain: chain });
          getOrder({ ...query, chain: chain });
          getBid(query);
          getOwner({ ...query, chain: chain });
        } else {
          setStatusModal({ isFailed: true });
          setStatusTextModal({
            fail: "This NFT is not supported!",
          });
        }
      }
    } catch (err) {
      console.log({ err });
    }
  };

  useEffect(() => {
    let fn = async () => {
      if (statusModal?.isSuccess) {
        await getData();
      }
    };
    fn();
  }, [statusModal]);

  const getMysteryBox = async (query) => {
    const resCol = await getListGame();
    const listOrder = await getListMysteryBox({
      game_id: resCol[0]._id,
    });
    setNftData(listOrder);
    // if (isAuthenticatedUser) {
    //   const totalBuyed = await Promise.all(
    //     listOrder.map((el) => {
    //       return getTotalBuyBox(el.box_id);
    //     })
    //   );
    //   setOrderInfor({ ...listOrder, totalBuyed: totalBuyed });
    // } else {
    //   setOrderInfor({ ...listOrder, totalBuyed: -1 });
    // }
    console.log("listOrder", listOrder);
    setOrderInfor(listOrder);
    if (listOrder[0]?.owner_address?.toLowerCase() === getUser()?.address) {
      setIsOwnerOrder(true);
    }
  };

  const getOwner = async (query) => {
    try {
      if (!query?.token) return;
      const res = await getOwnerTokenErc721(
        query?.contract,
        query?.token,
        query?.chain
      );
      if (
        res?.toLowerCase() === getUser()?.address ||
        orderInfor?.owner_address?.toLowerCase() === getUser()?.address
      ) {
        setIsOwnerNft(true);
        return;
      } else setIsOwnerNft(false);
    } catch (err) {
      console.log({ err });
    }
  };

  const getOrder = async (query) => {
    const res = await getOrderInfor({
      contract_address: query.contract,
      token_id: query.token,
      chain: query.chain._id,
    });
    const bundleInfo = await getInfoBundle({
      contract_address: query.contract,
      token_id: query.token,
      chain: query.chain._id,
    });
    if (
      res?.owner_address?.toLowerCase() === getUser()?.address ||
      bundleInfo?.owner_address === getUser()?.address
    ) {
      setIsOwnerOrder(true);
    } else {
      setIsOwnerOrder(false);
    }
    if (res?.owner_address) setOrderInfor({ ...res, chain: query.chain });
    else setOrderInfor(res);
  };

  const getParentsItem = async (query, resNFT) => {
    let listUri = [];
    let parents = [];

    if (resNFT?.details?.parents && resNFT?.details?.parents[0]?.uri) {
      Object?.values(resNFT?.details?.parents).forEach((val) => {
        if (val?.uri) {
          listUri.push(getByUrl(val.uri));
        }
      });
      try {
        parents = await Promise.all(listUri);
        parents.map((val, index) => {
          parents[index] = {
            ...val,
            dna_raw: resNFT?.details?.parents[index].dna_raw,
            token: resNFT?.details?.parents[index].value,
            is_mongen: true,
          };
        });
        if (parents.length) {
          setNftData({ ...query, ...resNFT, parents });
        }
      } catch (err) {
        console.log(err);
        setNftData({ ...query, ...resNFT });
      }
    } else {
      setNftData({ ...query, ...resNFT });
    }
  };

  const getNFT = async (query) => {
    let nftInfor = await getNFTInfo({
      contract_address: query.contract,
      token_id: query.token,
      chain: query.chain._id,
    });
    let res = nftInfor?.token_uri;
    if (!res) {
      res = await getUri(query.contract, query.token, query.chain);
    }
    let resNFT = {};
    try {
      resNFT = await getByUrl(res);
    } catch (er) {
      resNFT = nftInfor;
    }

    resNFT = {
      ...resNFT,
      isOperatorNFT: nftInfor.isOperatorNFT,
      description: resNFT?.description ?? nftInfor.description,
      static_image: nftInfor.static_image,
      nft_info: nftInfor.nft_info,
      chain: nftInfor?.chain,
    };
    console.log({res, resNFT})
    if (res?.includes("get-land")) {
      getPlots(query, resNFT);
    } else if (res?.includes("get-plot")) {
      const decorationParts = resNFT.attributes.find(
        (el) => el.trait_type == "Decoration parts"
      );
      if (decorationParts) {
        setNftData({
          ...query,
          valueDecoration: decorationParts?.value,
          isOperatorNFT: nftInfor.isOperatorNFT,
          static_image: nftInfor.static_image,
          name: resNFT.name,
          image: resNFT.image,
          isPlot: true,
          meta: resNFT.meta,
          chain: nftInfor?.chain,
        });
      } else setNftData({ ...query, ...resNFT, isPlot: true });
    } else if (res?.includes("get-treasury-box") || res?.includes("get-box")) {
      setNftData({ ...query, ...resNFT, isTreasuryBox: true });
    } else if (res?.includes("get-rune")) {
      console.log({query, resNFT})
      setNftData({ ...query, ...resNFT, static_image: true, isRune: true });
    } else {
      getParentsItem(query, resNFT);
    }
  };

  const getPlots = async (query, resNFT) => {
    let listUri = [];
    let plots = [];

    if (resNFT?.details?.plots) {
      Object?.values(resNFT?.details?.plots).forEach((val) => {
        listUri.push(getByUrl(val.find((el) => el.name == "URI").value));
      });
      try {
        plots = await Promise.all(listUri);
        if (plots.length) {
          setNftData({ ...query, ...resNFT, plots, isLand: true });
        }
      } catch (err) {
        console.log(err);
        setNftData({ ...query, ...resNFT });
      }
    } else {
      setNftData({ ...query, ...resNFT });
    }
  };

  const getBid = async (query) => {
    const res = await getBidInfor({
      contract_address: query.contract,
      token_id: query.token,
      chain: query.chain,
    });
    const itemBid = res.findIndex(
      (item) =>
        item?.owner_address?.toLowerCase() === getUser()?.address.toLowerCase()
    );
    if (itemBid != -1) {
      res.unshift(res.splice(itemBid, 1)[0]);
      setOwnerBid(res[0]);
    } else {
      setOwnerBid();
    }
    setBidInfor(res);
  };

  const getBundle = async (query) => {
    const res = await getBundleInfo(query);
    if (res?.owner_address?.toLowerCase() === getUser()?.address) {
      setIsOwnerOrder(true);
    }
    setOrderInfor(res);
  };

  return (
    <>
      {orderInfor?.bundle_id && (
        <DetailBundleTemplate
          bundleInfor={orderInfor}
          isOwnerBundle={isOwnerOrder}
          setVisibleModal={setVisibleModal}
          renderList={renderList}
          isHome={isHome}
        />
      )}
      {orderInfor[0]?.box_info && (
        <DetailPrimaryTemplate
          nftData={nftData}
          orderInfor={orderInfor}
          setOrderInfor={setOrderInfor}
          bidInfor={bidInfor}
          isOwnerOrder={isOwnerOrder}
          ownerBid={ownerBid}
          isOwnerNft={isOwnerNft}
        />
      )}
      {!orderInfor[0]?.box_info && !orderInfor?.bundle_id && (
        <DetailMonTemplate
          nftData={nftData}
          orderInfor={orderInfor}
          bidInfor={bidInfor}
          isOwnerOrder={isOwnerOrder}
          ownerBid={ownerBid}
          isOwnerNft={isOwnerNft}
          setVisibleModal={setVisibleModal}
          renderList={renderList}
        />
      )}
    </>
  );
}

export default withContext(DetailTemplate);
