import { Col, Popover, Row } from "antd";
import React, { useMemo } from "react";
import { withContext } from "../../../../../hook/AppContext";
import S3Image from "../../../../common/s3-image";

import Rarity from "../../../../elements/rarity";
import Star from "../../../../elements/star";
import Stats from "../../../../elements/stats";

function DescriptionTab(props) {
  const { nftData, orderInfor, renderLinkImage } = props;

  const renderRarityBox = useMemo(() => {
    if (!orderInfor?.box_info?.attributes) return;
    let listAb = [];
    Object.values(orderInfor?.box_info?.attributes).forEach((val, index) => {
      let item;
      if (val.trait_type === "Evolution") {
        item = (
          <Star
            className="box evolution"
            readonly={true}
            key={index * 3}
            initialRating={val.value}
          />
        );
      } else if (val.trait_type === "Rarity") {
        const modifiedVal = {
          name: val?.trait_type,
          value: val?.value,
        };
        item = (
          <div className="box rarity" key={index * 11}>
            <Rarity {...modifiedVal} />
          </div>
        );
      } else {
        item = (
          <div className="box yellow other" key={index * 8}>
            <div className="box-name">{val.trait_type}</div>
            <div className="box-value">
              <div className="box-line"></div>
              <div className="content">{val.value}</div>
            </div>
          </div>
        );
      }
      listAb.push(item);
    });
    return listAb;
  }, [orderInfor]);

  const renderAbout = useMemo(() => {
    if (!nftData?.details?.about) return;
    let listAb = Array(3);
    Object.values(nftData?.details?.about).forEach((val, index) => {
      let item;
      if (val.name === "Race") {
        listAb[0] = (
          <div className="box" key={index * 2}>
            <div className="box-name">Race</div>

            <div className="box-value">
              <div className="rarity-race">
                {val.value && val.value?.toLowerCase() != "all" && (
                  <img
                    className="rarity-race__rarity"
                    src={`/images/race-icon/${val.value?.toLowerCase()}.png`}
                    alt="rarity"
                  />
                )}
                <div className="content">{val.value}</div>
              </div>
            </div>
          </div>
        );
      } else if (val.name === "Rarity") {
        listAb[1] = (
          <div className="box" key={index * 11}>
            <div className="box-name">Rarity</div>
            <div className="rarity-race">
              {val.value && (
                <img
                  className="rarity-race__rarity"
                  src={`/images/rarity-icon/${val.value?.toLowerCase()}.svg`}
                  alt="rarity"
                />
              )}
              <div className="content">{val.value}</div>
            </div>
          </div>
        );
      } else if (val.name === "Plot Type") {
        listAb[1] = (
          <div className="box" key={index * 11}>
            <div className="box-name">Plot Type</div>
            <div className="content">{val.value}</div>
          </div>
        );
      } else if (val.name === "Children") {
        listAb[2] = (
          <div className="box" key={index * 3}>
            <div className="box-name">BREED COUNT</div>
            <div className="box-value">
              <div className="content">
                {val.value} / {val.max}
              </div>
            </div>
          </div>
        );
      }
    });
    return listAb;
  }, [nftData]);

  const renderStats = useMemo(() => {
    if (!nftData?.details?.stats) return;
    let listAb = [];
    let troop;
    Object.values(nftData?.details?.stats).forEach((val) => {
      let item;
      if (val?.name == "Trainable troops") {
        troop = (
          <Row className="stat-item troop-content">
            <Col className="square">
              <img
                className="square-icon"
                src={`/images/detail-icon/${val?.name.replace(" ", "")}.png`}
              />
            </Col>
            <Col span={10}>{val?.name}</Col>
            <Col className="troop-container" span={10}>
              {val?.value?.map((el) => (
                <Col className="troop-icon">
                  <img
                    src={`/images/rarity-icon/${el.toLowerCase()}.svg`}
                    alt="rarity"
                  />
                </Col>
              ))}
            </Col>
          </Row>
        );
      } else item = <Stats {...val} isPlot={nftData?.isPlot} />;
      listAb.push(item);
    });
    return (
      <>
        {!nftData?.isPlot && (
          <Row className="stat-item">
            <Col className="square"></Col>
            <Col md={6} xs={3}></Col>
            <Col span={4} className="stat-value title">
              Base
            </Col>
            <Col span={4} className="stat-value title">
              Mutated
            </Col>
            <Col span={4} className="stat-value title">
              Current
            </Col>
            <Col span={4} className="stat-value title">
              Max
            </Col>
          </Row>
        )}
        {listAb}
        {troop}
      </>
    );
  }, [nftData]);

  const renderContent = (el) => {
    const { additionIcons, description, image, name, stats } = el;
    return (
      <div className="popover-content">
        {name}
        <div className="popover-container">
          <Row>
            <Col span={12} className="popover-container__left">
              <img src={renderLinkImage(image)} className="detail-image" />
              <Row className="detail-attribute">
                {additionIcons.map((val) => (
                  <img
                    src={renderLinkImage(val)}
                    className="detail-attribute__icon"
                    alt="detail"
                  />
                ))}
              </Row>
            </Col>
            <Col span={12}>
              {Object.values(stats).map(
                (el, index) =>
                  el != null && (
                    <Row className="popover-container__right">
                      <Col span={12}>
                        <img
                          className="skill-troop"
                          src={`/images/skill-troop/${Object.keys(stats)[
                            index
                          ]?.toLowerCase()}.png`}
                          alt="skill"
                        />
                      </Col>
                      <Col span={12}>{el}</Col>
                    </Row>
                  )
              )}
            </Col>
          </Row>
          <div className="popover-container__description">{description}</div>
        </div>
      </div>
    );
  };

  const renderSkills = useMemo(() => {
    if (!nftData?.skills) return;
    let listAb = [];
    nftData?.skills.forEach((val) => {
      let item = (
        <>
          {val.type}
          <Row className="skill-item" gutter={{ xs: 8, sm: 16, md: 24 }}>
            {val?.skillInfos?.map((el) => (
              <Col>
                <Popover
                  placement="topLeft"
                  trigger="hover"
                  content={renderContent(el)}
                >
                  <div>
                    <S3Image
                      src={renderLinkImage(el?.image)}
                      className={`skill-image ${
                        el.isActive && "skill-image-active"
                      }`}
                    />
                  </div>
                </Popover>
              </Col>
            ))}
          </Row>
        </>
      );

      listAb.push(item);
    });
    return listAb;
  }, [nftData]);

  const renderRunes = useMemo(() => {
    if (!nftData?.runeInfos) return;
    let listAb = [];
    let item = (
      <>
        <Row className="skill-item" gutter={{ xs: 8, sm: 16, md: 24 }}>
          {nftData?.runeInfos?.map((el) => {
            if (!el) return;
            return (
              <Col>
                <Popover
                  placement="topLeft"
                  trigger="hover"
                  content={renderContent({
                    ...el,
                    additionIcons: [],
                    stats: {},
                  })}
                >
                  <div>
                    <S3Image
                      src={renderLinkImage(el?.image)}
                      className={`skill-image ${
                        el.isActive && "skill-image-active"
                      }`}
                    />
                  </div>
                </Popover>
              </Col>
            );
          })}
        </Row>
      </>
    );

    listAb.push(item);
    return listAb;
  }, [nftData]);

  return (
    <div className="description-tab">
      {nftData?.details?.about.length > 1 && (
        <div className="item-container about-list">
          <div className="detail-item">About</div>
          <Row className="item-box" gutter={[0, 24]}>
            {renderAbout}
          </Row>
        </div>
      )}

      {nftData?.details?.skills && (
        <div className="item-container about-list">
          <div className="box-list">{renderRarityBox}</div>
        </div>
      )}

      {!!nftData?.details?.stats?.length && (
        <div className="item-container">
          <div className="detail-item">Stats</div>
          <div className="item-box stat-list">{renderStats}</div>
        </div>
      )}

      {nftData?.skills && (
        <div className="item-container">
          <div className="detail-item">Skills</div>
          <div className="item-box skill-block">{renderSkills}</div>
        </div>
      )}

      {nftData?.runeInfos && (
        <div className="item-container">
          <div className="detail-item">Runes</div>
          <div className="item-box skill-block">{renderRunes}</div>
        </div>
      )}
    </div>
  );
}

export default withContext(DescriptionTab);
