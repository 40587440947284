import React, { useEffect } from "react";
import DetailTemplate from "../../components/templates/detail";
import SiteLayout from "../../layouts/site-layout";
import "./style.scss";

function DetailPage(props) {
    return (
        <SiteLayout>
            <DetailTemplate />
        </SiteLayout>
    );
}

export default DetailPage;
