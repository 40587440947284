import React, { useEffect } from "react";
import PrimaryMarketTemplate from "../../components/templates/primary-market";
import SiteLayout from "../../layouts/site-layout";
import "./style.scss";

function PrimaryMarket(props) {

    return (
        <SiteLayout noStyle>
            <PrimaryMarketTemplate />
        </SiteLayout>
    );
}

export default PrimaryMarket;
