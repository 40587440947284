import React, { useEffect, useState, useCallback } from "react";
import "./style.scss";
import FilterComponent from "../../common/filter-component";
import CardComponent from "../../common/card-component";
import { getListGame, getListOrderOwner } from "../../../api";
import { Row, Tabs, Col, Pagination } from "antd";
import {
    approveWallet,
    getUser,
    handleResultTransaction,
    handleTransactionExWallet,
    sleep
} from "../../../ultis/common";
import Web3 from "web3";
import marketABI from "../../../constants/ABI/market.json"
import tokenABI from "../../../constants/ABI/token.json"
import { ACTION_SEND_TRANSACTION } from "../../../constants";
import { message } from "antd";
import CustomButtonField from "../../elements/button";
import ConfirmModal from "../../../components/templates/inventory/modal-confirm";
import ProgressModal from "../../common/progress-modal"
import { withContext } from "../../../hook/AppContext";
import useBlockchainFunc from "../../../ultis/blockchain";



function MyListingTemplate(props) {
    const {setStatusModal} = props
    const { TabPane } = Tabs;
    const [showModal, setShowModal] = useState(false);
    const[nftUpdate, setNftUpdate] = useState()
    const [listCol, setListCol] = useState([]);
    const [dataCol, setDataCol] = useState([]);
    const [activeTab, setActiveTab] = useState(0);
    const [listItem, setListItem] = useState({});
    const [filterSort, setFilterSort] = useState({});
    const [paging, setPaging] = useState({});
    const [totalItem, setTotalItem] = useState();
    const {cancelOrder, updateOrder} = useBlockchainFunc()

    useEffect(async () => {
        const resCol = await getListGame();
        let listNFT = {};
        let page = {};
        const colection = {};
        resCol.map((el) => {
            page[el._id] = {
                currentPage: 1,
            }
            colection[el._id] = el;
            listNFT[el._id] = <></>;
        });
        setPaging(page);
        setActiveTab(resCol[0]._id);
        setListItem(listNFT);
        setDataCol(colection);
    }, []);

    useEffect(() => {
        renderListCol();
    }, [dataCol, listItem]);

    useEffect(() => {
        if (Object.keys(dataCol).length) {
            renderList();
        }
    }, [activeTab, dataCol, filterSort, paging]);


    const renderList = async (params) => {
        const listOrder = await getListOrderOwner({
            game_id: dataCol[activeTab]._id,
            page: paging[activeTab].currentPage,
            limit: 12,
            ...filterSort[activeTab],
        });
        setTotalItem(listOrder?.metadata?.total)
        const comp = listOrder?.data.map((el, index) => (
            
            <Col>
                <CardComponent
                    key={index}
                    cardFooter={() => renderCardFooter(el)}
                    nft_data={el.nft_data}
                    price={el.price}
                />
            </Col>

        ));
        setListItem((prevState) => {
            let newSta = Object.assign({}, prevState);
            newSta[activeTab] = comp;
            return newSta;
        });
    };
    const onChangeTab = (value) => {
        setActiveTab(value);
    };

    const onChangePage = (value) => {
        setPaging((prevState) => {
            let newSta = Object.assign({}, prevState);
            newSta[activeTab].currentPage = value;
            return newSta;
        });
    }

    const renderListCol = async (dataFilter) => {
        if (dataFilter?._id) {
            setFilterSort((prevState) => {
                let newSta = Object.assign({}, prevState);
                newSta[dataFilter?._id] = dataFilter;
                return newSta;
            });
        }
        let results = [];
        for (const key in dataCol) {
            let el = dataCol[key]
            results.push(
                <TabPane className="market-cont" tab={el.name} key={el._id}>
                    <Row className="list-item">
                        {listItem[el._id]}
                    </Row>
                    <Pagination
                        defaultCurrent={1}
                        total={totalItem}
                        pageSize={1}
                        current={paging[activeTab].currentPage}
                        onChange={onChangePage}
                    />
                </TabPane>
            );
        }
        setListCol(results);
    };


    const handleEdit = (el) => {

        setNftUpdate({
            ...el.nft_data,
            price: el.price,
            order_id: el.order_id
        })
        setShowModal(true)
    }

    const renderCardFooter = (el) => {
        return (
            <div className="card-footer">
                <CustomButtonField
                    onClick={
                        () => onCancelOrder(el)
                    }
                    classNames="btn-cancel"
                >
                    {"CANCEL"}
                </CustomButtonField>
                <CustomButtonField
                    onClick={() => handleEdit(el)}
                >
                    {"EDIT"}
                </CustomButtonField>
            </div>
        );
    };


    const onCancelOrder = async (data) => {
        cancelOrder(data, setStatusModal, renderList)
    };

    const onUpdateOrder = async (order_id, price) => {
        updateOrder({order_id, price}, setStatusModal)
    };

    return (
        <Row className="my-listing-page">
            <FilterComponent 
                {...dataCol[activeTab]}
                renderListCol={renderListCol}
            />
            <Row wrap={true} className="list-order">
                <Tabs defaultActiveKey="1" onChange={onChangeTab}>
                    {listCol}
                </Tabs>
            </Row>  
            <ConfirmModal nft={nftUpdate} onClick={onUpdateOrder} showModal={showModal} setShowModal={setShowModal} /> 
        </Row>
    );
}

export default withContext(MyListingTemplate);
