import React, { useEffect, useState, useMemo } from "react";
import { withContext } from "../../../hook/AppContext";
import useBlockchainFunc from "../../../ultis/blockchain";
import { useWindowResizeMobile } from "../../../hook/ultis.hook";
import { getUser } from "../../../ultis/common";
import { Row, Col } from "antd";
import "./style.scss";
import CustomModal from "../../elements/modal";
import RankingGame from "../../common/ranking-game";
import { getUserInfoGame } from "../../../api";
import moment from "moment";
import ReactGA4 from "react-ga4";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";

function NFTGamePage(props) {
  const {
    history,
    getRank,
    setStatusModal,
    setStatusTextModal,
    statusTextModal,
    gameInfo
  } = props;
  const { registerNFTGame, checkRegister } = useBlockchainFunc();
  const [showRanking, setShowRanking] = useState(false);
  const [showData, setshowData] = useState(true);
  const [activeSeason, setActiveSeason] = useState({});
  const [activeGame, setActiveGame] = useState(2);
  const [isMobile] = useWindowResizeMobile(576);
  const [isRegister, setIsRegister] = useState(false);
  const [showReward, setShowReward] = useState(false);
  const [infoUser, setInfoUser] = useState();

 useEffect(async () => {
    getRank(activeGame)
  }, [])

  useEffect(async () => {
    try {      
      setshowData(false);
      console.log("gameInfo",gameInfo)
      setActiveSeason(gameInfo?.seasons[0][0]);
      // const check = await checkRegister();
      // if (check) {
      //   setIsRegister(true);
      // }
      setshowData(true);
      const userInfo = await getUserInfoGame({game_id: gameInfo?.listGame[activeGame]?._id});
      setInfoUser(userInfo);
    } catch (er) {
        console.log(er);
      setActiveSeason();
    }

    return () => {};
  }, [activeGame, gameInfo]);

  const renderNumber = (number) => {
    switch (number) {
      case 1:
        return <div className="reward-box__text name-prize"><img className="ranking-number" src="/images/first-prize.svg" /> Top 1</div>;
      case 2:
        return (
          <div className="reward-box__text name-prize"><img className="ranking-number" src="/images/second-prize.svg" /> Top 2</div>
        );
      case 3:
        return <div className="reward-box__text name-prize"><img className="ranking-number" src="/images/third-prize.svg" /> Top 3</div>
      default:
        return <div className="ranking-number">{number}</div>;
    }
  };

  const renderPlay = useMemo(() => {
    const turn_time = 7200000 
    const total_time = infoUser?.time_fully_recovery_turn *1000
    const remainder = (total_time - Date.now()-1000) % turn_time
    const quotient = (total_time - Date.now()-1000)/ turn_time
    let turn = total_time-1000  < Date.now() ? 5 : 5 - parseInt(quotient)
    turn =  remainder > 0 ? turn -1 : turn
    const totalTurn =  turn + infoUser?.bonus_turn

    return <>
    <div className="big-text">Play Now</div>
      <div className="small-text"><img src="/images/energy.svg"/> {totalTurn >= 0 ?  totalTurn : `--`}/5</div>
    </>
  },[infoUser])

  const handleRedirect = () => {
    if(activeGame == 0) {
      if (infoUser?.is_ready_battle) {
        if (!history?.location?.pathname?.includes("/nft-game/home")) {
          history.push("/nft-game/home");
        }
      } else {
        if (!history?.location?.pathname?.includes("/nft-game/home")) {
          history.push("nft-game/home?tab=MyDeck");
          setStatusModal({ isSuccess: true });
          setStatusTextModal({
            ...statusTextModal,
            success: "You must create a deck to play.",
          });
        }
      }
    } else {
      history.push("/nft-game/game");
    }
    
  };

  const register = async () => {
    const user = getUser();
    // let check = isRegister;
    // await getRank(activeGame);
    // if (!check) {
    //   check = await registerNFTGame(user?.address, gameInfo?.listGame[activeGame].game_index);
    //   ReactGA4.event("underhand_register", user?.address);
    // }
    // if (check) {
      handleRedirect();
      // setIsRegister(true);
      ReactGA4.event("underhand_play", user?.address);
    // }
  };

  const onRules = () => {
    window.open(
      "https://marketplace-monsterra.gitbook.io/marketplace-monsterra/",
      "_blank"
    );
  };

  const changeActiveSeason = (el) => {
    setActiveSeason(el);
  };

  const onClickView = () => {
    setShowRanking(true);
    setShowReward(true);
  };

  const onChangeGame = (index) => {
      console.log(index);
      setActiveGame(index)
  }

  return (
    <>
      <div className={`register-game-page game-${activeGame}` }>
        <div className="layout-content">
          <div className="logo-block">
            <img src={`images/game-title-${activeGame}.png`} className="title-game" />
            {!activeSeason ? (
              <div className="no-seas-txt">No season available</div>
            ) : (
              <>
                
                <div className="reward-box">
                    {/* <div className="reward-box__title">Rewards </div>
                    <div className="reward-box__numb-txt">
                      <div className="numb-txt">
                        {activeSeason?.total_user_registered} registered players
                      </div>
                      <div
                        className="btn-block-2"
                        data-size="small"
                        onClick={() => setShowRanking(true)}
                      >
                        Rankings
                      </div>
                    </div> */}
                    {activeSeason?.start_time && (
                      <div className="time-txt">
                        {`${moment
                          .utc(moment.unix(activeSeason?.start_time))
                          .format("h:mm A UTC, MMMM D")} - ${moment
                          .utc(moment.unix(activeSeason?.end_time))
                          .format("h:mm A UTC, MMMM D, YYYY")}`}
                      </div>
                    )}
                    <div className="reward-box__item">
                      {renderNumber(1)}
                      <div className="reward-box__text">
                        <img src="/images/token/usdt.svg" />
                        <div>
                          x{parseInt(activeSeason?.reward?.no1?.reward) || 0}{" "}
                          USDT
                        </div>
                      </div>
                    </div>
                    <div className="reward-box__item">
                      {renderNumber(2)}
                      <div className="reward-box__text">
                        <img src="/images/token/usdt.svg" />
                        <div>
                          x{parseInt(activeSeason?.reward?.no2?.reward) || 0}{" "}
                          USDT
                        </div>
                      </div>
                    </div>
                    <div className="reward-box__item">
                      {renderNumber(3)}
                      <div className="reward-box__text">
                        <img src="/images/token/usdt.svg" />
                        <div>
                          x{parseInt(activeSeason?.reward?.no3?.reward) || 0}{" "}
                          USDT
                        </div>
                      </div>
                    </div>
                    {/* <div className="reward-box__item">
                      <div className="reward-box__lucky">
                        Lucky (x{activeSeason?.reward?.lucky_reward?.limit || 0}
                        )
                      </div>
                      <div className="reward-box__text">
                        <img src="/images/token/usdt.svg" />
                        <div>
                          x
                          {parseInt(
                            activeSeason?.reward?.lucky_reward?.reward
                          ) || 0}{" "}
                          USDT{" "}
                        </div>
                      </div>
                    </div> */}
                      <div className="reward-box__detail" onClick={onRules}>{"Detail >> "}</div>
                  </div>
                  
                  {/* <div className="left-btns">
                    {activeSeason?.is_ended && (
                      <div
                        className="btn-block"
                        data-size="small"
                        onClick={onClickView}
                      >
                        Results
                      </div>
                    )}
                    <div className="btn-block big-txt" onClick={onRules}>
                      Rules & Rewards
                    </div>
                  </div> */}

                {showData &&
                  (activeSeason?.is_ended ? (
                    ""
                  ) : (
                    <div className="right-btns" onClick={register}>
                      <div className="btn-block btn-active btn-play" data-size="medium">
                        <div className="txt-block">
                          {/* {!isRegister ? (
                            <>
                              <div className="big-text">Register Now</div>
                              <div className="small-text">{activeSeason?.price
                                ? `${activeSeason?.price} USDT`
                                : "FREE"}</div>
                            </>
                          ) : renderPlay} */}
                          {renderPlay}
                        </div>
                      </div>
                    </div>
                  ))}
              </>
            )}
            {/* <div className="footer-btns">
              {gameInfo?.seasons[activeGame]?.map((el) => (
                <div
                  className={`btn-block ${
                    activeSeason?._id === el?._id ? "btn-active" : ""
                  }`}
                  data-size="small"
                  onClick={() => changeActiveSeason(el)}
                >
                  {el?.name}
                </div>
              ))}
            </div> */}
            {/* <div className="select-game">
                <div className="game-container">
                  {gameInfo?.listGame?.map((el, index) => (
                      <div className={`game-block ${index == activeGame ? 'active': ''}`} onClick={() => onChangeGame(index)}>
                        <img src={`images/logo-game-${index}.png`} className="logo-img"/>
                        <div className="game-name">{el?.game_info[0]?.info?.name}</div>
                      </div>
                  ))}
            </div>
            </div> */}
          </div>
        </div>
        {/* <CustomModal
          visible={showRanking}
          footer={null}
          onCancel={() => {
            setShowRanking(false);
            setShowReward(false);
          }}
          className={
            showReward ? "ranking-modal modal-reward" : "ranking-modal"
          }
        >
          <RankingGame
            classNames="modal-content"
            activeSeason={activeSeason}
            showReward={showReward}
            activeGame={activeGame}
          />
        </CustomModal> */}
      </div>
    </>
  );
}

export default withContext(NFTGamePage);
