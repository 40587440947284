import React from "react";
import "./style.scss";
import CustomModal from "../../elements/modal";
import CustomButton from "../../elements/button";
import SiteLayout from "../../../layouts/site-layout";
import { Row, Col } from "antd";
import CardComponent from "../card-component";

export const CardModal = (props) => {
    const { showModal, setShowhowModal, cards, setVisibleModal, chainInfo } = props;
    
    const onCancel = () => {
        if(setVisibleModal) setVisibleModal(false)
        setShowhowModal(false)
    }

    return (
        <CustomModal
            visible={showModal}
            footer={null}
            onCancel={onCancel}
            className="deck-modal card-modal"
        >
            <SiteLayout styleMarket="master-layout__market">
                <div className="title-modal">Congratulations on successfully unboxing, your NFT will be displayed in your inventory within the next 5-15 minutes.</div>
                {/* <div className="items-txt">{cards?.length} item(s)</div>
                <Row justify="center">
                    {cards?.map((el, index) => (
                        <Col>
                            <CardComponent
                                key={index}
                                nft_data={el}
                                token_id={
                                    el.nft_info ? el.nft_info?.token_id : null
                                }
                                quantity={el.amount}
                                noFooter={true}
                                setShowhowModal={setShowhowModal}
                                chain={chainInfo}
                            />
                        </Col>
                    ))}
                </Row> */}
            </SiteLayout>
        </CustomModal>
    );
};
