import React, { useMemo, useState } from "react";
import "./style.scss";
import { Row, Col, Tabs } from "antd";
import { getTokens } from "../../../../ultis/common";
import { useWindowResizeMobile } from "../../../../hook/ultis.hook";
import cliTruncate from "cli-truncate";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import DetailModal from "../../../common/detail-modal";
import Mongen from "../../../elements/static-anim/mongen";
import Decor from "../../../elements/static-anim/decor";
import { OPERATOR_ADDRESS } from "../../../../constants";

const TYPE_CARD = {
  MON: 1,
  BUN: "Bundles",
  MYSTERY_BOX: "Mystery",
};

function ProductItem(props) {
  const {
    nft_data,
    type,
    isItems,
    tx,
    transaction,
    price,
    payment_token,
    convert_price,
    quantity,
    isHistory,
    from_address,
    to_address,
    bundle_id,
    renderList,
    price_usdt,
    chain,
    time
  } = props;
  const [isMobile] = useWindowResizeMobile(1295);
  const [visibleModal, setVisibleModal] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const query = {
    contract:
      nft_data?.nft_info?.contract_address || nft_data?.contract_address,
    token: nft_data?.nft_info?.token_id || nft_data?.token_id,
    chain: chain._id,
  };

  const goDetailPage = async () => {
    if (!isItems && !(isHistory && type === TYPE_CARD.BUN)) {
      setVisibleModal(true);
      const pathname = location.pathname;
      window.history.pushState(null, "", `#${pathname}?tab=${type}`);
      if (type === TYPE_CARD.BUN) {
        window.history.replaceState(
          null,
          "",
          `#/detail?bundle_id=${bundle_id}&chain=${chain._id}`
        );
      } else {
        window.history.replaceState(
          null,
          "",
          `#/detail?contract=${
            nft_data?.nft_info?.contract_address || nft_data?.contract_address
          }&token=${nft_data?.nft_info?.token_id || nft_data?.token_id}&chain=${
            chain._id
          }`
        );
      }
    }
  };
  const renderRarityRace = () => {
    const rarity = nft_data?.attributes?.find(
      (el) => el.trait_type === "Rarity"
    );
    const race = nft_data?.attributes?.find((el) => el.trait_type === "Race");
    const amount = nft_data?.amount || quantity;
    if (isItems) {
      return <div className="rarity-race amount">Amount: {amount}</div>;
    } else {
      if (type !== TYPE_CARD.BUN)
        return (
          <div className={`rarity-race ${rarity?.value?.toLowerCase()}`}>
            {race?.value && race?.value != "All" && (
              <img
                alt="race"
                className="rarity-race__rarity"
                src={`/images/race-icon/${race?.value?.toLowerCase()}.png`}
              />
            )}
            #
            {nft_data?.token_id
              ? nft_data?.token_id
              : nft_data?.nft_info?.token_id}
          </div>
        );
      else
        return (
          <div className="rarity-race bundle">
            {" "}
            {nft_data?.quantity?.length} items
          </div>
        );
    }
  };

  const renderBreedCount = () => {
    const children = nft_data?.attributes?.find(
      (el) => el.trait_type === "Children"
    );
    return (
      <div className="breed-count">
        {" "}
        {children ? `Breed count: ${children?.value}` : nft_data?.name}
      </div>
    );
  };

  const renderAttri = () => {
    const level = nft_data?.attributes?.find((el) => el.trait_type === "Level");
    const arr = nft_data?.attributes?.filter(
      (item) =>
        item.trait_type === "Health" ||
        item.trait_type === "Sturdiness" ||
        item.trait_type === "Magic" ||
        item.trait_type === "Aggression" ||
        item.trait_type === "Spookiness"
    );
    return (
      <div className="attributes-children">
        <div className="attributes-children__item">
          {arr?.map((item) => (
            <div>
              <img
                alt="icon"
                src={`/images/detail-icon/${
                  item.trait_type == "Spookiness"
                    ? "Sturdiness"
                    : item.trait_type
                }.png`}
              />
              {item.value}
            </div>
          ))}
        </div>
        <div className="attributes-children__level">
          {level && (
            <>
              Lvl <div>{level?.value}</div> | {level?.max}{" "}
            </>
          )}
        </div>
      </div>
    );
  };

  const renderTime = (time) => {
    const timeConvert = moment.unix(time).fromNow();
    return timeConvert.includes("minute") || timeConvert.includes("seconds")
      ? moment.unix(time).fromNow()
      : moment.unix(time).format("MMM DD, YYYY");
  };

  const renderPrice = () => {
    const token = getTokens()?.find(
      (item) =>
        item?.contract_address.toLowerCase() == payment_token?.toLowerCase()
    )?.sort_name;

    return (
      <div className="product-item__price">
        <img
          src={`/images/chain/${chain?.sort_name?.toLowerCase()}.svg`}
          alt="chain"
        />
        <div className="price">
          {price} {token}
          <div className="purchase">
            <div className="purchase__icon">
              {" "}
              ~ <br /> ~{" "}
            </div>{" "}
            ${convert_price?.toFixed(2) || price_usdt?.toFixed(2)}
          </div>

          <div className="time">
            {renderTime(
              isHistory ? tx?.time / 1000 : (time ?? transaction?.create_time) / 1000
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderLinkImage = (image) => {
    // console.log("image", image);
    if (type === TYPE_CARD.BUN) return image;
    const array = image?.split("/");
    var newImage = "/images";
    for (let i = 3; i < array?.length; i++) {
      newImage = newImage + "/" + array[i];
    }
    return newImage.replace("+", "_");
  };

  const renderImage = useMemo(() => {
    // if(isMobile && (nft_data?.is_mongen || nft_data?.is_decor)){
    //   return <img  className="product-item__image" src={nft_data?.image} />
    // }
    if (nft_data?.is_mongen)
      return (
        <Mongen
          lock={[]}
          dna={nft_data?.dna_raw}
          className="product-item__image"
        />
      );
    if (nft_data?.is_decor)
      return (
        <div className="product-item__image">
          <Decor
            rarity={nft_data?.meta?.rarity}
            decoration_list={nft_data?.meta?.decoration_list}
            isDetail={true}
          />
        </div>
      );

    return (
      <img
        alt="card"
        className={
          isItems || type === TYPE_CARD.BUN
            ? "product-item__image items"
            : "product-item__image"
        }
        src={renderLinkImage(nft_data?.image)}
      />
    );
  }, [nft_data?.dna_raw, nft_data?.image]);

  const renderMobile = () => {
    return (
      <div className="product-item" onClick={goDetailPage}>
        <Row justify="space-between">
          <Col>
            <Row>
              <Col className="product-item__container">{renderImage}</Col>
              <Col>
                {renderRarityRace()}
                {renderBreedCount()}
              </Col>
            </Row>
          </Col>
          <Col>{renderPrice()}</Col>
        </Row>
        {isHistory ? (
          <>
            <Col className="history-from">
              FROM
              <div className="history-from-col">{`${
                OPERATOR_ADDRESS.includes(from_address.toLowerCase())
                  ? "Shop In-game"
                  : from_address
                  ? cliTruncate(from_address, 20, { position: "middle" })
                  : ""
              }`}</div>
            </Col>
            <Col className="history-from">
              TO
              <div className="history-from-col">{`${
                OPERATOR_ADDRESS.includes(to_address.toLowerCase())
                  ? "Shop In-game"
                  : to_address
                  ? cliTruncate(to_address, 20, { position: "middle" })
                  : ""
              }`}</div>
            </Col>
          </>
        ) : (
          <Col xs={10} className="attribute-container">
            {renderAttri()}
          </Col>
        )}
      </div>
    );
  };

  return (
    <>
      {isMobile ? (
        renderMobile()
      ) : (
        <Row onClick={goDetailPage} className="product-item">
          <Col className="product-item__container">{renderImage}</Col>
          <Col xs={5}>
            {renderRarityRace()}
            {renderBreedCount()}
          </Col>
          {isHistory ? (
            <>
              <Col xs={5} className="history-from">
                FROM
                <div className="history-from-col">{`${
                  OPERATOR_ADDRESS.includes(from_address.toLowerCase())
                    ? "Shop In-game"
                    : from_address
                    ? cliTruncate(from_address, 10, { position: "middle" })
                    : ""
                }`}</div>
              </Col>
              <Col xs={5} className="history-from">
                TO
                <div className="history-from-col">{`${
                  OPERATOR_ADDRESS.includes(to_address.toLowerCase())
                    ? "Shop In-game"
                    : to_address
                    ? cliTruncate(to_address, 10, { position: "middle" })
                    : ""
                }`}</div>
              </Col>
            </>
          ) : (
            <Col xs={10} className="attribute-container">
              {renderAttri()}
            </Col>
          )}
          <Col xs={6}>{renderPrice()}</Col>
        </Row>
      )}
      <DetailModal
        visible={visibleModal}
        setVisibleModal={setVisibleModal}
        url={`#${history.location.pathname}${history.location.search}`}
        query={bundle_id ? { bundle_id: bundle_id } : query}
        renderList={renderList}
        isHome={true}
      />
    </>
  );
}

export default ProductItem;
