import React, { useEffect, useState, useMemo } from "react";
import { Row, Col } from "antd";
import S3Image from "../../../../common/s3-image";
import { withContext } from "../../../../../hook/AppContext";
import "./style.scss";

function PlotItem(props) {
  const { plot } = props;

  const renderLinkImage = (image) => {
      const array = image?.split("/")
      var newImage = "/images" 
      for(let i=3; i< array?.length; i++){
          newImage = newImage +'/' + array[i]
      }
      return newImage.replace("+","_")
  }

  const renderPlotInfor = useMemo(() => {
    if (!plot?.details) return;
    const rarityItem = plot?.details?.about.find((el) => el.name === "Rarity");
    const levelItem = plot?.details?.about.find((el) => el.name === "Level");
    let item = (
      <div className="plot-info">
        <div className="box level" key={11}>
          <div className="content">
            <span className="level-title">Level</span>{" "}
            <span className="level-numb">{levelItem.value}</span> |{" "}
            {levelItem.max}
          </div>
          <div className="rarity-race">
            {rarityItem.value && (
              <img
                className="rarity-race__rarity"
                src={`/images/rarity-icon/${rarityItem.value?.toLowerCase()}.svg`}
              />
            )}
            <div className="value-name">{rarityItem.value}</div>
          </div>
        </div>
      </div>
    );

    return item;
  }, [plot]);

  return (
    <div className="plot-item">
      <Row>
        <Col xs={7}>
          <S3Image src={renderLinkImage(plot.image)} className="plot-image" />
        </Col>
        <Col xs={9}>
          <div className="plot-info">
            <div className="name">{plot.name}</div>
            <div className="info-type">
              {plot.attributes?.find((el) => el.trait_type === "Type").value}
            </div>
          </div>
        </Col>
        <Col xs={8}>{renderPlotInfor}</Col>
      </Row>
    </div>
  );
}

export default withContext(PlotItem);
