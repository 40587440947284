import React, { useEffect, useState, useMemo } from "react";
import "./style.scss";
import moment from 'moment';
import { withContext } from "../../../../hook/AppContext";

function CountDownBox(props) {
  const { time_end, detailBox} = props;
  const [time, setTime] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  })

    setTimeout(() => {
      const now = moment().unix();
      const countdown = time_end - now;
      if (countdown >= 0) {
        const days = Math.floor(countdown / 60 / 60 / 24);
        const hours = Math.floor((countdown / 60 / 60) % 24);
        const minutes = Math.floor((countdown / 60) % 60);
        const seconds = Math.floor(countdown % 60);

        setTime({
          days: days,
          hours: hours,
          minutes: minutes,
          seconds: seconds
        })
      }else setTime({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
      })
    }, 1000);
       

  return (
    detailBox ? 
    <div className="time-left">
      Start in
      <div className="time-value">{time.days}d  {time.hours}h: {time.minutes}m: {time.seconds}s</div>
    </div>
    :
    <div className="season-time__countdown">
      <div className="time-text">
        <div className="time-value">{time.days}</div>
        Days
      </div>
      <div className="time-text">
        <div className="time-value">{time.hours}</div>
        Hours
      </div>
      <div className="time-text">
        <div className="time-value">{time.minutes}</div>
        minutes
      </div>
      <div className="time-text">
        <div className="time-value">{time.seconds}</div>
        seconds
      </div>
    </div>
  )
}

export default withContext(CountDownBox);
