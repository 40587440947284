import React, { useEffect, useState, useMemo, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Table, Tabs } from "antd";
import { withContext } from "../../../../../hook/AppContext";
import GeneralModal from "../../../../elements/modal/general-modal";
import CustomButton from "../../../../elements/button";
import { useWeb3 } from "../../../../../ultis/common";
import Markdown from "../../../../common/markdown-component";
import useBlockchainFunc from "../../../../../ultis/blockchain";
import CountDownBox from "../../../../common/ranking-game/countdown-box";

const { TabPane } = Tabs;

function BoxItem(props) {
  const {
    t,
    statusModal,
    setStatusModal,
    callback,
    boxInfo,
    index,
    token,
    start_time,
    end_time,
  } = props;
  const [nftData, setNftData] = useState(boxInfo);
  const history = useHistory();
  const [isShowingPopup, setIsShowingPopup] = useState();
  const web3 = useWeb3();
  const [modalType, setModalType] = useState("");
  const { getBoxInfo, buyMysteryBox, getTotalBuyBox, checkLogin } =
    useBlockchainFunc();
  const [totalBuy, setTotalBuy] = useState();
  const [remaining, setRemaining] = useState();

  const getTotalCanBuyBox = async () => {
    const res = await getTotalBuyBox(boxInfo?.box_id);
    setTotalBuy(res); // box can buy
    const res2 = await getBoxInfo(boxInfo?.box_id);
    setRemaining(res2.maxSupply - res2.totalBuy);
  };

  useEffect(getTotalCanBuyBox, []);

  const offerExpirationOpts = [
    {
      value: "3",
      label: t("offerExpirationOptions.in3days"),
    },
    {
      value: "7",
      label: t("offerExpirationOptions.in7days"),
    },
    {
      value: "10",
      label: t("offerExpirationOptions.in10days"),
    },
    {
      value: "15",
      label: t("offerExpirationOptions.in15days"),
    },
  ];

  const initialValues = {
    amount: "",
    offerExpiration: offerExpirationOpts[0].value,
  };

  useEffect(async () => {
    if (statusModal?.isSuccess) {
      setIsShowingPopup(false);
    }
  }, [statusModal]);

  const buyBoxSucessfully = (quantity) => {
    getTotalCanBuyBox();
  };

  const onFinish = async (values) => {
    if (totalBuy - values?.amount >= 0) {
      await buyMysteryBox(
        boxInfo.box_id,
        boxInfo.price,
        boxInfo.payment_token,
        values?.amount,
        setStatusModal,
        () => buyBoxSucessfully(values?.amount),
        () => {}
      );
    }
    // else {
    //   setStatusModal({ isNotify: true });
    //   setStatusTextModal({
    //     noti: `You only can buy`,
    //   });
    // }
  };

  const [changeLayout, setChangeLayout] = useState(window.innerWidth <= 500);

  useEffect(() => {
    if (window.innerWidth <= 500) {
      setChangeLayout(true);
    } else setChangeLayout(false);
  });

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  window.addEventListener("resize", () => {
    setWindowHeight(window.innerHeight);
  });

  const infoTabPrimary = ["Description", "Series Content"];

  const generalTabLayout = () => {
    return (
      <div className="about-tab-content" key={index}>
        <div className="about-tab-content-name">{boxInfo.name}</div>
        <Row>
          <Col md={6} xs={12} className="about-tab-content-stat">
            <div className="stat-title">TOTAL SALES</div>
            {boxInfo?.total_supply}
          </Col>
          <Col md={6} xs={12} className="about-tab-content-stat">
            <div className="stat-title">REMAINING</div>
            {remaining}
          </Col>
          <Col md={6} xs={12} className="about-tab-content-stat">
            <div className="stat-title">PURCHASE LIMIT</div>
            {boxInfo?.purchase_limit == boxInfo?.total_supply
              ? "Unlimited"
              : `${boxInfo?.purchase_limit}`}
          </Col>
          <Col md={6} xs={12} className="about-tab-content-stat">
            <div className="stat-title">PURCHASED</div>
            {/* <div><img src={`/images/token/${token?.toLowerCase()}.svg`}/>{token?.toUpperCase()}</div> */}
            <div>{boxInfo?.total_supply - totalBuy || 0}</div>
          </Col>
        </Row>
        {boxInfo?.total_supply - boxInfo?.total_buy == 0 && (
          <div className="about-tab-content-note"> Sold out!</div>
        )}
        {start_time > Date.now() && (
          <CountDownBox time_end={start_time / 1000} detailBox />
        )}
        {totalBuy >= 0 && (
          <CustomButton
            shape="circle-custom"
            classNames="orange-btn btn-item btn-box"
            children={
              <Row className="btn-card">
                <Col span={11} className="icon-token">
                  <img
                    src={`/images/token/${token?.toLowerCase()}.svg`}
                    alt="token"
                  />
                </Col>
                <Col span={13} className="purchase">
                  Purchase
                  <div className="price">
                    {boxInfo?.price} {token}
                  </div>
                </Col>
              </Row>
            }
            disabled={totalBuy == 0 || start_time > Date.now()}
            onClick={() => {
              if (!checkLogin()) return;
              setIsShowingPopup(true);
              setModalType("checkout-box");
            }}
            htmlType="button"
          />
        )}
      </div>
    );
  };

  const customInfoTab = (title) => {
    return (
      <div className="custom-tab">
        <div className="tab-title">{title}</div>

        <div className={`bottom-line`}></div>
      </div>
    );
  };

  const columnRarity = [
    {
      title: "Reward",
      dataIndex: "reward",
      key: "reward",
      width: "40%",
      render: (reward) => <div className="from-col">{`${reward}`}</div>,
      ellipsis: true,
    },

    {
      title: "Probability",
      dataIndex: "probability",
      key: "probability",
      render: (probability) => <>{probability.toFixed(2)}%</>,
      width: "25%",
    },
    // {
    //   title: "Amount",
    //   dataIndex: "amount",
    //   key: "amount",
    //   render: (amount) => <>{amount}</>,
    //   width:   "20%",
    // },
  ];

  const renderTabContent = () => {
    return (
      <div className="item-container">
        <div className="detail-item"></div>
        <div className="item-box offer-list">
          <Table
            className="table-custom"
            columns={columnRarity}
            dataSource={boxInfo?.series_content}
            rowKey="id"
            pagination={false}
          />
        </div>
      </div>
    );
  };

  const renderInfoTab = useMemo(() => {
    if (!infoTabPrimary) return;
    let listAb = [];
    infoTabPrimary.forEach((title, index) => {
      let item = (
        <TabPane className={`info-tab`} tab={customInfoTab(title)} key={index}>
          {title === "Series Content" && renderTabContent()}
          {title === "Description" && (
            <div className="box-description">
              <Markdown>{boxInfo?.description}</Markdown>
            </div>
          )}
        </TabPane>
      );

      listAb.push(item);
    });
    return listAb;
  }, [nftData]);

  return (
    <>
      {generalTabLayout()}
      <Tabs className="info-table" type="card" onChange={callback}>
        {renderInfoTab}
      </Tabs>

      {modalType === "checkout-box" && (
        <GeneralModal
          isShowingPopup={isShowingPopup}
          nftData={nftData}
          setIsShowingPopup={setIsShowingPopup}
          setModalType={setModalType}
          initialValues={initialValues}
          onFinish={onFinish}
          orderInfor={{
            ...boxInfo,
            currency: token,
            maxBuyPerAddress: totalBuy < remaining ? totalBuy : remaining,
          }}
          type={modalType}
        />
      )}
    </>
  );
}

export default withContext(BoxItem);
