import { WalletConnectConnector } from '@web3-react/walletconnect-connector'

const MaxUint = '115792089237316195423570985008687907853269984665640564039457584007913129639935'


const ACTION_SEND_TRANSACTION = {
    allow: -1,
    sale: 0,
    buy: 1,
    cancel: 2,
    update: 3,
    makeOffer: 4,
    acceptOffer: 5,
    cancelOffer: 6,
    updateOffer: 7,
    createBundle: 8,
    buyBundle: 9,
    cancelBundle: 10,
    buyTurn: 11,
    convertNFT: 12,
    buyMysteryBox: 13,
    updateBundle: 17,
    bridge: 18
}

const THEME = {
    dark: 'dark',
    light: 'light'
}

export const RarityInterface = {
    0: "Common",
    1: "Uncommon",
    2: "Rare",
    3: "Epic",
    4: "Legendary"
}

const signMessage =
    "Welcome. By signing this message you are verifying your digital identity. This is completely secure and does not cost anything!";

const USER_WALLET_TYPE = {
    metamask: 1,
    walletConnect: 2,
    terraStation: 3,
    walletConnectTerra: 4,
    metamaskAvax: 5,
    walletConnectAvax: 6,
    metamaskOkex: 7,
    walletConnectOkex: 8,
    keplrAura: 9
}

const WALLET_TYPE = {
    bsc: 1,
    terra: 2,
    avax: 3,
    okex: 4,
    aura: 5
}

const FEE_TYPE = {
    default: 1,
    bundle: 2
}

const CSS_TYPE = {
    slideRange: "slide_range",
    searchText: "search_text"
}

const TYPE_CARD = {
    MON: "Mongens",
    BUN: "Bundles",
    MYSTERY_BOX: "Mystery",
};

export {
    MaxUint,
    THEME,
    USER_WALLET_TYPE,
    ACTION_SEND_TRANSACTION,
    signMessage,
    WALLET_TYPE,
    FEE_TYPE,
    CSS_TYPE,
    TYPE_CARD
}

export const DEFAULT_ITEMS_PER_PAGE = 20;

export const EVENT_SOCKET = [
    "updateNotification",
    "box/buy",
    "box/unBox",
    "bundle/buy",
    "bundle/cancel",
    "bundle/list",
    "nft/list",
    "nft/bid",
    "nft/accept",
    "nft/buy",
    "nft/cancelOrder",
    "nft/cancelBid",
    "nft/updateBid",
    "nft/updateOrder",
    "mini-game/buy-turn",
    "nft/convert",
    "nft/updateBundle"
]

export const walletConnector = new WalletConnectConnector({
    qrcode: true,
    rpc: { [56]: 'https://bsc-dataseed1.binance.org', [97]: 'https://data-seed-prebsc-1-s1.binance.org:8545/' },
    pollingInterval: 12000,
})

export const walletConnectorAvax = new WalletConnectConnector({
    qrcode: true,
    rpc: { [43114]: 'https://api.avax.network/ext/bc/C/rpc', [43113]: 'https://nd-151-102-486.p2pify.com/9bf8f46184eba930f76759a9414d5185/ext/bc/C/rpc' },
    pollingInterval: 12000,
})

export const walletConnectorOkex = new WalletConnectConnector({
    qrcode: true,
    rpc: { [66]: 'https://exchainrpc.okex.org', [65]: 'https://exchaintestrpc.okex.org' },
    pollingInterval: 12000,
})

export * as marketABI from './ABI/market.json'
export * as tokenABI from './ABI/token.json'
export * as nftABI from './ABI/nft.json'
export * as paymentABI from './ABI/payment.json'
export * as convertNFTABI from './ABI/convertNFT.json'

export const VERSION = 1

export const DEFAULT_ITEMS_DECK = 6;
export const DEFAULT_CONNECT_TYPE = {
    metamask: 1,
    wallet_connect: 2,
    terraStation: 3,
    walletConnectTerra: 4,
    metaMaskAvax: 5,
    walletConnectAvax: 6,
    metamaskOkex: 7,
    walletConnectOkex: 8,
    keplrAura: 9,
}

export const OPERATOR_ADDRESS = ['0x02f1addffbedc0d70343ffa43ff9fa317ad74a9a', '0x3ce5d7e422996e3ed544514355e92252ae7d04d8', 'aura1undutwmq28clg3rra7grwt0z8u8vl4r3sx53g3']

export const TRANSACTION_TYPE_ENUM = {
    IBCTransfer: '/ibc.applications.transfer.v1.MsgTransfer',
    IBCReceived: '/ibc.core.channel.v1.MsgRecvPacket',
    IBCAcknowledgement: '/ibc.core.channel.v1.MsgAcknowledgement',
    IBCUpdateClient: '/ibc.core.client.v1.MsgUpdateClient',
    IBCTimeout: '/ibc.core.channel.v1.MsgTimeout',
    IBCChannelOpenInit: '/ibc.core.channel.v1.MsgChannelOpenInit',
    IBCConnectionOpenInit: '/ibc.core.connection.v1.MsgConnectionOpenInit',
    IBCCreateClient: '/ibc.core.client.v1.MsgCreateClient',
    IBCChannelOpenAck: '/ibc.core.channel.v1.MsgChannelOpenAck',
    Send: '/cosmos.bank.v1beta1.MsgSend',
    MultiSend: '/cosmos.bank.v1beta1.MsgMultiSend',
    Delegate: '/cosmos.staking.v1beta1.MsgDelegate',
    Undelegate: '/cosmos.staking.v1beta1.MsgUndelegate',
    Redelegate: '/cosmos.staking.v1beta1.MsgBeginRedelegate',
    GetReward: '/cosmos.distribution.v1beta1.MsgWithdrawDelegatorReward',
    SwapWithinBatch: '/tendermint.liquidity.v1beta1.MsgSwapWithinBatch',
    DepositWithinBatch: '/tendermint.liquidity.v1beta1.MsgDepositWithinBatch',
    EditValidator: '/cosmos.staking.v1beta1.MsgEditValidator',
    CreateValidator: '/cosmos.staking.v1beta1.MsgCreateValidator',
    Unjail: '/cosmos.slashing.v1beta1.MsgUnjail',
    StoreCode: '/cosmwasm.wasm.v1.MsgStoreCode',
    InstantiateContract: '/cosmwasm.wasm.v1.MsgInstantiateContract',
    InstantiateContract2: '/cosmwasm.wasm.v1.MsgInstantiateContract2',
    ExecuteContract: '/cosmwasm.wasm.v1.MsgExecuteContract',
    ModifyWithdrawAddress: '/cosmos.distribution.v1beta1.MsgSetWithdrawAddress',
    JoinPool: '/osmosis.gamm.v1beta1.MsgJoinPool',
    LockTokens: '/osmosis.lockup.MsgLockTokens',
    JoinSwapExternAmountIn: '/osmosis.gamm.v1beta1.MsgJoinSwapExternAmountIn',
    SwapExactAmountIn: '/osmosis.gamm.v1beta1.MsgSwapExactAmountIn',
    BeginUnlocking: '/osmosis.lockup.MsgBeginUnlocking',
    Vote: '/cosmos.gov.v1beta1.MsgVote',
    Vesting: '/cosmos.vesting.v1beta1.MsgCreateVestingAccount',
    Deposit: '/cosmos.gov.v1beta1.MsgDeposit',
    SubmitProposalTx: '/cosmos.gov.v1beta1.MsgSubmitProposal',
    GetRewardCommission: '/cosmos.distribution.v1beta1.MsgWithdrawValidatorCommission',
    PeriodicVestingAccount: '/cosmos.vesting.v1beta1.MsgCreatePeriodicVestingAccount',
    BasicAllowance: '/cosmos.feegrant.v1beta1.BasicAllowance',
    PeriodicAllowance: '/cosmos.feegrant.v1beta1.PeriodicAllowance',
    MsgGrantAllowance: '/cosmos.feegrant.v1beta1.MsgGrantAllowance',
    MsgRevokeAllowance: '/cosmos.feegrant.v1beta1.MsgRevokeAllowance',
    AllowedMsgAllowance: '/cosmos.feegrant.v1beta1.AllowedMsgAllowance',
    AllowedContractAllowance: '/cosmos.feegrant.v1beta1.AllowedContractAllowance',
    GrantAuthz: '/cosmos.authz.v1beta1.MsgGrant',
    ExecuteAuthz: '/cosmos.authz.v1beta1.MsgExec',
    RevokeAuthz: '/cosmos.authz.v1beta1.MsgRevoke',
    MsgMigrateContract: '/cosmwasm.wasm.v1.MsgMigrateContract',
    AuraExecuteContract: '/auranw.aura.wasm.MsgExecuteContract',
    Fail: 'FAILED',
}

export const KEPLR_ERRORS = {
    Success: 'OK',
    Failed: 'FAILED',
    NoChainInfo: 'THERE IS NO CHAIN INFO FOR',
    SameChain: 'SAME CHAIN IS ALREADY REGISTERED',
    NotRegistered: 'CHAIN IS NOT REGISTERED',
    RequestRejected: 'REQUEST REJECTED',
    NotInstall: 'NOT INSTALL',
    NOT_EXIST: "KEY DOESN'T EXIST",
}
