import React, { useEffect, useState, useMemo, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Table, Pagination } from "antd";
import { withContext } from "../../../../../hook/AppContext";
import { getTokens } from "../../../../../ultis/common";
import { getListHistory } from "../../../../../api";
import cliTruncate from "cli-truncate";
import { OPERATOR_ADDRESS } from "../../../../../constants";

const PAGE_SIZE = 6;

function HistoryTab(props) {
  const { nftData } = props;
  const history = useHistory();
  const [changeLayout, setChangeLayout] = useState(false);
  const [listHistory, setListHistory] = useState();
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState();

  const getHistoryList = async () => {
    if (nftData) {
      const res = await getListHistory({
        contract_address: nftData?.contract,
        token_id: nftData?.token,
        page: page,
        limit: PAGE_SIZE,
      });

      const { metadata, data } = res;
      setListHistory(data);
      setPage(metadata?.page);
      setTotal(metadata.total);
    }
  };
  useEffect(() => getHistoryList(), [page]);
  useEffect(() => {
    if (window.innerWidth <= 768) {
      setChangeLayout(true);
    } else setChangeLayout(false);
  });

  const columnsDefault = [
    {
      title: "From",
      dataIndex: "from_address",
      key: "from",
      width: window.innerWidth <= 575 ? "30%" : "25%",
      render: (from) => {
        if (OPERATOR_ADDRESS.includes(from.toLowerCase())) {
          return <div className="from-col">{`Shop In-game`}</div>;
        }
        return (
          <div className="from-col">{`${
            from ? cliTruncate(from, 10, { position: "middle" }) : ""
          }`}</div>
        );
      },
      ellipsis: true,
      className: "address-col",
    },

    {
      title: "To",
      dataIndex: "to_address",
      key: "to",
      width: window.innerWidth <= 575 ? "30%" : "25%",
      render: (from) => {
        if (OPERATOR_ADDRESS.includes(from.toLowerCase())) {
          return <div className="from-col">{`Shop In-game`}</div>;
        }
        return (
          <div className="from-col">{`${
            from ? cliTruncate(from, 10, { position: "middle" }) : ""
          }`}</div>
        );
      },
      ellipsis: true,
      className: "address-col",
    },

    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (price, record) => {
        const token = getTokens()?.find(
          (el) =>
            el?.contract_address.toLowerCase() ==
            record?.payment_token.toLowerCase()
        );

        return (
          <>
            <img
              className="img-token"
              src={`/images/token/${token?.sort_name?.toLowerCase()}.svg`}
            />
            {`${price} ${token.sort_name}`}
          </>
        );
      },
      width: window.innerWidth <= 575 ? "20%" : "25%",
    },

    {
      title: "Date",
      dataIndex: "time",
      key: "time",
      render: (time) => {
        let date = new Date(time);
        let options = {
          year: "numeric",
          month: "short",
          day: "numeric",
        };
        const dateString = date.toLocaleDateString("en-US", options);
        return dateString;
      },
      width: window.innerWidth <= 575 ? "23%" : "25%",
    },
  ];
  const [columns, setColumns] = useState(columnsDefault);

  const onChangePage = (value) => {
    setPage(value);
  };

  return (
    <>
      <div className="item-container">
        <div className="detail-item">History</div>
        <div className="item-box offer-list">
          <Table
            className="table-custom"
            columns={columns}
            dataSource={listHistory}
            rowKey="id"
            pagination={false}
          />
        </div>
        {total > PAGE_SIZE && (
          <Pagination
            defaultCurrent={1}
            total={total}
            pageSize={PAGE_SIZE}
            current={page}
            onChange={onChangePage}
            showSizeChanger={false}
            className="history-paging"
            size="small"
          />
        )}
      </div>
    </>
  );
}

export default withContext(HistoryTab);
