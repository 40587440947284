import React, { useEffect, useCallback, useState } from "react";
import { withContext } from "../../../hook/AppContext";
import "./style.scss";
import { Menu, Popover, Row, Col } from "antd";
import { Link } from "react-router-dom";
import UserLoggedIn from "./user-logged-in";
import Notification from "./notification";
import { refreshJWTToken } from "../../../api/auth";
import { useWindowResizeMobile } from "../../../hook/ultis.hook";
import { useHistory, withRouter } from "react-router-dom";
import { useCookies } from 'react-cookie';
import { getChains } from "../../../api";
import { getUser } from "../../../ultis/common";
import { WALLET_TYPE } from "../../../constants";


const Header = (props) => {
  const {
    setIsShowingLoginPopup,
    isAuthenticatedUser,
    setAuthToken,
    setRefreshToken,
    refreshToken,
    setChains,
    chains,
    logout
  } = props;
  const [currentActiveMenuItem, setCurrentActiveMenuItem] = useState("");
  const history = useHistory();
  const [isMobile] = useWindowResizeMobile(769);

  const listMenu = [
    {
      title: "Main - game",
      desc: "Main gameplay",
      image: "/images/menu/play-game.svg",
      link: `${process.env.REACT_APP_SERVER_ENDPOINT.includes("testnet-marketplace")? "https://testnet-play.monsterra.io/": "https://play.monsterra.io/"}`
    },
    {
      title: "Marketplace",
      desc: "Buy and sell your game asset",
      image: "/images/menu/marketplace.svg",
      link: `${process.env.REACT_APP_SERVER_ENDPOINT.includes("testnet-marketplace")? "https://testnet-marketplace.monsterra.io/": "https://marketplace.monsterra.io"}`
    },
    {
      title: "DAO",
      desc: "Manage your account and join the governance",
      image: "/images/menu/dao.svg",
      link: `${process.env.REACT_APP_SERVER_ENDPOINT.includes("testnet-marketplace")? "https://testnet-dao.monsterra.io/": "https://dao.monsterra.io/"}`
    },
    {
      title: "Mini games",
      desc: "Play minigames with your assets.",
      image: "/images/menu/portal-game.svg",
      link: `${process.env.REACT_APP_SERVER_ENDPOINT.includes("testnet-marketplace")? "": "https://minigames.monsterra.io"}`
    },
    {
      title: "Guild",
      desc: "Make higher benefits with your friends and fellows",
      image: "/images/menu/guild.svg",
      link: `${process.env.REACT_APP_SERVER_ENDPOINT.includes("testnet-marketplace")? "https://testnet-guild.monsterra.io/": ""}`
    }, 
    {
      title: "Bridge",
      desc: "Transfer your tokens between supported chains",
      image: "/images/menu/bridge.svg",
      link: `${process.env.REACT_APP_SERVER_ENDPOINT.includes("testnet-marketplace")? "https://testnet-bridge.monsterra.io/": "https://bridge.monsterra.io/"}`
    },
    {
      title: "MonsTV",
      desc: "Watch and share",
      image: "/images/menu/monstv.svg",
      link: `${process.env.REACT_APP_SERVER_ENDPOINT.includes("testnet-marketplace")? "https://testnet-monstv.monsterra.io/": ""}`
    },
    {
      title: "Events",
      desc: "Join to earn",
      image: "/images/menu/events.svg",
      link: `${process.env.REACT_APP_SERVER_ENDPOINT.includes("testnet-marketplace")? "https://testnet-event.monsterra.io/": "https://event.monsterra.io/"}`
    },
    {
      title: "MonsWallet",
      desc: "Store once, play anywhere",
      image: "/images/menu/monswallet.png",
      link: `${process.env.REACT_APP_SERVER_ENDPOINT.includes("testnet-marketplace")? "https://testnet-monswallet.io/": ""}`
    },
    {
      title: "Support Center",
      desc: "Share your feedback or track your support ticket.",
      image: "/images/menu/support.svg",
      link: `${process.env.REACT_APP_SERVER_ENDPOINT.includes("testnet-marketplace")? "https://testnet-support.monsterra.io/": "https://support.monsterra.io/"}`
    },
    {
      title: "Guideline",
      desc: "Detailed Instructions",
      image: "/images/menu/guideline.svg",
      link: `${process.env.REACT_APP_SERVER_ENDPOINT.includes("testnet-marketplace")? "https://testnet-docs.monsterra.io/": "https://docs.monsterra.io/"}`
    },
    {
      title: "Homepage",
      desc: "Landing page",
      image: "/images/menu/homepage.svg",
      link: `${process.env.REACT_APP_SERVER_ENDPOINT.includes("testnet-marketplace")? "https://monsterra.io/": "https://monsterra.io"}`

    },
  ]

  useEffect(async () => {
    try {
      const dataChains = await getChains()
      setChains(dataChains)
      if (isAuthenticatedUser) {
        const res = await refreshJWTToken({
          refresh_token: refreshToken,
        });
        setAuthToken(res.access_token);
        setRefreshToken(res.refresh_token);
      }}
    catch(er) {
      logout()
    }
  }, []);

  useEffect(() => {
     if (history.location.pathname.includes("home")) {
      setCurrentActiveMenuItem("home");
    } else if (history.location.pathname.includes("p2p-market")) {
      setCurrentActiveMenuItem("p2p-mar");
    } else if (history.location.pathname.includes("primary-market")) {
      setCurrentActiveMenuItem("pri-mar");
    } else if (history.location.pathname.includes("inventory")) {
      setCurrentActiveMenuItem("inventory");
    }  
  }, [history.location.pathname]);

  useEffect(()=>{
    const href = window.location.href
    if (href.includes("src=")){
      const firstIndex = href.indexOf("src=") + 4
      const lastIndex = href.indexOf("#")
      const src = href.slice(firstIndex,lastIndex) 
      const expires = new Date();
      expires.setTime(expires.getTime() + (1000*3600*2));
      document.cookie = `src=${src}; expires=${expires.toUTCString()}`
    }
  },[])

  const content = () => (
    <div className="popover-menu-container">
    <Row className="menu-container">
      {listMenu.map(el => (
        <Col sm={8} xs={24} className={!el.link ? "disabled-item" : null}>
          <a href={el.link ? el.link : null} target="_blank">
            <Row className="menu-item">
              <Col>
                <img src={el.image}/>
              </Col>
              <Col>
                <div className="item-title">{el.title}</div>
                {el.desc}
              </Col>
            </Row>
          </a>
        </Col>
      ))}
    </Row>
    </div>
  )

  const onChangeMenu = (e) => {
    setCurrentActiveMenuItem(e.key);
  };

  return (
    <>
      <div className="header">
        <Menu
          mode="horizontal"
          className="header-menu"
          selectedKeys={[currentActiveMenuItem]}
          defaultSelectedKeys={[currentActiveMenuItem]}
          onClick={onChangeMenu}
        >
          <Menu.Item key="home" className="">
            <Link to="/">
              <img className="img-logo" src="/images/logo-game.png" />
            </Link>
            
          </Menu.Item>
          <Menu.Item>
            <Popover placement="bottomRight" content={content} trigger="click" className="popover-menu">
              <div >
                <svg width="74" height="70" viewBox="0 0 74 70" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.6704 24.5H16.7704C14.5904 24.5 13.4404 25.65 13.4404 27.83V29.73C13.4404 31.91 14.5904 33.06 16.7704 33.06H18.6704C20.8504 33.06 22.0004 31.91 22.0004 29.73V27.83C22.0004 25.65 20.8504 24.5 18.6704 24.5Z" fill="#A4A5AF"></path><path d="M7.24 35.9299H5.34C3.15 35.9299 2 37.0799 2 39.2599V41.1599C2 43.3499 3.15 44.4999 5.33 44.4999H7.23C9.41 44.4999 10.56 43.3499 10.56 41.1699V39.2699C10.57 37.0799 9.42 35.9299 7.24 35.9299Z" fill="#A4A5AF"></path><path d="M6.29 33.08C8.6593 33.08 10.58 31.1593 10.58 28.79C10.58 26.4207 8.6593 24.5 6.29 24.5C3.9207 24.5 2 26.4207 2 28.79C2 31.1593 3.9207 33.08 6.29 33.08Z" fill="#A4A5AF"></path><path d="M17.7099 44.4999C20.0792 44.4999 21.9999 42.5792 21.9999 40.2099C21.9999 37.8406 20.0792 35.9199 17.7099 35.9199C15.3406 35.9199 13.4199 37.8406 13.4199 40.2099C13.4199 42.5792 15.3406 44.4999 17.7099 44.4999Z" fill="#A4A5AF"></path><g opacity="0.7"><path d="M41.9465 31.9534H37.7932H34.0532C33.4132 31.9534 33.0932 32.7267 33.5465 33.18L36.9998 36.6334C37.5532 37.1867 38.4532 37.1867 39.0065 36.6334L40.3198 35.32L42.4598 33.18C42.9065 32.7267 42.5865 31.9534 41.9465 31.9534Z" fill="#A4A5AF"></path></g></svg>
              </div>
            </Popover>
          </Menu.Item>
          {!isMobile && <Menu.Item key="home" className="mr-auto">
            <Link to="/home">Home</Link>
          </Menu.Item>}
          {!isMobile && <Menu.Item key="p2p-mar" className="mr-auto">
            <Link to="/p2p-market">P2P Market</Link>
          </Menu.Item>}
          {/* {!isMobile &&<Menu.Item key="pri-mar" className="mr-auto">
            <Link to="/primary-market">Primary Market</Link>
          </Menu.Item>} */}
          {/* { !isMobile && <Menu.Item key="boxes" className="mr-auto">
            <Link to="/boxes">Treasury Boxes</Link>
          </Menu.Item>} */}
          {!isMobile &&<Menu.Item key="inventory" className="mr-auto">
            <Link to="/inventory">Inventory</Link>
          </Menu.Item>}
          
          {/* {!isMobile && getUser()?.walletType === WALLET_TYPE.bsc && <Menu.Item key="nft-game" className="mr-auto">
            <Link to="/nft-game">Mini Games</Link>
          </Menu.Item>} */}
          {/* {!isMobile && <Menu.Item key="docs" className="mr-auto">
            <a target="_blank" href="https://testnet-docs.monsterra.io/marketplace">Docs</a>
          </Menu.Item>} */}
          {isAuthenticatedUser ? (
            <Menu.Item
              className="user-notification"
              key="user-noti"
            >
              <Notification />
            </Menu.Item>
          ) : (
            <></>
          )}
          {isAuthenticatedUser ? (
            <Menu.Item className="user-logged-in">
              {/* <AuthGuard> */}
              <UserLoggedIn />
              {/* </AuthGuard> */}
            </Menu.Item>
          ) : (
            <>
             
            </>
          )}
        </Menu>
        {isMobile &&<Menu 
          mode="horizontal"
          className="header-menu header-mobile"
          selectedKeys={[currentActiveMenuItem]}
          defaultSelectedKeys={[currentActiveMenuItem]}
          onClick={onChangeMenu}
          popupClassName="menu-popup"
        >
          <Menu.Item key="home" className="mr-auto">
            <Link to="/home">Home</Link>
          </Menu.Item>
          <Menu.Item key="p2p-mar" className="mr-auto">
            <Link to="/p2p-market">P2P Market</Link>
          </Menu.Item>
          {/* <Menu.Item key="pri-mar" className="mh-auto">
            <Link to="/primary-market">Primary Market</Link>
          </Menu.Item> */}
          {/* <Menu.Item key="boxes" className="mr-auto">
            <Link to="/boxes">Treasury Boxes</Link>
          </Menu.Item> */}
          <Menu.Item key="inventory" className="mr-auto">
            <Link to="/inventory">Inventory</Link>
          </Menu.Item>
          
          {/* <Menu.Item key="nft-game" className="mr-auto">
            <Link to="/nft-game">Mini Games</Link>
          </Menu.Item> */}
          {/* <Menu.Item key="docs" className="mr-auto">
            <a target="_blank" href="https://testnet-docs.monsterra.io/marketplace">Docs</a>
          </Menu.Item> */}
        </Menu>}
      </div>
    </>
  );
};

export default withContext(withRouter(Header));
