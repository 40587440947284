import React, { useEffect } from "react";
import CreateBundleTemplate from "../../components/templates/create-bundle";
import SiteLayout from "../../layouts/site-layout";
import "./style.scss";

function CreateBundle(props) {

    return (
        <SiteLayout styleMarket="master-layout__market">
            <CreateBundleTemplate />
        </SiteLayout>
    );
}

export default CreateBundle;
