import { Button, Col, Form, Row, Select } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useWindowResizeMobile } from "../../../../hook/ultis.hook";
import useBlockchainFunc from "../../../../ultis/blockchain";
import S3Image from "../../../common/s3-image";
import CustomButton from "../../../elements/button";
import CustomInput from "../../../elements/input";
import CustomModal from "../../../elements/modal";
import Decor from "../../../elements/static-anim/decor";
import Mongen from "../../../elements/static-anim/mongen";
import "./style.scss";
import { getUser, networkValidation, useWeb3 } from "../../../../ultis/common";
import Web3 from "web3";

function BridgeModal(props) {
  const {
    nft,
    showModal,
    setShowModal,
    setStatusModal,
    onClick,
    type,
    callBack,
    isItems,
    chains,
  } = props;
  const [quantity, setQuantity] = useState(1);
  const [fee, setFee] = useState(0);
  const [balanceNative, setBalanceNative] = useState(0);
  const [form] = Form.useForm();
  const [isMobile] = useWindowResizeMobile(787);
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const chainInfo = chains?.filter(
    (chain) => chain.sort_name !== nft?.chain?.sort_name
  );
  const currentChainInfo = chains?.find(
    (chain) => chain.sort_name === nft?.chain?.sort_name
  );

  const [destChain, setDestChain] = useState("");
  const { bridgeItem, estimateBridgeItem } = useBlockchainFunc();
  const web3 = useWeb3();

  const rules = {
    quantity: [
      {
        validator: async (_, value) => {
          const amount = nft?.amount ? nft?.amount : 1;

          if (value == "") {
            return Promise.reject(new Error("This field is required"));
          }
          if (value <= 0) {
            return Promise.reject(new Error("Amount must be greater than 0"));
          }
          if (value <= Number(amount)) {
            return Promise.resolve();
          }
          return Promise.reject(new Error("Insufficient quantity"));
        },
      },
    ],
  };

  useEffect(() => {
    if (type === "put") {
      form.setFieldsValue({ price: 0 });
      form.setFieldsValue({ quantity: 1 });
      setQuantity(1);
    }
  }, [nft]);

  useEffect(() => {
    if (!nft || !destChain) {
      return;
    }
    let contract = nft.nft_info?.contract_address || nft.contract;
    const data = nft.nft_info?.token_id || quantity;
    const currentChain = nft.chain.sort_name;
    const getFee = async () => {
      let bnbFee = await estimateBridgeItem(
        currentChain,
        destChain,
        contract,
        data
      );
      setFee(web3.utils.fromWei(bnbFee[0]?.toString()));
    };

    const getNative = async () => {
      try {
        await networkValidation({
          requiredChainNetwork: nft.chain,
          isMetamask: true,
        });
        const provider = new Web3.providers.HttpProvider(nft.chain.list_rpc[0]);
        const web3 = new Web3(provider);
        const balanceNative = await web3.eth.getBalance(getUser()?.address);
        const balanceNativeConv = web3.utils.fromWei(
          `${balanceNative}`,
          "ether"
        );
        setBalanceNative(balanceNativeConv);
      } catch (err) {
        console.log({ err });
      }
    };

    getFee();
    getNative();
  }, [nft, quantity, destChain]);

  const onCancel = () => {
    form.resetFields();
    setDestChain("");
    setShowModal(false);
    setFee(0);
  };

  const onChangeQuantity = (e) => {
    setQuantity(parseInt(e.target.value));
    form.setFieldsValue({ quantity: parseInt(e.target.value).toString() });
  };

  const onChangeMaxAmount = () => {
    form.setFieldsValue({ quantity: nft?.amount });
    setQuantity(nft?.amount);
  };

  const handleConfirm = async () => {
    const isNFT = !!nft.nft_info;
    let contract = nft.nft_info?.contract_address || nft.contract;
    const data = nft.nft_info?.token_id || quantity;
    const currentChain = nft.chain.sort_name;

    await bridgeItem(
      isNFT,
      currentChain,
      destChain,
      contract,
      data,
      setStatusModal
    );

    callBack(nft);
    form.resetFields();
    setDestChain("");
    setFee(0);
    setShowModal(false);
    setShowModalConfirm(false);
  };

  const renderLinkImage = (image) => {
    const array = image?.split("/");
    var newImage = "/images";
    for (let i = 3; i < array?.length; i++) {
      newImage = newImage + "/" + array[i];
    }
    return newImage.replace("+", "_");
  };

  const renderImage = useMemo(() => {
    if (
      isMobile &&
      (nft?.dna_raw?.length > 2 || nft?.meta?.decoration_list?.length > 0)
    ) {
      return (
        <S3Image
          className="nft-card_image item-image modal-image"
          src={nft?.image}
        />
      );
    }
    if (nft?.dna_raw?.length > 2)
      return (
        <Mongen
          lock={[]}
          dna={nft?.dna_raw}
          className="nft-card_image item-image modal-image"
        />
      );
    if (nft?.meta?.decoration_list?.length > 0)
      return (
        <div className="card-decor__image">
          <Decor
            rarity={nft?.meta?.rarity}
            decoration_list={nft?.meta?.decoration_list}
            className=""
          />
        </div>
      );

    return (
      <S3Image
        className="nft-card_image item-image modal-image"
        src={nft?.static_image ? renderLinkImage(nft?.image) : nft?.image}
      />
    );
  }, [nft]);

  const renderChain = useMemo(
    () => (
      <div className="" value={nft?.chain?.sort_name}>
        <img
          className="token-icon"
          src={`/images/chain/${nft?.chain?.sort_name.toLowerCase()}.svg`}
          alt="token"
        />{" "}
        {nft?.chain?.name}
      </div>
    ),
    [nft]
  );

  const renderPickChain = useMemo(
    () => (
      <Select
        style={{ width: 100 }}
        bordered
        onChange={(value) => {
          setDestChain(value);
        }}
        value={destChain}
      >
        {chainInfo?.map((chain) => {
          return (
            <Select.Option value={chain.sort_name}>
              {chain.sort_name}
            </Select.Option>
          );
        })}
      </Select>
    ),
    [nft, destChain]
  );

  return (
    <>
      <CustomModal
        visible={showModal}
        footer={null}
        onCancel={onCancel}
        className="confirm-modal make-offer-modal"
      >
        <div className="title-popup">{"Bridge"}</div>
        <div className="wallet-box-line"></div>
        <Form form={form} onFinish={handleConfirm}>
          <Row className="row-infor offer-info" gutter={38}>
            <Col
              xs={15}
              sm={15}
              md={10}
              lg={10}
              xl={10}
              className="left-column"
            >
              <div className="image-block">{renderImage}</div>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={14}
              lg={14}
              xl={14}
              className="right-column"
            >
              <div className="name-item offer-name">{nft?.name} </div>
              {!isItems && (
                <div className="id-item id-modal">
                  #{nft?.nft_info?.token_id || nft?.token}
                </div>
              )}
              {isItems && (
                <Row className="amount-row">
                  <Col span={18} className="left-col">
                    Quantity
                    <Form.Item
                      className="m-0 mb-0"
                      name="quantity"
                      rules={rules.quantity}
                    >
                      <CustomInput
                        classNames={["amount-item"]}
                        min={0}
                        type="number"
                        // value={quantity}
                        onChange={onChangeQuantity}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Button
                      className="max-button"
                      onClick={onChangeMaxAmount}
                      type="button"
                    >
                      {"Max"}
                    </Button>
                  </Col>
                </Row>
              )}
              <div className="bridge-network" style={{ marginTop: "20px" }}>
                <div className="title">From Network: </div>
                {renderChain}
              </div>
              <div className="bridge-network" style={{ marginTop: "20px" }}>
                <div className="title">To Network: </div>
                {renderPickChain}
              </div>
              <div className="bridge-network" style={{ marginTop: "20px" }}>
                <div className="title">Estimate fee: </div>
                {fee}{" "}
                {currentChainInfo?.native_token ?? ""}
              </div>
              {(balanceNative && Number(fee) > Number(balanceNative)) ? (
                <div className="error-text">Insufficient native token</div>
              ) : (
                <div></div>
              )}
            </Col>
          </Row>
          <div className="btn-block bottom-modal-btn confirm-modal-btn">
            <CustomButton
              classNames="red-btn btn-item btn-popup"
              children={"Cancel"}
              shape="circle-custom"
              htmlType="button"
              onClick={() => {
                setShowModal(false);
                form.resetFields();
                setDestChain("");
                setFee(0);
              }}
            />
            <CustomButton
              classNames="orange-btn btn-item btn-popup"
              children={"Approve"}
              shape="circle-custom"
              htmlType="submit"
              disabled={
                (!destChain && !quantity) ||
                !fee ||
                (balanceNative && Number(fee) > Number(balanceNative))
              }
            />
          </div>
        </Form>
      </CustomModal>
    </>
  );
}

export default BridgeModal;
