import { SigningCosmWasmClient } from "@cosmjs/cosmwasm-stargate";
import { Decimal } from "@cosmjs/math";
import { Registry } from "@cosmjs/proto-signing";
import { MsgExecuteContract } from "cosmjs-types/cosmwasm/wasm/v1/tx";
import { KEPLR_ERRORS, TRANSACTION_TYPE_ENUM } from "../constants";
import auraInfo from "../constants/ABI/aura-chain.json";

const chainInfo = process.env.REACT_APP_IS_PRODUCTION == 'true'
  ? auraInfo.mainnet
  : auraInfo.testnet;

async function getKeplr() {
  // @ts-ignore
  if (window.keplr) {
    // @ts-ignore
    return window.keplr;
  }

  if (document.readyState === "complete") {
    // @ts-ignore
    return window.keplr;
  }

  return new Promise((resolve) => {
    const documentStateChange = (event) => {
      if (
        event.target &&
        (event.target).readyState === "complete"
      ) {
        // @ts-ignore
        resolve(window.keplr);
        document.removeEventListener("readystatechange", documentStateChange);
      }
    };

    document.addEventListener("readystatechange", documentStateChange);
  });
}

async function getSigner() {
  let keplr = await getKeplr();
  if (!keplr) {
    return null;
  }
  // @ts-ignore
  let offlineSigner = window.keplr.getOfflineSignerOnlyAmino(chainInfo.chainId);
  // let offlineSigner = keplr.getOfflineSigner(chainInfo.chainId);
  let gasStep = chainInfo?.gasPriceStep?.average || 0.0025;

  //convert gasPrice to Decimal
  let pow = 1;
  while (!Number.isInteger(gasStep)) {
    gasStep = gasStep * Math.pow(10, pow);
    pow++;
  }

  try {
    const registry = new Registry();
    registry.register(
      TRANSACTION_TYPE_ENUM.ExecuteContract,
      MsgExecuteContract
    );
    const client = await SigningCosmWasmClient.connectWithSigner(
      chainInfo.rpc,
      offlineSigner,
      {
        registry: registry,
        gasPrice: {
          amount: Decimal.fromAtomics(gasStep.toString(), pow),
          denom: chainInfo.feeCurrencies[0].coinMinimalDenom,
        },
      }
    );
    return client;
  } catch (err) {
    console.log(err);
    return null;
  }
}

async function keplrSuggestChain(loginType) {
  let chainSuggest = chainInfo;

  if (chainSuggest) {
    return (await getKeplr())
      .experimentalSuggestChain(chainSuggest)
      .catch((e) => {
        console.log({ e });
        throw e;
      });
  }

  return KEPLR_ERRORS.NoChainInfo;
}

function getKeplrError(err) {
  if (err.toUpperCase().includes(KEPLR_ERRORS.NoChainInfo)) {
    return KEPLR_ERRORS.NoChainInfo;
  } else if (err.toUpperCase().includes(KEPLR_ERRORS.NOT_EXIST)) {
    return KEPLR_ERRORS.NOT_EXIST;
  } else if (err.toUpperCase().includes(KEPLR_ERRORS.RequestRejected)) {
    return KEPLR_ERRORS.RequestRejected;
  }

  return KEPLR_ERRORS.Failed;
}

async function handleErrors(err) {
  const error = getKeplrError(err.message);
  switch (error) {
    case KEPLR_ERRORS.NoChainInfo:
      return null;
    case KEPLR_ERRORS.NOT_EXIST:
      window.open(
        "https://chrome.google.com/webstore/detail/keplr/dmkamcknogkgcdfhhbddcghachkejeap?hl=en"
      );
      return null;
    default:
      return err.message || err;
  }
}

async function connect(provider) {
  switch (provider) {
    case "KEPLR":
      return await connectKeplr(chainInfo);
  }
}

const connectKeplr = async (chainInfo) => {
  console.log(chainInfo);
  const checkWallet = async () => {
    try {
      const keplr = await getKeplr();

      if (keplr) {
        await suggestChain(keplr);
        await keplr.enable(chainInfo.chainId);
        const account = await keplr.getKey(chainInfo.chainId);

        if (account) {
          return account.bech32Address;
        }
      } else {
        window.open(
          "https://chrome.google.com/webstore/detail/keplr/dmkamcknogkgcdfhhbddcghachkejeap?hl=en"
        );
        return null;
      }
    } catch (e) {
      console.log({ e });
      catchErrors(e);
    }
  };
  return checkWallet();
};

const suggestChain = (w) => {
  return w.experimentalSuggestChain(chainInfo);
};

const catchErrors = async (e) => {
  handleErrors(e).then((msg) => {
    if (msg) {
      console.log({ msg });
      // ui.alertFailed(msg, "");
    }
  });
};

const signMessage = async (signer, message) => {
  let keplr = await getKeplr();
  let rs = await keplr.signArbitrary(chainInfo.chainId, signer, message);
  return rs;
};

const verifySignature = async (
  signer,
  message,
  signature
) => {
  let keplr = await getKeplr();
  let rs = await keplr.verifyArbitrary(
    chainInfo.chainId,
    signer,
    message,
    signature
  );
  return rs;
};

const getBalance = async (address) => {
  let client = await getSigner();
  if (client) {
    let balance = await client.getBalance(
      address,
      chainInfo.stakeCurrency.coinMinimalDenom
    );
    return balance.amount;
  }
};

const getTokenBalance = async (address, contractAddress) => {
  let client = await getSigner();
  if (client) {
    let balance = await client.queryContractSmart(contractAddress, {
      balance: { address: address },
    });
    return balance?.balance;
  }
};

const getTokenAllowance = async (
  contractAddress,
  owner,
  spender
) => {
  let client = await getSigner();
  if (client) {
    let balance = await client.queryContractSmart(contractAddress, {
      allowance: { owner: owner, spender: spender },
    });
    return balance?.allowance;
  }
};

const isApproveNft = async (
  contractAddress,
  owner,
  spender
) => {
  try {
    let client = await getSigner();
    if (client) {
      console.log({ owner, spender, contractAddress })
      let approval = await client.queryContractSmart(contractAddress, {
        operator: { owner: owner, operator: spender },
      });
      return approval?.approval?.spender?.toLowerCase() === spender.toLowerCase();
    }
  } catch (err) {
    return false;
  }
};

const allOperators = async (
  contractAddress,
  owner,
  spender
) => {
  try {
    let client = await getSigner();
    if (client) {
      let operators = await client.queryContractSmart(contractAddress, {
        all_operators: { owner },
      });
      let isApprove = false;
      if(operators && operators.operators) {
        operators.operators.map(ope => {
          if(ope.spender == spender) {
            isApprove = true;
          }
        })
      }
      return isApprove;
    }
  } catch (err) {
    console.log({err})
    return false;
  }
};

const executeContract = async (
  signerAddress,
  messages
) => {
  let client = await getSigner();
  if (client) {
    const estimateGas = await keplrService.simulate(signerAddress, messages);
    console.log({ estimateGas });

    const fee = {
      amount: [
        {
          denom: chainInfo.stakeCurrency.coinMinimalDenom,
          amount: (
            chainInfo.gasPriceStep.average *
            Math.pow(10, chainInfo.stakeCurrency.coinDecimals)
          ).toString(),
        },
      ],
      gas: String(estimateGas * 1.5),
    };

    let tx = await client.signAndBroadcast(signerAddress, messages, fee);
    return tx;
  }
};

const execute = async (
  userAddress,
  contract_address,
  msg,
  feeGas = null
) => {
  // signer = window.getOfflineSignerOnlyAmino(this.chainId);
  let client = await getSigner();
  if (!client) {
    return;
  }
  let rs = await client.execute(
    userAddress,
    contract_address,
    msg,
    feeGas || "auto",
    "",
    []
  );
  return rs;
};

const simulate = async (signerAddress, messages) => {
  let client = await getSigner();
  if (client) {
    let gas = await client.simulate(signerAddress, messages, "");
    return gas;
  }
  return 100000;
};

const queryContractSmart = async (address, queryMsg) => {
  let signer = await getSigner();
  let rs = await signer.queryContractSmart(address, queryMsg)
  return rs;
}

const keplrService = {
  allOperators,
  getBalance,
  getKeplr,
  keplrSuggestChain,
  getKeplrError,
  handleErrors,
  connect,
  connectKeplr,
  suggestChain,
  catchErrors,
  signMessage,
  verifySignature,
  getTokenBalance,
  executeContract,
  simulate,
  getSigner,
  execute,
  getTokenAllowance,
  isApproveNft,
  queryContractSmart
};

export default keplrService;
