import "./style.scss";
import React, { useEffect, useState, useMemo } from "react";
import { Row, Pagination, Col, Tabs, message } from "antd";
import { Link, useHistory, useLocation } from "react-router-dom";
import FilterComponent from "../../common/filter-component";
import { getInventory, getAllCollection, getListGame } from "../../../api";
import queryString from "query-string";
import { useWindowResizeMobile } from "../../../hook/ultis.hook";
import { DEFAULT_ITEMS_PER_PAGE } from "../../../constants";
import CollectionFilterComponent from "../../common/collection-filter-component";
import CustomButtonField from "../../elements/button";
import CardItem from "./card-footer";
import ProgressModal from "../../common/progress-modal";

function CreateBundleTemplate(props) {
  const [isLoading, setIsLoading] = useState(false);
  const { TabPane } = Tabs;
  const [listCol, setListCol] = useState([]);
  const [dataCol, setDataCol] = useState([]);
  const [activeTab, setActiveTab] = useState();
  const [renderKey, setRenderKey] = useState(0);
  const [listItem, setListItem] = useState({});
  const [paging, setPaging] = useState({});
  const [totalItem, setTotalItem] = useState();
  const [bundle, setBundle] = useState([]);
  const [filterSort, setFilterSort] = useState({});
  const [isMobile] = useWindowResizeMobile(891);
  const [length, setLength] = useState(bundle.length);

  const history = useHistory();
  const location = useLocation();
  const query = queryString.parse(location.search, { ignoreQueryPrefix: true });   


  useEffect(async () => {
    const resGame = await getListGame();
    const resCol = await getAllCollection({
      game_id: resGame[0]._id,
      chain: query?.chain
    });
    let listNFT = {};
    let page = {};
    const colection = {};
    let listCol = []
    resCol.map((el) => {
      if (el.is_erc721 && el.name != "Bundles"){
        page[el._id] = {
          currentPage: 1,
        };
        colection[el._id] = el;
        listNFT[el._id] = <></>;
        listCol.push(el)
      } 
    });
    setPaging(page);
    setActiveTab(listCol[0]._id);
    setListItem(listNFT);
    setDataCol(colection);
  }, []);

  useEffect(() => {
    setFilterSort({});
  }, [renderKey]);

  useEffect(() => {
    renderListCol();
  }, [dataCol, listItem]);

  useEffect(() => {
    if (Object.keys(dataCol).length) {
      renderList();
    }
  }, [dataCol, activeTab, paging, filterSort, bundle]);

  const renderList = async (params) => {
      const listOrder = await getInventory({
      page: paging[activeTab]?.currentPage,
      limit: DEFAULT_ITEMS_PER_PAGE,
      ...filterSort[activeTab],
      contract_address: filterSort[activeTab]?.contract_address?.length > 0 ? filterSort[activeTab].contract_address : dataCol[activeTab]?.list_contract[0].address,
    });
    setTotalItem(listOrder?.metadata?.total);

    const comp = listOrder?.data.map((el, index) => {
      const isAdd = bundle.some(
        (item) =>
          item.tokenId === el.nft_info.token_id &&
          item.tokenAddress === el.nft_info.contract_address
      );
      return (
        <CardItem
          onClick={() => onClickAdd(el)}
          el={el}
          index={index}
          isAdd={isAdd}
          bundle={bundle}
          activeTab={activeTab}
        />
      );
    });
    setListItem((prevState) => {
      let newSta = Object.assign({}, prevState);
      newSta[activeTab] = comp;
      return newSta;
    });
  };
  const onChangeTab = (value) => {
    setRenderKey(renderKey + 1);
    setActiveTab(value);
    const name = dataCol[value]?.name
    history.replace(`/create-bundle?chain=${query?.chain}&tab=${name}`);
  };

  const onChangePage = (value) => {
    setPaging((prevState) => {
      let newSta = Object.assign({}, prevState);
      newSta[activeTab].currentPage = value;
      return newSta;
    });
  };

  const renderListCol = async (dataFilter) => {
    if (dataFilter?._id) {
      setFilterSort((prevState) => {
        let newSta = Object.assign({}, prevState);
        newSta[dataFilter?._id] = dataFilter;
        return newSta;
      });
    }
    let results = [];
    const firstTabKey = Object.keys(dataCol)[0]
    for (const key in dataCol) {
      let el = dataCol[key];
      if (isMobile) {
        if (el._id === activeTab) {
          results = (
            <>
              <Row className="list-item" xs={24}>
                {listItem[el._id]}
              </Row>
              {totalItem / DEFAULT_ITEMS_PER_PAGE > 1 && (
                <Pagination
                  defaultCurrent={1}
                  total={totalItem}
                  pageSize={DEFAULT_ITEMS_PER_PAGE}
                  current={paging[activeTab].currentPage}
                  onChange={onChangePage}
                  showSizeChanger={false}
                />
              )}
            </>
          );
        }
      } else {
        results.push(
          <TabPane
            className="market-cont"
            tab={
              <>
                {el._id != 0 && <img className={key == firstTabKey ? "m-0" : null} src={`/images/collection-icon/${el.name?.toLowerCase()}.svg`} />} {el.name}
              </>
            }
            key={el._id}
          >
            {totalItem ? (
              <>
                {" "}
                <Row className="list-item">{listItem[el._id]}</Row>
                {totalItem / DEFAULT_ITEMS_PER_PAGE > 1 && (
                  <Pagination
                    defaultCurrent={1}
                    total={totalItem}
                    pageSize={DEFAULT_ITEMS_PER_PAGE}
                    current={paging[activeTab].currentPage}
                    onChange={onChangePage}
                    showSizeChanger={false}
                  />
                )}
              </>
            ) : null}
          </TabPane>
        );
      }
    }

    setListCol(results);
  };

  const onClickAdd = (nft) => {
    const item = {
      tokenAddress: nft.nft_info.contract_address,
      tokenId: nft.nft_info.token_id,
      ...nft,
    };
    let list = bundle;
    const itemAdd = bundle.find(
      (el) =>
        el.tokenId === item.tokenId && el.tokenAddress === item.tokenAddress
    );
    if (itemAdd) {
      list = bundle.filter(
        (el) =>
          el.tokenId !== itemAdd.tokenId ||
          el.tokenAddress !== itemAdd.tokenAddress
      );
    } else list.push(item);

    setBundle(list);
    setLength(list.length);
  };

  const changeTab = (value) => {
    const name = dataCol[value]?.name
    history.replace(`/create-bundle?tab=${name}`);
  }

  const renderCount = () => <div className="quantity">{length}/20</div>;

  return (
    <div className="create-bundle-page">
      <Row wrap={true} className="create-bundle-tab">
        {isMobile ? (
          <CollectionFilterComponent
            listCol={listCol}
            dataCol={dataCol}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            setRenderKey={setRenderKey}
            isInventory={true}
            renderListCol={renderListCol}
            bundle={bundle}
            toPage={{
              pathname: `/confirm-bundle`,
              state: {
                bundle: bundle,
                chain: query?.chain
              },
            }}
            titleBtn="Next"
            disabled={length < 2}
            callBack={changeTab}
            isErc721={dataCol[activeTab]?.is_erc721}

          />
        ) : (
          <Tabs activeKey={`${activeTab}`} onChange={onChangeTab} >
            <FilterComponent
              {...dataCol[activeTab]}
              renderKey={renderKey}
              setRenderKey={setRenderKey}
              renderListCol={renderListCol}
              isInventory={true}
              isErc721={dataCol[activeTab]?.is_erc721}
            />
            {renderCount()}
            <div className="note">
              Only NFTs that has not been listed on the market can be added to
              the bundle
            </div>
            <Link
              to={{
                pathname: `/confirm-bundle`,
                state: {
                  bundle: bundle,
                  chain: query?.chain
                },
              }}
            >
              <CustomButtonField
                classNames="btn-next"
                color="green"
                size="medium"
                disabled={length < 2}
              >
                {"Next"}
              </CustomButtonField>
            </Link>
            {listCol}
          </Tabs>
        )}
      </Row>

      <ProgressModal
        statusModal={isLoading}
        title={{ pending: "Put On Sale" }}
      />
    </div>
  );
}

export default CreateBundleTemplate;
