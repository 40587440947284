import React, { useEffect, useState } from "react";
import "./style.scss";
import CustomButtonField from "../../elements/button";
import { getListGame, getListMysteryBox } from "../../../api";
import {  Carousel } from "antd";
import { useHistory } from "react-router-dom";
import CountDownBox from "./countdown-box";
import { withContext } from "../../../hook/AppContext";
import { useWindowResizeMobile } from "../../../hook/ultis.hook";

function P2PMarketTemplate(props) {
  const { setStatusModal, setStatusTextModal } = props;
  const [listOrder, setListOrder] = useState([]);
  const [soldOut, setSoldOut] = useState(false)
  const [isMobile] = useWindowResizeMobile();
  const history = useHistory();


  useEffect(async () => {
    const resCol = await getListGame();
    const listOrder = await getListMysteryBox({
      game_id: resCol[0]._id
    });
    setListOrder(listOrder)
    
  }, []);

  const onClickDetail = (boxId) => {
    history.push(`/detail?box_id=${boxId}`);
  }

  const renderList = () => (
     listOrder?.map((el, index) => (
       <div className="bg-banner">
         {/* {isMobile ? <img className="campain-img" src="/images/table-campain-mobile.svg" /> : <img className="campain-img" src="/images/table-campain.svg" /> }
        <div className="content-campain">
          <div className="content-campain__left">
             {soldOut && <img className="sold-out" src="/images/sold-out.svg" />}
             <div className="campain-name">{el.campaign_name}</div>
             <div className="campain-note">Only <div className="campain-price">{el.price} {el.currency.toUpperCase()}</div> to get mystery box</div>
             {!isMobile && <CustomButtonField
              color="green"
              size="medium"
              onClick={() => onClickDetail(el.box_id)}

            >
              {"Detail"}

            </CustomButtonField>}
          </div>
           <CountDownBox  time_end={el.end_time} 
                          soldOut={soldOut}
                          setSoldOut={setSoldOut}
           />
           {isMobile && <CustomButtonField
             color="green"
             size="medium"
             onClick={() => onClickDetail(el.box_id)}
           >
             {"Detail"}

           </CustomButtonField>}
        </div> */}
      </div>
    ))
  );
  

  

  return (
    <Carousel  >
      {renderList()}
    </Carousel>
  );
}

export default withContext(P2PMarketTemplate);
