import "./style.scss";
import React, { useEffect, useRef, useState, useCallback } from "react";
import { Menu, Dropdown } from "antd";
import { useHistory } from "react-router-dom";
import cliTruncate from "cli-truncate";
import { withContext } from "../../../../hook/AppContext";
import { useWindowResizeMobile } from "../../../../hook/ultis.hook";
import { getNoti, readAllNotifications } from "../../../../api";
import {getTokens} from "../../../../ultis/common"

const typeNoti = {
  makeOffer: "nft/bid",
  acceptOffer: "nft/accept",
  buy: "nft/buy",
  buyBundle: "bundle/buy",
};

const Notification = (props) => {
  const { resetNoti, setResetNoti, getBalanceWallet} = props
  const [isMobile] = useWindowResizeMobile();
  const defaultState = {
    items: [],
    page: 0,
    isLoading: false,
    hasMore: true,
  };
  const [noti, setNoti] = useState(defaultState);
  const [unRead, setUnRead] = useState(false);
  const [visible, setVisible] = useState(false);
  const history = useHistory();

  const convertTime = (time) => {
    let date = new Date(time);
    const dateString =
      date.getDate() +
      "/" +
      (date.getMonth() + 1) +
      "/" +
      date.getFullYear() +
      " " +
      date.getHours() +
      ":" +
      date.getMinutes();
    return dateString;
  };

  const getNotification = async (next=false) => {
    if(resetNoti){
      try {
        setNoti({
          ...noti,
          isLoading: true,
        });
        getBalanceWallet()
        let nextPage = next ? noti.page + 1 : 1;
        const res = await getNoti(nextPage, 10);
        if (res) {
          const { data, metadata, un_read_notifications } = res;
          setUnRead(un_read_notifications > 0);
          const result = data?.map((item) => ({
            type: item.type,
            username: item.from_address,
            nftName: item.nft_info?.name,
            nftPrice: item.price,
            currency: getTokens()?.find(el => el.contract_address.toLowerCase() == item?.payment_token?.toLowerCase())?.sort_name || "MSTR",
            contractAddress: item.nft_info?.contract_address,
            tokenId: item.nft_info?.token_id,
            bundleId: item.bundle_id,
            time: item.created_time && convertTime(item.created_time),
            isRead: item.is_read,
            isItem: item.is_item,
            quantity: item.quantity || 1,
            chainId : item.chain._id
          }));
          setNoti({
            items: next ? [...noti.items, ...result] : [...result],
            page: metadata.page,
            isLoading: false,
            hasMore: metadata.page < metadata.total / 10,
          });
        }
      } catch (err) {
        setNoti({
          ...noti,
          isLoading: false,
        });
      }
      setResetNoti(false)
    }   
  };

  useEffect(getNotification, [resetNoti]);

  const observer = useRef();
  const lastNftEle = useCallback(
    (node) => {
      if (noti.isLoading || !noti.hasMore) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          getNotification(true);
        }
      });

      if (node) observer.current.observe(node);
    },
    [noti]
  );

  const goDetailPage = (item) => {
    if (typeNoti.buyBundle !== item.type && !item.isItem) {
      const { contractAddress, tokenId, chainId } = item;
      history.push(`/detail?contract=${contractAddress}&token=${tokenId}&chain=${chainId}`);
    }
  };

  const onClickBellRed = async () => {
    try {
      setVisible(true)
      if (unRead) {
        await readAllNotifications();
        setUnRead(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onClickClose = () => setVisible(false)

  const renderContent = (noti) => {
    switch (noti.type) {
      case typeNoti.buy:
        return {
          icon: <img src="/images/noti-buy-icon.svg" />,
          content: (
            <>
              <div>
                {cliTruncate(noti.username, 16, { position: "middle" })}
              </div>
              bought your {noti.isItem && noti.quantity}
              <div> {noti.nftName}</div>
              for {noti.nftPrice} {noti.currency}
            </>
          ),
        };
      case typeNoti.buyBundle:
        return {
          icon: <img src="/images/noti-buy-icon.svg" />,
          content: (
            <>
              <div>
                {cliTruncate(noti.username, 16, { position: "middle" })}
              </div>{" "}
              bought your <div>Bundle #{noti.bundleId}</div> for {noti.nftPrice}{" "}
              {noti.currency}
            </>
          ),
        };
      case typeNoti.makeOffer:
        return {
          icon: <img src="/images/noti-make-offer.svg" />,
          content: (
            <>
              <div>
                {cliTruncate(noti.username, 16, { position: "middle" })}
              </div>{" "}
              made an offer for about {noti.nftPrice} {noti.currency} on{" "}
              <div>{noti.nftName}</div>
            </>
          ),
        };
      case typeNoti.acceptOffer:
        return {
          icon: <img src="/images/noti-accept-offer.svg" />,
          content: (
            <>
              <div>
                {cliTruncate(noti.username, 16, { position: "middle" })}
              </div>{" "}
              accepted your offer to sell <div>{noti.nftName}</div> for{" "}
              {noti.nftPrice} {noti.currency}
            </>
          ),
        };
      default:
        return <></>;
    }
  };

  const menu = (
    <Menu className="dropdown-noti">
      <div className="dropdown-noti__title">Notification {isMobile &&<img src="/images/close-btn.svg" onClick={onClickClose}></img>} </div>
      {noti.items?.map((item, index) => {
        const itemContent = renderContent(item);
        return (
          <Menu.Item key={index} onClick={() => goDetailPage(item)}>
            <div
              ref={(ref) => {
                if (index === noti.items?.length - 1) return lastNftEle(ref);
              }}
              className="noti-item"
            >
              {itemContent.icon}
              <div className="noti-item__text">
                <div className="noti-item__text-content">
                  {itemContent.content}
                </div>
                {item.time}
              </div>
              {!item.isRead && (
                <img className="noti-item__dot" src="/images/noti-dot.svg" />
              )}
            </div>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  return isMobile ? <Dropdown overlay={menu} visible={visible}>
    <div className="notify-block">
      {unRead ? (
        <img
          className="notify-icon"
          src="/images/noti-bell-red.svg"
          alt="noti"
          onClick={onClickBellRed}
        />
      ) : (
          <img
            className="notify-icon"
            src="/images/notify-bell.png"
            alt="noti"
            onClick={() => setVisible(true)}
          />
        )}
    </div>
  </Dropdown> :
  <Dropdown overlay={menu} placement="bottomLeft" trigger={["click"]} >
      <div className="notify-block">
        {unRead ? (
          <img
            className="notify-icon"
            src="/images/noti-bell-red.svg"
            alt="noti"
            onClick={onClickBellRed}
          />
        ) : (
          <img
            className="notify-icon"
            src="/images/notify-bell.png"
            alt="noti"
          />
        )}
      </div>
    </Dropdown>
};

export default withContext(Notification);
