import React, { useEffect, useState,useMemo } from "react";
import { Row, Tabs } from "antd";
import "./style.scss";
import {
  getCompetitorList,
  getUserInfoGame,
} from "../../../api";
import CardGame from "../../common/card-game";
import RankingGame from "../../common/ranking-game";
import MyDeckTemplate from "../my-deck";
import RuleRecovery from "./rule-recovery";
import { withContext } from "../../../hook/AppContext";
import { useWindowResizeMobile } from "../../../hook/ultis.hook";
import queryString from "query-string";
import HistoryGame from "../history-game";

function NFTGameHomeTemplate(props) {
  const { history, location, gameInfo } = props;
  const [listCompetitor, setListCompetitor] = useState();
  const [userInfo, setUserInfo] = useState();
  const [activeTab, setActiveTab] = useState("Home");
  const [showModal, setShowModal] = useState(false);
  const [isMobile] = useWindowResizeMobile(787);

  const getListCompetitor = async () => {
    const res = await getCompetitorList({ game_id: gameInfo?.seasonId });
    setListCompetitor(res);
  };

  useEffect(() => {
    if(history.location.search=="?tab=Home" || !history.location.search){
      getRank();
      getInfoUser();
    } 
  }, [history.location.search]);

  const { TabPane } = Tabs;

  const getRank = async () => {
    getListCompetitor();
  };

  const getInfoUser = async () => {
    const infoUser = await getUserInfoGame();
    setUserInfo(infoUser);
  };

  const onChangeTab = (value) => {
    setActiveTab(value);
    history.push(`/nft-game/home?tab=${value}`);
  };

  useEffect(() => {
    const query = queryString.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    setActiveTab(query?.tab||"Home");
  }, [location.search]);

  // const onHandleCard = (address) => {
  //   setaddressMatch(address);
  //   setshowPopup(true);
  // };

  const renderHigherList = useMemo(() => {
    return listCompetitor?.higher_elo_competitor_list?.map((item) => (
      <CardGame {...item}  userInfo={userInfo} setShowModal={setShowModal} higherList={true}/>
    ));
  },[listCompetitor, userInfo]);

  const renderLowerList = useMemo(() => {
    return listCompetitor?.lower_elo_competitor_list?.map((item) => (
      <CardGame {...item} userInfo={userInfo} setShowModal={setShowModal}/>
    ));
  },[listCompetitor,userInfo]);

  
        
  const renderTabHome = () => {
    return (
      <>
        <RuleRecovery
          userInfo={userInfo}
          getInfoUser={getInfoUser}
          timeRecoveryTurn={userInfo?.time_fully_recovery_turn}
          showModal={showModal}
          setShowModal={setShowModal}
        />
        <Row className="list-competitor">
          {renderHigherList}
          {renderLowerList}
        </Row>
        {!isMobile && <RankingGame userInfo={userInfo}/>}
      </>
    );
  };

  return (
    <div className="game-content">
      <Tabs
        activeKey={`${activeTab}`}
        onChange={onChangeTab}
        className={"tab-game"}
        destroyInactiveTabPane={true}
      >
        <TabPane key="Home" tab="Home" className="home-container">

          {renderTabHome()}
        </TabPane>

        <TabPane key="MyDeck" tab="My Deck" >
          <MyDeckTemplate />
        </TabPane>
        <TabPane key="History" tab="History" className="home-container" >
          <HistoryGame />
          {!isMobile && <RankingGame userInfo={userInfo}/> }
        </TabPane>
      </Tabs>
      {/* <CustomModal
        visible={showPopup}
        footer={null}
        onCancel={() => setshowPopup(false)}
        className="deck-modal"
        destroyOnClose={true}
      >
        <SiteLayout>
          <MatchResult address={addressMatch} onClickHome={() => setshowPopup(false)}/>
        </SiteLayout>
      </CustomModal> */}
    </div>
  );
}

export default withContext(NFTGameHomeTemplate);
