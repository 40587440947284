import React, { useEffect } from "react";
import BoxesTemplate from "../../components/templates/boxes";
import SiteLayout from "../../layouts/site-layout";
import "./style.scss";

function Boxes(props) {

    return (
        <SiteLayout noStyle>
            <BoxesTemplate />
        </SiteLayout>
    );
}

export default Boxes;
