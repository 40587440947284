import React, { useEffect, useRef, useMemo, useState } from "react";
import "./style.scss";
import { useHistory } from "react-router-dom";
import { Row, Col } from "antd";
import { withContext } from "../../../../hook/AppContext";
import CardComponent from "../../../common/card-component";
import useBlockchainFunc from "../../../../ultis/blockchain";
import {getTokens} from "../../../../ultis/common";
import CustomButton from "../../../elements/button";
import CheckoutBundle from "../../../elements/modal/general-modal/checkout-bundle";

function DetailBundleTemplate(props) {
  const { bundleInfor, setStatusModal, isOwnerBundle, setVisibleModal, renderList, isHome } = props;
  const history = useHistory();
  const { buyBundle, cancelBundle, updateBundle} = useBlockchainFunc();
  const [isBuy, setIsBuy] = useState(false);
  const [token, setToken] = useState();


  useEffect(() => {
    setToken(getTokens()?.find(el => el.contract_address?.toLowerCase() == bundleInfor?.payment_token?.toLowerCase()))
  }, [])

  const callBackBuyBundle = () => {
    if (!isOwnerBundle){
      setIsBuy(false);
      if(setVisibleModal)setVisibleModal(false)
      if(isHome) history.push(`/inventory`);
      else history.push(`/p2p-market?tab=Bundles`);
    }
    if (renderList) renderList()
  }

  const onFinish = async (price= 0) => {
    setIsBuy(false)
    if(isOwnerBundle){
      const res = await updateBundle(
        bundleInfor.bundle_id,
        price,
        bundleInfor.chain,
        setStatusModal,
        callBackBuyBundle
      );
    }else {
      const res = await buyBundle(
        bundleInfor.bundle_id,
        bundleInfor.price,
        token,
        bundleInfor.chain,
        setStatusModal,
        callBackBuyBundle
      );
    }
  };
  const handleCancelBundle = () => {
    history.push(`/inventory`);
  }

  const onCancelBundle = async () => {
      await cancelBundle(bundleInfor.bundle_id,bundleInfor.chain, setStatusModal, handleCancelBundle);
    
  };
  const cardSize = 244 + 25;
  const tabRef = useRef(null);  
  const [changeLayout, setChangeLayout] = useState(false);
  useEffect(() => {
    if (window.innerWidth <= 768) {
      setChangeLayout(true);
    } else setChangeLayout(false);
    window.addEventListener("resize", () => {
      if (window.innerWidth <= 768) {
        setChangeLayout(true);
      } else {
        setChangeLayout(false);
      }
    });
    return window.addEventListener("resize", () => {});
  }, []);

  const renderAbout = useMemo(() => {
    return (
      <>
        {isOwnerBundle ? (
          <div className="btn-container">
            <div className="btn-item red-btn" onClick={onCancelBundle}>
              Cancel listing
            </div>
            <CustomButton
              classNames="green-btn btn-item"
              children={`Change listing`}
              shape="circle-custom"
              onClick={() => setIsBuy(true)}
              htmlType="button"
            />
          </div>
        ) : (
          <CustomButton
            classNames="orange-btn btn-item"
            children={"Purchase"}
            shape="circle-custom"
            onClick={() => setIsBuy(true)}
            htmlType="button"
          />      
        )}
      </>
    );
  }, [bundleInfor]);

  return (
    <div className={setVisibleModal ? "bundle-detail master-layout__market " : "bundle-detail"}>
      <div className="confirm-bundle-page">
      <div className="list-bundle-item">
        
        <Row justify="center">
          {bundleInfor.nft_data?.map((el, index) => (
            <Col>
              <CardComponent
                key={index}
                nft_data={el}
                isInventory={true}
                token_id={el.tokenId}
                chain={bundleInfor.chain}
              />
            </Col>
          ))}
        </Row>
      </div>
      <div className="bundle-info">
          Bundle 
            {bundleInfor?.bundle_id && (
              " #"+bundleInfor?.bundle_id
            )}
          <div className="bundle-info__quantity">
            {bundleInfor.nft_data?.length || 0} items
          </div>
          <div className="info-container">
            <Row>
              <Col xs={12}>
                Price
              </Col>
              <Col xs={12} className={`price-container`}>
                <img className="token-logo" src={`/images/token/${token?.sort_name?.toLowerCase()}.svg`} />
                {bundleInfor?.price}{" "}
                {bundleInfor?.bundle_id ? token?.sort_name : `USDT`}
              </Col>
            </Row>
            <Row className={`network-container`}>
              <Col xs={12}>Network</Col>
              <Col xs={12}>
                <img src={`/images/chain/${bundleInfor.chain.sort_name.toLowerCase()}.svg`}/>
                {bundleInfor.chain.sort_name}
              </Col>
            </Row>
          
            
          </div>
          
           
        {renderAbout}
      </div>
    </div>
      {isBuy && (
        <CheckoutBundle
          setVisible={setIsBuy}
          onFinish={onFinish}
          bundleData={bundleInfor}
          isOwnerBundle={isOwnerBundle}
        />
      )}
    </div>
  );
}

export default withContext(DetailBundleTemplate);
