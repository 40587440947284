import React, {useMemo} from "react";
import "./style.scss";
import { useLocation } from "react-router-dom";
import CustomModal from "../../elements/modal";
import DetailTemplate from "../../templates/detail"

const DetailModal = (props) => {
  const { visible, setVisibleModal, renderList, query, isHome } = props;
  const location = useLocation();

  window.onhashchange = ()=> {
    if (!window.location.hash.includes("detail")){setVisibleModal(false)}
  }

  const onCancel = () => {
    setVisibleModal(false)
    // window.history.replaceState(null, "", url)
    window.history.back()
  };

  return <CustomModal
        className="detail-modal"
        footer={null}
        visible={visible}
        zIndex={10000}
        onCancel={onCancel}
        mask={false}
        zIndex={500}
        destroyOnClose={true}
        closeIcon={<div className="close-detail">
          <img src="/images/arrow-right-left.svg"/>
          Back
        </div>}
      >
        <DetailTemplate renderList={renderList} setVisibleModal={setVisibleModal} queryFromModal= {query} isHome={isHome}/>
      </CustomModal>
  
};

export default DetailModal;
