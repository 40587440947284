import { Row } from "antd";
import React from "react";
import { RarityInterface } from "../../../../../constants";
import { withContext } from "../../../../../hook/AppContext";

function RunesTab(props) {
  const { runeInfos, renderLinkImage } = props;

  const renderRunesItem = (el) => {
    if (el)
      return (
        <div className="troop-item">
          {el.name}
          <div
            className="rune-container"
            style={{ marginTop: "12px", display: "flex", gap: "10px" }}
          >
            <div className="rune-image-container">
              <img className="rune-icon" src={renderLinkImage(el.image)} alt="icon" />
              <img
                alt="rarity"
                className="rarity-rune"
                src={`/images/rarity-icon/${RarityInterface[
                  el.rarity
                ]?.toLowerCase()}.svg`}
              />
            </div>
            <div className="rune-item-description">{el.description}</div>
          </div>
        </div>
        // <div className="troop-item">
        //   <div className="text-capitalize">{el.name}</div>
        //   <Row className="" style={{width: "100%"}}>
        //     <img
        //       className="troop-item-image"
        //       src={renderLinkImage(el.image)}
        //       alt="icon"
        //     />
        //     <div className="troop-item-description">{el.description}</div>
        //   </Row>
        // </div>
      );
  };

  return (
    <div className="description-tab">
      {runeInfos?.map((el) => renderRunesItem(el))}
    </div>
  );
}

export default withContext(RunesTab);
