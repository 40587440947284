import React, { useEffect } from "react";
import NFTIngameTemplate from "../../components/templates/nft-ingame";
import SiteLayout from "../../layouts/site-layout";
import "./style.scss";

function NFTInGamePage(props) {
  return <NFTIngameTemplate />
}

export default NFTInGamePage;
