import React, { useState, useMemo,useEffect } from "react";
import "./style.scss";
import { Statistic } from "antd";
import {
  saveAtkDeck, 
  saveDefDeck
} from "../../../../api";
import { useUIContext } from "../../../../hook/AppContext";
import {DEFAULT_ITEMS_DECK} from "../../../../constants";
import InventoryModal from "./inventory-modal";
import CustomButtonField from "../../../elements/button";
import CustomButton from "../../../elements/button";
import CustomModal from "../../../elements/modal";


const { Countdown } = Statistic;

function DeckTab(props) {
  const {
    statusTextModal,
    setStatusTextModal,
    setStatusModal,
    gameInfo,
    getGameUserInfo
  } = useUIContext();
  const {listDeck, setListDeck, deskTab} = props;
  const [showInventory, setShowInventory] = useState(false);
  const [renderList, setRenderList] = useState(false);
  const [showSave, setShowSave] = useState(false);
  const [countdown, setCountdown] = useState(false);
  const [visible, setVisible] = useState(false)

  useEffect(()=>{
    if(gameInfo?.infoUser?.delay_save_deck > Date.now()) setCountdown(true)
  },[])

  const onClickEmpty = () => {
    setShowInventory(true)
  }

  const  onCancel = () => setVisible(false)
  const openModal = () => setVisible(true)
  const onClickSave = async() =>{
    setVisible(false)
    setStatusTextModal({
      ...statusTextModal,
      pending: "Save pending",
      success: "You have successfully saved deck!",
      fail: "Failed to save",
    });
    setStatusModal({ isPending: true });
    try{
      const listId = listDeck?.map(el=> el._id)
      let res
      if(deskTab == "Defense"){
        res= await saveDefDeck({list_nft: listId})
      }else res = await saveAtkDeck({list_nft: listId})
      if(res) setStatusModal({ isPending: false, isSuccess: true });
      setShowSave(false)
      setCountdown(true)
      getGameUserInfo()
    }catch(e) {
      setStatusModal({ isPending: false, isFailed: true });
    }  
  }

  const  onClickRemove = (el) =>{
    let list = listDeck
    const index = list.indexOf(el);
    if (index > -1) {
      list.splice(index, 1); 
    }
    setShowSave(true)
    setListDeck(list)
    setRenderList(true)
  }

  const onFinish = (val) =>{
    setCountdown(false)
  }

  const renderEmptyItem = (total) =>{
    let list = []
    for(let i=0; i < total; i++) {
      if(countdown && deskTab == "Defense"){
        list[i] = <div className="no-hover"/>
      }else list[i] = <div className="item-empty" onClick={onClickEmpty}/>
    }
    return list
  }
  

  const renderCardContent = (el) =>{
    const {attributes} = el
    const rarity = attributes?.find((el) => el.trait_type === "Rarity");
    const race = attributes?.find((el) => el.trait_type === "Race");
    return (
      <div className="card-deck__content">
        <img className="image-card" src={el.image}/>
        <div>Score: {el.score}</div>
        <div className="rarity-race">
        {rarity && <img className="rarity-race__rarity" src={`/images/rarity-icon/${rarity?.value?.toLowerCase()}.svg`}/>}
        {race && race?.value != "All" && <img className="rarity-race__rarity" src={`/images/race-icon/${race?.value?.toLowerCase()}.png`}/>}
      </div>
      </div>
    ) 
  }

  const renderCardItem = (el,index) => (
    <div className="item-deck" key={index}>
      <div className="id-card">ID: {el.nft_info?.token_id}</div>
      {renderCardContent(el)}
      {(!countdown || deskTab != "Defense" )&& <CustomButtonField
        classNames="btn-remove"
        color="red"
        size="medium"
        onClick={()=>onClickRemove(el)}
      >
        {"Remove"}
      </CustomButtonField>}
    </div>
  )

  const renderListDeck = useMemo(() => {
    setRenderList(false)
    const list = listDeck
    return <div className="list-deck">
      {list?.map((el,index)=> renderCardItem(el,index))}
      {renderEmptyItem(DEFAULT_ITEMS_DECK - list?.length)}
    </div>
   
  },[listDeck?.length, renderList,listDeck, countdown, deskTab])

  const renderBtn = useMemo(() => {
    const time = gameInfo?.infoUser?.delay_save_deck
    if(countdown && deskTab == "Defense") return <CustomButtonField
                                        classNames="btn-card btn-save"
                                        color="green"
                                        size="medium"
                                        disabled
                                      >
                                        <Countdown
                                          className="time-countdown"
                                          value={time}
                                          format={`HH : mm : ss`}
                                          onFinish={onFinish}
                                        />
                                    </CustomButtonField>
    else return showSave && <CustomButtonField
                              classNames="btn-card btn-save"
                              color="green"
                              size="medium"
                              onClick={deskTab == "Defense" ? openModal : onClickSave}
                            >
                              {deskTab == "Defense" ? `Save defense deck` : 'Save attack deck'}
                            </CustomButtonField>
  },[gameInfo, showSave, countdown, deskTab ])

  
  const renderContent = () => (
    <div className="deck-tab">
      <div className="desk-note">{`Defense & Attack: Please select 6 NFTs per deck, at least 1 NFT. The score of the card without NFT is 0.`}
      <div className="desk-note note-second">{deskTab == "Defense" ?`You can only update the defense deck every 2 hours.`: `You can update the attack deck continuously.`}</div>
      </div>
       {renderListDeck}
       {renderBtn}
      <InventoryModal deskTab={deskTab} setShowSave={setShowSave} setRenderList={setRenderList}showInventory={showInventory} setShowInventory={setShowInventory} listDeck={listDeck} setListDeck={setListDeck} renderCardContent={renderCardContent}/>
    <CustomModal
        className="confirm-defense"
        footer={null}
        visible={visible}
        onCancel={onCancel}
        closable={false}
        zIndex={10000}
      >
        <div className="d-flex align-items-center justify-content-center notify-content">
          <div>
            Please notice that you can only update the defense deck every 2 hours.
          </div>
          <div className="btn-container">
            <CustomButton
            children={"Cancel"}
            classNames="red-btn btn-item"
            shape="circle-custom"
            onClick={onCancel}
          />
          <CustomButton
            children={"Confirm"}
            classNames="orange-btn btn-item"
            shape="circle-custom"
            onClick={onClickSave}
          />
          </div>
          
        </div>
      </CustomModal>
    </div>
    
  );

  return renderContent()
}

export default DeckTab;
