import { Col, Form, Row, Skeleton, Tabs } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { RarityInterface } from "../../../../constants";
import { withContext } from "../../../../hook/AppContext";
import { useWindowResizeMobile } from "../../../../hook/ultis.hook";
import useBlockchainFunc from "../../../../ultis/blockchain";
import {
  getContractFee,
  getTokens,
  getUser,
  useWeb3,
} from "../../../../ultis/common";
import { CardModal } from "../../../common/card-modal";
import Anim from "../../../elements/anim";
import CustomButton from "../../../elements/button";
import GeneralModal from "../../../elements/modal/general-modal";
import Rarity from "../../../elements/rarity";
import Star from "../../../elements/star";
import Decor from "../../../elements/static-anim/decor";
import DescriptionTab from "./description";
import DescriptionDPTab from "./description-bp";
import DNATab from "./dna-parent";
import HistoryTab from "./history";
import RunesTab from "./runes";
import "./style.scss";
import TroopsTab from "./troops";
import ConfirmModal from "../../inventory/modal-confirm";

const { TabPane } = Tabs;

const infoTabPrimary = [
  "Description",
  "DNA",
  "History",
  "Offers",
  "Troops",
  "Runes",
];

function DetailMonTemplate(props) {
  const {
    nftData,
    orderInfor,
    setStatusModal,
    setStatusTextModal,
    t,
    isAuthenticatedUser,
    isOwnerOrder,
    ownerBid,
    isOwnerNft,
    bidInfor,
    statusModal,
    setVisibleModal,
    renderList,
    chains,
  } = props;
  const location = useLocation();
  const [isShowingPopup, setIsShowingPopup] = useState();
  const [form] = Form.useForm();
  const [balanceWallet, setBalanceWallet] = useState({});
  const web3 = useWeb3();
  const [showModal, setShowModal] = useState(false);
  const [nftUpdate, setNftUpdate] = useState();
  const [nftPut, setNftPut] = useState();
  const [isUpdateOffer, setUpdateOffer] = useState(false);
  const [modalType, setModalType] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [showCardModal, setShowCardModal] = useState(false);
  const [listCard, setlistCard] = useState([]);
  const [isMobile] = useWindowResizeMobile(1025);

  const {
    buyNFT,
    cancelOrder,
    createOrder,
    makeOffer,
    updateOrder,
    cancelOffer,
    updateOffer,
    acceptOffer,
    buyMysteryBox,
    getTotalBuyBox,
    openMysteryBox,
    checkLogin,
    convertNFT,
  } = useBlockchainFunc();
  const offerExpirationOpts = [
    {
      value: "3",
      label: t("offerExpirationOptions.in3days"),
    },
    {
      value: "7",
      label: t("offerExpirationOptions.in7days"),
    },
    {
      value: "10",
      label: t("offerExpirationOptions.in10days"),
    },
    {
      value: "15",
      label: t("offerExpirationOptions.in15days"),
    },
  ];

  const [currentBid, setCurrentBid] = useState(0);

  const customInfoTab = (title) => {
    return (
      <div className="custom-tab">
        <div className="tab-title">{title}</div>

        <div className={`bottom-line`}></div>
      </div>
    );
  };

  const rules = {
    amount: [
      {
        required: true,
        message: t("requiredField"),
      },
    ],
  };

  const initialValues = {
    amount: "",
    offerExpiration: offerExpirationOpts[0].value,
  };

  useEffect(async () => {
    if (statusModal?.isSuccess) {
      setIsShowingPopup(false);
    }
  }, [statusModal]);

  useEffect(() => {
    if (nftData)
      setTimeout(() => {
        setIsLoading(false);
      }, 1500);
  }, [nftData]);

  const renderRarityBox = useMemo(() => {
    if (!orderInfor?.box_info?.attributes) return;
    let listAb = [];
    Object.values(orderInfor?.box_info?.attributes).forEach((val, index) => {
      let item;
      if (val.trait_type === "Evolution") {
        item = (
          <Star
            className="box evolution"
            readonly={true}
            key={index * 3}
            initialRating={val.value}
          />
        );
      } else if (val.trait_type === "Rarity") {
        const modifiedVal = { name: val?.trait_type, value: val?.value };
        item = (
          <div className="box" key={index * 11}>
            <Rarity {...modifiedVal} />
          </div>
        );
      } else {
        item = (
          <div className="box yellow other" key={index * 8}>
            <div className="box-name">{val.trait_type}</div>
            <div className="box-value">
              <div className="box-line"></div>
              <div className="content">{val.value}</div>
            </div>
          </div>
        );
      }
      listAb.push(item);
    });
    return listAb;
  }, [orderInfor]);
  const renderLinkImage = (image) => {
    const array = image?.split("/");
    var newImage = "/images";
    for (let i = 3; i < array?.length; i++) {
      newImage = newImage + "/" + array[i];
    }
    return newImage.replace("+", "_");
  };

  const renderAnim = () => {
    if (nftData?.dna_raw?.length)
      return (
        <Anim
          width={"100%"}
          height={"500px"}
          dna={nftData?.dna_raw}
          className="item-image p2p-detail-img"
          lock={[]}
        />
      );
    if (nftData?.meta?.decoration_list?.length > 0)
      return (
        <div className="item-image  decor-image ">
          <Decor
            rarity={nftData?.meta?.rarity}
            decoration_list={nftData?.meta?.decoration_list}
            isDetail={true}
          />
        </div>
      );

    if (isMobile)
      return (
        <img
          src={
            nftData?.static_image
              ? renderLinkImage(nftData?.image)
              : nftData?.image
          }
          className="item-image p2p-detail-img"
          alt="p2p"
        />
      );
    else
      return (
        <div
          style={{
            width: "100%",
            position: "relative",
          }}
        >
          <div
            style={{ paddingTop: "100%", position: "absolute", width: "100%" }}
          >
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  width: "100%",
                }}
              >
                {(nftData?.static_image || nftData?.image) && (
                  <img
                    src={
                      nftData?.static_image
                        ? renderLinkImage(nftData?.image)
                        : nftData?.image
                    }
                    className="item-image p2p-detail-img"
                    alt="item detail"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      );
  };

  const onFinish = (values) => {
    var date = new Date();
    date.setDate(date.getDate() + Number(values.offerExpiration));
    let token = getTokens()?.find(
      (el) => el.contract_address?.toLowerCase() == values.token?.toLowerCase()
    );
    token = token ? token : getTokens()?.find((el) => el.is_active);

    const data = {
      contract: nftData?.contract,
      payment_token: token,
      token: nftData?.token,
      price: web3.utils.toWei(values.amount.toString()),
      expired: (date.getTime() / 1000).toFixed(0),
    };
    if (isUpdateOffer)
      updateOffer(
        {
          bid_id: ownerBid.bid_id,
          price: web3.utils.toWei(values.amount.toString()),
          expired: (date.getTime() / 1000).toFixed(0),
          token: token,
        },
        setStatusModal
      );
    else makeOffer(data, setStatusModal);
  };

  const [changeLayout, setChangeLayout] = useState(false);
  useEffect(() => {
    if (window.innerWidth <= 375) {
      setChangeLayout(true);
    } else setChangeLayout(false);
  });

  const [isPurchase, setIsPurchase] = useState(false);
  const onBuyNft = async () => {
    // TODO: AURA
    let token = getTokens()?.find(
      (el) =>
        el.contract_address?.toLowerCase() ==
        orderInfor?.payment_token?.toLowerCase()
    );
    const res = await buyNFT(
      orderInfor.order_id,
      orderInfor.price,
      1,
      token,
      setStatusModal,
      nftData?.chain
    );
  };
  const onBuyMysteryBox = async () => {
    const totalBuyBox = await getTotalBuyBox(orderInfor.box_id);
    if (totalBuyBox < orderInfor.maxBuyPerAddress) {
      await buyMysteryBox(
        orderInfor.box_id,
        orderInfor.payment_token,
        orderInfor.price,
        setStatusModal
      );
    } else {
      setStatusModal({ isNotify: true });
      setStatusTextModal({
        noti: `You only can buy ${orderInfor.maxBuyPerAddress} Treasury Boxes`,
      });
    }
  };

  const onOpenMysteryBox = async () => {
    await openMysteryBox(nftData, setStatusModal);
  };

  const onClickListSale = async () => {
    setNftPut(nftData);
    setNftUpdate({});
    setShowModal(true);
    setModalType("put-on-sale");
  };

  const onClickCancelSale = async () => {
    const res = await cancelOrder(
      { order_id: orderInfor.order_id, chainInfo: nftData.chain },
      setStatusModal
    );
  };
  const onChangeSale = () => {
    setNftPut({});
    setNftUpdate({
      ...nftData,
      price: orderInfor?.price,
      order_id: orderInfor?.order_id,
      payment_token: orderInfor?.payment_token,
    });
    setModalType("update-on-sale");

    setShowModal(true);
  };
  const handleConvertDone = (result) => {
    setShowCardModal(true);
    setlistCard(result?.nftdata);
    renderList();
  };

  const onConfirmConvert = async () => {
    const listConvert = [nftData?.nft_info];
    let type;
    if (nftData?.isOperatorNFT && nftData?.static_image) type = "BOX";
    if (!nftData?.static_image) type = "BINANCE";
    await convertNFT(listConvert, setStatusModal, handleConvertDone, type);
  };

  const onClickCancelOffer = async () => {
    const res = await cancelOffer({ bid_id: ownerBid.bid_id }, setStatusModal);
  };

  const onClickChangeOffer = async () => {
    setUpdateOffer(true);
    form.setFieldsValue({ amount: ownerBid?.price });
    setIsShowingPopup(true);
    setModalType("change-offer");
  };

  const onClickAcceptOffer = async (id, price, tokenAddress) => {
    const res = await acceptOffer(
      {
        bid_id: id,
        contractNft: nftData.contract,
        price: price,
        tokenAddress: tokenAddress,
      },
      setStatusModal
    );
  };

  const [posY, setPosY] = useState(window.pageYOffset);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  window.addEventListener("resize", () => {
    setWindowHeight(window.innerHeight);
  });
  window.addEventListener("scroll", () => {
    setPosY(window.pageYOffset);
  });

  const renderInfoTab = useMemo(() => {
    if (!infoTabPrimary) return;
    // const titleOffer = (
    //   <>
    //     {infoTabPrimary[3]}{" "}
    //     {bidInfor.length > 0 && (
    //       <span className="number-offer">({bidInfor.length})</span>
    //     )}
    //   </>
    // );
    let listAb = (
      <>
        {nftData?.isLand ||
        nftData?.isOperatorNFT ||
        nftData?.valueDecoration ||
        nftData?.isRune ||
        nftData?.isTreasuryBox ? (
          <TabPane
            className={`info-tab`}
            tab={customInfoTab(infoTabPrimary[0])}
            key={1}
          >
            <DescriptionDPTab
              nftData={nftData}
              orderInfor={orderInfor}
              valueDecoration={nftData?.valueDecoration}
            />
          </TabPane>
        ) : (
          <>
            <TabPane
              className={`info-tab`}
              tab={customInfoTab(infoTabPrimary[0])}
              key={1}
            >
              <DescriptionTab
                nftData={nftData}
                orderInfor={orderInfor}
                renderLinkImage={renderLinkImage}
              />
            </TabPane>
            {nftData?.troopInfos && (
              <TabPane
                className={`info-tab`}
                tab={customInfoTab(infoTabPrimary[4])}
                key={5}
              >
                <TroopsTab
                  troopInfos={nftData?.troopInfos}
                  renderLinkImage={renderLinkImage}
                />
              </TabPane>
            )}
            {nftData?.runeInfos?.length > 0 && (
              <TabPane
                className={`info-tab`}
                tab={customInfoTab(infoTabPrimary[5])}
                key={6}
              >
                <RunesTab
                  runeInfos={nftData?.runeInfos}
                  renderLinkImage={renderLinkImage}
                />
              </TabPane>
            )}
            {(nftData?.details?.dna || nftData?.parents) && (
              <TabPane
                className={`info-tab`}
                tab={customInfoTab(
                  nftData?.details?.dna ? infoTabPrimary[1] : "Parents"
                )}
                key={2}
              >
                <DNATab nftData={nftData} orderInfor={orderInfor} />
              </TabPane>
            )}
          </>
        )}
        <TabPane
          className={`info-tab`}
          tab={customInfoTab(infoTabPrimary[2])}
          key={3}
        >
          <HistoryTab nftData={nftData} />
        </TabPane>
        {/* <TabPane className={`info-tab`} tab={customInfoTab(titleOffer)} key={4}>
          <OfferTab
            nftData={nftData}
            bidInfor={bidInfor}
            isOwnerOrder={isOwnerOrder}
            ownerBid={ownerBid}
            setModalType={setModalType}
            setIsShowingPopup={setIsShowingPopup}
            onClickChangeOffer={onClickChangeOffer}
            setCurrentBid={setCurrentBid}
            isOwnerNft={isOwnerNft}
          />
        </TabPane> */}
      </>
    );
    return listAb;
  }, [nftData, isOwnerOrder, bidInfor, isOwnerNft]);

  const renderInforNft = useMemo(() => {
    if (!nftData?.details?.about) return;
    let listAb = Array(2);
    Object.values(nftData?.details?.about).forEach((val, index) => {
      if (val.name === "Evolution") {
        listAb[0] = (
          <Star
            className="box evolution"
            readonly={true}
            key={index * 3}
            initialRating={val.value > 5 ? val.value -  5 : val.value}
            fullSymbolStar={val.value > 5 ? "/images/star-pro.png" : "/images/star-full.png"}
          />
        );
      } else if (val.name === "Level") {
        listAb[1] = (
          <div className="box green level" key={index * 5}>
            <div className="box-name">{val.name}</div>

            <div className="box-value">
              <div className="box-line"></div>
              <div className="content">
                <span className="level-numb">{val.value}</span> | {val.max}
              </div>
            </div>
          </div>
        );
      }
    });
    if (!listAb[0] && !listAb[1]) return;
    return (
      <div className="nft-infor">
        <img
          className="nft-infor__chain"
          src={`/images/chain/${nftData?.chain?.sort_name?.toLowerCase()}.svg`}
        />
        <div className="nft-infor__chain-name">{nftData?.chain?.sort_name}</div>
        {listAb}
      </div>
    );
  }, [nftData]);

  const renderBtnPurchase = useMemo(() => {
    const token = getTokens()?.find(
      (el) =>
        el?.contract_address?.toLowerCase() ==
        orderInfor?.payment_token?.toLowerCase()
    )?.sort_name;
    if (
      orderInfor &&
      orderInfor.owner_address?.toLowerCase() !== getUser()?.address &&
      !isOwnerNft
    )
      return (
        <CustomButton
          classNames="orange-btn btn-item btn-purchase"
          children={
            <div className="btn-card">
              {" "}
              <img src={`/images/token/${token?.toLowerCase()}.svg`} />
              <div className="purchase">
                Purchase
                <div className="price">
                  {orderInfor?.price} {token}
                </div>
                {/* {orderInfor?.order_id || !orderInfor ? `MSTR` : `USDT`}</div> */}
              </div>
            </div>
          }
          shape="circle-custom"
          onClick={() => {
            if (!checkLogin()) return;
            setIsPurchase(true);
            setModalType("checkout");
          }}
          htmlType="button"
        />
      );
  }, [orderInfor, nftData, isOwnerNft]);

  return (
    <>
      <Row className="detail-mon row-infor">
        <Col className="left-container">
          <div className={`col-fixed left-column left-detail`}>
            <div className="image-block">
              {renderAnim()}
              {changeLayout && nftData?.token && (
                <div className={`name-item text-capitalize`}>
                  {nftData?.name}{" "}
                  {nftData?.token && (
                    <div className="id-item">#{nftData?.token}</div>
                  )}
                </div>
              )}
              {/* {changeLayout && (
                <div className="item-container about-list">
                  <div className="box-list">{renderRarityBox}</div>
                </div>
              )} */}
            </div>
          </div>
        </Col>

        <Col className="mb-100 right-column">
          {/* {isLoading? <img className="icon-loading" src="/images/loading-icon-2.png"/> : 
         <> */}
          <Skeleton loading={isLoading} paragraph={{ rows: 3 }}>
            {!changeLayout && (
              <div className={`name-item text-capitalize`}>
                {nftData?.name}{" "}
                {nftData?.token && (
                  <div className="id-item">#{nftData?.token}</div>
                )}
                {nftData?.rarity !== null && nftData.isRune && (
                  <div
                    className=""
                    style={{
                      display: "flex",
                      fontSize: "14px",
                      marginTop: "10px",
                    }}
                  >
                    <img
                      alt="rarity"
                      className="rarity-icon"
                      src={`/images/rarity-icon/${RarityInterface[
                        nftData.rarity
                      ]?.toLowerCase()}.svg`}
                    />
                    <div className="value-name">
                      {RarityInterface[nftData.rarity]}
                    </div>
                  </div>
                )}
              </div>
            )}
            {renderInforNft}

            <div className="btn-block detail-btn-container">
              {!orderInfor && isOwnerNft && (
                <>
                  <CustomButton
                    classNames={
                      !nftData?.static_image ||
                      (nftData?.isOperatorNFT && nftData?.static_image)
                        ? "orange-btn btn-item"
                        : "orange-btn btn-item btn-purchase"
                    }
                    children={`Put on sale`}
                    shape="circle-custom"
                    onClick={onClickListSale}
                    htmlType="button"
                  />
                  {(!nftData?.static_image ||
                    (nftData?.isOperatorNFT && nftData?.static_image)) && (
                    <CustomButton
                      classNames="orange-btn btn-item"
                      children={
                        nftData?.isOperatorNFT && nftData?.static_image
                          ? "Unbox"
                          : "Convert"
                      }
                      shape="circle-custom"
                      onClick={onConfirmConvert}
                      htmlType="button"
                      color="brown"
                    />
                  )}
                </>
              )}
              {!!orderInfor?.order_id && isOwnerOrder && (
                <>
                  <CustomButton
                    classNames="red-btn btn-item"
                    children={`Cancel listing`}
                    shape="circle-custom"
                    onClick={() => setModalType("remove-from-sale")}
                    htmlType="button"
                  />
                  <CustomButton
                    classNames="green-btn btn-item"
                    children={`Change listing`}
                    shape="circle-custom"
                    onClick={onChangeSale}
                    htmlType="button"
                  />
                </>
              )}
              {/* {!isOwnerOrder &&
                    !orderInfor?.box_id &&
                    !isOwnerNft &&
                    (!ownerBid &&
                      <CustomButton
                        classNames="brown-btn btn-item"
                        children={"Make offer"}
                        shape="circle-custom"
                        onClick={onClickMakeOffer}
                        htmlType="button"
                      />
                    )} */}
              {renderBtnPurchase}
              {orderInfor &&
                orderInfor.owner_address?.toLowerCase() !==
                  getUser()?.address &&
                orderInfor.owner_address &&
                !nftData?.details?.skills &&
                nftData?.contract?.includes("0x2b6ed196e4db86431cb562de") &&
                !isOwnerNft && (
                  <CustomButton
                    classNames="orange-btn btn-item purchase"
                    children={"Purchase"}
                    shape="circle-custom"
                    onClick={() => {
                      if (!checkLogin()) return;
                      setIsPurchase(true);
                      setModalType("checkout-box");
                    }}
                    htmlType="button"
                  />
                )}
            </div>

            {!changeLayout && !nftData?.details?.skills && (
              <div className="item-container about-list">
                <div className="box-list">{renderRarityBox}</div>
              </div>
            )}

            <Tabs className="info-table" type="card">
              {renderInfoTab}
            </Tabs>
          </Skeleton>
          {/* } */}
        </Col>
      </Row>

      {isPurchase && modalType === "checkout" && (
        <GeneralModal
          isShowingPopup={isPurchase}
          setIsShowingPopup={setIsPurchase}
          nftData={nftData}
          initialValues={initialValues}
          onFinish={orderInfor.box_id ? onBuyMysteryBox : onBuyNft}
          orderInfor={orderInfor}
          rules={rules}
          type={modalType}
        />
      )}

      {isPurchase && modalType === "checkout-box" && (
        <GeneralModal
          isShowingPopup={isPurchase}
          setIsShowingPopup={setIsPurchase}
          nftData={nftData}
          initialValues={initialValues}
          onFinish={orderInfor.box_id ? onBuyMysteryBox : onBuyNft}
          orderInfor={orderInfor}
          rules={rules}
          type={modalType}
        />
      )}

      {modalType === "make-offer" && (
        <GeneralModal
          isShowingPopup={isShowingPopup}
          setIsShowingPopup={setIsShowingPopup}
          nftData={nftData}
          initialValues={initialValues}
          onFinish={onFinish}
          orderInfor={orderInfor}
          rules={rules}
          type={modalType}
          setUpdateOffer={setUpdateOffer}
          balanceWallet={balanceWallet}
        />
      )}

      {modalType === "open-box" && (
        <GeneralModal
          isShowingPopup={isShowingPopup}
          setIsShowingPopup={setIsShowingPopup}
          nftData={nftData}
          initialValues={initialValues}
          onFinish={onOpenMysteryBox}
          orderInfor={orderInfor}
          rules={rules}
          type={modalType}
          getTotalBuyBox={getTotalBuyBox}
        />
      )}

      {modalType === "change-offer" && (
        <GeneralModal
          isShowingPopup={isShowingPopup}
          setIsShowingPopup={setIsShowingPopup}
          nftData={nftData}
          initialValues={initialValues}
          onFinish={onFinish}
          orderInfor={orderInfor}
          rules={rules}
          type={modalType}
          balanceWallet={balanceWallet}
          offerPrice={ownerBid?.price || 0}
          bidInfor={bidInfor[0]}
        />
      )}

      {modalType === "cancel-offer" && (
        <GeneralModal
          isShowingPopup={isShowingPopup}
          setIsShowingPopup={setIsShowingPopup}
          nftData={nftData}
          initialValues={initialValues}
          onFinish={onClickCancelOffer}
          orderInfor={bidInfor[currentBid]}
          rules={rules}
          offerPrice={ownerBid?.price || 0}
          type={modalType}
          setUpdateOffer={setUpdateOffer}
        />
      )}
      {modalType === "accept-offer" && (
        <GeneralModal
          isShowingPopup={isShowingPopup}
          setIsShowingPopup={setIsShowingPopup}
          nftData={nftData}
          initialValues={initialValues}
          onFinish={() =>
            onClickAcceptOffer(
              bidInfor[currentBid]?.bid_id,
              bidInfor[currentBid]?.price,
              bidInfor[currentBid]?.payment_token
            )
          }
          orderInfor={bidInfor[currentBid]}
          rules={rules}
          type={modalType}
          setUpdateOffer={setUpdateOffer}
          getContractFee={getContractFee}
        />
      )}
      {modalType === "remove-from-sale" && (
        <GeneralModal
          isShowingPopup={modalType === "remove-from-sale"}
          setIsShowingPopup={() => setModalType("")}
          onFinish={onClickCancelSale}
          nftData={nftData}
          initialValues={initialValues}
          orderInfor={orderInfor}
          rules={rules}
          type={modalType}
        />
      )}

      {nftPut && modalType === "put-on-sale" && (
        <ConfirmModal
          nft={nftPut}
          onClick={createOrder}
          showModal={showModal}
          setShowModal={setShowModal}
          setStatusModal={setStatusModal}
          orderInfor={orderInfor}
          type={"put"}
          getContractFee={getContractFee}
        />
      )}
      {nftUpdate && modalType === "update-on-sale" && (
        <ConfirmModal
          nft={nftUpdate}
          onClick={updateOrder}
          showModal={showModal}
          setShowModal={setShowModal}
          setStatusModal={setStatusModal}
          type={"change"}
          callBack={renderList}
        />
      )}
      <CardModal
        setShowhowModal={setShowCardModal}
        showModal={showCardModal}
        cards={listCard}
        setVisibleModal={setVisibleModal}
        chainInfo={chains?.find((el) => el.sort_name == "BSC")}
      />
    </>
  );
}

export default withContext(DetailMonTemplate);
