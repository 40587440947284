import React, { useMemo } from "react";
import "./style.scss";
import { Col, Row } from "antd";

function MutateItem(props) {
  const { value, text } = props;

  let BodyParts = [
    "Form",
    "Head",
    "Eyes",
    "Horns",
    "Tail",
    "Back",
    "FrontLeg",
    "BackLeg",
    "Mouth",
    "Aura",
  ];

  let getStatIcon = (stat) => {
    switch (Number(stat)) {
      case 0:
        return "/images/detail-icon/Health.png";
      case 1:
        return "/images/detail-icon/Sturdiness.png";
      case 2:
        return "/images/detail-icon/Magic.png";
      case 3:
        return "/images/detail-icon/Aggression.png";
      default:
        return "";
    }
  };

  return (
    <div className="dna-item flex">
      <Row justify="center">
        {Object.keys(value.mutated_part_value ?? {}).map((stat) => {
          let valueStat =
            value.mutated_part_value?.[stat]?.[BodyParts.indexOf(text)] ?? 0;

          if (valueStat)
            return (
              <Col
                span={24}
                style={{
                  color: `${valueStat > 0 ? "#3DB35F" : "#C14948"}`,
                }}
              >
                <img
                  src={getStatIcon(stat)}
                  alt="stat"
                  style={{
                    height: "16px",
                    objectFit: "contain",
                  }}
                />{" "}
                {valueStat > 0 && "+"}
                {valueStat}
              </Col>
            );
        })}
      </Row>
    </div>
  );
}

export default MutateItem;
