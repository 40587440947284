import "./style.scss";
import React, { useEffect, useState } from "react";
import { Row, Col, Form, Select } from "antd";
import { useLocation, useHistory } from "react-router-dom";
import { withContext } from "../../../hook/AppContext";
import useBlockchainFunc from "../../../ultis/blockchain";
import { useWindowResizeMobile } from "../../../hook/ultis.hook";
import { FEE_TYPE } from "../../../constants";
import CardComponent from "../../common/card-component";
import CustomInput from "../../elements/input";
import CustomButtonField from "../../elements/button";
import CustomButton from "../../elements/button";
import { getAllCollection, getListGame } from "../../../api";
import {
  getUser,
  formatInputNumber,
  getContractFee,
  getTokens,
  getDiscountPayment,
} from "../../../ultis/common";

const { Option } = Select;

function ConfirmBundleTemplate(props) {
  const { t, setStatusModal, chains } = props;
  const { createBundle } = useBlockchainFunc();
  const [listItem, setListItem] = useState([]);
  const [price, setPrice] = useState();
  const [collection, setCollection] = useState();
  const [fee, setFee] = useState(0);
  const [token, setToken] = useState(
    getTokens()?.find((el) => el.is_active)?.contract_address
  );
  const [isMobile] = useWindowResizeMobile();
  const location = useLocation();
  const [form] = Form.useForm();
  const history = useHistory();
  const chainInfo = chains.find((el) => el._id == location.state?.chain);
  useEffect(async () => {
    setListItem(location.state?.bundle);
    const resGame = await getListGame();
    const collection = await getAllCollection({
      game_id: resGame[0]._id,
      chain: getUser()?.chainInfo?._id,
    });
    setCollection(collection);
  }, []);

  const onChangeSelect = (value) => {
    setToken(value);
  };

  const getFee = async () => {
    const fee = await getContractFee(
      "",
      FEE_TYPE.bundle,
      chainInfo?.payment_contract,
      chainInfo
    );
    const discount = await getDiscountPayment(
      token,
      chainInfo?.payment_contract,
      chainInfo
    );
    setFee((fee - discount) / 100);
  };
  useEffect(getFee, []);

  const rules = {
    price: [
      {
        required: true,
        message: t("requiredField"),
      },
    ],
  };

  const initialValues = {
    price: "",
  };

  const onChange = (e) => {
    setPrice(formatInputNumber(e.target.value.toString(), 3, 10));
    form.setFieldsValue({
      price: formatInputNumber(e.target.value.toString(), 3, 10),
    });
  };

  const onCancel = () => {
    history.push("/inventory");
  };

  const gotoP2PMarket = () => {
    const bundleCollection = collection.find((el) => el.name === "Bundles");
    history.push(`/p2p-market?tab=Bundles`);
  };

  const onFinish = async () => {
    await createBundle(
      listItem,
      price,
      token,
      setStatusModal,
      chainInfo,
      gotoP2PMarket
    );
  };

  return (
    <div className={"confirm-bundle-page"}>
      <div className="list-bundle-item">
        <Row justify="center">
          {listItem?.map((el, index) => (
            <Col>
              <CardComponent
                key={index}
                nft_data={el}
                isInventory={true}
                token_id={el.tokenId}
                chain={chainInfo}
              />
            </Col>
          ))}
        </Row>
      </div>
      <div className="bundle-info">
        Confirm Bundle
        <div className="bundle-info__quantity">
          #{listItem?.length || 0} items
        </div>
        <Form initialValues={initialValues} form={form} onFinish={onFinish}>
          <Row>
            <Col xs={15} className="left-col">
              Bundle Price{" "}
              <Form.Item className="m-0 mb-0" name="price" rules={rules.price}>
                <CustomInput
                  classNames="amount-item"
                  min={0}
                  onChange={onChange}
                  prefix={
                    <img className="prefix-input" src="/images/rune2.png"></img>
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={9} className="right-col">
              <Select
                className="select-token"
                onChange={onChangeSelect}
                defaultValue={chainInfo?.sort_name}
                open={false}
                showArrow={false}
              >
                <Option value={chainInfo?.sort_name}>
                  <img
                    className="token-icon"
                    src={`/images/chain/${chainInfo?.sort_name?.toLowerCase()}.svg`}
                  />
                  {chainInfo?.sort_name}
                </Option>
              </Select>
            </Col>
          </Row>
          <div className="noti-fee">
            <div>
              You will receive{" "}
              {Number(((price * (100 - fee)) / 100).toFixed(4)) || 0}{" "}
              {
                getTokens()?.find(
                  (el) =>
                    el.contract_address.toLowerCase() == token?.toLowerCase()
                )?.sort_name
              }{" "}
            </div>
            Fee: {fee}%
          </div>
          <div className="btn-container">
            <div className="btn-item red-btn" onClick={onCancel}>
              Cancel
            </div>
            <CustomButton
              classNames="btn-item"
              color="green"
              children={`Approve`}
              shape="circle-custom"
              htmlType="submit"
            />
          </div>
        </Form>
      </div>
    </div>
  );
}

export default withContext(ConfirmBundleTemplate);
