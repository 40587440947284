import React, { useEffect } from "react";
import Home from "../../components/templates/home";
import SiteLayout from "../../layouts/site-layout";
import "./style.scss";

function HomePage(props) {
    return (
        <SiteLayout >
            <Home />
        </SiteLayout>
    );
}

export default HomePage;
