import { customAxios } from "../ultis/custom-axios";

const endpoint = process.env.REACT_APP_SERVER_ENDPOINT;
const endpointGame = process.env.REACT_APP_SERVER_ENDPOINT_GAME

export const getInventory = (params) => {
    return customAxios({
        method: "get",
        params,
        url: `${endpoint}/nft/inventory`,
    })
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            throw err;
        });
};

export const getListOrder = async (params) => {
    return customAxios({
        method: "get",
        url: `${endpoint}/order/list-order`,
        params,
    })
        .then((res) => res.data)
        .catch((err) => {
            throw err;
        });
};

export const getAllCollection = async (params) => {
    return customAxios({
        method: "get",
        url: `${endpoint}/collections/get-all-collection`,
        params
    })
        .then((res) => res.data)
        .catch((err) => {
            throw err;
        });
};

export const getListGame = async () => {
    return customAxios({
        method: "get",
        url: `${endpoint}/game/list-game`,
    })
        .then((res) => res.data)
        .catch((err) => {
            throw err;
        });
};

export const sendTx = (data) => {
    return customAxios({
        method: "post",
        url: `${endpoint}/transaction/send-tx`,
        data,
    })
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            throw err;
        });
};

export const getByUrl = async (url) => {
    return customAxios({
        method: "get",
        url: url,
    })
        .then((res) => res.data)
        .catch((err) => {
            throw err;
        });
};

export const getListOrderOwner = async (params) => {
    return customAxios({
        method: "get",
        url: `${endpoint}/order/list-order-owner`,
        params,
    })
        .then((res) => res.data)
        .catch((err) => {
            throw err;
        });
};

export const getOrderInfor = async (params) => {
    return customAxios({
        method: "get",
        url: `${endpoint}/order/order-info`,
        params,
    })
        .then((res) => res.data)
        .catch((err) => {
            throw err;
        });
};

export const getBidInfor = async (params) => {
    return customAxios({
        method: "get",
        url: `${endpoint}/bid/bid-info`,
        params,
    })
        .then((res) => res.data)
        .catch((err) => {
            throw err;
        });
};

export const getListBundle = async (params) => {
    return customAxios({
        method: "get",
        url: `${endpoint}/bundle/list-bundle`,
        params,
    })
        .then((res) => res.data)
        .catch((err) => {
            throw err;
        });
};
export const getListMysteryBox = async (params) => {
    return customAxios({
        method: "get",
        url: `${endpoint}/mystery-box/list-campaign`,
        params,
    })
        .then((res) => res.data)
        .catch((err) => {
            throw err;
        });
};

export const getMysteryBoxInfo = async (params) => {
    return customAxios({
        method: "get",
        url: `${endpoint}/mystery-box/mystery-box-info`,
        params,
    })
        .then((res) => res.data)
        .catch((err) => {
            throw err;
        });
};

export const getListBid = async (params) => {
    return customAxios({
        method: "get",
        url: `${endpoint}/bid/list-bid`,
        params,
    })
        .then((res) => res.data)
        .catch((err) => {
            throw err;
        });
};

export const getBundleInfo = async (params) => {
    return customAxios({
        method: "get",
        url: `${endpoint}/bundle/get-bundle`,
        params,
    })
        .then((res) => res.data)
        .catch((err) => {
            throw err;
        });
}


export const getNoti = async (page, limit) => {
    return customAxios({
        method: "get",
        url: `${endpoint}/notification/get-notifications`,
        params: { page, limit },
    })
        .then((res) => res.data)
        .catch((err) => {
            throw err;
        });
}

export const readAllNotifications = async (params) => {
    return customAxios({
        method: "post",
        url: `${endpoint}/notification/read-all-notifications`,
        params,
    })
        .then((res) => res.data)
        .catch((err) => {
            throw err;
        });
}

export const getAllNftOwner = async (params) => {
    return customAxios({
        method: "get",
        url: `${endpoint}/nft/get-all-nft-owner`,
        params,
    })
        .then((res) => res.data)
        .catch((err) => {
            throw err;
        });
}

export const getListItems = async (params) => {
    return customAxios({
        method: "get",
        url: `${endpoint}/order/list-order-items`,
        params,
    })
        .then((res) => res.data)
        .catch((err) => {
            throw err;
        });
};

export const getChains = async () => {
    return customAxios({
        method: "get",
        url: `${endpoint}/chains/list-chains`,
    })
        .then((res) => res.data)
        .catch((err) => {
            throw err;
        });
};

export const getInfoBundle = async (params) => {
    return customAxios({
        method: "get",
        url: `${endpoint}/bundle/bundle-info`,
        params
    })
        .then((res) => res.data)
        .catch((err) => {
            throw err;
        });
};

export const getNFTInfo = async (params) => {
    return customAxios({
        method: "get",
        url: `${endpoint}/nft/nft-info`,
        params
    })
        .then((res) => res.data)
        .catch((err) => {
            throw err;
        });
};

export const getListMiniGame = async () => {
    return customAxios({
        method: "get",
        url: `${endpointGame}/mini-game/list-game`,
    })
        .then((res) => res.data)
        .catch((err) => {
            throw err;
        });
};

export const getCompetitorList = async (params) => {
    return customAxios({
        method: "get",
        url: `${endpointGame}/mini-game/get-competitor_list`,
        params
    })
        .then((res) => res.data)
        .catch((err) => {
            throw err;
        });
};

export const matchGame = async (data) => {
    return customAxios({
        method: "post",
        url: `${endpointGame}/mini-game/match`,
        data,
    })
        .then((res) => res.data)
        .catch((err) => {
            throw err;
        });
}

export const getListSeasons = async (params) => {
    return customAxios({
        method: "get",
        url: `${endpointGame}/mini-game/get-list-season`,
        params
    })
        .then((res) => res.data)
        .catch((err) => {
            throw err;
        });
};

export const getRanking = async (params) => {
    return customAxios({
        method: "get",
        url: `${endpointGame}/mini-game/get-ranking`,
        params,
    })
        .then((res) => res.data)
        .catch((err) => {
            throw err;
        });
}

export const getListGameNFT = async (params) => {
    return customAxios({
        method: "get",
        url: `${endpointGame}/mini-game/list-game`,
        params,
    })
        .then((res) => res.data)
        .catch((err) => {
            throw err;
        });
}

export const saveAtkDeck = async (data) => {
    return customAxios({
        method: "post",
        url: `${endpointGame}/mini-game/save-atk-deck`,
        data,
    })
        .then((res) => res.data)
        .catch((err) => {
            throw err;
        });
}

export const saveDefDeck = async (data) => {
    return customAxios({
        method: "post",
        url: `${endpointGame}/mini-game/save-def-deck`,
        data,
    })
        .then((res) => res.data)
        .catch((err) => {
            throw err;
        });
}


export const getUserInfoGame = async (params) => {
    return customAxios({
        method: "get",
        url: `${endpointGame}/mini-game/get-user-info`,
        params
    })
        .then((res) => res.data)
        .catch((err) => {
            throw err;
        });
}

export const getHistoryGame = async (params) => {
    return customAxios({
        method: "get",
        url: `${endpointGame}/mini-game/get-history`,
        params
    })
        .then((res) => res.data)
        .catch((err) => {
            throw err;
        });
}

export const getNftOwnerGame = async (params) => {
    return customAxios({
        method: "get",
        url: `${endpointGame}/mini-game/nft-owner`,
        params
    })
        .then((res) => res.data)
        .catch((err) => {
            throw err;
        });
}

export const getListTokens = async (params) => {
    return customAxios({
        method: "get",
        url: `${endpoint}/chains/list-tokens`,

    })
        .then((res) => res.data)
        .catch((err) => {
            throw err;
        });
}

export const getTxConvertNt = async (data) => {
    return customAxios({
        method: "post",
        url: `${endpoint}/transaction/convert-nft`,
        data,
    })
        .then((res) => res.data)
        .catch((err) => {
            throw err;
        });
}

export const getAuraTxConvertNt = async (data) => {
    return customAxios({
        method: "post",
        url: `${endpoint}/transaction/convert-aura-nft`,
        data,
    })
        .then((res) => res.data)
        .catch((err) => {
            throw err;
        });
}

export const getListHistory = async (params) => {
    return customAxios({
        method: "get",
        url: `${endpoint}/transaction-history/get-history`,
        params,
    })
        .then((res) => res.data)
        .catch((err) => {
            throw err;
        });
}
export const getListHistoryHomepage = async (params) => {
    return customAxios({
        method: "get",
        url: `${endpoint}/transaction-history/get-homepage-history`,
        params,
    })
        .then((res) => res.data)
        .catch((err) => {
            throw err;
        });
}

export const getNftAtkOwner = (params) => {
    return customAxios({
        method: "get",
        params,
        url: `${endpoint}/mini-game/nft-atk-owner`,
    })
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            throw err;
        });
};

export const getNftDefOwner = (params) => {
    return customAxios({
        method: "get",
        params,
        url: `${endpoint}/mini-game/nft-def-owner`,
    })
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            throw err;
        });
};

export const getStatistic = (params) => {
    return customAxios({
        method: "get",
        params,
        url: `${endpoint}/transaction-history/market-statistic`,
    })
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            throw err;
        });
}
