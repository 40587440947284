import React, { useMemo } from "react";
import { withContext } from "../../../../../hook/AppContext";
import PlotItem from "./PlotItem";
import Markdown from "../../../../common/markdown-component"

function DescriptionBPTab(props) {
    const { nftData, valueDecoration } = props;

    const renderAbout = useMemo(() => {
        if (!nftData?.details?.about) return;
        let listAb = Array(3);
        Object.values(nftData?.details?.about).forEach((val, index) => {
            if (val.name === "Landcore slot") {
                listAb[0] = (
                    <div className="box" key={index * 2}>
                        <div className="box-name">Landsize</div>

                        <div className="box-value">
                                <div className="content">{val.value}/{val.max}</div>
                        </div>
                    </div>
                );
            }
        });
        listAb[1] = (
            <div className="box" key={1}>
                <div className="box-name">Landplots include</div>
                <div className="box-value">
                        <div className="content">{nftData?.details?.plots?.length}</div>
                </div>
            </div>
        );
        return listAb;
    }, [nftData]);

    const renderPlots = useMemo(() => {
        if (!nftData?.plots) return;
        let listAb = [];
        Object.values(nftData?.plots).forEach((val) => {
            let item = (
               <PlotItem plot={val}/>
            );

            listAb.push(item);
        });
        return listAb;
    }, [nftData]);

    

    return (
        <div className="description-tab">
            {nftData?.details?.about?.length > 1 && (
                <div className="item-container about-list">
                    <div className="detail-item">About</div>
                    <div className="item-box">{renderAbout}</div>
                </div>
            )}

            {nftData?.details?.plots && (
                <div className="item-container">
                    <div className="detail-item">Landplot list</div>
                    <div className="plot-box">{renderPlots}</div>
                </div>
            )}
            {(nftData?.isRune) && nftData?.description?.length > 0 &&(
                <div className="item-container">
                    <div className="detail-item">Mongen Effect</div>
                    <div className="description-content">
                        {nftData?.description}
                    </div>
                </div>
            )}
            {(nftData?.isRune) && nftData?.rune_plot_description?.length > 0 &&(
                <div className="item-container">
                    <div className="detail-item">Plot Effect</div>
                    <div className="description-content">
                    {nftData?.rune_plot_description}
                    </div>
                </div>
            )}
            {(nftData?.isOperatorNFT || nftData?.isTreasuryBox) && nftData?.description?.length > 0 &&<div className="description-content"><Markdown>{nftData?.description}</Markdown></div>}
            {valueDecoration &&(
                <div className="item-container">
                <div className="detail-item">Decoration list</div>
                <div className="description-content">
                    {valueDecoration.map(el => <div className="description-content-container"><div className="description-content-container__dot"></div>{ el.decoration_code}</div>)}
                </div>
                <div className="detail-item">Stats</div>
                <div className="description-content">
                    {valueDecoration.map(el => <div className="description-content-container"><div className="description-content-container__dot"></div>{ el.decoration_code_desc}</div>)}
                </div>
                </div>
            )}
        </div>
    );
}

export default withContext(DescriptionBPTab);
