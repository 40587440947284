import React, { useRef, useMemo, useState, useEffect } from "react";
import { Row, Col, Carousel, Select } from "antd";
import {
  getContractFee,
  getTokens,
  getUser,
  getDiscountPayment,
  formatInputNumber,
} from "../../../../../ultis/common";
import "./style.scss";
import "../style.scss";
import CustomModal from "../../index";
import CustomInput from "../../../../elements/input";
import CustomButton from "../../../../elements/button";
import { FEE_TYPE } from "../../../../../constants";
import CardComponent from "../../../../common/card-component";
import { withContext } from "../../../../../hook/AppContext";

const CardItem = (props) => {
  const { key, nft_data, chain } = props;
  return (
    <div key={key} className="bundle-modal-item">
      <CardComponent nft_data={nft_data} chain={chain} noFooter={true} />
    </div>
  );
};

function CheckoutBundle(props) {
  const { setVisible, onFinish, bundleData, isOwnerBundle } = props;
  const { Option } = Select;
  const [price, setPrice] = useState();
  const [fee, setFee] = useState(0);
  const sliderRef = useRef();
  const tabRef = useRef(null);
  const bundleSize = bundleData?.nft_data?.length;
  const token = getTokens().find(
    (el) =>
      el.contract_address?.toLowerCase() ==
      bundleData?.payment_token?.toLowerCase()
  );
  const tokenActive = getTokens().find((el) => el.is_active);
  const [currentItem, setCurrentItem] = useState(0);

  const getFee = () => {
    let fn = async () => {
      const fee = await getContractFee(
        "",
        FEE_TYPE.bundle,
        getUser()?.chainInfo?.payment_contract,
        bundleData?.chain
      );
      const discount = await getDiscountPayment(
        tokenActive?.contract_address,
        getUser()?.chainInfo?.payment_contract,
        bundleData?.chain
      );
      setFee((fee - discount) / 100);
    };
    fn();
  };
  
  useEffect(getFee, []);

  const onChange = (e) => {
    setPrice(formatInputNumber(e.target.value.toString(), 3, 10));
  };
  const onArrowClick = (direction) => {
    if (direction === "left") {
      if (currentItem !== 0) {
        sliderRef.current.goTo(currentItem - 1);
        setCurrentItem(currentItem - 1);
      } else {
        sliderRef.current.goTo(bundleSize - 1);
        setCurrentItem(bundleSize - 1);
      }
    } else if (direction === "right") {
      if (currentItem < bundleSize - 1) {
        sliderRef.current.goTo(currentItem + 1);
        setCurrentItem(currentItem + 1);
      } else {
        sliderRef.current.goTo(0);
        setCurrentItem(0);
      }
    }
  };

  const renderItem = useMemo(() => {
    return (
      <>
        <Carousel ref={sliderRef} className={`card-list`}>
          {bundleData?.nft_data?.map((el, index) => {
            return (
              <Row className="carousel-container">
                <div className={"bundle-item"}>
                  <CardItem
                    key={index}
                    nft_data={el}
                    chain={bundleData?.chain}
                  />
                </div>
              </Row>
            );
          })}
        </Carousel>
      </>
    );
  }, [bundleData]);

  return (
    <CustomModal
      className="checkout-bundle-modal make-offer-modal general"
      footer={null}
      visible={true}
      onCancel={() => {
        setVisible(false);
      }}
    >
      <div className="top-block">
        <div className="title-popup">{`Checkout`}</div>

        <div className="middle-container">
          {bundleSize > 1 && (
            <div
              className="left-arrow cursor-pointer"
              onClick={() => onArrowClick("left")}
            >
              <img src="/images/detail-icon/arrow-left.svg" />
            </div>
          )}
          {renderItem}
          {bundleSize > 1 && (
            <div
              className="right-arrow cursor-pointer"
              onClick={() => onArrowClick("right")}
            >
              <img src="/images/detail-icon/arrow-right.svg" />
            </div>
          )}
        </div>
        <div ref={tabRef} className="image-block bundle-card-block">
          <Row justify="center" wrap className="bundle-container">
            {bundleData?.nft_data && (
              <div className="page-row">
                <span className="current-page">{currentItem + 1}</span> |
                {` ${bundleData?.nft_data.length}`}
              </div>
            )}
          </Row>
        </div>
        {!isOwnerBundle ? (
          <div className="bundle-info">
            <Row justify="space-between" className={`price-row`}>
              <Col>Price</Col>
              <Col className="amount-value">
                <img
                  className="token-logo"
                  src={`/images/token/${token?.sort_name?.toLowerCase()}.svg`}
                />
                {bundleData?.price}{" "}
                {bundleData?.bundle_id ? token?.sort_name : `USDT`}
              </Col>
            </Row>
            <Row justify="space-between" className={`amount-row`}>
              <Col>Bundle size</Col>
              <Col className="amount-value">
                {bundleData?.nft_data?.length}{" "}
                {bundleData?.nft_data?.length > 1 ? "NFTs" : "NFT"}
              </Col>
            </Row>
            <Row justify="space-between" className={`amount-row`}>
              <Col>Network</Col>
              <Col className="amount-value">
                <img
                  src={`/images/chain/${bundleData.chain.sort_name?.toLowerCase()}.svg`}
                />
                {bundleData.chain.sort_name}
              </Col>
            </Row>
          </div>
        ) : (
          <>
            <Row className="amount-container">
              <Col span={15} className="left-col">
                Price
                <CustomInput
                  classNames={["amount-item"]}
                  min={0}
                  step={0.001}
                  onChange={onChange}
                  value={price}
                />
              </Col>
              <Col span={9} className="right-col">
                <div className="title-select">Payment token</div>
                <Select
                  className="select-token"
                  // onChange={onChangeSelect}
                  defaultValue={
                    getTokens()?.find((el) => el.is_active)?.contract_address
                  }
                  open={false}
                  showArrow={false}
                >
                  {getTokens()?.map((el) => {
                    if (el.is_active)
                      return (
                        <Option value={el.contract_address}>
                          <img
                            className="token-icon"
                            src={`/images/token/${el?.sort_name?.toLowerCase()}.svg`}
                          />
                          {el?.sort_name}
                        </Option>
                      );
                  })}
                </Select>
              </Col>
            </Row>
            <div className="noti-fee">
              <div>
                You will receive{" "}
                {Number(((price * (100 - fee)) / 100).toFixed(4)) || 0}{" "}
                {tokenActive?.name}{" "}
              </div>
              Fee: {fee}%
            </div>
          </>
        )}
      </div>

      <div className={`btn-block bottom-modal-btn checkout-bundle-btn`}>
        <CustomButton
          classNames="red-btn btn-item btn-popup"
          children={"Cancel"}
          shape="circle-custom"
          htmlType="button"
          onClick={() => {
            setVisible(false);
          }}
        />
        <CustomButton
          classNames="orange-btn btn-item btn-popup"
          children={"Approve"}
          shape="circle-custom"
          onClick={() => onFinish(price)}
          htmlType="button"
        />
      </div>
    </CustomModal>
  );
}

export default withContext(CheckoutBundle);
