import React, { useMemo, useState, useEffect } from "react";
import "./style.scss";
import S3Image from "../s3-image";
import { useHistory, useLocation } from "react-router-dom";
import Star from "../../elements/star";
import CountProgress from "../../elements/count-progress";
import DetailModal from "../../common/detail-modal";
import Mongen from "../../elements/static-anim/mongen";
import Decor from "../../elements/static-anim/decor";
import { TYPE_CARD } from "../../../constants";
import { getTokens } from "../../../ultis/common";
import { useWindowResizeMobile } from "../../../hook/ultis.hook";

function CardComponent(props) {
  const {
    type,
    nft_data,
    cardFooter,
    bundle_id,
    expired,
    token_id,
    order_id,
    total_buy,
    maxSupply,
    callBack,
    image,
    quantity,
    isItems,
    isDetail,
    checkNft,
    noFooter,
    renderList,
    setShowhowModal,
    price,
    payment_token,
    convert_price,
    chain,
  } = props;
  const [visibleModal, setVisibleModal] = useState(false);
  const [isNft, setIsNft] = useState();
  const [isMobile] = useWindowResizeMobile(787);
  const history = useHistory();
  const location = useLocation();

  const query = {
    contract:
      nft_data?.nft_info?.contract_address || nft_data?.contract_address,
    token: nft_data?.nft_info?.token_id || nft_data?.token_id,
    chain: chain?._id,
  };

  useEffect(async () => {
    if (checkNft) {
      const check = await checkNft(token_id);
      setIsNft(check);
    }
  }, []);

  const goDetailPage = async () => {
    if (isDetail) {
      if (isNft)
        window.open(
          `#/detail?contract=${localStorage.getItem(
            "MongenContract"
          )}&token=${token_id}&chain=${chain._id}`,
          "_blank"
        );
    } else {
      if (!isItems) {
        if (!callBack) {
          if (setShowhowModal) {
            setShowhowModal(false);
            history.push(
              `/detail?contract=${
                nft_data?.nft_info?.contract_address ||
                nft_data?.contract_address
              }&token=${
                nft_data?.nft_info?.token_id || nft_data?.token_id
              }&chain=${chain._id}`
            );
          } else {
            setVisibleModal(true);
            const pathname = location.pathname;
            window.history.pushState(null, "", `#${pathname}?tab=${type}`);
            if (type === TYPE_CARD.BUN) {
              history.push(`/detail?bundle_id=${bundle_id}&chain=${chain._id}`);
              // window.history.replaceState(
              //   null,
              //   "",
              //   `#/detail?bundle_id=${bundle_id}&chain=${chain._id}`
              // );
            } else {
              window.history.replaceState(
                null,
                "",
                `#/detail?contract=${
                  nft_data?.nft_info?.contract_address ||
                  nft_data?.contract_address
                }&token=${
                  nft_data?.nft_info?.token_id || nft_data?.token_id
                }&chain=${chain._id}`
              );
            }
          }
        } else {
          callBack();
        }
      }
    }
  };

  const renderRarityRace = () => {
    const rarity = nft_data?.attributes?.find(
      (el) => el.trait_type === "Rarity"
    );
    const race = nft_data?.attributes?.find((el) => el.trait_type === "Race");
    return (
      <div className="rarity-race">
        {rarity?.value && (
          <img
            className="rarity-race__rarity"
            src={`/images/rarity-icon/${rarity?.value?.toLowerCase()}.svg`}
          />
        )}
        {race?.value && race?.value != "All" && (
          <img
            className="rarity-race__rarity"
            src={`/images/race-icon/${race?.value?.toLowerCase()}.png`}
          />
        )}
      </div>
    );
  };

  const renderCardMonHeader = () => {
    const evolution = nft_data?.attributes?.find(
      (el) => el.trait_type === "Evolution"
    );
    const level = nft_data?.attributes?.find((el) => el.trait_type === "Level");

    return (
      <div className="card-mon__header">
        <div className="level-children">
          {level?.value && level?.max && (
            <div className="level">
              Lvl <div>{level?.value}</div> | {level?.max}{" "}
            </div>
          )}
          {evolution?.value && (
            <Star 
              readonly={true} 
              initialRating={evolution?.value > 5 ? evolution?.value -  5 : evolution?.value} 
              fullSymbolStar={evolution.value > 5 ? "/images/star-pro.png" : "/images/star-full.png"}
            />
          )}
        </div>
        {renderRarityRace()}
      </div>
    );
  };

  const renderAttri = () => {
    const children = nft_data?.attributes?.find(
      (el) => el.trait_type === "Children"
    );
    const landcoreSlot = nft_data?.attributes?.find(
      (el) => el.trait_type === "Landcore slot"
    );
    const amount = isItems ? nft_data?.amount || quantity : null;
    // const arr = nft_data?.attributes?.filter(
    //   (item) =>
    //     item.trait_type === "Health" ||
    //     item.trait_type === "Sturdiness" ||
    //     item.trait_type === "Magic" ||
    //     item.trait_type === "Aggression" ||
    //     item.trait_type === "Spookiness"
    // );

    return (
      <div className="attributes-children">
        {/* <div className="card-mon__attributes">
          {arr?.map((item) => (
            <div>
              <img src={`/images/detail-icon/${item.trait_type == "Spookiness" ? "Sturdiness" : item.trait_type}.png`}/>
              {item.value}
            </div>
          ))}
        </div> */}
        {!children?.value < 0 && !landcoreSlot && !amount && (
          <div className="nft-card_header-id">
            #
            {isItems ? order_id : type !== TYPE_CARD.BUN ? token_id : bundle_id}
          </div>
        )}
        {children?.value >= 0 && `Breed count: ${children?.value}`}
        {landcoreSlot && (
          <div className="land-size">
            Land size:{" "}
            <div className="land-size__value">
              {landcoreSlot.value}/{landcoreSlot.max}
            </div>
          </div>
        )}
        {amount && (
          <div className="land-size">
            Amount: <div className="land-size__value">{amount}</div>
          </div>
        )}
      </div>
    );
  };

  const renderExpired = useMemo(() => {
    return (
      expired * 1000 < Date.now() && (
        <>
          <img src="/images/expired-icon.svg" className="offer-expired" />
          <div className="offer-expired-text">Expired</div>
        </>
      )
    );
  }, [expired]);

  const renderLinkImage = (image) => {
    const array = image?.split("/");
    var newImage = "/images";
    for (let i = 3; i < array?.length; i++) {
      newImage = newImage + "/" + array[i];
    }
    return newImage.replace("+", "_");
  };

  const renderImage = () => {
    // if(isMobile && (nft_data?.is_mongen || nft_data?.is_decor)){
    //   return <S3Image className="card-mon__image cursor-pointer" src={image ? image : nft_data?.image} />
    // }else {
    if (nft_data?.is_mongen)
      return (
        <Mongen
          lock={[]}
          dna={nft_data?.dna_raw}
          className={
            price
              ? "card-mon__image cursor-pointer price"
              : "card-mon__image cursor-pointer"
          }
        />
      );
    if (nft_data?.is_decor)
      return (
        <div
          className={price ? "card-decor__image price" : "card-decor__image"}
        >
          <Decor
            rarity={nft_data?.meta?.rarity}
            decoration_list={nft_data?.meta?.decoration_list}
            className=""
            isDetail={noFooter}
          />
        </div>
      );

    return (
      <S3Image
        className={
          price
            ? "card-mon__image cursor-pointer price"
            : "card-mon__image cursor-pointer"
        }
        src={
          nft_data?.static_image
            ? renderLinkImage(image ? image : nft_data?.image)
            : image
            ? image
            : nft_data?.image
        }
      />
    );
  };

  // }

  const renderImageBundle = (el) => {
    // if(isMobile && (el?.is_mongen || el?.is_decor)){
    //   return <S3Image data-size='small-icon' className="cursor-pointer" src={image ? image : el?.image} />
    // }else {
    if (el?.is_mongen)
      return (
        <Mongen
          lock={[]}
          dna={el?.dna_raw}
          className="cursor-pointer"
          dataSize="small-icon"
        />
      );
    if (el?.is_decor)
      return (
        <div className="card-decor__bundle">
          <Decor
            rarity={el?.meta?.rarity}
            decoration_list={el?.meta?.decoration_list}
            isDetail={true}
          />
        </div>
      );
    return (
      <S3Image
        src={el?.static_image ? renderLinkImage(el?.image) : el?.image}
        data-size="small-icon"
        className="cursor-pointer"
      />
    );
    // }
  };

  const renderPrice = () => {
    const token = getTokens()?.find(
      (item) =>
        item?.contract_address.toLowerCase() == payment_token?.toLowerCase()
    )?.sort_name;
    return (
      <div className="nft-card_price">
        <div className="price">
          {price} <img src={`/images/token/${token?.toLowerCase()}.svg`} />
        </div>
        <div className="purchase">
          <div className="purchase__icon">
            {" "}
            ~ <br /> ~{" "}
          </div>{" "}
          ${convert_price?.toFixed(2)}
        </div>
      </div>
    );
  };

  return (
    <div className={`nft-card ${noFooter && "no-footer"}`}>
      {renderExpired}
      {type !== TYPE_CARD.BUN && (
        <div className={"card-mon"} onClick={goDetailPage}>
          {renderCardMonHeader()}
          {renderImage()}
          {price && renderPrice()}

          {/* {!isDetail && renderAttri()} */}

          {maxSupply && total_buy && (
            <CountProgress
              title={`Remaining`}
              totalBuy={total_buy}
              maxSupply={maxSupply}
            />
          )}
        </div>
      )}

      {type === TYPE_CARD.BUN && (
        <>
          <div className="card-bun-container" onClick={goDetailPage}>
            {nft_data?.map((el, index) => {
              if (nft_data.length === 4 || (nft_data.length > 4 && index < 3)) {
                return (
                  <div
                    className={
                      index === 0 || index === 1
                        ? "nft-card_image cursor-pointer border-adius-6px-left border-adius-2px-right"
                        : "nft-card_image cursor-pointer border-adius-6px-right border-adius-2px-left"
                    }
                    key={index}
                  >
                    {renderImageBundle(el)}
                  </div>
                );
              }
              if (nft_data.length === 3) {
                return (
                  <>
                    {index < 2 && (
                      <div className="nft-card_image cursor-pointer border-adius-6px-left border-adius-2px-right">
                        {renderImageBundle(el)}
                      </div>
                    )}
                    {index === 2 && (
                      <div className="full-height border-adius-6px-right border-adius-2px-left">
                        {renderImageBundle(el)}
                      </div>
                    )}
                  </>
                );
              }
              if (nft_data.length === 2) {
                return (
                  <>
                    <div
                      className={
                        index === 0
                          ? "full-height border-adius-6px-left border-adius-2px-right"
                          : "full-height border-adius-6px-right border-adius-2px-left"
                      }
                    >
                      {renderImageBundle(el)}
                    </div>
                  </>
                );
              }
            })}
            {nft_data.length > 4 && (
              <div className="nft-card_image more-items border-adius-6px-right border-adius-2px-left">{`+ ${
                nft_data.length - 3
              } more`}</div>
            )}
            {renderPrice()}
          </div>
        </>
      )}

      <div className="nft-card_header cursor-pointer" onClick={goDetailPage}>
        <div className="nft-card_header-name">
          {type == TYPE_CARD.BUN ? `${nft_data?.length} items` : nft_data?.name}
          {renderAttri()}
        </div>
        <img className="nft-card_img" src={`/images/chain/${chain?.sort_name.toLowerCase()}.svg`} alt="img" />
      </div>

      {!isNft && isDetail && (
        <div className="nft-note">This mongen hasn't been a NFT yet</div>
      )}

      <div className="nft-card_btn">{cardFooter ? cardFooter() : null}</div>
      <DetailModal
        visible={visibleModal}
        setVisibleModal={setVisibleModal}
        url={`#${history.location.pathname}${history.location.search}`}
        query={bundle_id ? { bundle_id: bundle_id } : query}
        renderList={renderList}
      />
    </div>
  );
}

export default CardComponent;
