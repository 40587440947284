import { Button, Col, Form, Row, Select } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { FEE_TYPE } from "../../../../constants";
import { useWindowResizeMobile } from "../../../../hook/ultis.hook";
import {
  formatInputNumber,
  getContractFee,
  getDiscountPayment,
  getTokens
} from "../../../../ultis/common";
import S3Image from "../../../common/s3-image";
import CustomButton from "../../../elements/button";
import CustomInput from "../../../elements/input";
import CustomModal from "../../../elements/modal";
import GeneralModal from "../../../elements/modal/general-modal";
import Decor from "../../../elements/static-anim/decor";
import Mongen from "../../../elements/static-anim/mongen";
import "./style.scss";

const { Option } = Select;

function ConfirmModal(props) {
  const {
    nft,
    showModal,
    setShowModal,
    setStatusModal,
    onClick,
    type,
    callBack,
    isItems,
  } = props;
  const [price, setPrice] = useState();
  const [quantity, setQuantity] = useState(1);
  const [fee, setFee] = useState(0);
  const [token, setToken] = useState(
    getTokens()?.find((el) => el.is_active)?.contract_address
  );
  const [form] = Form.useForm();
  const [isMobile] = useWindowResizeMobile(787);
  const [showModalConfirm, setShowModalConfirm] = useState(false);

  const rules = {
    quantity: [
      {
        validator: async (_, value) => {
          const amount = nft?.amount ? nft?.amount : 1;

          if (value == "") {
            return Promise.reject(new Error("This field is required"));
          }
          if (value <= 0) {
            return Promise.reject(new Error("Amount must be greater than 0"));
          }
          if (value <= Number(amount)) {
            return Promise.resolve();
          }
          return Promise.reject(new Error("Insufficient quantity"));
        },
      },
    ],
    price: [
      {
        validator: async (_, value) => {
          if (!value) {
            return Promise.reject(new Error("Price must be greater than 0"));
          }
          if (value <= 0) {
            return Promise.reject(new Error("Price must be greater than 0"));
          }
        },
      },
    ],
  };

  const getFee = async () => {
    const address = nft?.contract
      ? nft?.contract
      : nft?.nft_info?.contract_address;

    if (address) {
      const fee = await getContractFee(
        address,
        FEE_TYPE.default,
        nft?.chain?.payment_contract,
        nft?.chain
      );
      const discount = await getDiscountPayment(
        token,
        nft?.chain?.payment_contract,
        nft?.chain
      );
      setFee((fee - discount) / 100);
    }
  };

  useEffect(() => {
    if (type === "put") {
      form.setFieldsValue({ price: 0 });
      form.setFieldsValue({ quantity: 1 });
      setPrice(0);
      setQuantity(1);
    }
    getFee();
    const token = getTokens()?.find((el) => el.is_active)?.contract_address;
    setToken(token);
  }, [nft]);

  const onCancel = () => {
    form.resetFields();
    setPrice(0);
    setShowModal(false);
  };

  const onChangeSelect = (value) => {
    setToken(value);
  };

  const onChange = (e) => {
    if (e.target.value > 0) {
      setPrice(formatInputNumber(e.target.value.toString(), 3, 10));
      form.setFieldsValue({
        price: formatInputNumber(e.target.value.toString(), 3, 10),
      });
    } else {
      setPrice(e.target.value);
      form.setFieldsValue({ price: e.target.value.toString() });
    }
  };

  const onChangeQuantity = (e) => {
    setQuantity(parseInt(e.target.value));
    form.setFieldsValue({ quantity: parseInt(e.target.value).toString() });
  };

  const onChangeMaxAmount = () => {
    form.setFieldsValue({ quantity: nft?.amount });
    setQuantity(nft?.amount);
  };

  useEffect(getFee, [nft?.nft_info?.contract_address, nft?.contract]);

  const handleCallBack = () => {
    callBack(nft);
  };

  const handleApprove = () => {
    setShowModalConfirm(true);
    form.resetFields();
    setShowModal(false);
  };

  const handleConfirm = () => {
    if (nft?.price)
      onClick(
        {
          order_id: nft?.order_id,
          price: price,
          quantity: quantity,
          payment: nft?.chain.token_contract,
          chainInfo: nft?.chain,
        },
        setStatusModal,
        callBack
      );
    else if (isItems) {
      const chainInfo = nft.list_contract.find(
        (el) => el.chain_name == nft?.chain.sort_name
      );
      onClick(
        {
          contract_address: nft.contract,
          token_address: token
            ? token
            : getTokens()?.find((el) => el.is_active)?.contract_address,
          token_id: 0,
          price: price ? price : 0,
          quantity: quantity ? quantity : 1,
          isItems: true,
          chainInfo: { ...nft.chain, ...chainInfo, _id: chainInfo.chain },
        },
        setStatusModal,
        handleCallBack
      );
    } else {
      onClick(
        {
          contract_address: nft?.nft_info?.contract_address
            ? nft?.nft_info?.contract_address
            : nft?.contract,
          token_address: token
            ? token
            : getTokens()?.find((el) => el.is_active)?.contract_address,
          token_id: nft?.nft_info?.token_id
            ? nft?.nft_info?.token_id
            : nft?.token,
          price: price ? price : 0,
          quantity: 1,
          chainInfo: nft?.chain,
        },
        setStatusModal,
        callBack
      );
    }
    setPrice(0);
    form.resetFields();
    setShowModal(false);
    setShowModalConfirm(false);
  };

  const renderLinkImage = (image) => {
    const array = image?.split("/");
    var newImage = "/images";
    for (let i = 3; i < array?.length; i++) {
      newImage = newImage + "/" + array[i];
    }
    return newImage.replace("+", "_");
  };

  const renderImage = useMemo(() => {
    if (
      isMobile &&
      (nft?.dna_raw?.length > 2 || nft?.meta?.decoration_list?.length > 0)
    ) {
      return (
        <S3Image
          className="nft-card_image item-image modal-image"
          src={nft?.image}
        />
      );
    }
    if (nft?.dna_raw?.length > 2)
      return (
        <Mongen
          lock={[]}
          dna={nft?.dna_raw}
          className="nft-card_image item-image modal-image"
        />
      );
    if (nft?.meta?.decoration_list?.length > 0)
      return (
        <div className="card-decor__image">
          <Decor
            rarity={nft?.meta?.rarity}
            decoration_list={nft?.meta?.decoration_list}
            className=""
          />
        </div>
      );

    return (
      <S3Image
        className="nft-card_image item-image modal-image"
        src={nft?.static_image ? renderLinkImage(nft?.image) : nft?.image}
      />
    );
  }, [nft]);

  const renderChain = useMemo(
    () => (
      <div className="select-token" value={nft?.chain?.sort_name}>
        <img
          className="token-icon"
          src={`/images/chain/${nft?.chain?.sort_name.toLowerCase()}.svg`}
        />
        {nft?.chain?.name}
      </div>
    ),
    [nft]
  );

  const renderFee = useMemo(() => <div>Fee: {fee}%</div>, [fee]);
  const renderTotal = useMemo(
    () => (
      <span className="received-mon">
        {" "}
        {Number((price * quantity * (1 - fee / 100)).toFixed(4)) || 0}{" "}
      </span>
    ),
    [price, quantity, fee, nft]
  );
  const renderModalConfirm = useMemo(() => {
    if (showModalConfirm) {
      return (
        <GeneralModal
          // onFinish={handleConfirm}
          isShowingPopup={showModalConfirm}
          setIsShowingPopup={setShowModalConfirm}
          nftData={nft}
          orderInfor={{
            payment_token: token
              ? token
              : getTokens()?.find((el) => el.is_active)?.contract_address,
            price: price,
            quantity: quantity,
            isItems: isItems,
            fee: fee,
            total: Number((price * quantity * (1 - fee / 100)).toFixed(4)) || 0,
          }}
          type={"confirm"}
        />
      );
    }
  }, [nft, showModalConfirm]);

  return (
    <>
      <CustomModal
        visible={showModal}
        footer={null}
        onCancel={onCancel}
        className="confirm-modal make-offer-modal"
      >
        <div className="title-popup">
          {type === "put" ? "Put on sale" : "Change listing"}
        </div>
        <div className="wallet-box-line"></div>
        <Form form={form} onFinish={handleConfirm}>
          <Row className="row-infor offer-info" gutter={38}>
            <Col
              xs={15}
              sm={15}
              md={10}
              lg={10}
              xl={10}
              className="left-column"
            >
              <div className="image-block">{renderImage}</div>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={14}
              lg={14}
              xl={14}
              className="right-column"
            >
              <div className="name-item offer-name">{nft?.name} </div>
              {!isItems && (
                <div className="id-item id-modal">
                  #{nft?.nft_info?.token_id || nft?.token}
                </div>
              )}
              {isItems && (
                <Row className="amount-row">
                  <Col span={18} className="left-col">
                    Quantity
                    <Form.Item
                      className="m-0 mb-0"
                      name="quantity"
                      rules={rules.quantity}
                    >
                      <CustomInput
                        classNames={["amount-item"]}
                        min={0}
                        type="number"
                        // value={quantity}
                        onChange={onChangeQuantity}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Button
                      className="max-button"
                      onClick={onChangeMaxAmount}
                      type="button"
                    >
                      {"Max"}
                    </Button>
                  </Col>
                </Row>
              )}
              <Row className="amount-row price-row put-price">
                <Col span={14} className="left-col">
                  Price{" "}
                  <Form.Item
                    className="m-0 mb-0"
                    name="price"
                    rules={rules.price}
                  >
                    <CustomInput
                      classNames={["amount-item"]}
                      // min={0}
                      step={0.001}
                      onChange={onChange}
                      value={price}
                      prefix={
                        <img
                          className="prefix-input"
                          src="/images/rune2.png"
                        ></img>
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={10}>{renderChain}</Col>
              </Row>
              {/* {type === "put" && (
              <> */}
              <div className="receive-note">
                You will receive {renderTotal}{" "}
                {token
                  ? getTokens()?.find(
                      (el) =>
                        el.contract_address.toLowerCase() ==
                        token?.toLowerCase()
                    )?.sort_name
                  : getTokens()?.find((el) => el.is_active)?.sort_name}
              </div>
              {renderFee}
              {/* </>
            )} */}
            </Col>
          </Row>
          <div className="btn-block bottom-modal-btn confirm-modal-btn">
            <CustomButton
              classNames="red-btn btn-item btn-popup"
              children={"Cancel"}
              shape="circle-custom"
              htmlType="button"
              onClick={() => {
                setShowModal(false);
                form.resetFields();
              }}
            />
            <CustomButton
              classNames="orange-btn btn-item btn-popup"
              children={"Approve"}
              shape="circle-custom"
              htmlType="submit"
              disabled={nft?.amount && quantity > nft?.amount}
            />
          </div>
        </Form>
      </CustomModal>
      {renderModalConfirm}
    </>
  );
}

export default ConfirmModal;
