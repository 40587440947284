import React, { useEffect, useState } from "react";
import { Row, Col, Button, Form } from "antd";
import CustomInput from "../../../input";
import "./style.scss";
import {getTokens} from "../../../../../ultis/common";
import { withContext } from "../../../../../hook/AppContext";

function CheckoutEvol(props) {
  const { orderInfor, t, type, form } = props;
  const [total, setTotal] = useState(0);
  const obj = getTokens().find(el => el.contract_address?.toLowerCase() == orderInfor?.payment_token?.toLowerCase() )


  const onChangeMaxItem = async () => {
    form.setFieldsValue({ quantity: orderInfor.quantity});
    setTotal(orderInfor.quantity * orderInfor.price)
  };

  const onChangeQuantity = async (e) => {
    setTotal(e.target.value * orderInfor.price)
  }

  const rules= {
    quantity: [
      ({ }) => ({
        validator(_, value) {
          if (!value) {
            return Promise.reject(new Error(t("requiredField")));
          }
          if (value <= orderInfor.quantity) {
            return Promise.resolve();
          }
          return Promise.reject(new Error("Insufficient quantity"));
        },
      }),
    ],

  }


  return (
    <>
      <Row className="amount-row">
        <Col span={18} className="left-col">
          Quantity
          <Form.Item className="m-0 mb-0" name="quantity" rules={rules.quantity} >
            <CustomInput
              classNames={["amount-item"]}
              min={0}
              type="number"
              onChange={onChangeQuantity}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Button
            className="max-button"
            onClick={onChangeMaxItem}
            type="button"
          >
            {"Max"}
          </Button>
        </Col>
      </Row>
      <div className="item-box offer-modal-box mt-20 pd-box">
        <div className="info-item">
            <Row justify="space-between">
              <Col className="name">Price</Col>
              {orderInfor?.price || 90} {obj?.sort_name}
            </Row>
             <Row className="chain-image" justify="space-between">
              <Col className="name">Network</Col>
             <Col >
            <img  src={`/images/chain/${orderInfor.chain.sort_name.toLowerCase()}.svg`}/>
                {orderInfor.chain.sort_name}
          </Col>
            </Row>
            
          </div>

      </div>
      <div className="estimated-amount"> Estimated amount
        <div>{total} {obj?.sort_name}</div>
      </div>    
    </>
  );
}

export default withContext(CheckoutEvol);
