import React, { useEffect, useState, useMemo } from "react";
import "./style.scss";
import { Row, Select, Pagination } from "antd";
import {
  getInventory,
  getListGame,
  getAllCollection,
  getNftDefOwner,
  getNftAtkOwner,
  getListMiniGame,
} from "../../../../../api";
import { withContext } from "../../../../../hook/AppContext";
import SiteLayout from "../../../../../layouts/site-layout";
import CustomModal from "../../../../elements/modal";
import CustomButtonField from "../../../../elements/button";
import { useWindowResizeMobile } from "../../../../../hook/ultis.hook";
import InventoryItem from "./inventory-item";

const { Option } = Select;
export const defaultNftState = {
  items: [],
  page: 1,
  totalItem: 0,
};

const SOULCORE = "Soulcores";

function InventoryModal(props) {
  const {
    listDeck,
    setListDeck,
    showInventory,
    setShowInventory,
    renderCardContent,
    setRenderList,
    setShowSave,
    deskTab,
  } = props;
  const [listSelect, setListSelect] = useState(listDeck);
  const [length, setLength] = useState(listDeck?.length);
  const [data, setData] = useState();
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState();
  const [collection, setCollection] = useState();
  const [type, setType] = useState("all");
  const [race, setRace] = useState();
  const [isMobile] = useWindowResizeMobile(576);

  const getListInventory = async (collectionAddress) => {
    const listGame = await getListMiniGame();
    try {
      let listNft;
      if (type == "all") {
        if (deskTab == "Defense") {
          listNft = await getNftDefOwner({
            game_id: listGame[0]?._id,
            page: page,
            limit: 42,
            Race: race ? [race] : null,
          });
        } else {
          listNft = await getNftAtkOwner({
            game_id: listGame[0]?._id,
            page: page,
            limit: 42,
            Race: race ? [race] : null,
          });
        }
      } else {
        listNft = await getInventory({
          contract_address: collectionAddress,
          page: page,
          limit: 42,
          Type: type && type != SOULCORE ? [type] : null,
          Race: race ? [race] : null,
        });
      }
      const { metadata, data } = listNft;
      setData(data);
      setPage(metadata.page);
      setTotal(metadata.total);
    } catch (err) {}
  };

  useEffect(() => {
    const fn = async () => {
      const resGame = await getListGame();
      const resCollection = await getAllCollection({
        game_id: resGame[0]._id,
      });
      const collectionLandplot = resCollection.find(
        (el) => el.name == "Landplots"
      );
      const collectionSoulcore = resCollection.find(
        (el) => el.name == SOULCORE
      );
      setCollection({
        ...collectionLandplot,
        collectionSoulcore: {
          name: SOULCORE,
          contract_address: collectionSoulcore?.contract_address,
        },
      });
      getListInventory(collectionLandplot?.contract_address);
    };
    fn();
  }, []);

  useEffect(
    () =>
      getListInventory(
        type == SOULCORE
          ? collection?.collectionSoulcore?.contract_address
          : collection?.contract_address
      ),
    [type, race, page]
  );

  useEffect(() => {
    setListSelect(listDeck);
    setLength(listDeck?.length);
  }, [listDeck, showInventory]);

  const onClick = () => {
    setListDeck(listSelect);
    setShowInventory(false);
    setRenderList(true);
    setShowSave(true);
  };

  const handleChange = (value) => {
    setType(value);
  };

  const onClickRace = (name) => {
    if (name == SOULCORE) {
      getListInventory(collection?.collectionSoulcore?.contract_address);
    } else setRace(name == "all" ? null : name);
  };

  const onChangePage = (value) => {
    setPage(value);
  };

  const renderOption = () => {
    let filter = collection?.filter;
    return (
      <>
        <Option value={collection?.collectionSoulcore?.name}>
          {collection?.collectionSoulcore?.name}
        </Option>
        {filter?.map((el) => {
          if (el?.type == "Type")
            return <Option value={el?.name}>{el?.name}</Option>;
        })}
      </>
    );
  };

  const renderRace = () => {
    const filter = collection?.filter;
    return filter?.map((el) => {
      if (el.type == "Race")
        return (
          <img
            className={race == el.name && "img-click"}
            onClick={() => onClickRace(el.name)}
            src={`/images/race-icon/${el.name.toLowerCase()}.png`}
            alt="race"
          />
        );
    });
  };

  const renderListCheckBox = useMemo(() => {
    return (
      <div className="content-list">
        <Row>
          {data?.map((el) => (
            <InventoryItem
              length={length}
              setLength={setLength}
              el={el}
              renderCardContent={renderCardContent}
              listSelect={listSelect}
              setListSelect={setListSelect}
            />
          ))}
        </Row>
        {data?.length > 0 && (
          <Pagination
            defaultCurrent={1}
            total={total}
            pageSize={42}
            current={page}
            onChange={onChangePage}
            showSizeChanger={false}
          />
        )}
      </div>
    );
  }, [listSelect, length, data, showInventory, race, type]);

  const renderLength = useMemo(() => {
    return (
      <div className="total-quantity">
        {length}/6
        <CustomButtonField
          classNames="btn-done"
          color="green"
          size="medium"
          onClick={onClick}
        >
          {"Done"}
        </CustomButtonField>
      </div>
    );
  }, [listSelect, showInventory, length]);

  return (
    <CustomModal
      visible={showInventory}
      footer={null}
      onCancel={() => setShowInventory(false)}
      className="deck-modal"
      closable={false}
    >
      <SiteLayout styleMarket="master-layout__market">
        <div className="inventory-title">Inventory</div>
        <div className="inventory-header">
          <Row className="filter-container">
            <Select
              className="select-type"
              defaultValue="all"
              onChange={handleChange}
              style={{ width: isMobile ? 128 : 166, height: 54 }}
              suffixIcon={
                <img className="select-icon" src="/images/select-icon.svg" alt="select" />
              }
            >
              <Option value="all">All Type</Option>
              {renderOption()}
            </Select>
            <Row className="select-race">
              <img
                onClick={() => onClickRace("all")}
                src="/images/all.svg"
                className={!race && "img-click"}
                alt="all"
              />
              {renderRace()}
            </Row>
          </Row>
          {renderLength}
        </div>

        {renderListCheckBox}
      </SiteLayout>
    </CustomModal>
  );
}

export default withContext(InventoryModal);
