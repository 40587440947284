import "./style.scss";
import React, { useState, useMemo } from "react";
import { Select, Drawer } from "antd";
import { Link, useHistory } from "react-router-dom";
import CustomButtonField from "../../elements/button";
import FilterComponent from "../filter-component";

function CollectionFilterComponent(props) {
  const {
    listCol,
    dataCol,
    renderKey,
    setRenderKey,
    setActiveTab,
    activeTab,
    callBack,
    renderListCol,
    isInventory,
    onClick,
    toPage,
    titleBtn,
    disabled,
    handleConvert,
    onConfirmConvert,
    isConverting,
    listCheckConvert,
    setisConverting,
    isErc721,
    hideSearch,
  } = props;
  const [showFilter, setShowFilter] = useState(false);

  const history = useHistory();
  const renderOptionSelect = () => {
    let results = [];
    for (const key in dataCol) {
      let el = dataCol[key];
      results.push(
        <Select.Option value={el._id}>
          {el._id != 0 && (
            <img
              src={`/images/collection-icon/${el.name?.toLowerCase()}.svg`}
              alt="icon"
            />
          )}{" "}
          {el.name}
        </Select.Option>
      );
    }
    return results;
  };

  const handleChangeCollection = (value) => {
    setActiveTab(value);
    setRenderKey(renderKey + 1);
    if (callBack) callBack(value);
  };

  return (
    <div className="filter-block">
      <div className="collection-filter">
        <Select
          value={activeTab}
          style={{ width: 200 }}
          size="large"
          bordered
          onChange={(value) => handleChangeCollection(value)}
          className="dropdown-collection"
        >
          {renderOptionSelect()}
        </Select>
        {((activeTab != 0 && dataCol[activeTab]?.is_erc721) ||
          !isInventory) && (
          <CustomButtonField
            classNames={"filter-mobile"}
            color="gardient"
            size="small"
            onClick={() => setShowFilter(true)}
          >
            <img src="/images/filter.svg" alt="filter" />
            {"Filter"}
          </CustomButtonField>
        )}
      </div>
      {isConverting ? (
        <>
          <div className="btn-block">
            <CustomButtonField
              classNames="btn-create btn-bundle"
              color="green"
              size="medium"
              onClick={onConfirmConvert}
            >
              {"Confirm"}
            </CustomButtonField>
            <CustomButtonField
              classNames="btn-convert red btn-bundle"
              size="medium"
              color="green"
              onClick={() => setisConverting(false)}
            >
              {"Cancel"}
            </CustomButtonField>
          </div>
          <div className="select-txt btn-bundle">
            Selected{" "}
            {Object.values(listCheckConvert).filter((el) => el?.check).length}
          </div>
        </>
      ) : (
        <div className="btn-block">
          <Link to={toPage}>
            <CustomButtonField
              classNames="btn-bundle"
              color="green"
              onClick={isInventory && onClick}
              disabled={disabled}
            >
              {titleBtn}
            </CustomButtonField>
          </Link>
          {history.location.pathname === "/inventory" && (
            <>
              {dataCol[activeTab]?.name.toLowerCase() ===
                "Binance NFTs".toLowerCase() && (
                <CustomButtonField
                  classNames="btn-convert brown btn-bundle"
                  color="green"
                  size="medium"
                  onClick={handleConvert}
                >
                  {"Multiple Convert"}
                </CustomButtonField>
              )}
              {dataCol[activeTab]?.name.toLowerCase() ===
                "Treasury Boxes".toLowerCase() && (
                <CustomButtonField
                  classNames="btn-convert brown btn-bundle"
                  color="green"
                  size="medium"
                  onClick={handleConvert}
                >
                  {"Multiple Unbox"}
                </CustomButtonField>
              )}
            </>
          )}
        </div>
      )}
      {titleBtn === "Next" && (
        <div className="note">
          Only NFTs that has not been listed on the market can be added to the
          bundle
        </div>
      )}

      {listCol}

      <Drawer
        closable={false}
        onClose={() => setShowFilter(false)}
        visible={showFilter}
        className="drawer-filter"
      >
        <FilterComponent
          renderListCol={renderListCol}
          renderKey={renderKey}
          setRenderKey={setRenderKey}
          {...dataCol[activeTab]}
          isErc721={isErc721}
          hideSearch={hideSearch}
          isInventory={isInventory}
        />
      </Drawer>
    </div>
  );
}

export default CollectionFilterComponent;
