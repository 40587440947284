import { useWeb3React } from '@web3-react/core';
import { message } from "antd";
import ReactGA4 from "react-ga4";
import Web3 from "web3";
import { getTxConvertNt, getAuraTxConvertNt } from "../api";
import { ACTION_SEND_TRANSACTION, USER_WALLET_TYPE, WALLET_TYPE, walletConnector } from "../constants";
import marketABI from "../constants/ABI/market.json";
import mysteryBoxABI from "../constants/ABI/mysteryBox.json";
import nftABI from "../constants/ABI/nft.json";
import nftGameABI from '../constants/ABI/nftgame.json';
import bridgeABI from '../constants/ABI/bridge.json';
import tokenABI from "../constants/ABI/token.json";
import { useUIContext } from "../hook/AppContext";
import {
    approveAllWallet,
    approveFTWallet,
    approveWallet,
    getBalanceItem,
    getMaxBalance,
    getUser,
    getWalletType,
    handleResultTransaction,
    handleTransactionExternalWallet,
    networkValidation,
    queryContractTerra,
    sleep
} from "../ultis/common";
import keplrService from './keplr';

const web3 = new Web3()
// TODO: Handle logic here

const useBlockchainFunc = () => {
    const {
        statusTextModal,
        setStatusTextModal,
        setCallBackFunc,
        isAuthenticatedUser,
        setIsShowingLoginPopup,
        chains,
        setStatusModal,
        history,
        gameInfo,
        logout
    } = useUIContext();

    const { library, activate, deactivate } = useWeb3React()
    const user = getUser();
    const RPC_BSC = user?.chainInfo?.rpc || process.env.REACT_APP_BSC_PROVIDER

    const buyNFT = async (
        order_id,
        price,
        quantity,
        token,
        setStatusModal,
        chainInfo,
        callback = Function()
    ) => {
        try {
            setCallBackFunc(() => callback);
            setStatusTextModal({
                pending: "Transaction pending",
                success: "You have successfully bought this item!",
                fail: "Failed to buy NFT!",
                noti: "Opps!!! This item has just been sold",
                checkBalance: "Insufficient funds!",
            });
            setStatusModal({ isUnbox: false, isPending: true });
            const balance = await getBalanceItem(
                getUser()?.address,
                token?.contract_address,
                chainInfo?.rpc,
                chainInfo?.sort_name,
                2,
                true
            );
            if (balance < price * quantity) {
                await sleep(1000);
                setStatusModal({ isPending: false, isCheckBalance: true });
                return false;
            }
            const check = await checkOrder(order_id, chainInfo);
            if (!check) {
                await sleep(1000);
                setStatusModal({ isPending: false, isNotify: true });
                callback();
                return false;
            }
            if (user.walletType === WALLET_TYPE.terra) {
                await excQueryContract(
                    user.chainInfo.market_contract,
                    {
                        buy_order: { order_id: order_id + "", quantity: quantity + "" },
                    },
                    ACTION_SEND_TRANSACTION.buy
                );
            } else if (user.walletType === WALLET_TYPE.aura) {
                let AuraMSTRapprove = await keplrService.getTokenAllowance(
                    token?.contract_address,
                    user.address,
                    chainInfo?.market_contract
                );
                let auraRemainAllowence = web3.utils.fromWei(AuraMSTRapprove);

                if (Number(auraRemainAllowence ?? 0) < Number(price * quantity)) {
                    await keplrService.execute(user.address, token?.contract_address, {
                        increase_allowance: {
                            amount: web3.utils.toWei("100000000", "ether"),
                            spender: chainInfo?.market_contract,
                        },
                    });
                }

                let rs = await keplrService.execute(user.address, chainInfo?.market_contract, {
                    buy_order: {
                        order_id: order_id + "",
                        quantity: quantity + ""
                    },
                });

                let tx = {
                    txHash: rs.transactionHash,
                    chain: chainInfo._id,
                    action: ACTION_SEND_TRANSACTION.buy
                }
                await handleResultTransaction(tx);
            } else {
                let walletAppr
                if (!token?.is_native_token) {
                    walletAppr = await handleApproveWallet({
                        contractABI: tokenABI,
                        contractAddress: token?.contract_address,
                        marketContractAddr: chainInfo?.market_contract,
                        setStatusModal: setStatusModal,
                        chainInfo: chainInfo,
                        price: price * quantity
                    });
                }
                if (walletAppr || token?.is_native_token) {
                    const web3 = new Web3(chainInfo.rpc);
                    const marketContract = new web3.eth.Contract(
                        marketABI,
                        chainInfo?.market_contract
                    );

                    const approveData = marketContract.methods
                        .buy(order_id, quantity, web3.utils.toWei(`${price}`, "ether"), token?.contract_address)
                        .encodeABI();
                    const total = price * quantity
                    const dataSend = {
                        signData: token?.is_native_token ? {
                            data: approveData,
                            from: user.address,
                            to: chainInfo?.market_contract,
                            value: web3.utils.toHex(web3.utils.toWei(total.toString(), "ether"))
                        } :
                            {
                                data: approveData,
                                from: user.address,
                                to: chainInfo?.market_contract,
                            },
                    };
                    await handleTransactionExWallet(
                        1,
                        dataSend,
                        handleResultTransaction,
                        chainInfo,
                        ACTION_SEND_TRANSACTION.buy
                    );
                    return true;
                }
            }
            ReactGA4.event("market_buy", order_id);
        } catch (err) {
            console.log(err);
            setStatusModal({ isPending: false, isFailed: true });
            ReactGA4.event("failed_to_buy", getUser()?.address);

        }
    };

    const makeOffer = async (data, setStatusModal, callback = Function()) => {
        try {
            setCallBackFunc(() => callback);
            setStatusModal({ isPending: true });
            setStatusTextModal({
                ...statusTextModal,
                success: "You have successfully make an offer!",
                fail: "Failed to make an offer!",

            });
            if (user.walletType === WALLET_TYPE.terra) {
                const price = web3.utils.fromWei(data.price)
                await excQueryContract(
                    user.chainInfo.market_contract,
                    {
                        "create_bid": {
                            "token_address": data.contract + "",
                            "payment_contract": user.chainInfo.token_contract,
                            "token_id": data.token + "",
                            "price": web3.utils.toWei(`${price + ""}`, 'mwei'),
                            "quantity": "1",
                            "expired": Number(data.expired)
                        }
                    },
                    ACTION_SEND_TRANSACTION.makeOffer,
                    "",
                    false,
                    web3.utils.toWei(`${price + ""}`, 'mwei')
                );
            } else {
                let walletAppr
                if (!data.payment_token?.is_native_token) {
                    walletAppr = await handleApproveWallet({
                        contractABI: tokenABI,
                        contractAddress: data.payment_token?.contract_address,
                        marketContractAddr: user?.chainInfo?.market_contract,
                        setStatusModal: setStatusModal,
                        price: data.price,
                    });
                }
                if (walletAppr || data.payment_token?.is_native_token) {
                    const web3 = new Web3(RPC_BSC);
                    const marketContract = new web3.eth.Contract(
                        marketABI,
                        user?.chainInfo?.market_contract
                    );
                    const approveData = marketContract.methods
                        .createBid(
                            data.contract,
                            data.payment_token?.contract_address,
                            data.token,
                            data.price,
                            data.expired
                        )
                        .encodeABI();
                    const dataSend = {
                        signData: data.payment_token?.is_native_token ? {
                            data: approveData,
                            from: user.address,
                            to: user?.chainInfo?.market_contract,
                            value: data.price
                        } :
                            {
                                data: approveData,
                                from: user.address,
                                to: user?.chainInfo?.market_contract,
                            },
                    };
                    await handleTransactionExWallet(
                        1,
                        dataSend,
                        handleResultTransaction,
                        ACTION_SEND_TRANSACTION.makeOffer
                    );
                }
            }
            ReactGA4.event("market_make_offer", data);

        } catch (err) {
            console.log(err);
            setStatusModal({ isPending: false, isFailed: true });
            ReactGA4.event("failed_to_make_offer", getUser()?.address);
        }
    };

    const cancelOffer = async (data, setStatusModal, callback = Function()) => {
        try {
            setCallBackFunc(() => callback);
            setStatusModal({ isPending: true });
            setStatusTextModal({
                ...statusTextModal,
                success: "You have successfully canceled the offer!",
                fail: "Failed to cancel offer!",
                noti: "Offer does not exist",
            });

            const check = await checkBid(data.bid_id);
            if (!check) {
                await sleep(1000);
                setStatusModal({ isPending: false, isNotify: true });
                callback();
                return false;
            }
            if (user.walletType === WALLET_TYPE.terra) {
                await excQueryContract(
                    user.chainInfo.market_contract,
                    {
                        "cancel_bid": { "bid_id": data.bid_id + "" }
                    },
                    ACTION_SEND_TRANSACTION.cancelOffer
                );
            } else {
                setStatusModal({ isPending: true });
                const walletAppr = true
                if (walletAppr) {
                    const web3 = new Web3(RPC_BSC);
                    const marketContract = new web3.eth.Contract(
                        marketABI,
                        user?.chainInfo?.market_contract
                    );
                    const approveData = marketContract.methods
                        .cancelBid(data.bid_id)
                        .encodeABI();
                    const dataSend = {
                        signData: {
                            data: approveData,
                            from: user.address,
                            to: user?.chainInfo?.market_contract,
                        },
                    };
                    await handleTransactionExWallet(
                        1,
                        dataSend,
                        handleResultTransaction,
                        ACTION_SEND_TRANSACTION.cancelOffer
                    );
                }
            }
        } catch (err) {
            console.log(err);
            setStatusModal({ isPending: false, isFailed: true });
        }
    };

    const updateOffer = async (data, setStatusModal, callback = Function()) => {
        try {
            setCallBackFunc(() => callback);
            setStatusModal({ isPending: true });
            setStatusTextModal({
                ...statusTextModal,
                success: "You have successfully changed the offer!",
                fail: "Failed to change offer!",
                noti: "Offer does not exist",
            });
            const check = await checkBid(data.bid_id);
            if (!check) {
                await sleep(1000);
                setStatusModal({ isPending: false, isNotify: true });
                callback();
                return false;
            }

            if (user.walletType === WALLET_TYPE.terra) {
                const price = web3.utils.fromWei(data.price)
                await excQueryContract(
                    user.chainInfo.market_contract,
                    {
                        "update_bid": {
                            "bid_id": data.bid_id + "",
                            "price": web3.utils.toWei(`${price + ""}`, 'mwei'),
                            "expired": Number(data.expired)
                        }
                    },
                    ACTION_SEND_TRANSACTION.updateOffer,
                    "",
                    false,
                    web3.utils.toWei(`${price + ""}`, 'mwei')
                );
            } else {
                setStatusModal({ isPending: true });
                let walletAppr
                if (!data.token?.is_native_token) {

                    walletAppr = await handleApproveWallet({
                        contractABI: tokenABI,
                        contractAddress: data.token?.contract_address,
                        marketContractAddr: user?.chainInfo?.market_contract,
                        setStatusModal: setStatusModal,
                        price: data.price
                    });
                }
                if (walletAppr || data.token?.is_native_token) {
                    const web3 = new Web3(RPC_BSC);
                    const marketContract = new web3.eth.Contract(
                        marketABI,
                        user?.chainInfo?.market_contract
                    );
                    const approveData = marketContract.methods
                        .updateBid(data.bid_id, data.price, data.expired, data.token?.contract_address)
                        .encodeABI();
                    const dataSend = {
                        signData: data.token?.is_native_token ? {
                            data: approveData,
                            from: user.address,
                            to: user?.chainInfo?.market_contract,
                            value: data.price
                        } :
                            {
                                data: approveData,
                                from: user.address,
                                to: user?.chainInfo?.market_contract,
                            },
                    };
                    await handleTransactionExWallet(
                        1,
                        dataSend,
                        handleResultTransaction,
                        ACTION_SEND_TRANSACTION.updateOffer
                    );
                }
            }
        } catch (err) {
            console.log(err);
            setStatusModal({ isPending: false, isFailed: true });
        }
    };

    const acceptOffer = async (data, setStatusModal, callback = Function()) => {
        try {
            setCallBackFunc(() => callback);
            setStatusModal({ isPending: true });
            setStatusTextModal({
                ...statusTextModal,
                success: "You have successfully accept the offer!",
                fail: "Failed to accept offer!",
                noti: "Offer does not exist",
            });
            const check = await checkBid(data.bid_id);
            if (!check) {
                await sleep(1000);
                setStatusModal({ isPending: false, isNotify: true });
                callback();
                return false;
            }

            if (user.walletType === WALLET_TYPE.terra) {
                await excQueryContract(
                    user.chainInfo.market_contract,
                    {
                        "accept_bid": { "bid_id": data.bid_id + "" }
                    },
                    ACTION_SEND_TRANSACTION.acceptOffer,
                    data.contractNft
                );
            } else {
                setStatusModal({ isPending: true });
                await handleApproveAllWallet(
                    nftABI,
                    user?.chainInfo?.market_contract,
                    data.contractNft,
                    setStatusModal
                );

                const web3 = new Web3(RPC_BSC);
                const marketContract = new web3.eth.Contract(
                    marketABI,
                    user?.chainInfo?.market_contract
                );
                const approveData = marketContract.methods
                    .acceptBid(data.bid_id, web3.utils.toWei(`${data.price}`, "ether"), data.tokenAddress)
                    .encodeABI();
                const dataSend = {
                    signData: {
                        data: approveData,
                        from: user.address,
                        to: user?.chainInfo?.market_contract,
                    },
                };
                await handleTransactionExWallet(
                    1,
                    dataSend,
                    handleResultTransaction,
                    ACTION_SEND_TRANSACTION.acceptOffer
                );
            }
        } catch (err) {
            console.log(err);
            setStatusModal({ isPending: false, isFailed: true });
        }
    };

    const cancelOrder = async (data, setStatusModal, callback = Function()) => {
        try {
            const chainInfo = data.chainInfo;
            setCallBackFunc(() => callback);
            setStatusModal({ isPending: true });
            setStatusTextModal({
                ...statusTextModal,
                success: "You have successfully cancel the order!",
                fail: "Failed to cancel order!",
                noti: "Opps!!! This item has just been sold",
            });
            const check = await checkOrder(data.order_id, data.chainInfo);
            if (!check) {
                await sleep(1000);
                setStatusModal({ isPending: false, isNotify: true });
                callback();
                return false;
            }

            if (user.walletType === WALLET_TYPE.terra) {
                await excQueryContract(
                    user.chainInfo.market_contract,
                    {
                        "cancel_order": { "order_id": data.order_id + "" }
                    },
                    ACTION_SEND_TRANSACTION.cancel,
                );
            } else if (user.walletType === WALLET_TYPE.aura) {
                let rs = await keplrService.execute(user.address, chainInfo?.market_contract, {
                    cancel_order: {
                        order_id: data.order_id + "",
                    },
                });

                let tx = {
                    txHash: rs.transactionHash,
                    chain: chainInfo._id,
                    action: ACTION_SEND_TRANSACTION.cancel
                }
                await handleResultTransaction(tx);
            } else {
                await checkNetwork(data.chainInfo)
                setStatusModal({ isPending: true });
                const walletAppr = true
                if (walletAppr) {
                    const web3 = new Web3(chainInfo.rpc);
                    const marketContract = new web3.eth.Contract(
                        marketABI,
                        chainInfo?.market_contract
                    );
                    const approveData = marketContract.methods
                        .cancelOrder(data.order_id)
                        .encodeABI();
                    const dataSend = {
                        signData: {
                            data: approveData,
                            from: user.address,
                            to: chainInfo?.market_contract,
                        },
                    };
                    await handleTransactionExWallet(
                        1,
                        dataSend,
                        handleResultTransaction,
                        chainInfo,
                        ACTION_SEND_TRANSACTION.cancel
                    );
                }
            }
        } catch (err) {
            setStatusModal({ isPending: false, isFailed: true });
            console.log(err);
        }
    };

    const updateOrder = async (data, setStatusModal, callback = Function()) => {
        try {
            setStatusModal({ isPending: true });
            setCallBackFunc(() => callback);
            setStatusTextModal({
                pending: "Transaction pending",
                success: "You have successfully changed the order!",
                fail: "Failed to change listing!",
                noti: "Opps!!! This item has just been sold",
            });
            const check = await checkOrder(data.order_id, data.chainInfo);
            if (!check) {
                await sleep(1000);
                setStatusModal({ isPending: false, isNotify: true });
                callback();
                return false;
            }

            if (user.walletType === WALLET_TYPE.terra) {
                await excQueryContract(
                    user.chainInfo.market_contract,
                    {
                        "update_order": {
                            "order_id": data.order_id + "",
                            "price": web3.utils.toWei(`${data.price}`, 'mwei'),
                            "quantity": data?.quantity ? data?.quantity + "" : "1"
                        }
                    },
                    ACTION_SEND_TRANSACTION.update,
                    "",
                    false,
                    web3.utils.toWei(`${data.price}`, 'mwei')
                );
            } else if (user.walletType === WALLET_TYPE.aura) {
                setStatusModal({ isPending: true });
                let rs = await keplrService.execute(user.address, data.chainInfo.market_contract, {
                    update_order: {
                        order_id: data.order_id + "",
                        price: `${web3.utils.toWei(`${data.price}`, "ether")}`,
                        quantity: `${data?.quantity ? data?.quantity : 1}`,
                    },
                })
                let tx = {
                    txHash: rs.transactionHash,
                    chain: data.chainInfo._id,
                    action: ACTION_SEND_TRANSACTION.update
                }
                await handleResultTransaction(tx);
            } else {
                await checkNetwork(data.chainInfo)
                setStatusModal({ isPending: true });
                const web3 = new Web3(data.chainInfo.rpc);
                const marketContract = new web3.eth.Contract(
                    marketABI,
                    data.chainInfo.market_contract
                );
                const approveData = marketContract.methods
                    .updateOrder(
                        data.order_id,
                        web3.utils.toWei(`${data.price}`, "ether"),
                        data?.quantity ? data?.quantity : 1,
                        data.payment
                    )
                    .encodeABI();

                const dataSend = {
                    signData: {
                        data: approveData,
                        from: user.address,
                        to: data.chainInfo?.market_contract,
                    },
                };

                await handleTransactionExWallet(
                    1,
                    dataSend,
                    handleResultTransaction,
                    data.chainInfo,
                    ACTION_SEND_TRANSACTION.update
                );
            }
        } catch (err) {
            setStatusModal({ isPending: false, isFailed: true });
            // message.error(err);
        }
    };

    const createOrder = async (data, setStatusModal, callback) => {
        const { contract_address, token_address, token_id, price, quantity, isItems, chainInfo } = data;
        try {
            setCallBackFunc(() => callback);
            setStatusModal({ isPending: true });
            setStatusTextModal({
                ...statusTextModal,
                pending: "Transaction pending",
                success: "You have successfully put on sale!",
                fail: "Failed to put on sale!",
            });
            if (user.walletType === WALLET_TYPE.terra) {
                await excQueryContract(
                    user.chainInfo.market_contract,
                    {
                        create_order: {
                            token_address: contract_address,
                            payment_contract: user.chainInfo.token_contract,
                            token_id: token_id + "",
                            price: web3.utils.toWei(price + "", 'mwei'),
                            quantity: quantity + "",
                        },
                    },
                    ACTION_SEND_TRANSACTION.sale,
                    contract_address,
                    isItems
                );
            } else if (user.walletType === WALLET_TYPE.aura) {
                if (isItems) {
                    let AuraBalanceapprove = await keplrService.getTokenAllowance(
                        contract_address,
                        user.address,
                        chainInfo?.market_contract
                    );
                    let auraRemainAllowence = web3.utils.fromWei(AuraBalanceapprove);
                    if (Number(auraRemainAllowence ?? 0) < Number(price)) {
                        await keplrService.execute(user.address, contract_address, {
                            increase_allowance: {
                                amount: web3.utils.toWei("100000000", "ether"),
                                spender: chainInfo?.market_contract,
                            },
                        });
                    }
                } else {
                    let isApprove = await keplrService.isApproveNft(
                        contract_address,
                        user.address,
                        chainInfo?.market_contract
                    );
                    if (!isApprove) {
                        await keplrService.execute(user.address, contract_address, {
                            approve_all: {
                                operator: chainInfo?.market_contract,
                            },
                        });
                    }
                }

                let rs = await keplrService.execute(user.address, chainInfo?.market_contract, {
                    create_order: {
                        token_address: contract_address,
                        payment_contract: user.chainInfo.token_contract,
                        token_id: token_id + "",
                        price: `${web3.utils.toWei(`${price}`, "ether")}`,
                        quantity: quantity + "",
                    },
                });


                let tx = {
                    txHash: rs.transactionHash,
                    chain: chainInfo._id,
                    action: ACTION_SEND_TRANSACTION.sale
                }
                await handleResultTransaction(tx);

            } else {
                if (isItems) {
                    await handleApproveWallet({
                        contractABI: tokenABI,
                        contractAddress: contract_address,
                        marketContractAddr: chainInfo?.market_contract,
                        setStatusModal: setStatusModal,
                        chainInfo: chainInfo,
                        price: price
                    });
                } else {
                    await handleApproveAllWallet(
                        nftABI,
                        chainInfo?.market_contract,
                        contract_address,
                        setStatusModal,
                        chainInfo,
                        price
                    );
                }
                const web3 = new Web3(chainInfo.rpc);
                const marketContract = new web3.eth.Contract(
                    marketABI,
                    chainInfo?.market_contract
                );
                const approveData = marketContract.methods
                    .createOrder(
                        contract_address,
                        chainInfo?.token_contract,
                        token_id,
                        web3.utils.toWei(`${price}`, "ether"),
                        quantity
                    )
                    .encodeABI();

                const dataSend = {
                    signData: {
                        data: approveData,
                        from: user.address,
                        to: chainInfo?.market_contract,
                    },
                };

                await handleTransactionExWallet(
                    1,
                    dataSend,
                    handleResultTransaction,
                    chainInfo,
                    ACTION_SEND_TRANSACTION.sale
                );

            }
            ReactGA4.event("market_put_on_sale", contract_address);
        } catch (err) {
            setStatusModal({ isPending: false, isFailed: true });
            console.log(err);
            ReactGA4.event("failed_to_put_on_sale", getUser()?.address);
        }
    };

    const buyMysteryBox = async (
        box_id,
        price,
        payment_token,
        quantity,
        setStatusModal,
        buyBoxSucessfully,
        callback = Function()
    ) => {
        setCallBackFunc(() => callback);
        setStatusModal({ isPending: true });
        setStatusTextModal({
            pending: "Transaction pending",
            success: "You have successfully bought the item!",
            fail: "Failed to buy the item!",
            checkBalance: "Insufficient funds!",
        });
        try {
            const chainInfo = chains?.find(el => el.sort_name == "BSC")
            const balance = await getBalanceItem(
                getUser()?.address,
                payment_token,
                chainInfo?.rpc,
                chainInfo?.sort_name,
                2,
                true
            );
            if (balance < price) {
                await sleep(1000);
                setStatusModal({ isPending: false, isCheckBalance: true });
                return false;
            }
            const walletAppr = await handleApproveWallet({
                contractABI: tokenABI,
                contractAddress: payment_token,
                marketContractAddr: process.env.REACT_APP_MYSTERY_BOX_CONTRACT_ADDRESS,
                setStatusModal: setStatusModal,
                chainInfo: chainInfo,
                price: price
            });
            if (walletAppr) {
                const web3 = new Web3(chainInfo.rpc);
                const mysteryBoxContract = new web3.eth.Contract(
                    mysteryBoxABI,
                    process.env.REACT_APP_MYSTERY_BOX_CONTRACT_ADDRESS
                );
                const approveData = mysteryBoxContract.methods
                    .buyMysteryBox(box_id, quantity)
                    .encodeABI();
                const dataSend = {
                    signData: {
                        data: approveData,
                        from: user.address,
                        to: process.env.REACT_APP_MYSTERY_BOX_CONTRACT_ADDRESS,
                    },
                };
                await handleTransactionExWallet(
                    1,
                    dataSend,
                    handleResultTransaction,
                    chainInfo,
                    ACTION_SEND_TRANSACTION.buyMysteryBox
                );
                buyBoxSucessfully();
                return true;
            }
        } catch (err) {
            console.log(err);
            setStatusModal({ isPending: false, isFailed: true });
        }
    };

    const estimateBridgeItem = async (
        current_sort_name,
        dest_sort_name,
        itemContract,
        data,
    ) => {
        try {
            const destChainInfo = chains?.find(el => el.sort_name == dest_sort_name)
            const currentChainInfo = chains?.find(el => el.sort_name == current_sort_name)

            const bridgeContract = currentChainInfo.bridge_contract

            const web3 = new Web3(currentChainInfo.rpc);
            const executeContract = new web3.eth.Contract(
                bridgeABI,
                bridgeContract
            );

            const bnbFee = await executeContract.methods
                .estimateFee(Number(destChainInfo.bridge_chain_id), itemContract, Number(data))
                .call();

            return bnbFee;
        } catch (err) {
            console.log(err);
        }
    };

    const bridgeItem = async (
        isNFT,
        current_sort_name,
        dest_sort_name,
        itemContract,
        data,
        setStatusModal,
        callback = Function()
    ) => {
        setCallBackFunc(() => callback);
        setStatusModal({ isPending: true });
        setStatusTextModal({
            pending: "Transaction pending",
            success: "You have successfully convert the item!",
            fail: "Failed to bridge the item!",
            checkBalance: "Insufficient funds!",
        });
        try {
            const destChainInfo = chains?.find(el => el.sort_name == dest_sort_name)
            const currentChainInfo = chains?.find(el => el.sort_name == current_sort_name)

            const bridgeContract = currentChainInfo.bridge_contract
            let walletAppr;

            if (isNFT) {
                walletAppr = await handleApproveAllWallet(
                    nftABI,
                    bridgeContract,
                    itemContract,
                    setStatusModal,
                    currentChainInfo,
                );
            } else {
                walletAppr = await handleApproveFTWallet({
                    contractABI: tokenABI,
                    contractAddress: itemContract,
                    spenderAddress: bridgeContract,
                    setStatusModal: setStatusModal,
                    chainInfo: currentChainInfo,
                    quantity: data
                });
            }

            if (walletAppr) {
                const web3 = new Web3(currentChainInfo.rpc);
                const executeContract = new web3.eth.Contract(
                    bridgeABI,
                    bridgeContract
                );

                const bnbFee = await executeContract.methods
                    .estimateFee(Number(destChainInfo.bridge_chain_id), itemContract, Number(data))
                    .call();

                let approveData;

                if (isNFT) {
                    approveData = await executeContract.methods
                        .bridgeNft(Number(destChainInfo.bridge_chain_id), itemContract, Number(data))
                        .encodeABI();
                } else {
                    approveData = await executeContract.methods
                        .bridgeFt(Number(destChainInfo.bridge_chain_id), itemContract, Number(data))
                        .encodeABI();
                }
                console.log({ bnbFee, bridge_chain_id: destChainInfo.bridge_chain_id, itemContract, data })

                const dataReturn = {
                    chainId: currentChainInfo?.chain_id,
                    approveData: {
                        data: approveData,
                        from: user.address,
                        to: bridgeContract,
                        value: bnbFee[0]
                    },
                };

                await handleTransactionExternalWallet(
                    -1,
                    dataReturn,
                    handleResultTransaction,
                    null,
                    currentChainInfo,
                    library
                );
                setStatusModal({ isSuccess: true });
                return true;
            }
        } catch (err) {
            console.log(err);
            setStatusModal({ isPending: false, isFailed: true });
        }
    };

    const buyBundle = async (
        bundle_id,
        price,
        token,
        chainInfo,
        setStatusModal,
        callback = Function()
    ) => {
        try {
            setStatusModal({ isPending: true });
            setCallBackFunc(() => callback);
            setStatusTextModal({
                ...statusTextModal,
                success: "You have successfully bought the bundle!",
                fail: "Failed to buy the bundle!",
                noti: "Bundle does not exist",
                checkBalance: "Insufficient funds!",
            });
            const check = await checkBundle(bundle_id, chainInfo);
            if (!check) {
                await sleep(1000);
                setStatusModal({ isPending: false, isNotify: true });
                callback();
                return false;
            }
            if (user.walletType === WALLET_TYPE.terra) {
                await excQueryContract(
                    user.chainInfo.market_contract,
                    {
                        "buy_bundle": { "bundle_id": bundle_id + "" }
                    },
                    ACTION_SEND_TRANSACTION.buyBundle
                );
            } else if (user.walletType === WALLET_TYPE.aura) {
                let AuraMSTRapprove = await keplrService.getTokenAllowance(
                    token?.contract_address,
                    user.address,
                    chainInfo?.market_contract
                );
                let auraRemainAllowence = web3.utils.fromWei(AuraMSTRapprove);

                if (Number(auraRemainAllowence ?? 0) < Number(price)) {
                    await keplrService.execute(user.address, token?.contract_address, {
                        increase_allowance: {
                            amount: web3.utils.toWei("100000000", "ether"),
                            spender: chainInfo?.market_contract,
                        },
                    });
                }

                let rs = await keplrService.execute(user.address, chainInfo?.market_contract, {
                    buy_bundle: {
                        bundle_id: bundle_id + "",
                    },
                });
                let tx = {
                    txHash: rs.transactionHash,
                    chain: chainInfo._id,
                    action: ACTION_SEND_TRANSACTION.buyBundle
                }
                await handleResultTransaction(tx);
            } else {
                const balance = await getBalanceItem(
                    getUser()?.address,
                    token?.contract_address,
                    chainInfo?.rpc,
                    chainInfo?.sort_name,
                    2,
                    true
                );
                if (balance < price) {
                    await sleep(1000);
                    setStatusModal({ isPending: false, isCheckBalance: true });
                    return false;
                }
                let walletAppr
                if (!token.is_native_token) {
                    walletAppr = await handleApproveWallet({
                        contractABI: tokenABI,
                        contractAddress: token?.contract_address,
                        marketContractAddr: chainInfo?.market_contract,
                        setStatusModal: setStatusModal,
                        chainInfo: chainInfo,
                        price: price
                    });
                }
                if (walletAppr || token.is_native_token) {
                    const web3 = new Web3(chainInfo.rpc);
                    const marketContract = new web3.eth.Contract(
                        marketABI,
                        chainInfo?.market_contract
                    );
                    const approveData = marketContract.methods
                        .buyBundle(bundle_id, web3.utils.toWei(`${price}`, "ether"), token?.contract_address)
                        .encodeABI();
                    const dataSend = {
                        signData: token.is_native_token ? {
                            data: approveData,
                            from: user.address,
                            to: chainInfo?.market_contract,
                            value: web3.utils.toHex(web3.utils.toWei(price.toString(), "ether"))
                        } :
                            {
                                data: approveData,
                                from: user.address,
                                to: chainInfo?.market_contract,
                            },
                    };

                    await handleTransactionExWallet(
                        1,
                        dataSend,
                        handleResultTransaction,
                        chainInfo,
                        ACTION_SEND_TRANSACTION.buyBundle
                    );
                    return true;
                }
            }
        } catch (err) {
            console.log(err);
            setStatusModal({ isPending: false, isFailed: true });
        }
    };

    const cancelBundle = async (
        bundle_id,
        chainInfo,
        setStatusModal,
        callback = Function()
    ) => {
        try {
            setStatusModal({ isPending: true });
            setCallBackFunc(() => callback);
            setStatusTextModal({
                ...statusTextModal,
                success: "You have successfully cancel bundle!",
                fail: "Failed to cancel bundle!",
                noti: "Bundle does not exist",
            });
            const check = await checkBundle(bundle_id, chainInfo);
            if (!check) {
                await sleep(1000);
                setStatusModal({ isPending: false, isNotify: true });
                callback();
                return false;
            }
            if (user.walletType === WALLET_TYPE.terra) {
                await excQueryContract(
                    user.chainInfo.market_contract,
                    {
                        "cancel_bundle": { "bundle_id": bundle_id + "" }
                    },
                    ACTION_SEND_TRANSACTION.cancelBundle
                );
            } else if (user.walletType === WALLET_TYPE.aura) {
                let rs = await keplrService.execute(user.address, chainInfo?.market_contract, {
                    cancel_bundle: {
                        bundle_id: bundle_id + "",
                    },
                });
                let tx = {
                    txHash: rs.transactionHash,
                    chain: chainInfo._id,
                    action: ACTION_SEND_TRANSACTION.cancelBundle
                }
                await handleResultTransaction(tx);
            } else {
                await checkNetwork(chainInfo)
                const walletAppr = true
                if (walletAppr) {
                    const web3 = new Web3(chainInfo?.rpc);
                    const marketContract = new web3.eth.Contract(
                        marketABI,
                        chainInfo?.market_contract
                    );
                    const approveData = marketContract.methods
                        .cancelBundle(bundle_id)
                        .encodeABI();
                    const dataSend = {
                        signData: {
                            data: approveData,
                            from: user.address,
                            to: chainInfo?.market_contract,
                        },
                    };
                    await handleTransactionExWallet(
                        1,
                        dataSend,
                        handleResultTransaction,
                        chainInfo,
                        ACTION_SEND_TRANSACTION.cancelBundle
                    );
                    return true;
                }
            }
        } catch (err) {
            console.log(err);
            setStatusModal({ isPending: false, isFailed: true });
        }
    };

    const openMysteryBox = async (
        data,
        setStatusModal,
        callback = Function()
    ) => {
        setCallBackFunc(() => callback);
        setStatusModal({ isPending: true });
        setStatusTextModal({
            ...statusTextModal,
            success: "You have successfully unbox!",
            fail: "Failed to unbox!",
        });
        try {
            await handleApproveAllWallet(
                nftABI,
                process.env.REACT_APP_MYSTERY_BOX_CONTRACT_ADDRESS,
                data.contract_address,
                setStatusModal
            );
            const web3 = new Web3(RPC_BSC);
            const mysteryBoxContract = new web3.eth.Contract(
                mysteryBoxABI,
                process.env.REACT_APP_MYSTERY_BOX_CONTRACT_ADDRESS
            );
            const approveData = mysteryBoxContract.methods
                .unBox(data.token)
                .encodeABI();
            const dataSend = {
                signData: {
                    data: approveData,
                    from: user.address,
                    to: process.env.REACT_APP_MYSTERY_BOX_CONTRACT_ADDRESS,
                },
            };
            await handleTransactionExWallet(
                1,
                dataSend,
                handleResultTransaction,
                ACTION_SEND_TRANSACTION.unboxMystery
            );
            return true;
        } catch (err) {
            console.log(err);
            setStatusModal({ isPending: false, isFailed: true });
        }
    };

    const getTotalBuyBox = async (boxId) => {
        const web3 = new Web3(RPC_BSC);
        const mysteryBoxContract = new web3.eth.Contract(
            mysteryBoxABI,
            process.env.REACT_APP_MYSTERY_BOX_CONTRACT_ADDRESS
        );
        const totalBuyBox = await mysteryBoxContract.methods
            .remainingBoxCanBuy(user.address, boxId)
            .call();
        return totalBuyBox;
    };

    const getBoxInfo = async (boxId) => {
        const web3 = new Web3(RPC_BSC);
        const mysteryBoxContract = new web3.eth.Contract(
            mysteryBoxABI,
            process.env.REACT_APP_MYSTERY_BOX_CONTRACT_ADDRESS
        );
        const infoBox = await mysteryBoxContract.methods
            .MysteryBoxes(boxId)
            .call();
        return infoBox;
    };

    const createBundle = async (
        listItem,
        price,
        token,
        setStatusModal,
        chainInfo,
        callback = Function()
    ) => {
        setStatusModal({ isPending: true });
        setStatusTextModal({
            ...statusTextModal,
            pending: "Transaction pending",
            success: "You have successfully created a bundle!",
            fail: "Failed to create a bundle!",
        });
        setCallBackFunc(() => callback);
        try {
            if (user.walletType === WALLET_TYPE.terra) {
                const allow = checkMultiAllow(listItem)
                if (allow) {
                    let listAddress = [];
                    let listTokenId = [];
                    listItem.map((item) => {
                        listAddress.push(item.tokenAddress);
                        listTokenId.push(item.tokenId + "");
                    })
                    await signTxMsg(
                        user.chainInfo.market_contract,
                        {
                            "create_bundle": {
                                "list_token_address": listAddress,
                                "payment_contract": user.chainInfo.token_contract,
                                "list_token_id": listTokenId,
                                "price": web3.utils.toWei(price + "", 'mwei'),
                            }
                        },
                        ACTION_SEND_TRANSACTION.createBundle
                    );
                }
            } else if (user.walletType === WALLET_TYPE.aura) {
                let listAddress = [];
                let listTokenId = [];
                const tokenAddrCall = listItem.filter((value, index, self) =>
                    index === self.findIndex((t) => (
                        t.tokenAddress === value.tokenAddress
                    ))
                )
                await Promise.all(
                    tokenAddrCall.map(async (item) => {
                        let isApprove = await keplrService.isApproveNft(
                            item.tokenAddress,
                            user.address,
                            chainInfo?.market_contract
                        );
                        console.log({ isApprove })
                        if (!isApprove) {
                            await keplrService.execute(user.address, item.tokenAddress, {
                                approve_all: {
                                    operator: chainInfo?.market_contract,
                                },
                            });
                        }
                    })
                );
                listItem.map((item) => {
                    listAddress.push(item.tokenAddress);
                    listTokenId.push(item.tokenId + "");
                })
                let rs = await keplrService.execute(user.address, chainInfo?.market_contract, {
                    create_bundle: {
                        list_token_address: listAddress,
                        list_token_id: listTokenId,
                        payment_contract: chainInfo?.token_contract,
                        price: web3.utils.toWei(`${price}`, "ether")
                    }
                })
                let tx = {
                    txHash: rs.transactionHash,
                    chain: chainInfo._id,
                    action: ACTION_SEND_TRANSACTION.createBundle
                }
                await handleResultTransaction(tx);
            } else {
                let listAddress = [];
                let listTokenId = [];
                const tokenAddrCall = listItem.filter((value, index, self) =>
                    index === self.findIndex((t) => (
                        t.tokenAddress === value.tokenAddress
                    ))
                )
                await Promise.all(
                    tokenAddrCall.map(async (item) => {
                        await handleApproveAllWallet(
                            nftABI,
                            chainInfo?.market_contract,
                            item.tokenAddress,
                            setStatusModal,
                            chainInfo
                        );
                    })
                );
                listItem.map((item) => {
                    listAddress.push(item.tokenAddress);
                    listTokenId.push(item.tokenId + "");
                })
                const web3 = new Web3(chainInfo.rpc);
                const marketContract = new web3.eth.Contract(
                    marketABI,
                    chainInfo?.market_contract
                );
                const approveData = marketContract.methods
                    .createBundle(
                        listAddress,
                        listTokenId,
                        web3.utils.toWei(`${price}`, "ether"),
                        chainInfo?.token_contract
                    )
                    .encodeABI();

                const dataSend = {
                    signData: {
                        data: approveData,
                        from: user.address,
                        to: chainInfo?.market_contract,
                    },
                };
                await handleTransactionExWallet(
                    1,
                    dataSend,
                    handleResultTransaction,
                    chainInfo,
                    ACTION_SEND_TRANSACTION.createBundle
                );
            }
        } catch (err) {
            setStatusModal({ isPending: false, isFailed: true });
            console.log(err);
        }
    };

    const updateBundle = async (bundle_id, price, chainInfo, setStatusModal, callback = Function()) => {
        try {
            setStatusModal({ isPending: true });
            setCallBackFunc(() => callback);
            setStatusTextModal({
                ...statusTextModal,
                success: "You have successfully changed the bundle!",
                fail: "Failed to change listing!",
                noti: "Opps!!! This item has just been sold",
            });
            const check = await checkBundle(bundle_id, chainInfo);
            if (!check) {
                await sleep(1000);
                setStatusModal({ isPending: false, isNotify: true });
                callback();
                return false;
            }
            if (user.walletType === WALLET_TYPE.aura) {
                setStatusModal({ isPending: true });
                let rs = await keplrService.execute(user.address, chainInfo?.market_contract, {
                    update_bundle: {
                        bundle_id: bundle_id + "",
                        price: web3.utils.toWei(`${price}`, "ether"),
                    }
                })
                let tx = {
                    txHash: rs.transactionHash,
                    chain: chainInfo._id,
                    action: ACTION_SEND_TRANSACTION.updateBundle
                }
                await handleResultTransaction(tx);
            } else {
                setStatusModal({ isPending: true });
                const walletAppr = true
                if (walletAppr) {
                    const web3 = new Web3(chainInfo.rpc);
                    const marketContract = new web3.eth.Contract(
                        marketABI,
                        chainInfo?.market_contract
                    );
                    const approveData = marketContract.methods
                        .updateBundle(
                            bundle_id,
                            web3.utils.toWei(`${price}`, "ether"),
                            // data.payment
                        )
                        .encodeABI();

                    const dataSend = {
                        signData: {
                            data: approveData,
                            from: user.address,
                            to: chainInfo?.market_contract,
                        },
                    };

                    await handleTransactionExWallet(
                        1,
                        dataSend,
                        handleResultTransaction,
                        chainInfo,
                        ACTION_SEND_TRANSACTION.updateBundle
                    );
                }
            }
        } catch (err) {
            setStatusModal({ isPending: false, isFailed: true });
            // message.error(err);
        }
    };

    const checkRegister = async (address, callback = Function()) => {
        try {
            const web3 = new Web3(RPC_BSC);
            const gameNFTCont = new web3.eth.Contract(
                nftGameABI,
                process.env.REACT_APP_NFT_GAME_REGISTER
            );

            const resData = await gameNFTCont.methods
                .isAddressJoinedSeasonTCP(address || getUser().address, gameInfo?.game_index)
                .call();
            return resData
        } catch (err) {
            console.log(err);
        }


        return false;
    };

    const checkBid = async (bid_id, callback = Function()) => {
        if (user.walletType === WALLET_TYPE.bsc) {
            try {
                const web3 = new Web3(RPC_BSC);
                const marketContract = new web3.eth.Contract(
                    marketABI,
                    user?.chainInfo?.market_contract
                );
                const resData = await marketContract.methods.bids(bid_id).call();
                if (resData?.quantity == 0) return false;
            } catch (err) {
                console.log(err);
            }
        }

        if (user.walletType === WALLET_TYPE.terra) {
            const resData = await queryContractTerra(user.chainInfo.market_contract, {
                bid_info: { bid_id: bid_id + "" }
            });
            if (!resData?.status) return false;
        }
        return true;

    };

    const checkBundle = async (bundle_id, chainInfo, callback = Function()) => {
        if (user.walletType === WALLET_TYPE.terra) {
            const resData = await queryContractTerra(user.chainInfo.market_contract, {
                bundle_info: { bundle_id: bundle_id + "" }
            });
            if (!resData?.status) return false;
        } else if (user.walletType === WALLET_TYPE.aura) {
            const resData = await keplrService.queryContractSmart(chainInfo?.market_contract, {
                bundle_info: { bundle_id: bundle_id + "" }
            });
            if (!resData?.status) return false;
        } else {
            try {
                const web3 = new Web3(chainInfo.rpc);
                const marketContract = new web3.eth.Contract(
                    marketABI,
                    chainInfo?.market_contract
                );

                const resData = await marketContract.methods
                    .Bundles(bundle_id)
                    .call();
                if (!resData?.status) return false;
            } catch (err) {
                console.log(err);
            }
        }
        return true;
    };

    const checkAccept = async (
        address,
        tokenAddress,
        tokenId,
        callback = Function()
    ) => {
        try {
            const web3 = new Web3(RPC_BSC);
            const marketContract = new web3.eth.Contract(
                marketABI,
                user?.chainInfo?.market_contract
            );
            const resData = await marketContract.methods
                .isAcceptable(address, tokenAddress, tokenId)
                .call();

            return true;
        } catch (err) {
            console.log(err);
        }
    };

    const checkLogin = () => {
        if (!isAuthenticatedUser) {
            setIsShowingLoginPopup(true);
            return false;
        }
        return true;
    };

    const checkAlowance = async (action, contract, contract_address, query, isItems = false, price = 0) => {
        if (
            [
                ACTION_SEND_TRANSACTION.sale,
                ACTION_SEND_TRANSACTION.acceptOffer,
                ACTION_SEND_TRANSACTION.createBundle,
            ].includes(action) && !isItems
        ) {
            const res = await queryContractTerra(contract_address, {
                approved_for_all: { owner: user.address },
            });
            if (!res.operators.find((el) => el.spender === user.chainInfo.market_contract)) {
                await signTxMsg(contract_address, {
                    approve_all: {
                        operator: user.chainInfo.market_contract,
                    },
                }, ACTION_SEND_TRANSACTION.allow);
            }
            return true
        } else {
            const contract = isItems ? contract_address : user.chainInfo.token_contract
            const res = await queryContractTerra(
                contract,
                {
                    allowance: {
                        owner: user.address,
                        spender: user.chainInfo.market_contract,
                    },
                }
            );

            if (+res.allowance <= price) {
                const amount = 1000000000 + Number(price)
                await signTxMsg(contract, {
                    increase_allowance: {
                        amount: amount + "",
                        spender: user.chainInfo.market_contract,
                    },
                }, ACTION_SEND_TRANSACTION.allow);
            }
            return true
        }
    };

    const excQueryContract = async (contract, query, action, contractNft, isItems, price) => {
        try {
            const allow = await checkAlowance(action, contract, contractNft, query, isItems, price);
            if (allow) {
                await signTxMsg(contract, query, action);
            }
        } catch (err) {
            throw err;
        }
    };

    const signTxMsg = async (contract, query, action) => {
        try {

            if (action !== ACTION_SEND_TRANSACTION.allow) {
                await handleResultTransaction({
                    txHash: "",
                    action,
                    chain: user.chainInfo._id,
                });
            }
            return true;
        } catch (error) {
            message.error(error.message || error);

            throw error
        }
    };

    const registerNFTGame = async (
        address,
        gameIndex
    ) => {
        try {
            const priceT = gameInfo?.seasons[1][0]?.price || 0
            setStatusTextModal({
                ...statusTextModal,
                pending: "Transaction pending",
                success: "Register successfully!",
                fail: "Failed to register",
                checkBalance: "Insufficient funds!",
            });
            setStatusModal({ isPending: true });
            const web3 = new Web3(RPC_BSC);
            const gameNFTCont = new web3.eth.Contract(
                nftGameABI,
                process.env.REACT_APP_NFT_GAME_REGISTER
            );
            if (priceT) {
                const balance = await getMaxBalance(
                    getUser()?.address,
                    process.env.REACT_APP_USDT_CONTRACT_ADDRESS
                );
                if (balance.token < priceT) {
                    await sleep(1000);
                    setStatusModal({ isPending: false, isCheckBalance: true });
                    return false;
                }
            }

            const resData = await gameNFTCont.methods
                .isAddressJoinedSeasonTCP(address, gameInfo?.listGame[gameIndex - 1]?.game_index || gameInfo?.game_index)
                .call();

            if (resData) {
                await sleep(1000);
                setStatusModal({ isPending: false, isNotify: true });
                history.push('/nft-game/home?tab=MyDeck')
                return true;
            }
            let walletAppr = true
            if (priceT) {
                walletAppr = await handleApproveWallet({
                    contractABI: tokenABI,
                    contractAddress: process.env.REACT_APP_USDT_CONTRACT_ADDRESS,
                    marketContractAddr: process.env.REACT_APP_NFT_GAME_REGISTER,
                    setStatusModal: setStatusModal,
                    price: priceT
                });
            }
            if (walletAppr) {
                const approveData = gameNFTCont.methods
                    .joinNewestSeasonTCP(gameInfo?.listGame[gameIndex - 1]?.game_index || gameInfo?.game_index)
                    .encodeABI();
                const dataSend = {
                    signData: {
                        data: approveData,
                        from: user.address,
                        to: process.env.REACT_APP_NFT_GAME_REGISTER,
                    },
                };
                await handleTransactionExWallet(
                    1,
                    dataSend,
                    handleResultTxDone
                )
            }
            return true;
        } catch (err) {
            console.log(err);
            setStatusModal({ isPending: false, isFailed: true });
        }
    };

    const checkTurnCanBuy = async (
        gameIndex
    ) => {

        try {
            const web3 = new Web3(RPC_BSC);
            const gameNFTCont = new web3.eth.Contract(
                nftGameABI,
                process.env.REACT_APP_NFT_GAME_REGISTER
            );
            const resData = await gameNFTCont.methods
                .remainingTurnCanBuy(getUser().address, gameInfo?.listGame[gameIndex - 1]?.game_index || gameInfo?.game_index)
                .call();
            return resData
        } catch (err) {
            console.log(err);
        }
        return 0
    }

    const buyTurnGame = async (
        quantity,
        price,
        callback = Function(),
        gameIndex
    ) => {
        try {
            setCallBackFunc(() => callback);
            setStatusTextModal({
                ...statusTextModal,
                pending: "Transaction pending",
                success: "Buy successfully!",
                fail: "Failed to buy",
                checkBalance: "Insufficient funds!",
            });
            setStatusModal({ isPending: true });
            const web3 = new Web3(RPC_BSC);
            const gameNFTCont = new web3.eth.Contract(
                nftGameABI,
                process.env.REACT_APP_NFT_GAME_REGISTER
            );
            const balance = await getMaxBalance(
                getUser()?.address,
                process.env.REACT_APP_USDT_CONTRACT_ADDRESS
            );
            if (balance.token < price) {
                await sleep(1000);
                setStatusModal({ isPending: false, isCheckBalance: true });
                return false;
            }
            const walletAppr = await handleApproveWallet({
                contractABI: tokenABI,
                contractAddress: process.env.REACT_APP_USDT_CONTRACT_ADDRESS,
                marketContractAddr: process.env.REACT_APP_NFT_GAME_REGISTER,
                setStatusModal: setStatusModal,
                price: price
            });
            if (walletAppr) {
                const approveData = gameNFTCont.methods
                    .buyTurnTCP(quantity, gameInfo?.listGame[gameIndex - 1]?.game_index || gameInfo?.game_index)
                    .encodeABI();
                const dataSend = {
                    signData: {
                        data: approveData,
                        from: user.address,
                        to: process.env.REACT_APP_NFT_GAME_REGISTER,
                    },
                };
                await handleTransactionExWallet(
                    1,
                    dataSend,
                    handleResultTransaction,
                    ACTION_SEND_TRANSACTION.buyTurn

                )
            }
            return true;
        } catch (err) {
            console.log(err);
            setStatusModal({ isPending: false, isFailed: true });
        }
    };

    const handleResultTxDone = (res) => {
        if (res?.isRefesh) {
            setStatusModal({ isPending: false, isSuccess: true });
        }
    }

    const checkMultiAllow = async (listItem) => {
        try {
            const tokenAddrCall = listItem.filter((value, index, self) =>
                index === self.findIndex((t) => (
                    t.tokenAddress === value.tokenAddress
                ))
            )
            const res = await Promise.all(
                tokenAddrCall.map(async (item) => {
                    return queryContractTerra(item.tokenAddress, {
                        approved_for_all: { owner: user.address },
                    });
                })
            );
            const multiMsg = []
            res.map((el, index) => {
                if (!el.operators.find((ele) => ele.spender === user.chainInfo.market_contract)) {
                    // multiMsg.push(new MsgExecuteContract(user.address, tokenAddrCall[index].tokenAddress, {approve_all: {
                    //     operator: user.chainInfo.market_contract,
                    // },}))
                }
            })
            if (multiMsg.length > 0) {

                return true
            }
        } catch (error) {
            throw (error.message || error);
        }
    }

    const checkOrder = async (order_id, chainInfo, callback = Function()) => {
        if (user.walletType === WALLET_TYPE.terra) {
            try {
                const resData = await queryContractTerra(user.chainInfo.market_contract, {
                    order_info: { order_id: order_id + "" },
                });
                if (resData?.quantity == 0 || !resData?.status) return false;
            } catch (err) {
                console.log(err);
            }
        } else if (user.walletType === WALLET_TYPE.aura) {
            try {
                const resData = await keplrService.queryContractSmart(user.chainInfo.market_contract, {
                    order_info: { order_id: order_id + "" },
                });
                if (resData?.quantity == 0 || !resData?.status) return false;
            } catch (err) {
                console.log(err);
            }
        } else {
            try {
                const web3 = new Web3(chainInfo?.rpc);
                const marketContract = new web3.eth.Contract(
                    marketABI,
                    chainInfo?.market_contract
                );
                const resData = await marketContract.methods
                    .orders(order_id)
                    .call();
                if (resData?.quantity == 0) return false;
            } catch (err) {
                console.log(err);
            }
        }
        return true;
    };

    const convertNFT = async (
        listItem,
        setStatusModal,
        callback = Function(),
        type
    ) => {
        setStatusModal({ isUnbox: true, type });
        setStatusTextModal({
            ...statusTextModal,
            pending: type === "BOX" ? "Unboxing" : "Converting",
            success: "Your new NFTs are being minted to your wallet. Please wait for around 15 minutes",
            fail: type === "BOX" ? "Fail to unboxing!" : "Fail to convert NFT!",
        });
        setCallBackFunc(() => callback);
        let chainInfo = chains?.find(el => el.sort_name == "BSC");
        if(user.walletType === WALLET_TYPE.aura) {
            chainInfo = chains?.find(el => el.sort_name == "Aura");
        }
        if(chainInfo.sort_name == "BSC") {

            try {
                let listAddress = [];
                let listTokenId = [];
                const tokenAddrCall = listItem.filter((value, index, self) =>
                    index === self.findIndex((t) => (
                        t.contract_address === value.contract_address
                    ))
                )
                await Promise.all(
                    tokenAddrCall.map(async (item) => {
                        await handleApproveAllWallet(
                            nftABI,
                            process.env.REACT_APP_CONVERT_NFT,
                            item.contract_address,
                            setStatusModal,
                            chainInfo
                        );
                    })
                );
                listItem.map((item) => {
                    listAddress.push(item.contract_address);
                    listTokenId.push(item.token_id + "");
                })
                const txRaw = await getTxConvertNt({
                    contract_address: listItem[0].contract_address,
                    list_token_id: listItem.map((el) => el.token_id),
                    type,
                });
                const dataSend = {
                    signData: {
                        data: txRaw,
                        from: user.address,
                        to: process.env.REACT_APP_CONVERT_NFT,
                    },
                };
                await handleTransactionExWallet(
                    1,
                    dataSend,
                    handleResultTransaction,
                    chainInfo,
                    ACTION_SEND_TRANSACTION.convertNFT
                )
                return true;
    
            } catch (err) {
                setStatusModal({ isUnbox: false, isPending: false, isFailed: true });
                console.log(err);
            }
        }
        if(chainInfo.sort_name == "Aura") {
            try {
                let listAddress = [];
                let listTokenId = [];
                const tokenAddrCall = listItem.filter((value, index, self) =>
                    index === self.findIndex((t) => (
                        t.contract_address === value.contract_address
                    ))
                )
                await Promise.all(
                    tokenAddrCall.map(async (item) => {
                        let isApprove = await keplrService.allOperators(
                            item.contract_address,
                            user.address,
                            process.env.REACT_APP_AURA_CONVERT_NFT
                        );
                        if (!isApprove) {
                            await keplrService.execute(user.address, item.contract_address, {
                                approve_all: {
                                    operator: process.env.REACT_APP_AURA_CONVERT_NFT,
                                },
                            });
                        }
                    })
                );
                listItem.map((item) => {
                    listAddress.push(item.contract_address);
                    listTokenId.push(item.token_id + "");
                })
                const txRaw = await getAuraTxConvertNt({
                    contract_address: listItem[0].contract_address,
                    list_token_id: listItem.map((el) => el.token_id),
                    type,
                });

                let rs = await keplrService.execute(
                    user.address,
                    process.env.REACT_APP_AURA_CONVERT_NFT,
                    {
                      convert: {
                        msg: {
                            boxes: txRaw.msg.boxes,
                            nfts: txRaw.msg.nfts,
                            timestamp: txRaw.timestamp + ""
                        },
                        signature: txRaw.signature
                      },
                    }
                );
                let tx = {
                    txHash: rs.transactionHash,
                    chain: chainInfo._id,
                    action: ACTION_SEND_TRANSACTION.convertNFT
                }
                await handleResultTransaction(tx);
                return true;
    
            } catch (err) {
                setStatusModal({ isUnbox: false, isPending: false, isFailed: true });
                console.log(err);
            }
        }
    };

    const handleTransactionExWallet = async (
        chainId = 1,
        dataReturn,
        callback,
        chainInfo,
        action
    ) => {
        await checkConnector()
        await handleTransactionExternalWallet(
            chainId,
            dataReturn,
            callback,
            action,
            chainInfo,
            library

        );
    };

    const handleApproveWallet = async ({
        contractABI,
        contractAddress,//token
        marketContractAddr,//market
        setStatusModal,
        chainInfo,
        price = 0
    }) => {
        await checkConnector()
        await checkNetwork(chainInfo)
        const res = await approveWallet(
            contractABI,
            contractAddress,//token
            marketContractAddr,//market
            setStatusModal,
            chainInfo,
            library,
            price
        );
        return res
    };

    const handleApproveFTWallet = async ({
        contractABI,
        contractAddress,//token
        spenderAddress,//market
        setStatusModal,
        chainInfo,
        quantity = 0
    }) => {
        await checkConnector()
        await checkNetwork(chainInfo)
        const res = await approveFTWallet(
            contractABI,
            contractAddress,//token
            spenderAddress,//market
            setStatusModal,
            chainInfo,
            library,
            quantity
        );
        return res
    };

    const handleApproveAllWallet = async (
        contractABI,//nftABI
        contractAddress,//market
        nftContractAddr,//nft
        setStatusModal,
        chainInfo
    ) => {
        console.log({ contractAddress, nftContractAddr })

        await checkConnector()
        await checkNetwork(chainInfo)
        await approveAllWallet(
            contractABI,//nftABI
            contractAddress,//market
            nftContractAddr,//nft
            setStatusModal,
            chainInfo,
            library
        );

        return true;
    };

    const checkConnector = async () => {
        if ((getWalletType() === USER_WALLET_TYPE.walletConnect || getWalletType() === USER_WALLET_TYPE.walletConnectAvax || getWalletType() === USER_WALLET_TYPE.walletConnectOkex) && !library) {
            // const connector = await connectWalletConnect()
            // connector.killSession();
            walletConnector.close()
            deactivate()
            logout();
            window.location.reload();
        }
    };

    const checkNetwork = async (requiredChainNetwork) => {
        await networkValidation({
            requiredChainNetwork: requiredChainNetwork,
            isMetamask: true,
        });
    }

    return {
        buyNFT,
        openMysteryBox,
        makeOffer,
        acceptOffer,
        cancelOffer,
        updateOffer,
        buyBundle,
        cancelBundle,
        cancelOrder,
        createOrder,
        updateOrder,
        buyMysteryBox,
        getTotalBuyBox,
        createBundle,
        checkOrder,
        checkBid,
        checkBundle,
        checkLogin,
        checkAccept,
        excQueryContract,
        registerNFTGame,
        checkRegister,
        checkTurnCanBuy,
        buyTurnGame,
        convertNFT,
        getBoxInfo,
        updateBundle,
        bridgeItem,
        estimateBridgeItem
    };
};

export default useBlockchainFunc;
