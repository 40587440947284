import React, { useEffect, useState } from "react";
import "./style.scss";
import {Tabs} from "antd";
import {
  getUserInfoGame
} from "../../../api";
import { useUIContext } from "../../../hook/AppContext";
import DeckTab from "./deck-tab";


const { TabPane } = Tabs;

function MyDeckTemplate(props) {
  const {
    getGameUserInfo,
  } = useUIContext();

  const [deskTab, setDeskTab] = useState("Defense");
  const [listAttackDeck, setListAttackDeck] = useState([]);
  const [listDefDeck, setListDefDeck] = useState([]);


  const onChange = e =>{
    setDeskTab(e)
  }

  const getInfoUser = async () => {
    const infoUser = await getUserInfoGame();
    setListAttackDeck(infoUser?.atk_deck)
    setListDefDeck(infoUser?.def_deck)
    getGameUserInfo()
  };


  useEffect(() =>getInfoUser(), [])

  return (
    <div className="my-deck">
      <Tabs
        activeKey={`${deskTab}`}
        onChange={onChange}
        className={"tab-deck"}
      >
        <TabPane key="Attack" tab="Attack" className="home-container">
          <DeckTab  listDeck={listAttackDeck} setListDeck={setListAttackDeck}  deskTab={deskTab}/>        
        </TabPane>
        <TabPane key="Defense" tab="Defense" className="home-container">
          <DeckTab  listDeck={listDefDeck} setListDeck={setListDefDeck}  deskTab={deskTab}/>        
        </TabPane>
      </Tabs>

      {/* {showSave && <CustomButtonField
        classNames="btn-card btn-save"
        color="green"
        size="medium"
        onClick={onClickSave}
      >
        {`Save Deck`}
      </CustomButtonField>} */}
   
    </div>

    
  );
}

export default MyDeckTemplate;


