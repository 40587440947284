import React, { useEffect, useState, useMemo } from "react";
import "./style.scss";
import { Row, Progress, Col, Form, Button, Statistic } from "antd";
import useBlockchainFunc from "../../../../ultis/blockchain";
import CustomModal from "../../../elements/modal";
import CustomInput from "../../../elements/input";
import CustomButton from"../../../elements/button";
import RankingGame from "../../../common/ranking-game"; 
import { useWindowResizeMobile } from "../../../../hook/ultis.hook";


const price = [
  {
    title: "#1~2",
    price: 0.25
  },
  {
    title: "#3~4",
    price: 0.5
  },
  {
    title: "#5~6",
    price: 0.75
  },
  {
    title: "#7~8",
    price: 1.0
  },
  {
    title: "#9~10",
    price: 1.25
  },
  {
    title: "#11~12",
    price: 1.5
  }
]

const { Countdown } = Statistic;

function RuleRecovery(props) {
  const {userInfo, getInfoUser,timeRecoveryTurn, setShowModal, showModal, isIngame, gameIndex} = props;
  const { checkTurnCanBuy, buyTurnGame } = useBlockchainFunc();
  const [quantity, setQuantity] = useState(0)
  const [turnCanBuy, setTurnCanBuy] = useState(12);
  const [showRanking, setShowRanking] = useState(false);
  const [isMobile] = useWindowResizeMobile(787);
  const [form] = Form.useForm();
  const rules = {
    quantity: [
      {validator: async(_, value) => {
          if (!value) {
            return Promise.reject(new Error("This field is required"));
          }
          if (value <= Number(turnCanBuy)) {
            return Promise.resolve();
          }
          return Promise.reject(new Error("Insufficient quantity"));
        },
      }
    ]

  }


  useEffect( async() => {
    const res = await checkTurnCanBuy(gameIndex);
    setTurnCanBuy(res)
  }, [showModal])

  const onChangeQuantity = e => {
    setQuantity(Number(e.target.value))
  }

  const onChangeMaxAmount = () =>{
    form.setFieldsValue({ quantity: turnCanBuy });
    setQuantity(Number(turnCanBuy))
  }

  const onClick = () =>{
    setShowModal(true)
  }

  const onFinish = (val) =>{
     getInfoUser()
  }

  const onSubmitForm = async() => {
    await buyTurnGame(quantity,renderTotalPrice,getInfoUser, gameIndex)
    setShowModal(false)
    form.resetFields();
    setQuantity(0)
  }
  const onRules = () => {
    window.open(
      "https://marketplace-monsterra.gitbook.io/marketplace-monsterra/", "_blank");
  }
  const renderEnergy = useMemo(()=>{
    if(userInfo?.address){
      const turn_time = 7200000 
      const total_time = userInfo?.time_fully_recovery_turn *1000
      const remainder = (total_time - Date.now()-1000) % turn_time
      const quotient = (total_time - Date.now()-1000)/ turn_time
      let turn = total_time-1000  < Date.now() ? 5 : 5 - parseInt(quotient)
      turn =  remainder > 0 ? turn -1 : turn
      const totalTurn =  turn + userInfo?.bonus_turn
      const percent = totalTurn*100/5;
      const icon = percent >=100 ? 100 : percent 
      const time_countdown = remainder > 0 ? (total_time - turn_time * parseInt(quotient)) : Date.now() + turn_time

      return (
        <div>
          {/* <div className="elo-text">Elo: {userInfo?.elo}</div> */}
          <div className="progress-container">
            <img className="recovery-icon" src={`/images/recovery-${icon ? icon : 0}.svg`}/>
            <Progress className="progress-time" steps={5} trailColor="#44240E"  percent={percent} strokeColor="linear-gradient(180deg, #F9C744 0%, #DF8C00 100%)"/>
            <img className="add-recovery" src="/images/add-btn.svg" onClick={onClick}/>
          </div>
          <div className="energy-container">
            <div>Energy {totalTurn ? totalTurn : 0}/5</div>
            {percent <100 && <Countdown
              className="time-countdown"
              value={time_countdown}
              format={`HH : mm : ss`}
              onFinish={onFinish}
            />}
            {percent <100 &&<div >+1 <img className="energy-container__note" src={`/images/recovery-100.svg`}/></div>}
          </div>
        </div>
      )}
  },[userInfo])

  const renderTotalPrice = useMemo(() =>{
    let totalPrice = 0
     for(let i =0; i< quantity; i++){
       let ticket = 12- turnCanBuy + i +1
       switch(ticket){
        case 1:
        case 2: 
          totalPrice = totalPrice + price[0].price;
          break;
        case 3:
      case 4: 
          totalPrice = totalPrice + price[1].price;
          break;
        case 5:
        case 6: 
          totalPrice = totalPrice + price[2].price;
          break;
        case 7:
        case 8: 
          totalPrice = totalPrice + price[3].price;
          break;
        case 9: 
        case 10: 
          totalPrice = totalPrice +price[4].price;
          break;
        case 11:
        case 12: 
          totalPrice = totalPrice + price[5].price;
          break;
        default: 
          totalPrice = 0;
          break;
       }
     }
     return totalPrice
  },[quantity, turnCanBuy]);

  const renderPlay = useMemo(()=> <Col>
            Play <div className="left-number">{12-turnCanBuy + userInfo?.bonus_turn}</div>
            <img src="/images/double-arrow.svg"/>
            <div className="right-number">{12 - turnCanBuy + quantity + userInfo?.bonus_turn}</div>
          </Col>,[quantity, turnCanBuy])

  const renderListPrice = (item) =>{
    return <Row className="price-container">{price.map(item =><Col sm={8} xs={12} className="item-price">{item.title}<div>{item.price}</div> USDT</Col>)}</Row>
  }

  return (
    <div className="rule-recovery">
      {!isIngame && <>
      <div className="win-streak">Win streak: {userInfo?.win_streak}</div>
      <div
          className="btn-block big-txt"
          onClick={onRules}
      >
          Rules & Rewards
      </div>
      {isMobile &&<div className="btn-block" onClick={()=>setShowRanking(true)}>Ranking</div>}
      {renderEnergy}
      </>}
      <CustomModal
        visible={showModal}
        footer={null}
        onCancel={() => setShowModal(false)}
        closeIcon={<></>}
        className="recovery-modal"
      >
        <div className="recovery-modal-title">
          <img src="/images/energy.png"/>
          Energy recovery
        </div>

        <div className="recovery-modal-note">
        You can purchase addition Energy up to 12 time(s) per day. <br/> Purchase details are as follows:        
        </div>
        {(renderListPrice())}
        <Form form={form} onFinish={onSubmitForm}>

          <Row className="quantity-container">
            <Col className="purchase-attempts">
              Purchase attempts left today:
              <div>{turnCanBuy}/12</div>
            </Col>
            <Col>
              <Row className="amount-row">
                <Col span={18} className="left-col">
                  Quantity
                  <Form.Item className="m-0 mb-0" name="quantity" rules={rules.quantity}>
                    <CustomInput
                      classNames={["amount-item"]}
                      min={0}
                      step={1}
                      type="number"
                      onChange={onChangeQuantity}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Button
                    className="max-button"
                    onClick={onChangeMaxAmount}
                    type="button"
                  >
                    {"Max"}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="estimate-container">
            {/* {renderPlay} */}
            <Col className="estimate-container__price">
              Estimated cost:
               <div>{renderTotalPrice} USDT</div>
            </Col>

          </Row>
          <div className={`btn-block bottom-modal-btn`}>
            <CustomButton
              classNames="red-btn btn-item btn-popup"
              children={"Cancel"}
              shape="circle-custom"
              onClick={() => {
                setShowModal(false);
                form.resetFields();
                setQuantity(0)

              }}
            />
            <CustomButton
              classNames="orange-btn btn-item btn-popup"
              children={"Purchase"}
              shape="circle-custom"
              htmlType="submit"
              disabled={turnCanBuy == 0}
            />
          </div>
          </Form>


      </CustomModal>
      <CustomModal
            visible={showRanking}
            footer={null}
            onCancel={() => setShowRanking(false)}
            className="ranking-modal"
          >
            <RankingGame classNames="modal-content" userInfo={userInfo}/>
      </CustomModal>
    </div>
  )

}

export default RuleRecovery