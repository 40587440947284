import Search from "antd/lib/transfer/search";
import { useEffect, useState } from "react";
// import { initPlayer, queryImage, removeQuery } from "./player";
import startGame, { queryImage, removeQuery } from "../../gamefix/game";

function MongenImage({ dna, className, dataSize }) {
  const [img, setImg] = useState(null);
  const [id, setId] = useState(0);

  useEffect(() => {
    startGame();
  }, []);

  useEffect(() => {
    if (id) {
      removeQuery(id);
    }
    let newId = queryImage(JSON.parse(dna), (img) => {
      setImg(img);
    });
    setId(newId);
    return () => {};
  }, [dna]);
  return img === null ? (
    <div>
      <img
        className="loading-icon img-loading"
        src="/images/loading-icon-2.png"
        data-size={dataSize}
        alt="loading"
      />
    </div>
  ) : (
    <img
      className={`max-h-full mx-auto -translate-y-10 ` + className}
      src={img}
      alt="mongen"
    />
  );
}

export default function Mongen({ dna, lock, className, dataSize }) {
  if (dna.length === 2) {
    return <></>;
  } else {
    return (
      <MongenImage
        dna={JSON.stringify(dna)}
        className={className}
        dataSize={dataSize}
      />
    );
  }
}
