import React, { useEffect, useState } from "react";
import "./style.scss";
import {Table} from 'antd';
import { useHistory } from "react-router-dom";
import moment from "moment";
import { getListGame,getListMysteryBox }  from "../../../api";
import { withContext } from "../../../hook/AppContext";
import { useWindowResizeMobile } from "../../../hook/ultis.hook";
import CustomButtonField from "../../elements/button";
import { now } from "lodash";


function BoxesTemplate(props) {
  const [campaign, setCampaign] = useState()
  const [isMobile] = useWindowResizeMobile();
  const history = useHistory();
  const column = [
    {
      title: "Box name",
      dataIndex: "name",
      key: "name",
      width:  "40%",
      render: (name) => <div className="from-col">{name}</div>,
      ellipsis: true,
    },

    {
      title: "Quantity",
      dataIndex: "total_supply",
      key: "total_supply",
      render: (total_supply) => <>{total_supply}</>,
      width: "20%",
    },

    {
      title: "Limit per user",
      dataIndex: "purchase_limit",
      key: "purchase_limit",
      render: (purchase_limit, record) => <>{record?.total_supply == purchase_limit ? "Unlimited" : purchase_limit}</>,
      width:   "25%",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (price) => <>{price} <img className="token-image" src="/images/token/mstr.svg"/></>,
      width:   "15%",
    }
  ];

  const getListCampaign = async () => {
    const resCol = await getListGame();
    const listCampaign = await getListMysteryBox({
      game_id: resCol[0]._id,
    });
    setCampaign(listCampaign[0])
  }

  useEffect(getListCampaign, [])

  const onClickDetail = () => {
    history.push(`/detail?box_id=${campaign?._id}`);
  }
return (
    <div className="bg-banner">
      <div className="box-content">
        {campaign?.campaign_name}
        <div className="box-content-time">Sale time: {moment
                          .utc(moment.unix(campaign?.start_time/1000))
                          .format("h:mm A UTC , MMM DD")} - {moment
                          .utc(moment.unix(campaign?.end_time/1000))
                          .format("h:mm A UTC , MMM DD")}</div>
        <Table
          className="box-table"
          columns={column}
          dataSource={campaign?.box_info}
          rowKey="id"
          pagination={false}
        />
       <CustomButtonField
          classNames="box-content-btn"
          color="green"
          onClick={onClickDetail}
        >
          {"Detail"}
        </CustomButtonField>
      </div>

    </div>
  );
}

export default withContext(BoxesTemplate);
