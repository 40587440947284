import React, { useEffect } from "react";
import P2PMarketTemplate from "../../components/templates/p2p-market";
import SiteLayout from "../../layouts/site-layout";
import "./style.scss";

function P2PMarket(props) {
    return (
        <SiteLayout styleMarket="master-layout__market">
            <P2PMarketTemplate />
        </SiteLayout>
    );
}

export default P2PMarket;
