import React, { useEffect, useState, useMemo, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Form, Table, Tabs } from "antd";
import S3Image from "../../../../common/s3-image";
import nftABI from "../../../../../constants/ABI/nft.json";
import Web3 from "web3";
import { withContext } from "../../../../../hook/AppContext";
import {
  getUser,
  queryContractTerra,
  RPC_BSC,
} from "../../../../../ultis/common";
import { TYPE_CARD, WALLET_TYPE } from "../../../../../constants";
import Star from "../../../../elements/star";
import DNAItem from "../../dna-item";
import CardComponent from "../../../../common/card-component";
import MutateItem from "../../mutate-item";

function DNATab(props) {
  const { nftData, chain } = props;
  const history = useHistory();
  const columnsDNA = [
    {
        title: "Body part",
        dataIndex: "name",
        key: "name",
        render: (text, record) => {
          return <DNAItem name={text} />;
        },
        width: "17%",
        ellipsis: true,
      },
    {
      title: "Mutated stats",
      dataIndex: "name",
      key: "name",
      render: (text, record) => {
        return <MutateItem value={nftData} text={text} />;
      },
      width: "20%",
      ellipsis: true,
    },
    {
      title: "Self",
      dataIndex: "name",
      key: "name",
      render: (text, record) => {
        return <DNAItem value={record.mongen} rarity={record?.mongenrarity} />;
      },
      width: "21%",
      ellipsis: true,
    },

    {
      title: "Father",
      dataIndex: "name",
      key: "name",
      render: (text, record) => {
        return <DNAItem value={record.r1} rarity={record?.r1rarity} />;
      },
      width: "21%",
    },

    {
      title: "Mother",
      dataIndex: "name",
      key: "name",
      render: (text, record) => {
        return <DNAItem value={record.r2} rarity={record?.r2rarity} />;
      },
      width: "21%",
    },
  ];

  const renderLinkImage = (image) => {
    const array = image?.split("/");
    var newImage = "/images";
    for (let i = 3; i < array?.length; i++) {
      newImage = newImage + "/" + array[i];
    }
    return newImage.replace("+", "_");
  };

  const checkNft = async (token) => {
    try {
      if (getUser().walletType === WALLET_TYPE.terra) {
        const result = await queryContractTerra(
          localStorage.getItem("MongenContract"),
          { nft_info: { token_id: token + "" } }
        );
        if (result.token_uri) return true;
      } else {
        const web3 = new Web3(RPC_BSC);
        const contractInstance = new web3.eth.Contract(
          nftABI,
          localStorage.getItem("MongenContract")
        );
        const tokenURI = await contractInstance.methods.tokenURI(token).call();
        if (tokenURI) return true;
      }
    } catch (err) {
      return false;
    }
  };
  const renderParent = useMemo(() => {
    if (!nftData?.parents) return;
    let listAb = [];
    nftData?.parents.forEach((val, index) => {
      const idParent = val?.attributes?.find(
        (el) => el?.trait_type == "ID"
      )?.value;
      let item = (
        <CardComponent
          type={TYPE_CARD.MON}
          key={index}
          nft_data={val}
          token_id={idParent}
          isDetail
          checkNft={checkNft}
          chain={nftData?.chain}
        />
      );

      listAb.push(item);
    });
    return listAb;
  }, [nftData?.parents]);

  const goDetailPage = (val) => {
    const dnaData = nftData?.details?.dna[0];
    let contractAddr = nftData?.contract;
    if (!dnaData.mongen && dnaData.r1 && dnaData.r2) {
      contractAddr = localStorage.getItem("MongenContract");
    }
    if (val.token) {
      history.push(`/detail?contract=${contractAddr}&token=${val.token}`);
    }
  };

  const renderDNA = useMemo(() => {
    console.log({ data: nftData?.details });
    if (!(nftData?.details?.dna && nftData?.details?.dna[0])) return;
    const dnaData = nftData?.details?.dna[0];
    let listAb = dnaData.r1 || dnaData.mongen;
    Object.keys(dnaData).forEach((el) => {
      if (dnaData[el]) {
        dnaData[el].forEach((ele, index) => {
          listAb[index][el] = ele.value;
          listAb[index][`${el}rarity`] = ele.rarity;
        });
      }
    });
    let item = (
      <Table
        className={`table-custom dna-list`}
        columns={columnsDNA}
        dataSource={listAb}
        rowKey="id"
        pagination={false}
      />
    );

    return item;
  }, [nftData?.details?.dna]);

  return (
    <>
      {nftData?.details?.dna && (
        <div className="item-container">
          <div className="detail-item">DNA</div>
          <div className="dna-list">
            <div className="item-box offer-list">{renderDNA}</div>
          </div>
        </div>
      )}
      {nftData?.parents && (
        <div className="item-container">
          <div className="detail-item">Parents</div>
          <div className="parent-block item-box">{renderParent}</div>
        </div>
      )}
      {nftData?.details?.mark && (
        <div className="item-container">
          <div className="detail-item">Mark</div>
          <div className="item-box dna-list">
            <div className="mark-name">{nftData?.details?.mark.name}</div>
            <div className="mark-container">
              <img src={renderLinkImage(nftData?.details?.mark.icon)} />
              <div className="mark-container__description">
                {nftData?.details?.mark.description}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default withContext(DNATab);
