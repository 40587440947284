import React, { useEffect, useState } from "react";
import "./style.scss";
import moment from 'moment';
import { withContext } from "../../../../hook/AppContext";

function CountDownBox(props) {
  const { time_end, setSoldOut, soldOut } = props;
  const [time, setTime] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0
  })

   useEffect(() => {
     if (!soldOut){
       setTimeout(() => {
         const now = moment().unix();
         const countdown = time_end - now;
         if (countdown >= 0) {
           const days = Math.floor(countdown / 60 / 60 / 24);
           const hours = Math.floor((countdown / 60 / 60) % 24);
           const minutes = Math.floor((countdown / 60) % 60);
           const seconds = Math.floor(countdown % 60);

           setTime({
             hours: Number(hours) + Number(days * 24),
             minutes: minutes,
             seconds: seconds
           })

         } else {
           setSoldOut(true)
         }
       }, 1000);
     }  
  })
  

  return (
    <div className="box-countdown">
      <div className="time-text">
        <div className="time-value">{time.hours}</div>
        HOURS
      </div>
      <div className="time-text">
        <div className="time-value">{time.minutes}</div>
        MINUTES
      </div>
      <div className="time-text">
        <div className="time-value">{time.seconds}</div>
        SECONDS
      </div>
    </div>
  );
}

export default withContext(CountDownBox);
