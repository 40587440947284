import { Col, Row, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { getStatistic } from "../../../../api";
import { withContext } from "../../../../hook/AppContext";

const STATISTIC = [
  {
    title: "Lifetime",
    value: 1000,
  },
  {
    title: "Last 24h",
    value: 1,
  },
  {
    title: "7 days",
    value: 7,
  },
  {
    title: "30 days",
    value: 30,
  },
];
const { TabPane } = Tabs;

function Statistic(props) {
  const [activeTab, setActiveTab] = useState(STATISTIC[0].value);
  const [listStatistic, setListStatistic] = useState();
  const { chains } = props;

  useEffect(() => {
    const fetch = async () => {
      const list = await Promise.all(
        STATISTIC.map(async (el) => {
          if (!chains?.length) {
            const params = {
              day: el.value,
            };
            const res = await getStatistic(params);
            return { ...res, ...el };
          }

          let data = await Promise.all(
            chains.map(async (chain) => {
              const params = {
                chain: chain._id,
                day: el.value,
              };
              const res = await getStatistic(params);
              return { ...res, ...el };
            })
          );

          let format = {
            ...data[0],
            total_sold: data.reduce((prev, cur) => {
              let curTotalSold = cur.total_sold;
              curTotalSold.forEach((item) => {
                let idx = prev.findIndex((i) => i.name === item.name);
                if (idx > -1) {
                  prev[idx] = {
                    ...prev[idx],
                    total_sold: prev[idx].total_sold + item.total_sold,
                  };
                } else {
                  prev.push(item);
                }
              });

              return prev;
            }, []),
          };

          return format;
        })
      );
      setListStatistic(list);
    };
    fetch();
  }, []);

  const onChangeTab = (value) => {
    setActiveTab(value);
  };

  const renderStatisticItem = (el) => {
    return (
      <Row className="statistic-table-container">
        <Col xs={24} md={6} className="statistic-table-item">
          <img src={`/images/total-sale.svg`} />
          <div>
            TOTAL SALE
            <div className="statistic-table-item__total">
              {parseInt(el?.total_transaction)}
            </div>
          </div>
        </Col>
        <Col xs={24} md={6} className="statistic-table-item">
          <img src={`/images/total-volume2.svg`} />
          <div>
            TOTAL VOLUME
            <div className="statistic-table-item__total">
              {parseInt(el?.total_trading_volume)}
            </div>
          </div>
        </Col>
        <Col xs={24} md={6} className="statistic-table-item">
          <img src={`/images/landplot-sold.svg`} />
          <div>
            LANDPLOT SOLD
            <div className="statistic-table-item__total">
              {parseInt(el?.total_sold[0]?.total_sold || 0)}
            </div>
          </div>
        </Col>
        <Col xs={24} md={6} className="statistic-table-item">
          <img src={`/images/mongen-sold.svg`} />
          <div>
            MONGEN SOLD
            <div className="statistic-table-item__total">
              {parseInt(el?.total_sold[1]?.total_sold || 0)}
            </div>
          </div>
        </Col>
      </Row>
    );
  };
  const renderStatistic = () => {
    return listStatistic?.map((el) => (
      <TabPane
        className="home-table-item "
        tab={<>{el?.title}</>}
        key={el.value}
      >
        {renderStatisticItem(el)}
      </TabPane>
    ));
  };

  return (
    <Tabs
      activeKey={`${activeTab}`}
      onChange={onChangeTab}
      className={"tab-home statistic-tab"}
      destroyInactiveTabPane={true}
    >
      {renderStatistic()}
    </Tabs>
  );
}

export default withContext(Statistic);
