import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useEffect,
} from "react";
import ProgressModal from "../components/common/progress-modal";
import { useHistory, useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import { initiateSocket, socket } from "../ultis/socket";
import { EVENT_SOCKET } from "../constants";
import {
  getBalanceToken,
  getUser,
  sleep,
} from "../ultis/common";
import queryString from "query-string";
import {
  getListSeasons,
  getListGameNFT,
  getRanking,
  getUserInfoGame,
  getListTokens,
} from "../api";

const UIContext = createContext();

export function useUIContext() {
  return useContext(UIContext);
}

export const UIConsumer = UIContext.Consumer;

export function AppUIProvider({ children }) {
  const [user, setuser] = useState(JSON.parse(localStorage.getItem("user")));
  const [chains, setchains] = useState(
    JSON.parse(localStorage.getItem("chains"))
  );
  const [listTokens, setTokens] = useState();
  const [authToken, setauthToken] = useState(localStorage.getItem("token"));
  const [gameInfo, setgameInfo] = useState(
    JSON.parse(localStorage.getItem("gameinfo"))
  );
  const [refreshToken, setrefreshToken] = useState(
    localStorage.getItem("refreshToken") || null
  );
  const [isShowingLoginPopup, setIsShowingLoginPopup] = useState(false);
  const [callBackFunc, setCallBackFunc] = useState();
  const [callBackGameFunc, setCallBackGameFunc] = useState();
  const intl = useIntl();
  const [balanceWallet, setBalanceWallet] = useState([]);
  const [resetNoti, setResetNoti] = useState(true);
  const [isRegister, setisRegister] = useState(
    localStorage.getItem("isRegister")
  );
  const history = useHistory();
  const location = useLocation();

  const isAuthenticatedUser = useMemo(() => {
    return !!authToken;
  }, [authToken]);

  //isPending: true,isFailed, isSuccess
  const [statusModal, setStatusModalProgress] = useState();
  //pending, success, fail
  const [statusTextModal, setStatusTextModal] = useState({
    pending: "Transaction pending",
    success: "Successful!",
    fail: "Fail!",
  });

  const setUser = (data) => {
    if (user) {
      setuser({ ...user, ...data });
    } else {
      setuser(data);
    }
    localStorage.setItem("user", JSON.stringify(data || ""));
  };

  const setChains = (data) => {
    setchains(data);
    localStorage.setItem("chains", JSON.stringify(data || ""));
  };

  const getListToken = async () => {
    if (getUser()?.chainInfo?._id) {
      const dataToken = await getListTokens({
        chain: getUser()?.chainInfo?._id,
      });
      setTokens(dataToken);
      localStorage.setItem("tokens", JSON.stringify(dataToken || ""));
    }
  };

  useEffect(() => getListToken(), [getUser()?.chainInfo?._id]);

  const setGameInfo = (data) => {
    setgameInfo(data);
    localStorage.setItem("gameinfo", JSON.stringify(data || ""));
  };

  const setAuthToken = (auth) => {
    setauthToken(auth);
    localStorage.setItem("token", auth);
  };

  const setRefreshToken = (auth) => {
    setrefreshToken(auth);
    localStorage.setItem("refreshToken", auth);
  };

  const setIsRegister = (check) => {
    setisRegister(check);
    localStorage.setItem("isRegister", check);
  };

  const logout = () => {
    localStorage.clear();
    setauthToken();
    setrefreshToken();
    setuser();
    document.cookie = `src=`;
    history.push("/connect-wallet");
  };

  const getQueryUrl = () => {
    const query = queryString.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    return query;
  };

  const getRank = async (activeGame = 0) => {
    const listG = await getListGameNFT();
    const gameActive = listG.find((el) => el.is_active);
    const listS = await getListSeasons({ game_id: gameActive[0]?._id });
    const listS2 = await getListSeasons({ game_id: listG[1]?._id });
    const rankU = await getRanking({ season_id: listS[0]?._id });
    setGameInfo({
      ...gameInfo,
      ...listG[1].game_info[0],
      ...rankU.user_ranking,
      seasons: { 0: listS, 1: listS2 },
      seasonId: listS[0]._id,
      game_index: gameActive.game_index,
      listGame: listG,
    });
    return {
      ...listG[0].game_info[0],
      ...rankU.user_ranking,
      seasons: { 0: listS, 1: listS2 },
      seasonId: listS[0]._id,
      game_index: gameActive.game_index,
      listGame: listG,
    };
  };

  const getGameUserInfo = async () => {
    const infoUser = await getUserInfoGame();
    setGameInfo({
      ...gameInfo,
      infoUser,
    });
    return {
      ...gameInfo,
      infoUser,
    };
  };

  const t = (message) => {
    return intl.formatMessage({ id: message });
  };

  useEffect(() => {
    if (user) {
      let nSocket;
      if (socket?.connected === undefined) {
        nSocket = initiateSocket(user?.address);
      } else {
        nSocket = socket;
      }
      EVENT_SOCKET.map((el) => {
        nSocket.off(el);
        nSocket.on(el, (payload) => {
          if (el == "updateNotification") setResetNoti(true);
          else checkSocketResult(payload);
        });
      });
    }
  }, [callBackFunc, user, callBackGameFunc]);

  useEffect(() => {
    if (!authToken) {
      if (history.location.pathname != "/connect-wallet") {
        history.push("/connect-wallet");
      }
    }

    history.listen((location) => {
      if (!localStorage.getItem("token")) {
        if (location.pathname != "/connect-wallet") {
          history.push("/connect-wallet");
        }
      }
    });
  }, [authToken]);

  useEffect(() => {
    if (chains && chains[0].is_maintenance) {
      if (history.location.pathname != "/maintain") {
        history.push("/maintain");
      }
    } else if (history.location.pathname == "/maintain") history.push("/home");
  }, [chains, history.location.pathname]);

  const setStatusModal = (value) => {
    if (statusModal?.isSuccess && value?.isSuccess) {
      return;
    }
    setStatusModalProgress(value);
  };

  const checkSocketResult = async (payload) => {
    if (
      payload?.data?.txHash?.toLowerCase() ===
      localStorage.getItem("txHash")?.toLowerCase()
    ) {
      await sleep(400);
      if (callBackFunc) {
        callBackFunc(payload?.data);
        setCallBackFunc();
      }
      if (callBackGameFunc) {
        callBackGameFunc();
        setCallBackGameFunc();
      }
      if (payload?.data?.nftdata?.length > 0) {
        setStatusModal({ isUnbox: false, isPending: false });
      } else {
        getBalanceWallet();
        console.log("isUnbox");
        setStatusModal({ isUnbox: false, isPending: false, isSuccess: true });
      }
    }
  };

  const getBalanceWallet = async () => {
    try {
      const res = await getBalanceToken(getUser()?.address, chains);
      setBalanceWallet(res);
    } catch (err) {
      console.log({ err });
      setStatusModal({ isFailed: true });
      setStatusTextModal({
        fail: "Can’t connect to the blockchain node provider!",
      });
    }
  };

  const value = {
    user,
    authToken,
    setUser,
    setAuthToken,
    isShowingLoginPopup,
    setIsShowingLoginPopup,
    refreshToken,
    setRefreshToken,
    isAuthenticatedUser,
    logout,
    setStatusModal,
    statusModal,
    statusTextModal,
    setStatusTextModal,
    history,
    t,
    setCallBackFunc,
    balanceWallet,
    getBalanceWallet,
    setChains,
    chains,
    resetNoti,
    setResetNoti,
    setGameInfo,
    gameInfo,
    location,
    getQueryUrl,
    getRank,
    getGameUserInfo,
    isRegister,
    setIsRegister,
    listTokens,
    setCallBackGameFunc,
  };

  return (
    <UIContext.Provider value={value}>
      <div className="app-element">{children}</div>
      {statusModal && (
        <ProgressModal
          statusModal={statusModal}
          title={statusTextModal}
          setStatusModal={setStatusModal}
        />
      )}
    </UIContext.Provider>
  );
}

export const withContext = (Component) => {
  return (props) => {
    return (
      <UIContext.Consumer>
        {(globalState) => {
          return <Component {...globalState} {...props} />;
        }}
      </UIContext.Consumer>
    );
  };
};
