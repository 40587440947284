import React, { useMemo, useState } from "react";
import { Row, Col } from "antd";
import "./style.scss";
import {getTokens} from "../../../../../ultis/common";
import { withContext } from "../../../../../hook/AppContext";

function Checkout(props) {
  const { orderInfor, price, nftData } = props;
  console.log("nftData",orderInfor);
  const renderContent = useMemo(()=>{ 
    const obj = getTokens().find(el => el.contract_address?.toLowerCase() == orderInfor?.payment_token?.toLowerCase() )

    return<Col className="value d-block">
              {`${price || orderInfor?.price} ${obj?.sort_name}`}
          </Col>},[orderInfor])
  const renderNetwork = useMemo(()=>{ 
    let chain = orderInfor?.chain?.sort_name ?  orderInfor?.chain : nftData?.chain
    return<Col >
            <img  src={`/images/chain/${chain?.sort_name?.toLowerCase()}.svg`}/>
                {chain?.sort_name}
          </Col>},[nftData])
  return (
    <>
      <div className="item-container modal-item-container">
        <div className="item-box offer-modal-box">
          <div className="info-item">
            <Row justify="space-between">
              <Col className="name">Price</Col>
             {renderContent}
            </Row>
             <Row className="chain-image" justify="space-between">
              <Col className="name">Network</Col>
             {renderNetwork}
            </Row>
            
          </div>
          {orderInfor?.isItems && <div className="info-item quantity">
            <div className="name">Quantity</div>
             {orderInfor?.quantity}
          </div>}
        </div>
        {orderInfor?.fee && <div>Fee: {orderInfor?.fee}%</div>}
          {orderInfor?.total &&<div className="receive-note">
                  You will receive{" "} <span className="received-mon"> {orderInfor?.total}</span>{" "}
                  {getTokens().find(el => el.contract_address?.toLowerCase() == orderInfor?.payment_token?.toLowerCase())?.sort_name}
                  </div>}
      </div>
    </>
  );
}

export default withContext(Checkout);
