import React, { useEffect, useMemo, useState } from "react";
import CustomModal from "../index";
import { Row, Col, Button, Form } from "antd";
import S3Image from "../../../common/s3-image";
import CustomButton from "../../../elements/button";
import Mongen from "../../../elements/static-anim/mongen";
import Decor from "../../../elements/static-anim/decor";
import "./style.scss";
import CheckoutBox from "./checkout-box";
import { withContext } from "../../../../hook/AppContext";
import MakeOffer from "./make-offer";
import Checkout from "./checkout";
import AcceptOffer from "./accept-offer";
import CheckoutEvol from "./checkout-evol";
import { useWindowResizeMobile } from "../../../../hook/ultis.hook";

function GeneralModal(props) {
  const {
    callback,
    isShowingPopup,
    setIsShowingPopup,
    nftData,
    initialValues = {},
    onFinish,
    orderInfor,
    t,
    type,
    offerPrice,
    balanceWallet,
    bidInfor,
    setUpdateOffer = () => {},
  } = props;
  const [form] = Form.useForm();
  const [inputForm, setInputForm] = useState(0);
  const [rules, setRules] = useState({
    amount: [
      {
        validator: async (_, value) => {
          if (!value) {
            return Promise.reject(new Error("Amount must be greater than 0"));
          }
          if (value <= 0) {
            return Promise.reject(new Error("Amount must be greater than 0"));
          }
        },
      },
    ],
  });

  useEffect(() => {
    if (type === "open-box") {
      form.setFieldsValue({ amount: 1 });
    }

    return;
  }, []);

  useEffect(() => {
    if (bidInfor?.price) {
      form.setFieldsValue({ amount: bidInfor.price });
      // form.setFieldsValue({token: bidInfor.payment_token})
    }
  }, [bidInfor]);

  const [info, setInfo] = useState({ title: "" });
  const [isMobile] = useWindowResizeMobile(787);

  useEffect(() => {
    switch (type) {
      case "checkout":
        setInfo({ ...info, title: "Checkout" });
        setInputForm(<Checkout orderInfor={orderInfor} nftData={nftData} />);
        return;
      case "confirm":
        setInfo({ ...info, title: "Confirm Sale" });
        setInputForm(<Checkout orderInfor={orderInfor} />);
        return;
      case "accept-offer":
        setInputForm(<AcceptOffer nftData={nftData} orderInfor={orderInfor} />);
        setInfo({ ...info, title: "Accept the offer?" });
        return;
      case "make-offer":
        setInputForm(
          <MakeOffer
            initialValues={initialValues}
            orderInfor={orderInfor}
            rules={rules}
            setRules={setRules}
            form={form}
            offerPrice={0}
            balanceWallet={balanceWallet}
          />
        );
        setInfo({ ...info, title: "Make offer" });
        return;
      case "change-offer":
        setInputForm(
          <MakeOffer
            initialValues={initialValues}
            orderInfor={orderInfor}
            rules={rules}
            setRules={setRules}
            form={form}
            offerPrice={offerPrice || 0}
            balanceWallet={balanceWallet}
            bidInfor={bidInfor}
          />
        );
        setInfo({ ...info, title: "Change offer" });
        return;
      case "cancel-offer":
        setInputForm(<Checkout price={offerPrice} orderInfor={orderInfor} />);
        setInfo({ ...info, title: "Cancel offer" });
        return;
      case "cancel-listing":
        setInputForm(<Checkout orderInfor={orderInfor} />);
        setInfo({ ...info, title: "Cancel Listing" });
        return;
      case "remove-from-sale":
        setInputForm(<Checkout orderInfor={orderInfor} />);
        setInfo({ ...info, title: "Remove from sale" });
        return;
      case "checkout-box":
        setInputForm(
          <CheckoutBox
            nftData={nftData}
            initialValues={initialValues}
            orderInfor={orderInfor}
            rules={rules}
            setRules={setRules}
            form={form}
            type={type}
          />
        );
        setInfo({ ...info, title: "Checkout" });
        return;
      case "checkout-evol":
        setInfo({ ...info, title: "Checkout" });
        setInputForm(
          <CheckoutEvol
            nftData={nftData}
            initialValues={initialValues}
            orderInfor={orderInfor}
            rules={rules}
            setRules={setRules}
            form={form}
          />
        );
        return;
      case "open-box":
        setInputForm(<Checkout orderInfor={orderInfor} />);
        setInfo({ ...info, title: "Open box" });
        return;
      default:
        return;
    }
  }, [type, balanceWallet, orderInfor]);
  const renderRarity = (value) => {
    switch (value) {
      case "Common":
        return <div className="attribute-container__rarity gray">Common</div>;
      case "Uncommon":
        return <div className="attribute-container__rarity blue">Uncommon</div>;
      case "Rare":
        return <div className="attribute-container__rarity yellow">Rare</div>;
      case "Epic":
        return <div className="attribute-container__rarity red">Epic</div>;
      case "Legendary":
      default:
        return (
          <div className="attribute-container__rarity pink">Legendary</div>
        );
    }
  };
  const renderLinkImage = (image) => {
    const array = image?.split("/");
    var newImage = "/images";
    for (let i = 3; i < array?.length; i++) {
      newImage = newImage + "/" + array[i];
    }
    return newImage.replace("+", "_");
  };

  const renderImage = () => {
    if (
      isMobile &&
      (nftData?.dna_raw?.length > 2 ||
        nftData?.meta?.decoration_list?.length > 0)
    ) {
      return (
        <S3Image className="item-image modal-image" src={nftData?.image} />
      );
    }
    if (nftData?.dna_raw?.length > 2)
      return (
        <Mongen
          lock={[]}
          dna={nftData?.dna_raw}
          className="item-image modal-image"
        />
      );
    if (nftData?.meta?.decoration_list?.length > 0)
      return (
        <div className="card-decor__image">
          <Decor
            rarity={nftData?.meta?.rarity}
            decoration_list={nftData?.meta?.decoration_list}
            className=""
          />
        </div>
      );

    return (
      <S3Image
        src={
          nftData?.static_image
            ? renderLinkImage(nftData?.image)
            : nftData?.image
        }
        className="item-image modal-image"
      />
    );
  };

  const onSubmitForm = (values) => {
    setIsShowingPopup(false);
    onFinish(values);
  };

  return isShowingPopup ? (
    <CustomModal
      className="make-offer-modal general"
      footer={null}
      visible={isShowingPopup}
      onCancel={() => {
        form.resetFields();
        setIsShowingPopup(false);
        if (type === "make-offer") {
          setUpdateOffer(false);
        }
      }}
    >
      <div className="title-popup">{info.title}</div>
      <div className="wallet-box-line"></div>
      <Form initialValues={initialValues} form={form} onFinish={onSubmitForm}>
        <Row className="row-infor offer-info" gutter={38}>
          <Col xs={12} sm={10} md={10} lg={10} xl={10} className="left-column">
            <div className="image-block">{renderImage()}</div>
          </Col>
          <Col xs={24} sm={24} md={14} lg={14} xl={14} className="right-column">
            <div className="name-item offer-name text-capitalize">
              {nftData?.name}{" "}
            </div>
            {nftData?.token && (
              <div className="id-item id-modal">#{nftData?.token}</div>
            )}
            {nftData?.attributes && (
              <div className="rarity-box">
                {renderRarity(nftData?.attributes[0]?.value)}
              </div>
            )}
            {inputForm}
          </Col>
          <div className={`btn-block bottom-modal-btn`}>
            <CustomButton
              classNames="red-btn btn-item btn-popup"
              children={"Cancel"}
              shape="circle-custom"
              htmlType="button"
              onClick={() => {
                setIsShowingPopup(false);
                if (type === "make-offer") {
                  setUpdateOffer(false);
                }
                form.resetFields();
              }}
            />
            <CustomButton
              classNames="orange-btn btn-item btn-popup"
              children={`${type !== "open-box" ? t("approve") : "Open box"}`}
              shape="circle-custom"
              htmlType="submit"
            />
          </div>
        </Row>
      </Form>
    </CustomModal>
  ) : (
    <></>
  );
}

export default withContext(GeneralModal);
